import { createAsyncThunk } from '@reduxjs/toolkit'
import { baseUrl, headersDefault } from '.'

export const getProducts = createAsyncThunk(
	'subscriptions/getProducts',
	async () => {
		const response = await fetch(`${baseUrl}/api/selling-subscription/list`, {
			method: 'GET',
			headers: headersDefault(),
		})

		if (response.status !== 200)
			throw new Error(
				(response.message || (await response.json()).error) ?? 'GenericError'
			)

		return await response.json()
	}
)

export const createOrder = createAsyncThunk(
	'subscriptions/createOrder',
	async (settings) => {
		const response = await fetch(settings.create_order_url, {
			method: 'POST',
			body: JSON.stringify({
				product_id: settings.product_id,
				subscriber_id: settings.subscriber_id,
			}),
			headers: headersDefault(),
		})

		if (response.status !== 200)
			throw new Error(
				(response.message || (await response.json()).error) ?? 'GenericError'
			)

		return await response.json()
	}
)

export const cancelAtPeriodEnd = createAsyncThunk(
	'subscriptions/cancelAtPeriodEnd',
	async ({ subscriptionId, value }) => {
		const response = await fetch(
			`${baseUrl}/api/master-club-subscriptions/${subscriptionId}`,
			{
				method: 'PATCH',
				body: JSON.stringify({ cancel_at_period_end: value }),
				headers: headersDefault(),
			}
		)

		if (response.status !== 200)
			throw new Error(
				(response.message || (await response.json()).error) ?? 'GenericError'
			)

		return await response.json()
	}
)

export const cancelFreeSubscription = createAsyncThunk(
	'subscriptions/cancelFreeSubscription',
	async () => {
		const response = await fetch(`${baseUrl}/api/master-club-subscriptions`, {
			method: 'POST',
			body: JSON.stringify({
				cancel_free_subscription: true,
			}),
			headers: headersDefault(),
		})

		if (response.status !== 200)
			throw new Error(
				(response.message || (await response.json()).error) ?? 'GenericError'
			)

		return await response.json()
	}
)

export const updatePaymentMethod = createAsyncThunk(
	'subscriptions/updatePaymentMethod',
	async () => {
		const response = await fetch(`${baseUrl}/api/payment/update-method`, {
			method: 'GET',
			headers: headersDefault(),
		})

		if (response.status !== 200)
			throw new Error(
				(response.message || (await response.json()).error) ?? 'GenericError'
			)

		return await response.json()
	}
)
