import {
	IonPage,
	IonContent,
	IonText,
	IonImg,
	IonButton,
	useIonLoading,
} from '@ionic/react'
import InitialConfigHeader from '../header'
import HandsClapping from '../../../assets/images/hands-clapping.svg'
import '../InitialConfiguration.min.css'
import { useEffect, useState } from 'react'
import config from '../../../settings/config'
import { useDispatch, useSelector } from 'react-redux'

import { useHistory } from 'react-router-dom'
import { setAdoc_p } from '../../../redux/reducer/userSlice'
import ActivateNotifications from '../../../components/modals/NotificationsModal/ActivateNotifications'

const WeHaveFinished = ({ step, totalSteps }) => {
	const history = useHistory()
	const dispatch = useDispatch()
	const [isLoading, setIsLoading] = useState(false)
	const initialConfig = useSelector((state) => state.initialConfig)
	const [error, setError] = useState(false)
	const [present, dismiss] = useIonLoading()
	const [showNotificationsModal, setShowNotificationsModal] = useState(false)
	const [savedUser, setSavedUser] = useState(null)
	const [notificationSystemAccepted, setNotificationSystemAccepted] =
		useState(null)

	useEffect(() => {
		present({
			message: 'Caricando informazioni...',
		})
		setIsLoading(true)
		updateUserInfo()
	}, [])

	const updateUserInfo = async () => {
		const backUrl = config.env[config.env.mode].url
		const headers = {
			Authorization: `Bearer ${localStorage.getItem('adoc_t')}`,
			'Content-Type': 'application/json',
			Accept: 'application/json',
		}
		const {
			name,
			surname,
			telephone,
			avatar,
			nickname,
			vegetarian,
			gluten_free,
			extras,
			sex,
			initial_weight,
			height,
			birth_date,
			province_id,
			city_id,
		} = initialConfig

		const {
			goals,
			weight_goal,
			abdominal_circumference,
			hip_circumference,
			waist_circumference,
			physical_impediments,
			sleep,
			intestine,
			cooking,
			eating_out,
			hobbies,
		} = extras
		const body1 = JSON.stringify({
			name,
			surname,
			telephone,
			...(avatar !== undefined &&
				avatar !== null &&
				avatar !== '' && { avatar }),
		})

		const body2 = JSON.stringify({
			sex,
			vegetarian,
			gluten_free,
			keep_weight: false,
			initial_weight,
			height,
			birth_date,
			province_id,
			city_id,
			extras: {
				goals,
				...(weight_goal !== undefined &&
					weight_goal !== null && { weight_goal }),
				...(abdominal_circumference !== undefined &&
					abdominal_circumference !== null && { abdominal_circumference }),
				...(hip_circumference !== undefined &&
					hip_circumference !== null && { hip_circumference }),
				...(waist_circumference !== undefined &&
					waist_circumference !== null && { waist_circumference }),
				...(physical_impediments !== undefined &&
					physical_impediments !== null &&
					physical_impediments.length !== 0 && { physical_impediments }),
				...(sleep !== undefined && sleep !== null && sleep !== '' && { sleep }),
				...(intestine !== undefined &&
					intestine !== null &&
					intestine !== '' && { intestine }),
				...(cooking !== undefined &&
					cooking !== null &&
					cooking !== '' && { cooking }),
				...(eating_out !== undefined &&
					eating_out !== null &&
					eating_out !== '' && { eating_out }),
				...(hobbies !== undefined &&
					hobbies !== null &&
					hobbies !== '' && { hobbies }),
			},
			...(nickname !== undefined &&
				nickname !== null &&
				nickname !== '' && { nickname }),
		})

		try {
			const response1 = await fetch(`${backUrl}/api/profilo-utenti`, {
				method: 'POST',
				body: body1,
				headers,
			})
			if (!response1.ok) throw new Error('Errore')
			const data1 = await response1.json()

			const response2 = await fetch(
				`${backUrl}/api/master-club-subscribers/1`,
				{
					method: 'PATCH',
					body: body2,
					headers,
				}
			)
			if (!response2.ok) throw new Error('Errore')
			const data2 = await response2.json()

			if (
				data1.message === 'Succefully updated' &&
				data2.message === 'Succefully updated'
			) {
				const response3 = await fetch(`${backUrl}/api/profilo-utenti/1/fetch`, {
					headers,
				})
				if (!response3.ok) throw new Error('Errore')
				const data3 = await response3.json()
				setSavedUser(data3)
				setIsLoading(false)
				dismiss()
				setError(false)
			} else {
				setIsLoading(false)
				dismiss()
				setError('Si è verificato un problema, riprova più tardi.')
			}
		} catch (e) {
			setIsLoading(false)
			dismiss()
			setError('Si è verificato un problema, riprova più tardi.')
		}
	}

	useEffect(() => {
		if (savedUser && !showNotificationsModal) {
			localStorage.setItem('adoc_p', JSON.stringify(savedUser))
			dispatch(setAdoc_p(savedUser))
			history.push('/home', {
				firstLogin: true,
				// showNotificationsModal: true,
			})
		}
	}, [showNotificationsModal, notificationSystemAccepted])

	return (
		<IonPage className="initial-configuration last-step">
			<InitialConfigHeader
				step={step}
				totalSteps={totalSteps}
				isLoading={isLoading}
			/>

			<IonContent>
				<IonImg
					alt={'Hands clapping'}
					src={HandsClapping}
				/>

				<IonText className="error">{error}</IonText>
				{savedUser?.master_club_subscriber?.subscription?.subscription_type
					?.name === 'premium' ? (
					<>
						<p>
							<b>
								<span className="capitalize-first-letter">
									{initialConfig.name}
								</span>
								, benvenut
								{initialConfig.sex === 'male' ? 'o' : 'a'} su Dieta Social App,
								il social network del benessere.
							</b>
						</p>

						<p>
							Come utente abbonato <b>Premium</b>, potrai accedere a:
						</p>

						<ul>
							<li>
								<b>Menu e Ricette:</b> Accedi a piani alimentari giornalieri e
								una vasta gamma di ricette, perfette per ogni esigenza e gusto,
								siano esse onnivore o vegetariane.
							</li>
							<li>
								<b>Lezioni degli Esperti:</b> Espandi la tua conoscenza con
								video lezioni complete su nutrizione e benessere, disponibili
								solo per te.
							</li>
							<li>
								<b>Community esclusive:</b> Interagisci nel Club Dieta Social e
								altre community tematiche, condividendo e imparando dagli altri
								membri premium.
							</li>
						</ul>

						<p>
							Utilizza il Diario Personale per tracciare progressi e benessere.
							La tua app Dieta Social è la tua compagna di viaggio verso una
							vita più sana.
						</p>

						<p>Grazie per essere un utente Premium.</p>
					</>
				) : (
					<>
						<p>
							<b>
								<span className="capitalize-first-letter">
									{initialConfig.name}
								</span>
								, benvenut
								{initialConfig.sex === 'male' ? 'o' : 'a'} su Dieta Social App,
								il social network del benessere.
							</b>
							<br /> Qui trovi ricette salutari, menù giornalieri, consigli di
							esperti e una community motivante!
						</p>

						<p>Esplora le opzioni di accesso:</p>

						<ul>
							<li>
								<b>Guest:</b> Visualizza contenuti selezionati.
							</li>
							<li>
								<b>Free:</b> Accedi gratuitamente a percorsi di rieducazione
								alimentare, community e selezioni di ricette e lezioni.
							</li>
							<li>
								<b>Premium:</b> Con l’abbonamento, scopri tutto: menu
								personalizzati, migliaia di ricette, lezioni approfondite e
								accesso completo alle community tematiche, incluso il Club Dieta
								Social.
							</li>
						</ul>

						<p style={{ marginBottom: '4em' }}>
							Inizia il tuo viaggio verso una vita più sana con Dieta Social!
						</p>
					</>
				)}

				<div className="buttons">
					<IonButton
						color="blue"
						disabled={isLoading || error}
						shape="round"
						onClick={() => setShowNotificationsModal(true)}
						expand="full">
						Esplora!
					</IonButton>
				</div>
				<ActivateNotifications
					firstTime={true}
					showNotificationsModal={showNotificationsModal}
					setShowNotificationsModal={setShowNotificationsModal}
					setNotificationSystemAccepted={setNotificationSystemAccepted}
				/>
			</IonContent>
		</IonPage>
	)
}

export default WeHaveFinished
