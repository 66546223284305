import { IonPage, IonContent, IonText, IonImg, IonButton } from '@ionic/react'
import { setInitialConfigProperty } from '../../../redux/reducer/initialConfigSlice'
import InitialConfigHeader from '../header'
import { dataURLtoBlob } from '../../../services/functions'
import { useDispatch, useSelector } from 'react-redux'
import config from '../../../settings/config'
import { useState } from 'react'
import '../InitialConfiguration.min.css'
import { useHistory } from 'react-router'

const maxSizeBites = 8388800

const ImageUploadedPage = ({ step, totalSteps, setPageSelected }) => {
	const history = useHistory()
	const avatarFile = useSelector((state) => state.initialConfig.avatarFile)
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState('')
	const dispatch = useDispatch()

	const updateInput = (e) => {
		setIsLoading(true)
		let isValid = true
		if (e.target.files[0] === undefined) {
			if (error !== '') setError('')
			isValid = false
		} else if (e.target.files.length > 1) {
			setError('Devi caricare un solo file')
			isValid = false
		} else if (e.target.files[0].size > maxSizeBites) {
			setError('Il file supera la dimensione massima di 1 MB')
			isValid = false
		} else if (error !== '') {
			setError('')
		}

		if (isValid) {
			const reader = new FileReader()
			reader.onloadend = () => {
				const dataURL = reader.result
				dispatch(
					setInitialConfigProperty({ key: 'avatarFile', value: dataURL })
				)
			}
			reader.readAsDataURL(e.target.files[0])
		}

		setIsLoading(false)
	}

	const handleSubmit = () => {
		setIsLoading(true)

		const blob = dataURLtoBlob(avatarFile)

		const body = new FormData()
		body.append('user-avatars', blob)

		fetch(`${config.env[config.env.mode].url}/api/file-upload/user-avatars`, {
			body,
			method: 'POST',
			headers: {
				Authorization: `Bearer ${localStorage.getItem('adoc_t')}`,
			},
		})
			.then((response) => {
				if (!response.ok) throw new Error(response.message)
				return response.json()
			})
			.then((data) => {
				dispatch(
					setInitialConfigProperty({ key: 'avatar', value: data.filename })
				)
				history.push(`/impostazioni-iniziali/${step + 1}`)
				setIsLoading(false)
			})
			.catch((e) => {
				setError('Si è verificato un problema, riprova più tardi.')
				setIsLoading(false)
			})
	}
	return (
		<IonPage className="initial-configuration">
			<InitialConfigHeader
				step={step}
				totalSteps={totalSteps}
				isLoading={isLoading}
				setPageSelected={setPageSelected}
				pagesBack={1}
			/>

			<IonContent>
				<h1>Foto inserita</h1>
				<h6>
					Deciderai in un secondo momento se la vorrai rendere visibile agli
					altri
				</h6>

				<IonImg
					className="uploaded-avatar rounded-ion-img"
					src={avatarFile}
				/>
				<input
					id="image_uploads"
					onChange={updateInput}
					accept="image/png,image/jpeg"
					type="file"
					className="d-false"
				/>

				<IonText className="error">{error}</IonText>

				<div className="buttons">
					<IonButton
						color="blue"
						shape="round"
						onClick={() => document.getElementById('image_uploads').click()}
						disabled={isLoading}
						expand="full">
						SELEZIONA ALTRO FILE
					</IonButton>
					<IonButton
						shape="round"
						onClick={handleSubmit}
						disabled={isLoading}
						expand="full">
						AVANTI
					</IonButton>
				</div>
			</IonContent>
		</IonPage>
	)
}

export default ImageUploadedPage
