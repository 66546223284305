import { createSlice } from '@reduxjs/toolkit'
import { getHomeGroupPosts, likeHomePost } from '../actions'

const segment = is_fixed => {
  return is_fixed ? 'fixed' : 'general'
}
const initialState = {
  getHomeGroupPosts: {
    fixed: {
      isLoading: false,
      error: null,
      data: null
    },
    general: {
      isLoading: false,
      error: null,
      data: null
    }
  }
}

const homeGroupSlice = createSlice({
  name: 'homeGroup',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getHomeGroupPosts.pending, (state, action) => {
      state.getHomeGroupPosts[segment(action.meta.arg.is_fixed)].isLoading = true
      state.getHomeGroupPosts[segment(action.meta.arg.is_fixed)].error = null
    })
    builder.addCase(getHomeGroupPosts.fulfilled, (state, action) => {
      state.getHomeGroupPosts[segment(action.meta.arg.is_fixed)].isLoading = false
      state.getHomeGroupPosts[segment(action.meta.arg.is_fixed)].error = null
      state.getHomeGroupPosts[segment(action.meta.arg.is_fixed)].data = action.payload
    })
    builder.addCase(getHomeGroupPosts.rejected, (state, action) => {
      state.getHomeGroupPosts[segment(action.meta.arg.is_fixed)].isLoading = false
      state.getHomeGroupPosts[segment(action.meta.arg.is_fixed)].error = action.error.message
      state.getHomeGroupPosts[segment(action.meta.arg.is_fixed)].data = initialState.getHomeGroupPosts[segment(action.meta.arg.is_fixed)].data
    })
    builder.addCase(likeHomePost.pending, (state, action) => {
      const postIndex = state.getHomeGroupPosts[segment(action.meta.arg.is_fixed)].data.posts.findIndex(post => post.id === action.meta.arg.element_id)
      const likedOldValue = state.getHomeGroupPosts[segment(action.meta.arg.is_fixed)].data.posts[postIndex].liked
      state.getHomeGroupPosts[segment(action.meta.arg.is_fixed)].data.posts[postIndex].liked = !likedOldValue
      if (likedOldValue) {
        state.getHomeGroupPosts[segment(action.meta.arg.is_fixed)].data.posts[postIndex].likes_count--
      } else {
        state.getHomeGroupPosts[segment(action.meta.arg.is_fixed)].data.posts[postIndex].likes_count++
      }
    })
    builder.addCase(likeHomePost.fulfilled, (state, action) => {})
    builder.addCase(likeHomePost.rejected, (state, action) => {
      const postIndex = state.getHomeGroupPosts[segment(action.meta.arg.is_fixed)].data.posts.findIndex(post => post.id === action.meta.arg.element_id)
      const likedOldValue = state.getHomeGroupPosts[segment(action.meta.arg.is_fixed)].data.posts[postIndex].liked
      state.getHomeGroupPosts[segment(action.meta.arg.is_fixed)].data.posts[postIndex].liked = !likedOldValue
      if (likedOldValue) {
        state.getHomeGroupPosts[segment(action.meta.arg.is_fixed)].data.posts[postIndex].likes_count--
      } else {
        state.getHomeGroupPosts[segment(action.meta.arg.is_fixed)].data.posts[postIndex].likes_count++
      }
    })
  }
})

export default homeGroupSlice.reducer
