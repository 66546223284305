const CourseAlert = (action) => {
	return {
		header: '🔒 Sblocca i contenuti',
		message:
			'Per accedere al menù del giorno, è necessario avanzare nel tuo percorso formativo. Ogni lezione ti avvicina un passo in più alla comprensione di una sana alimentazione. Completa le lezioni previste per sbloccare questo contenuto!',
		buttons: [
			{
				text: 'OK ANDIAMO!',
				cssClass: 'ion-button blue',
				handler: () => action(),
			},
		],
	}
}

export default CourseAlert
