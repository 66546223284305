import { createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'

const initialState = {
	data: {
		date: dayjs().format('YYYY-MM-DD'),
		weight: '0.00',
		neck_circumference: '0.00',
		umbilical_circumference: '0.00',
		hips_circumference: '0.00',
		middle_thigh_circumference: '0.00',
		arm_circumference: '0.00',
	},
}

const diarySlice = createSlice({
	name: 'diary',
	initialState,
	reducers: {
		resetDiaryState: (state, action) => {
			state.data = {
				...initialState.data,
				date: action.payload,
			}
		},
		updateMeasure: (state, action) => {
			state.data[action.payload.key] = action.payload.value
		},
		updateMeasures: (state, action) => {
			state.data = action.payload
		},
	},
})

export const { resetDiaryState, updateMeasure, updateMeasures } =
	diarySlice.actions
export default diarySlice.reducer
