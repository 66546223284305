import {
	IonContent,
	IonPage,
	IonButton,
	IonRange,
	IonImg,
	IonToast,
	useIonToast,
} from '@ionic/react'
import images from '../../assets/images/Measures/full'
import './DiaryMeasure.min.css'
import MainHeader from '../../components/headers/mainHeader'
import { useDispatch, useSelector } from 'react-redux'
import { updateMeasure } from '../../redux/reducer/diarySlice'
import { useState } from 'react'
import config from '../../settings/config'
import measures from '../../settings/measuresConfig'
import { useHistory } from 'react-router'

const DiaryMeasurePage = ({ match }) => {
	const history = useHistory()

	const { measureSlug } = match.params
	const measureIndex = measures.findIndex(
		(measure) => measure.slug === measureSlug
	)

	const gender =
		useSelector((state) => state.user?.master_club_subscriber?.sex) ?? 'male'

	let measuresValues = useSelector((state) => state.diary.data)
	const originalValue =
		(measuresValues && measuresValues[measures[measureIndex].value]) === '0.00'
			? measures[measureIndex].initialValue
			: measuresValues && measuresValues[measures[measureIndex].value]

	const [value, setValue] = useState(parseFloat(originalValue))
	const [toastIsOpen, setToastIsOpen] = useState(false)
	const dispatch = useDispatch()
	const { telephone } = useSelector((state) => state.user.user)
	const [ionToast] = useIonToast()

	const handleDecrease = () => {
		setValue(value - measures[measureIndex].step)
	}

	const handleIncrease = () => {
		setValue(value + measures[measureIndex].step)
	}

	return (
		<>
			<IonPage className="diary-measure-page">
				<MainHeader
					title="Il mio diario"
					href="/diario"
					page="DiaryMeasurePage"
				/>
				<IonContent class="ion-padding">
					<h1 className="measure-title">{measures[measureIndex].title}</h1>
					<IonRange
						min={measures[measureIndex].min}
						max={measures[measureIndex].max}
						step={measures[measureIndex].step}
						pinFormatter={(value) =>
							value.toFixed(2).replace('.', ',') +
							' ' +
							measures[measureIndex].suffix
						}
						pin={true}
						className="measure-range"
						value={value}
						onIonChange={(e) => setValue(e.target.value)}
						onIonDrag={(e) => setValue(e.detail.value)}
					/>
					<div className="measure-buttons">
						<IonButton
							shape="round"
							onClick={handleDecrease}
							color="primary">
							-
						</IonButton>
						<IonButton
							shape="round"
							onClick={handleIncrease}
							color="primary">
							+
						</IonButton>
					</div>
					<IonImg
						src={images[gender][measureIndex]}
						className="image"
					/>
					<IonButton
						onClick={() => {
							if (!telephone)
								return ionToast({
									message:
										'Per interagire verifica il tuo cellulare nel tuo profilo',
									duration: 3000,
								})
							const newMeasures = { ...measuresValues }
							newMeasures[measures[measureIndex].value] = value
							fetch(
								`${
									config.env[config.env.mode].url
								}/api/master-club-measure-diary`,
								{
									method: 'POST',
									body: JSON.stringify(newMeasures),
									headers: {
										Accept: 'application/json',
										'Content-Type': 'application/json',
										Authorization: `Bearer ${localStorage.getItem('adoc_t')}`,
									},
								}
							)
								.then((response) => {
									if (!response.ok) throw new Error('post diaryMeasure error')
									return response.json()
								})
								.then(() => {
									dispatch(
										updateMeasure({
											key: measures[measureIndex].value,
											value,
										})
									)

									history.push('/diario', 'backward', 'push')
								})
								.catch(() => {
									setToastIsOpen(true)
								})
						}}
						className="save-button"
						disabled={originalValue === value}
						shape="round"
						color="primary"
						expand="full">
						Salva
					</IonButton>
				</IonContent>
			</IonPage>
			<IonToast
				isOpen={toastIsOpen}
				message="Ops! C'è stato un errore. Riprova più tardi."
				onDidDismiss={() => setToastIsOpen(false)}
				duration={3000}
			/>
		</>
	)
}

export default DiaryMeasurePage
