import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { userAuthStatus } from '../../services/RouteAuth'
import dayjs from 'dayjs'
import { clearCache } from '../../services/functions'
import { checkPushSubscription } from '../../serviceWorkerRegistration'

const RedirectToHome = () => {
	const userStatus = userAuthStatus()
	if (userStatus !== 'full') return
	const history = useHistory()
	// const subscription_type = useSelector(
	// 	(state) =>
	// 		state.user?.master_club_subscriber?.subscription?.subscription_type
	// )
	// const subscriptionName = subscription_type?.name ?? null

	const [exitTime, setExitTime] = useState(dayjs())
	const [startTime, setStartTime] = useState(dayjs())

	useEffect(() => {
		document.addEventListener('visibilitychange', (event) => {
			if (document.visibilityState === 'hidden') {
				setExitTime(dayjs())
			}
			if (document.visibilityState === 'visible') {
				checkPushSubscription()
				setStartTime(dayjs())
			}
		})
	}, [])

	useEffect(() => {
		// console.log('exitTime', exitTime.format('YYYY-MM-DD HH:mm:ss'))
		// console.log('startTime', startTime.format('YYYY-MM-DD HH:mm:ss'))
		const diffInMinutes = Math.abs(startTime.diff(exitTime, 'minutes'))
		const diffInSeconds = Math.abs(startTime.diff(exitTime, 'seconds'))

		console.log('diffInSeconds', diffInSeconds)
		console.log('diffInMinutes', diffInMinutes)

		if (diffInMinutes > 4) {
			if (window.location.pathname !== '/home') {
				history.push('/home', {
					showNotificationsModal: true,
				})
			}
			clearCache()
		} else if (diffInSeconds > 5) {
			clearCache()
			// window.location.reload()
		}
	}, [startTime, exitTime])
}

export default RedirectToHome
