import notificationsSlice from './NotificationsSlice'
import diarySlice from './diarySlice'
import dietPlanSlice from './dietPlanSlice'
import homeGroupSlice from './homeGroupsSlice'
import initialConfigSlice from './initialConfigSlice'
import pushSettingsSlice from './pushSettingsSlice'
import toastSlice from './toastSlice'
import userSlice from './userSlice'
import courseSlice from './courseSlice'
import groupSlice from './groupSlice'
import groupsSlice from './groupsSlice'
import multimediaGroupsSlice from './multimediaGroupsSlice'
import menuDaySlice from './menuDaySlice'

const rootReducer = {
	user: userSlice,
	diary: diarySlice,
	toast: toastSlice,
	course: courseSlice,
	group: groupSlice,
	groups: groupsSlice,
	dietPlan: dietPlanSlice,
	homeGroup: homeGroupSlice,
	pushSettings: pushSettingsSlice,
	notifications: notificationsSlice,
	initialConfig: initialConfigSlice,
	multimediaGroups: multimediaGroupsSlice,
	menuDay: menuDaySlice,
}

export default rootReducer
