import { IonAvatar, IonBadge, IonItem, IonText } from '@ionic/react'
import { ImageLoader } from '../../ImageLoader'
import avatars from '../../../assets/avatars'
import { Icon } from '@iconify/react'
import dsPictogram from '../../../assets/images/ds-pictogram.svg'

export default function PostHeader({ postGroup = null, comment = null }) {
	const element = postGroup ? postGroup.post : comment
	const created_by = element.created_by

	const is_enabled = postGroup?.status?.name === 'Pubblicato'
	const appear_as_dieta_social = element.appear_as_dieta_social
	const avatarImage = appear_as_dieta_social
		? dsPictogram
		: created_by.avatar?.startsWith('avatar')
		? avatars.avatarsByNumber[created_by.avatar]
		: created_by.avatar
		? `${created_by.storage_path}${created_by.avatar}`
		: null
	const posted_by = created_by.fullname

	const alias = !appear_as_dieta_social
		? created_by?.master_club_subscriber?.alias
		: ''
	const badge = !appear_as_dieta_social
		? created_by?.master_club_subscriber?.badge
		: null
	const yearsAsSubscriber = !appear_as_dieta_social
		? parseInt(created_by?.master_club_subscriber?.days_as_subscriber / 364)
		: 0

	return (
		<>
			<div className="ion-padding-horizontal post-header">
				<IonItem
					lines="full"
					className="no-padding-item align-items-start">
					<IonAvatar slot="start">
						<ImageLoader
							imageType="user"
							image={avatarImage}
						/>
					</IonAvatar>
					<div className="user-info">
						<div className="top">
							<b className="name">
								{appear_as_dieta_social ? 'Dieta Social' : posted_by}{' '}
							</b>
							<span className="badges">
								{badge && <IonBadge color={badge.color}>{badge.name}</IonBadge>}
								{!!yearsAsSubscriber &&
									!badge &&
									[...Array(yearsAsSubscriber)].map((_, i) => {
										return (
											<Icon
												key={i}
												icon="fluent-emoji:1st-place-medal"
											/>
										)
									})}
							</span>
						</div>
						<div className="bottom">
							<IonText
								color="medium"
								className="alias">
								{!appear_as_dieta_social && alias}
							</IonText>
						</div>
						<div className="bottom ion-margin-bottom">
							<b className="waiting-approval">
								{!comment && !is_enabled ? ' (In attesa di approvazione)' : ''}
							</b>
						</div>
					</div>
				</IonItem>
			</div>
		</>
	)
}
