import avatar01 from './avatar-01.jpg'
import avatar02 from './avatar-02.jpg'
import avatar03 from './avatar-03.jpg'
import avatar04 from './avatar-04.jpg'
import avatar05 from './avatar-05.jpg'
import avatar06 from './avatar-06.jpg'
import avatar07 from './avatar-07.jpg'
import avatar08 from './avatar-08.jpg'
import avatar09 from './avatar-09.jpg'
import avatar10 from './avatar-10.jpg'
import avatar11 from './avatar-11.jpg'
import avatar12 from './avatar-12.jpg'
import avatar13 from './avatar-13.jpg'
import avatar14 from './avatar-14.jpg'
import avatar15 from './avatar-15.jpg'
import avatar16 from './avatar-16.jpg'

const avatars = {
	male: [
		{ name: 'avatar01', image: avatar01 },
		{ name: 'avatar03', image: avatar03 },
		{ name: 'avatar05', image: avatar05 },
		{ name: 'avatar07', image: avatar07 },
		{ name: 'avatar08', image: avatar08 },
		{ name: 'avatar09', image: avatar09 },
		{ name: 'avatar11', image: avatar11 },
		{ name: 'avatar12', image: avatar12 },
		{ name: 'avatar13', image: avatar13 },
		{ name: 'avatar14', image: avatar14 },
		{ name: 'avatar15', image: avatar15 },
	],
	female: [
		{ name: 'avatar02', image: avatar02 },
		{ name: 'avatar04', image: avatar04 },
		{ name: 'avatar06', image: avatar06 },
		{ name: 'avatar10', image: avatar10 },
		{ name: 'avatar16', image: avatar16 },
	],
	avatarsByNumber: {
		avatar01,
		avatar02,
		avatar03,
		avatar04,
		avatar05,
		avatar06,
		avatar07,
		avatar08,
		avatar09,
		avatar10,
		avatar11,
		avatar12,
		avatar13,
		avatar14,
		avatar15,
		avatar16,
	},
}

export default avatars
