import { IonItem, IonList } from '@ionic/react'
import './Recipe.min.css'

const Procedure = (props) => {
	return (
		<IonList>
			{props?.recipeData?.steps.map((item, index) => (
				<IonItem
					lines={index === props.recipeData.steps - 1 ? 'none' : 'full'}
					key={index}
					className="item-procedure-recipe">
					{index + 1}. {item.procedure}
				</IonItem>
			))}
		</IonList>
	)
}

export default Procedure
