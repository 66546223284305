import Post from '../../Post'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { getGroupPosts } from '../../../redux/actions/groupsActions'
import {
	selectGroupSlice,
	selectSegmentGroupSlice,
} from '../../../redux/reducer/groupsSlice'
import ErrorToast from '../../ErrorToast'
import {
	IonList,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	useIonViewDidEnter,
} from '@ionic/react'
import { PostSkeleton } from '../../skeletons/PostSkeleton'
import { NoRecords } from '../../NoRecords'

const LoadingRender = () => (
	<IonList>
		<PostSkeleton />
		<PostSkeleton />
	</IonList>
)

const ErrorRender = () => <ErrorToast />

const DataRender = ({ data, from_main_group }) => {
	const user = useSelector((state) => state.user.user)
	return (
		<IonList>
			{data?.posts.length ? (
				data?.posts.map((postGroup) => (
					<Post
						key={postGroup.id}
						is_preview={true}
						postGroup={postGroup}
						from_main_group={from_main_group}
					/>
				))
			) : (
				<NoRecords />
			)}
		</IonList>
	)
}

const GroupSegment = ({
	is_fixed,
	from_main_group,
	group_id,
	firstLoaded,
	setFirstLoaded,
}) => {
	const dispatch = useDispatch()

	const [page, setPage] = useState(1)
	const [pageFixed, setPageFixed] = useState(1)
	const [allData, setAllData] = useState({ posts: [] })
	const [allDataFixed, setAllDataFixed] = useState({ posts: [] })
	const [allLoaded, setAllLoaded] = useState(false)
	const [allLoadedFixed, setAllLoadedFixed] = useState(false)

	const { data, isLoading, error } = useSelector((state) => {
		const groupSelected = selectGroupSlice(from_main_group)
		const segmentSelected = selectSegmentGroupSlice(is_fixed)
		return state.groups.getGroupPosts[groupSelected][segmentSelected]
	})

	const loadData = (event = null) => {
		// console.table({
		// 	group_id,
		// 	firstLoaded,
		// 	is_fixed,
		// 	page,
		// 	pageFixed,
		// 	from_main_group,
		// })
		dispatch(
			getGroupPosts({
				is_fixed,
				page: is_fixed ? pageFixed : page,
				from_main_group,
				group_id,
			})
		).then((response) => {
			setFirstLoaded(true)
			if (response && response?.payload?.posts) {
				if (is_fixed) {
					setAllDataFixed({
						...allDataFixed,
						posts: [...allDataFixed.posts, ...response.payload.posts],
					})
					if (!response.payload.posts.length) setAllLoadedFixed(true)
					setPageFixed(pageFixed + 1)
				} else {
					setAllData({
						...allData,
						posts: [...allData.posts, ...response.payload.posts],
					})
					if (!response.payload.posts.length) setAllLoaded(true)
					setPage(page + 1)
				}
			}
			event && event.target.complete()
		})
	}

	// console.log('firstLoaded', firstLoaded)
	if (!firstLoaded && !isLoading && is_fixed) {
		// console.log('loadData')
		loadData()
	}

	useEffect(() => {
		// console.log('useEffect FIRST LOAD')
		loadData()
	}, [])

	const firstLoading =
		isLoading && ((is_fixed && pageFixed === 1) || page === 1)

	const showInfiniteScroll =
		(is_fixed && !allLoadedFixed) || (!is_fixed && !allLoaded)
	return (
		<>
			{!firstLoading && !error && (
				<>
					<DataRender
						data={is_fixed ? allDataFixed : allData}
						from_main_group={from_main_group}
					/>
					{showInfiniteScroll && (
						<IonInfiniteScroll
							onIonInfinite={(e) => loadData(e)}
							threshold="100px">
							<IonInfiniteScrollContent loadingText="Caricando altri posts..."></IonInfiniteScrollContent>
						</IonInfiniteScroll>
					)}
				</>
			)}
			{isLoading && !error && <LoadingRender />}
			{error && <ErrorRender />}
		</>
	)
}

export const SegmentGeneral = ({
	is_fixed,
	from_main_group = true,
	group_id = null,
	firstLoaded,
	setFirstLoaded,
}) => {
	return (
		<GroupSegment
			is_fixed={is_fixed}
			from_main_group={from_main_group}
			group_id={group_id}
			firstLoaded={firstLoaded}
			setFirstLoaded={setFirstLoaded}
		/>
	)
}
