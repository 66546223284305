import {
	IonInput,
	IonItem,
	IonModal,
	IonSelect,
	IonText,
	IonAlert,
	IonSelectOption,
	IonDatetimeButton,
	IonLabel,
	IonDatetime,
	IonToast,
	IonIcon,
	IonButton,
	IonGrid,
	IonRow,
	IonCol,
	IonImg,
	useIonLoading,
} from '@ionic/react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserInfo, headersDefault } from '../../redux/actions'
import {
	areArraysEquals,
	getMinimumLegalAgeDate,
	isValidGender,
	isValidName,
	isValidNickname,
} from '../../services/functions'
import config from '../../settings/config'
import { updateUserProperty } from '../../redux/reducer/userSlice'
import { happy } from 'ionicons/icons'
import avatars from '../../assets/avatars'
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import PhoneInput from '../../components/PhoneInput'
import 'react-phone-number-input/style.css'
import DietTypeInput from '../../components/Profile/DietTypeInput'

const backUrl = config.env[config.env.mode].url
let headers = {}

const NameInput = ({ setToastIsOpen }) => {
	const dispatch = useDispatch()
	const user = useSelector((state) => state.user?.user) ?? {}
	const { name = '', surname = '', telephone = '' } = user
	const [newName, setNewName] = useState(name)
	const updateInput = (e) => {
		const value = e.target.value
		if (!value || value === name) return
		if (value === '' || !isValidName(value)) {
			setToastIsOpen('Nome non valido')
			setNewName(name)
			return
		}

		const body1 = JSON.stringify({
			telephone,
			name: value,
			surname,
		})
		fetch(`${backUrl}/api/profilo-utenti`, {
			method: 'POST',
			body: body1,
			headers,
		})
			.then((response) => {
				if (!response.ok) throw new Error('fetch error')
				return response.json()
			})
			.then((data) => {
				dispatch(updateUserProperty({ stateKey: 'user', key: 'name', value }))
			})
			.catch((e) => setToastIsOpen())
	}
	return (
		<IonInput
			onIonBlur={updateInput}
			color="primary"
			label="Nome"
			placeholder={name}
			value={newName}
			onIonInput={(e) => setNewName(e.target.value)}
			labelPlacement="stacked"
		/>
	)
}

const SurnameInput = ({ setToastIsOpen }) => {
	const dispatch = useDispatch()
	const user = useSelector((state) => state.user?.user) ?? {}
	const { name = '', surname = '', telephone = '' } = user
	const [newSurname, setNewSurname] = useState(surname)

	const updateInput = (e) => {
		const value = e.target.value
		if (!value || value === surname) return
		if (value === '' || !isValidName(value)) {
			setNewSurname(surname)
			setToastIsOpen('Cognome non valido')
			return
		}

		const body1 = JSON.stringify({
			telephone,
			name,
			surname: value,
		})
		fetch(`${backUrl}/api/profilo-utenti`, {
			method: 'POST',
			body: body1,
			headers,
		})
			.then((response) => {
				if (!response.ok) throw new Error('fetch error')
				return response.json()
			})
			.then((data) => {
				dispatch(
					updateUserProperty({ stateKey: 'user', key: 'surname', value })
				)
			})
			.catch((e) => setToastIsOpen())
	}
	return (
		<IonInput
			onIonBlur={updateInput}
			label="Cognome"
			color="primary"
			placeholder={surname}
			value={newSurname}
			onIonInput={(e) => setNewSurname(e.target.value)}
			labelPlacement="stacked"
		/>
	)
}

const GenderInput = ({ setToastIsOpen }) => {
	const dispatch = useDispatch()
	const gender =
		useSelector((state) => state.user.master_club_subscriber?.sex) ?? ''

	const updateInput = (e) => {
		const value = e.target.value
		if (!isValidGender(value) || value === gender) return

		const body = JSON.stringify({
			sex: value,
		})
		fetch(`${backUrl}/api/master-club-subscribers/1`, {
			method: 'PATCH',
			body,
			headers,
		})
			.then((response) => {
				if (!response.ok) throw new Error('fetch error')
				return response.json()
			})
			.then((data) => {
				dispatch(
					updateUserProperty({
						stateKey: 'master_club_subscriber',
						key: 'sex',
						value,
					})
				)
			})
			.catch((e) => setToastIsOpen())
	}

	return (
		<IonItem className="no-padding-item">
			<IonSelect
				cancelText="Cancella"
				onIonChange={updateInput}
				label="Sesso"
				value={gender}
				placeholder="Selezionare"
				labelPlacement="stacked">
				<IonSelectOption value="male">Maschio</IonSelectOption>
				<IonSelectOption value="female">Femmina</IonSelectOption>
			</IonSelect>
		</IonItem>
	)
}

const goalsOptions = [
	'Dimagrire facilmente',
	'Mantenermi in salute',
	'Imparare a mangiare',
	'Migliorare le mie analisi',
]

const GoalsInput = ({ setToastIsOpen }) => {
	const dispatch = useDispatch()
	const extras =
		useSelector((state) => state.user.master_club_subscriber?.extras) ?? {}
	const goals = extras.goals ?? ''
	const [newGoals, setNewGoals] = useState(goals)

	const updateInput = (value) => {
		if (!value.every((option) => goalsOptions.includes(option))) {
			setNewGoals(goals)
			return
		}

		if (areArraysEquals(value, goals)) return

		const body = JSON.stringify({
			extras: { ...extras, goals: value },
		})
		fetch(`${backUrl}/api/master-club-subscribers/1`, {
			method: 'PATCH',
			body,
			headers,
		})
			.then((response) => {
				if (!response.ok) throw new Error('fetch error')
				return response.json()
			})
			.then((data) => {
				dispatch(
					updateUserProperty({
						stateKey: 'master_club_subscriber',
						key: 'extras',
						value: JSON.parse(body).extras,
					})
				)
			})
			.catch((e) => setToastIsOpen())
	}

	return (
		<>
			<IonItem
				button
				detail="false"
				id="present-alert-goalsInput"
				className="no-padding-item">
				<IonSelect
					cancelText="Cancella"
					disabled="true"
					multiple={true}
					value={newGoals}
					label="Cosa ti piacerebbe ottenere?"
					placeholder="Selezionare"
					labelPlacement="stacked"
					style={{
						'--placeholder-color': 'var(--ion-color-medium)',
						opacity: 1,
					}}>
					{goalsOptions.map((goal) => (
						<IonSelectOption
							key={goal}
							value={goal}>
							{goal}
						</IonSelectOption>
					))}
				</IonSelect>
			</IonItem>
			<IonAlert
				trigger="present-alert-goalsInput"
				header="Cosa ti piacerebbe ottenere con Dieta Social?"
				inputs={goalsOptions.map((goal) => ({
					name: goal,
					type: 'checkbox',
					label: goal,
					value: goal,
					checked: newGoals.includes(goal),
					handler: (e) => {
						if (newGoals.length === 1 && !e.checked) {
							e.checked = true
							return
						}

						const _newGoals = [...newGoals]
						if (e.checked) {
							_newGoals.push(e.value)
						} else {
							_newGoals.splice(_newGoals.indexOf(e.value), 1)
						}
						setNewGoals(_newGoals)
					},
				}))}
				buttons={[
					{
						text: 'Cancella',
						role: 'cancel',
						handler: () => {
							setNewGoals(goals)
						},
					},
					{
						text: 'OK',
						role: 'confirm',
						handler: updateInput,
					},
				]}
			/>
		</>
	)
}

const WeightGoalInput = ({ setToastIsOpen }) => {
	const dispatch = useDispatch()
	const extras =
		useSelector((state) => state.user.master_club_subscriber?.extras) ?? {}
	const weightGoal = extras.weight_goal ?? null
	const [newWeightGoal, setNewWeightGoal] = useState(weightGoal)

	const updateInput = (e) => {
		const value = Number(e.target.value).toFixed(2)
		if (!e.target.value || value === weightGoal) return
		if (value < 30 || value > 250) {
			setNewWeightGoal(weightGoal)
			setToastIsOpen('Peso non valido')
			return
		}

		if (value !== newWeightGoal) setNewWeightGoal(value)

		const body = JSON.stringify({
			extras: { ...extras, weight_goal: value },
		})
		fetch(`${backUrl}/api/master-club-subscribers/1 `, {
			method: 'PATCH',
			body,
			headers,
		})
			.then((response) => {
				if (!response.ok) throw new Error('fetch error')
				return response.json()
			})
			.then((data) => {
				dispatch(
					updateUserProperty({
						stateKey: 'master_club_subscriber',
						key: 'extras',
						value: JSON.parse(body).extras,
					})
				)
			})
			.catch((e) => setToastIsOpen())
	}
	return (
		<IonInput
			value={newWeightGoal}
			onIonInput={(e) => setNewWeightGoal(e.target.value)}
			inputmode="decimal"
			color="primary"
			onIonBlur={updateInput}
			min={30}
			max={250}
			label="Obiettivo di peso"
			type="number"
			placeholder={Number(weightGoal).toFixed(2)}
			labelPlacement="stacked"
		/>
	)
}

const HeightInput = ({ setToastIsOpen }) => {
	const dispatch = useDispatch()
	const height =
		useSelector((state) => state.user?.master_club_subscriber?.height) ?? null
	const [newHeight, setNewHeight] = useState(height)

	const updateInput = (e) => {
		const value = Number(e.target.value).toFixed(0)
		if (!e.target.value || value === height) return
		if (value < 50 || value > 250) {
			setNewHeight(height)
			setToastIsOpen('Altezza non valida')
			return
		}

		if (value !== newHeight) setNewHeight(value)

		const body = JSON.stringify({ height: value })
		fetch(`${backUrl}/api/master-club-subscribers/1 `, {
			method: 'PATCH',
			body,
			headers,
		})
			.then((response) => {
				if (!response.ok) throw new Error('fetch error')
				return response.json()
			})
			.then((data) => {
				dispatch(
					updateUserProperty({
						stateKey: 'master_club_subscriber',
						key: 'height',
						value,
					})
				)
			})
			.catch((e) => setToastIsOpen())
	}
	return (
		<IonInput
			value={newHeight}
			onIonInput={(e) => setNewHeight(e.target.value)}
			inputmode="numeric"
			onIonBlur={updateInput}
			min={140}
			max={200}
			label="Altezza"
			color="primary"
			type="number"
			placeholder={height}
			labelPlacement="stacked"
		/>
	)
}

const WeightInput = ({ setToastIsOpen }) => {
	const dispatch = useDispatch()
	const weight =
		useSelector(
			(state) => state.user?.master_club_subscriber?.initial_weight
		) ?? null
	const [newWeight, setNewWeight] = useState(weight)

	const updateInput = (e) => {
		const value = Number(e.target.value).toFixed(2)
		if (!e.target.value || value === weight) return
		if (value < 30 || value > 250) {
			setNewWeight(weight)
			setToastIsOpen('Peso non valido')
			return
		}

		if (value !== newWeight) setNewWeight(value)

		const body = JSON.stringify({ initial_weight: value })
		fetch(`${backUrl}/api/master-club-subscribers/1 `, {
			method: 'PATCH',
			body,
			headers,
		})
			.then((response) => {
				if (!response.ok) throw new Error('fetch error')
				return response.json()
			})
			.then((data) => {
				dispatch(
					updateUserProperty({
						stateKey: 'master_club_subscriber',
						key: 'initial_weight',
						value,
					})
				)
			})
			.catch((e) => setToastIsOpen())
	}
	return (
		<IonInput
			value={newWeight}
			onIonInput={(e) => setNewWeight(e.target.value)}
			inputmode="decimal"
			color="primary"
			onIonBlur={updateInput}
			min={35}
			max={150}
			label="Peso"
			type="number"
			placeholder={weight}
			labelPlacement="stacked"
		/>
	)
}

const BirthDayInput = ({ setToastIsOpen }) => {
	const birth_date =
		useSelector((state) => state.user.master_club_subscriber?.birth_date) ??
		null
	const minimumLegalAgeDate = getMinimumLegalAgeDate()
	const dispatch = useDispatch()

	const updateInput = (e) => {
		const value = e.target.value.slice(0, 10)
		if (value === null || minimumLegalAgeDate < value) return

		const body = JSON.stringify({ birth_date: value })
		fetch(`${backUrl}/api/master-club-subscribers/1 `, {
			method: 'PATCH',
			body,
			headers,
		})
			.then((response) => {
				if (!response.ok) throw new Error('fetch error')
				return response.json()
			})
			.then((data) => {
				dispatch(
					updateUserProperty({
						stateKey: 'master_club_subscriber',
						key: 'birth_date',
						value,
					})
				)
			})
			.catch((e) => setToastIsOpen())
	}

	return (
		<>
			<IonItem className="no-padding-item">
				<IonInput
					label="Data di nascita"
					readonly={true}
					disabled
					className="birthdate-input"
					style={{
						opacity: 1,
					}}
				/>
				<IonDatetimeButton
					datetime="birthday"
					slot="end"
				/>
			</IonItem>
			<IonModal keepContentsMounted={true}>
				<IonDatetime
					id="birthday"
					size="cover"
					value={birth_date}
					locale="it-IT"
					onIonBlur={updateInput}
					presentation="date"
					preferWheel={true}
					displayFormat="YYYY-MM-DD"
					max={minimumLegalAgeDate}
				/>
			</IonModal>
		</>
	)
}

const physicalImpedimentsOptions = [
	'Nessuno',
	'Dolore alla schiena',
	'Dolore alle ginocchia',
	'Dolore agli arti superiori (braccia)',
	'Dolore agli arti inferiori (gambe)',
	'Mobilità molto ridotta',
]

const ImpedimentsPhysicalInput = ({ setToastIsOpen }) => {
	const dispatch = useDispatch()
	const extras =
		useSelector((state) => state.user.master_club_subscriber?.extras) ?? {}
	const physical_impediments = extras.physical_impediments ?? ''
	const [newPhysicalImpediments, setNewPhysicalImpediments] =
		useState(physical_impediments)

	const updateInput = (value) => {
		if (!value.every((option) => physicalImpedimentsOptions.includes(option))) {
			setNewPhysicalImpediments(physical_impediments)
			return
		}

		if (areArraysEquals(value, physical_impediments)) return

		const body = JSON.stringify({
			extras: { ...extras, physical_impediments: value },
		})
		fetch(`${backUrl}/api/master-club-subscribers/1`, {
			method: 'PATCH',
			body,
			headers,
		})
			.then((response) => {
				if (!response.ok) throw new Error('fetch error')
				return response.json()
			})
			.then((data) => {
				dispatch(
					updateUserProperty({
						stateKey: 'master_club_subscriber',
						key: 'extras',
						value: JSON.parse(body).extras,
					})
				)
			})
			.catch((e) => setToastIsOpen())
	}

	return (
		<>
			<IonItem
				button
				detail="false"
				id="present-alert-ImpedimentsPhysicalInput"
				className="no-padding-item">
				<IonSelect
					cancelText="Cancella"
					disabled="true"
					multiple={true}
					value={newPhysicalImpediments}
					label="Impedimenti fisici"
					placeholder="Selezionare"
					labelPlacement="stacked"
					style={{
						'--placeholder-color': 'var(--ion-color-medium)',
						opacity: 1,
					}}>
					{physicalImpedimentsOptions.map((physicalImpediment) => (
						<IonSelectOption
							key={physicalImpediment}
							value={physicalImpediment}>
							{physicalImpediment}
						</IonSelectOption>
					))}
				</IonSelect>
			</IonItem>
			<IonAlert
				trigger="present-alert-ImpedimentsPhysicalInput"
				header="Impedimenti fisici"
				inputs={physicalImpedimentsOptions.map((physicalImpediment) => ({
					name: physicalImpediment,
					type: 'checkbox',
					label: physicalImpediment,
					value: physicalImpediment,
					checked: newPhysicalImpediments.includes(physicalImpediment),
					handler: (e) => {
						let _newPhysicalImpediments = [...newPhysicalImpediments]
						if (newPhysicalImpediments.length === 1 && !e.checked) {
							e.checked = true
							return
						}

						if (e.checked) {
							if (e.value === 'Nessuno') {
								_newPhysicalImpediments = ['Nessuno']
							} else {
								if (_newPhysicalImpediments.includes('Nessuno')) {
									_newPhysicalImpediments.splice(
										_newPhysicalImpediments.indexOf('Nessuno'),
										1
									)
								}
								_newPhysicalImpediments.push(e.value)
							}
						} else {
							_newPhysicalImpediments.splice(
								_newPhysicalImpediments.indexOf(e.value),
								1
							)
						}
						setNewPhysicalImpediments(_newPhysicalImpediments)
					},
				}))}
				buttons={[
					{
						text: 'Cancella',
						role: 'cancel',
						handler: () => {
							setNewPhysicalImpediments(physical_impediments)
						},
					},
					{
						text: 'OK',
						role: 'confirm',
						handler: updateInput,
					},
				]}
			/>
		</>
	)
}

const FreeSimulate = ({ setToastIsOpen }) => {
	const simulate_free =
		useSelector((state) => state.user.master_club_subscriber?.simulate_free) ??
		null
	const dispatch = useDispatch()

	const updateInput = (value) => {
		const body = JSON.stringify({ simulate_free: value })
		fetch(`${backUrl}/api/master-club-subscribers/1 `, {
			method: 'PATCH',
			body,
			headers,
		})
			.then((response) => {
				if (!response.ok) throw new Error('fetch error')
				return response.json()
			})
			.then((data) => {
				dispatch(
					updateUserProperty({
						stateKey: 'master_club_subscriber',
						key: 'simulate_free',
						value,
					})
				)
			})
			.catch((e) => {
				console.log('error', e)
				// setToastIsOpen()
			})
	}

	return (
		<>
			<IonItem
				button
				detail="false"
				id="present-alert-ImpedimentsPhysicalInput"
				className="no-padding-item">
				<IonSelect
					cancelText="Cancella"
					value={simulate_free}
					label="Simulare free"
					placeholder="Selezionare"
					labelPlacement="stacked"
					onIonChange={(e) => updateInput(e.target.value)}
					style={{
						'--placeholder-color': 'var(--ion-color-medium)',
						opacity: 1,
					}}>
					<IonSelectOption value="">Non simulare</IonSelectOption>
					<IonSelectOption value="current">Free in corso</IonSelectOption>
					<IonSelectOption value="next">Prossimo</IonSelectOption>
				</IonSelect>
			</IonItem>
		</>
	)
}

const SmartOrClassic = ({ setToastIsOpen }) => {
	const is_smart =
		useSelector((state) => state.user.master_club_subscriber?.is_smart) ?? false
	const dispatch = useDispatch()

	const updateInput = (value) => {
		const body = JSON.stringify({ is_smart: value })
		fetch(`${backUrl}/api/master-club-subscribers/1 `, {
			method: 'PATCH',
			body,
			headers,
		})
			.then((response) => {
				if (!response.ok) throw new Error('fetch error')
				return response.json()
			})
			.then((data) => {
				dispatch(getUserInfo())
			})
			.catch((e) => {
				console.log('error', e)
				// setToastIsOpen()
			})
	}

	return (
		<>
			<IonItem
				button
				detail="false"
				className="no-padding-item">
				<IonSelect
					cancelText="Cancella"
					value={is_smart}
					label="Tipo di menu"
					placeholder="Selezionare"
					labelPlacement="stacked"
					onIonChange={(e) => updateInput(e.target.value)}
					style={{
						'--placeholder-color': 'var(--ion-color-medium)',
						opacity: 1,
					}}>
					<IonSelectOption value={false}>Classic</IonSelectOption>
					<IonSelectOption value={true}>Smart</IonSelectOption>
				</IonSelect>
			</IonItem>
		</>
	)
}

const sleepOptions = [
	'Meno di 4 ore',
	'Più di 4 ore',
	'Più di 5 ore',
	'Più di 6 ore',
	'Più di 7 ore',
	'Più di 8 ore',
]

const SleepInput = ({ setToastIsOpen }) => {
	const dispatch = useDispatch()
	const extras =
		useSelector((state) => state.user.master_club_subscriber?.extras) ?? {}
	const sleep = extras.sleep ?? ''

	const updateInput = (e) => {
		const value = e.target.value
		if (!sleepOptions.includes(value) || value === sleep) return

		const body = JSON.stringify({
			extras: { ...extras, sleep: value },
		})
		fetch(`${backUrl}/api/master-club-subscribers/1`, {
			method: 'PATCH',
			body,
			headers,
		})
			.then((response) => {
				if (!response.ok) throw new Error('fetch error')
				return response.json()
			})
			.then((data) => {
				dispatch(
					updateUserProperty({
						stateKey: 'master_club_subscriber',
						key: 'extras',
						value: JSON.parse(body).extras,
					})
				)
			})
			.catch((e) => setToastIsOpen())
	}

	return (
		<IonItem className="no-padding-item">
			<IonSelect
				cancelText="Cancella"
				onIonChange={updateInput}
				label="Quante ore riesci a dormire?"
				value={sleep}
				placeholder="Selezionare"
				labelPlacement="stacked">
				{sleepOptions.map((sleepOption) => (
					<IonSelectOption
						key={sleepOption}
						value={sleepOption}>
						{sleepOption}
					</IonSelectOption>
				))}
			</IonSelect>
		</IonItem>
	)
}

const intestineOptions = [
	'Regolare',
	'Stitichezza',
	'Diarrea',
	'Irregolare',
	'Alvo alterno',
]

const IntestineInput = ({ setToastIsOpen }) => {
	const dispatch = useDispatch()
	const extras =
		useSelector((state) => state.user.master_club_subscriber?.extras) ?? {}
	const intestine = extras.intestine ?? ''

	const updateInput = (e) => {
		const value = e.target.value
		if (!intestineOptions.includes(value) || value === intestine) return

		const body = JSON.stringify({
			extras: { ...extras, intestine: value },
		})
		fetch(`${backUrl}/api/master-club-subscribers/1`, {
			method: 'PATCH',
			body,
			headers,
		})
			.then((response) => {
				if (!response.ok) throw new Error('fetch error')
				return response.json()
			})
			.then((data) => {
				dispatch(
					updateUserProperty({
						stateKey: 'master_club_subscriber',
						key: 'extras',
						value: JSON.parse(body).extras,
					})
				)
			})
			.catch((e) => setToastIsOpen())
	}

	return (
		<IonItem className="no-padding-item">
			<IonSelect
				cancelText="Cancella"
				onIonChange={updateInput}
				label="Funzionalità intestinale?"
				value={intestine}
				placeholder="Selezionare"
				labelPlacement="stacked">
				{intestineOptions.map((intestineOption) => (
					<IonSelectOption
						key={intestineOption}
						value={intestineOption}>
						{intestineOption}
					</IonSelectOption>
				))}
			</IonSelect>
		</IonItem>
	)
}

const WhereYouLiveInput = ({ setToastIsOpen }) => {
	const dispatch = useDispatch()
	const province_id =
		useSelector((state) => state.user.master_club_subscriber?.province_id) ?? ''
	const city_id =
		useSelector((state) => state.user.master_club_subscriber?.city_id) ?? ''
	const [provincesOptions, setProvincesOptions] = useState({
		data: null,
		isLoading: true,
		error: false,
	})
	const [citiesOptions, setCitiesOptions] = useState({
		data: null,
		isLoading: true,
		error: false,
	})
	const [newProvinceId, setNewProvinceId] = useState(province_id)

	useEffect(() => {
		headers = headersDefault()
		if (provincesOptions.data || citiesOptions.data) return
		setProvincesOptions({ data: null, isLoading: true, error: false })
		setCitiesOptions({ data: null, isLoading: true, error: false })

		const provincesFetch = fetch(
			`${backUrl}/api/postal-info/list?service=province&method=get`,
			{ headers }
		)
		const citiesFetch = province_id
			? fetch(
					`${backUrl}/api/postal-info/list?service=city&method=get&province_id=${province_id}`,
					{ headers }
			  )
			: Promise.resolve({ ok: true, json: () => [] })

		Promise.all([provincesFetch, citiesFetch])
			.then((responses) => {
				const provincesResponse = responses[0]
				const citiesResponse = responses[1]

				if (!provincesResponse.ok) throw new Error(provincesResponse.message)
				if (!citiesResponse.ok) throw new Error(citiesResponse.message)

				const provinceJson = provincesResponse.json()
				const citiesJson = citiesResponse.json()

				Promise.all([provinceJson, citiesJson])
					.then((datas) => {
						setProvincesOptions({
							data: datas[0],
							isLoading: true,
							error: false,
						})
						setCitiesOptions({ data: datas[1], isLoading: true, error: false })
					})
					.catch((e) => {
						setProvincesOptions({ data: null, isLoading: false, error: true })
						setCitiesOptions({ data: null, isLoading: false, error: true })
						setToastIsOpen()
					})
			})
			.catch((e) => {
				setProvincesOptions({ data: null, isLoading: false, error: true })
				setCitiesOptions({ data: null, isLoading: false, error: true })
				setToastIsOpen()
			})
	}, [])

	const updateProvinceInput = (e) => {
		const value = e.target.value

		if (
			!provincesOptions.data?.map((p) => p.id).includes(value) ||
			value === newProvinceId
		)
			return

		setNewProvinceId(value)
		setCitiesOptions({ data: null, isLoading: true, error: false })
		fetch(
			`${backUrl}/api/postal-info/list?service=city&method=get&province_id=${value}`,
			{ headers }
		)
			.then((response) => {
				if (!response.ok) throw new Error(response.message)

				return response.json()
			})
			.then((data) => {
				setCitiesOptions({ data, isLoading: true, error: false })
			})
			.catch((e) => {
				setCitiesOptions({ data: null, isLoading: false, error: true })
				setToastIsOpen()
			})
	}

	const updateCityInput = (e) => {
		const value = e.target.value

		if (
			!citiesOptions.data?.map((p) => p.id).includes(value) ||
			value === city_id
		)
			return

		const body = JSON.stringify({
			...(newProvinceId !== province_id && { province_id: newProvinceId }),
			city_id: value,
		})
		fetch(`${backUrl}/api/master-club-subscribers/1`, {
			method: 'PATCH',
			body,
			headers,
		})
			.then((response) => {
				if (!response.ok) throw new Error('fetch error')
				return response.json()
			})
			.then((data) => {
				newProvinceId !== province_id &&
					dispatch(
						updateUserProperty({
							stateKey: 'master_club_subscriber',
							key: 'province_id',
							value: newProvinceId,
						})
					)
				dispatch(
					updateUserProperty({
						stateKey: 'master_club_subscriber',
						key: 'city_id',
						value,
					})
				)
			})
			.catch((e) => setToastIsOpen())
	}
	return (
		<>
			<IonItem className="no-padding-item">
				<IonSelect
					cancelText="Cancella"
					onIonChange={updateProvinceInput}
					value={newProvinceId}
					label="Provincia"
					label-placement="stacked"
					placeholder="Selezionare">
					{provincesOptions.data?.map((provinceOption) => {
						return (
							<IonSelectOption
								key={provinceOption.id}
								value={provinceOption.id}>
								{provinceOption.name}
							</IonSelectOption>
						)
					})}
				</IonSelect>
			</IonItem>
			<IonItem className="no-padding-item">
				<IonSelect
					cancelText="Cancella"
					onIonChange={updateCityInput}
					value={city_id}
					label="Città"
					label-placement="stacked"
					placeholder="Selezionare">
					{citiesOptions.data?.map((cityOption) => {
						return (
							<IonSelectOption
								key={cityOption.id}
								value={cityOption.id}>
								{cityOption.name}
							</IonSelectOption>
						)
					})}
				</IonSelect>
			</IonItem>
		</>
	)
}

const cookingOptions = [
	'Si, mi piace e dedico molto tempo alla cucina',
	'Mi piace cucinare ma non ho tempo',
	'Non mi piace cucinare ma lo faccio quotidianamente',
	'Acquisto cibo pronto da asporto, anche online',
	'Uso spesso cibi pronti',
]

const CookingInput = ({ setToastIsOpen }) => {
	const dispatch = useDispatch()
	const extras =
		useSelector((state) => state.user.master_club_subscriber?.extras) ?? {}
	const cooking = extras.cooking ?? ''

	const updateInput = (e) => {
		const value = e.target.value
		if (!cookingOptions.includes(value) || value === cooking) return

		const body = JSON.stringify({
			extras: { ...extras, cooking: value },
		})
		fetch(`${backUrl}/api/master-club-subscribers/1`, {
			method: 'PATCH',
			body,
			headers,
		})
			.then((response) => {
				if (!response.ok) throw new Error('fetch error')
				return response.json()
			})
			.then((data) => {
				dispatch(
					updateUserProperty({
						stateKey: 'master_club_subscriber',
						key: 'extras',
						value: JSON.parse(body).extras,
					})
				)
			})
			.catch((e) => setToastIsOpen())
	}

	return (
		<IonItem className="no-padding-item">
			<IonSelect
				cancelText="Cancella"
				onIonChange={updateInput}
				label="Ti piace cucinare?"
				value={cooking}
				placeholder="Selezionare"
				labelPlacement="stacked">
				{cookingOptions.map((cookingOption) => (
					<IonSelectOption
						key={cookingOption}
						value={cookingOption}>
						<IonLabel>{cookingOption}</IonLabel>
					</IonSelectOption>
				))}
			</IonSelect>
		</IonItem>
	)
}

const eatingOutOptions = [
	'Mai',
	'Ogni tanto',
	'Sempre a colazione',
	'Sempre a pranzo',
	'Sempre a cena',
	'Solo nei weekend',
]

const EatingOutInput = ({ setToastIsOpen }) => {
	const dispatch = useDispatch()
	const extras =
		useSelector((state) => state.user.master_club_subscriber?.extras) ?? {}
	const eating_out = extras.eating_out ?? ''

	const updateInput = (e) => {
		const value = e.target.value
		if (!eatingOutOptions.includes(value) || value === eating_out) return

		const body = JSON.stringify({
			extras: { ...extras, eating_out: value },
		})
		fetch(`${backUrl}/api/master-club-subscribers/1`, {
			method: 'PATCH',
			body,
			headers,
		})
			.then((response) => {
				if (!response.ok) throw new Error('fetch error')
				return response.json()
			})
			.then((data) => {
				dispatch(
					updateUserProperty({
						stateKey: 'master_club_subscriber',
						key: 'extras',
						value: JSON.parse(body).extras,
					})
				)
			})
			.catch((e) => setToastIsOpen())
	}

	return (
		<IonItem className="no-padding-item">
			<IonSelect
				cancelText="Cancella"
				onIonChange={updateInput}
				label="Mangi spesso fuori?"
				value={eating_out}
				placeholder="Selezionare"
				labelPlacement="stacked">
				{eatingOutOptions.map((eatingOutOption) => (
					<IonSelectOption
						key={eatingOutOption}
						value={eatingOutOption}>
						<IonLabel>{eatingOutOption}</IonLabel>
					</IonSelectOption>
				))}
			</IonSelect>
		</IonItem>
	)
}

const motivationOptions = [
	'Si, ho bisogno di tenere alta la motivazione',
	'No, se mi pongo un obiettivo riesco a raggiungerlo da solo/a',
	'Alcune volte ne sento il bisogno',
]

const MotivationInput = ({ setToastIsOpen }) => {
	const dispatch = useDispatch()
	const extras =
		useSelector((state) => state.user.master_club_subscriber?.extras) ?? {}
	const motivation = extras.motivation ?? ''

	const updateInput = (e) => {
		const value = e.target.value
		if (!motivationOptions.includes(value) || value === motivation) return

		const body = JSON.stringify({
			extras: { ...extras, motivation: value },
		})
		fetch(`${backUrl}/api/master-club-subscribers/1`, {
			method: 'PATCH',
			body,
			headers,
		})
			.then((response) => {
				if (!response.ok) throw new Error('fetch error')
				return response.json()
			})
			.then((data) => {
				dispatch(
					updateUserProperty({
						stateKey: 'master_club_subscriber',
						key: 'extras',
						value: JSON.parse(body).extras,
					})
				)
			})
			.catch((e) => setToastIsOpen())
	}

	return (
		<IonItem className="no-padding-item">
			<IonSelect
				cancelText="Cancella"
				onIonChange={updateInput}
				label="Hai bisogno di motivazione?"
				value={motivation}
				placeholder="Selezionare"
				labelPlacement="stacked">
				{motivationOptions.map((motivationOption) => (
					<IonSelectOption
						key={motivationOption}
						value={motivationOption}>
						<IonLabel>{motivationOption}</IonLabel>
					</IonSelectOption>
				))}
			</IonSelect>
		</IonItem>
	)
}

const hobbiesOptions = [
	'Lettura',
	'Scrittura',
	'Pittura',
	'Disegno',
	'Fotografia',
	'Giardinaggio',
	'Cucina',
	'Cucito',
	'Lavori di bricolage',
	'Puzzle',
	'Giocare a giochi da tavolo',
	'Suonare uno strumento musicale',
	'Escursionismo',
	'Campeggio',
	'Corsa',
	'Yoga',
	'Nuoto',
	'Ciclismo',
	'Danza',
	'Sci o snowboard',
	'Pesca',
	'Birdwatching',
	'Giochi di carte',
	'Falegnameria',
	'Modellismo',
	'Escursioni in barca',
	'Collezionismo (timbri, monete, francobolli, etc.)',
	'Cinefilia (guardare film e analizzarli)',
	'Fare volontariato',
	'Praticare arti marziali',
	'Realizzare gioielli fatti a mano',
]

const HobbiesInput = ({ setToastIsOpen }) => {
	const dispatch = useDispatch()
	const extras =
		useSelector((state) => state.user.master_club_subscriber?.extras) ?? {}
	const hobbies = extras.hobbies ?? ''
	const [newHobbies, setNewHobbies] = useState(hobbies)

	const updateInput = (value) => {
		if (!value.every((option) => hobbiesOptions.includes(option))) {
			setNewHobbies(hobbies)
			return
		}

		if (areArraysEquals(value, hobbies)) return

		const body = JSON.stringify({
			extras: { ...extras, hobbies: value },
		})
		fetch(`${backUrl}/api/master-club-subscribers/1`, {
			method: 'PATCH',
			body,
			headers,
		})
			.then((response) => {
				if (!response.ok) throw new Error('fetch error')
				return response.json()
			})
			.then((data) => {
				dispatch(
					updateUserProperty({
						stateKey: 'master_club_subscriber',
						key: 'extras',
						value: JSON.parse(body).extras,
					})
				)
			})
			.catch((e) => setToastIsOpen())
	}

	return (
		<>
			<IonItem
				button
				detail="false"
				id="present-alert-HobbiesInput"
				className="no-padding-item">
				<IonSelect
					cancelText="Cancella"
					disabled="true"
					multiple={true}
					value={newHobbies}
					label="Quali sono i tuoi hobby?"
					placeholder="Selezionare"
					labelPlacement="stacked"
					style={{
						'--placeholder-color': 'var(--ion-color-medium)',
						opacity: 1,
					}}>
					{hobbiesOptions.map((hobbiesOption) => (
						<IonSelectOption
							key={hobbiesOption}
							value={hobbiesOption}>
							{hobbiesOption}
						</IonSelectOption>
					))}
				</IonSelect>
			</IonItem>
			<IonAlert
				trigger="present-alert-HobbiesInput"
				header="hobbies"
				inputs={hobbiesOptions.map((hobbiesOption) => ({
					name: hobbiesOption,
					type: 'checkbox',
					label: hobbiesOption,
					value: hobbiesOption,
					checked: newHobbies.includes(hobbiesOption),
					handler: (e) => {
						if (newHobbies.length === 1 && !e.checked) {
							e.checked = true
							return
						}

						const _newGoals = [...newHobbies]
						if (e.checked) {
							_newGoals.push(e.value)
						} else {
							_newGoals.splice(_newGoals.indexOf(e.value), 1)
						}
						setNewHobbies(_newGoals)
					},
				}))}
				buttons={[
					{
						text: 'Cancella',
						role: 'cancel',
						handler: () => {
							setNewHobbies(hobbies)
						},
					},
					{
						text: 'OK',
						role: 'confirm',
						handler: updateInput,
					},
				]}
			/>
		</>
	)
}

const NicknameInput = ({ setToastIsOpen }) => {
	const dispatch = useDispatch()
	const user = useSelector((state) => state.user?.user?.name) ?? 'user'
	const nickname = useSelector(
		(state) => state.user?.master_club_subscriber?.nickname
	)
	const [newNickname, setNewNickname] = useState(nickname)

	const updateNickname = (value) => {
		const body = JSON.stringify({
			nickname: value,
		})
		fetch(`${backUrl}/api/master-club-subscribers/1`, {
			method: 'PATCH',
			body,
			headers,
		})
			.then((response) => {
				if (!response.ok) throw new Error('fetch error')
				return response.json()
			})
			.then((data) => {
				dispatch(
					updateUserProperty({
						stateKey: 'master_club_subscriber',
						key: 'nickname',
						value,
					})
				)
			})
			.catch((e) => {
				setNewNickname(nickname)
				setToastIsOpen()
			})
	}

	const updateInput = (e) => {
		const value = e.target.value.trim()
		if (value === '') return updateNickname(value)
		if (!value || value === nickname) return
		if (!isValidNickname(value)) {
			setNewNickname(nickname)
			setToastIsOpen('Nickname non valido')
			return
		}

		fetch(
			`${
				config.env[config.env.mode].url
			}/api/master-club-subscribers/check-nickname?nickname=${value}`,
			{ headers }
		)
			.then((response) => {
				if (!response.ok) throw new Error(response.message)
				return response.json()
			})
			.then((data) => {
				if (data.message !== 'Nickname disponibile')
					throw new Error(data.message)

				updateNickname(value)
			})
			.catch((e) => {
				setNewNickname(nickname)
				setToastIsOpen()
			})
	}

	return (
		<IonInput
			onIonBlur={updateInput}
			label="Nickname"
			color="primary"
			placeholder="Inserisci un nickname"
			value={newNickname}
			onIonInput={(e) => setNewNickname(e.target.value)}
			labelPlacement="stacked"
		/>
	)
}

const AliasInput = ({ setToastIsOpen }) => {
	const dispatch = useDispatch()
	const alias = useSelector(
		(state) => state.user?.master_club_subscriber?.alias
	)
	const [newAlias, setNewAlias] = useState(alias)

	const updateAlias = (value) => {
		const body = JSON.stringify({
			alias: value,
		})
		fetch(`${backUrl}/api/master-club-subscribers/1`, {
			method: 'PATCH',
			body,
			headers,
		})
			.then((response) => {
				if (!response.ok) throw new Error('fetch error')
				return response.json()
			})
			.then((data) => {
				dispatch(
					updateUserProperty({
						stateKey: 'master_club_subscriber',
						key: 'alias',
						value,
					})
				)
			})
			.catch((e) => {
				setNewAlias(alias)
				setToastIsOpen()
			})
	}

	const updateInput = (e) => {
		const value = e.target.value?.trim()
		if (value === '') return updateAlias(value)
		if (!value || value === alias) return
		if (value.length < 3 || value.length > 35) {
			setNewAlias(alias)
			setToastIsOpen('Sottotitolo deve avere tra 3 e 35 caratteri')
			return
		}
		updateAlias(value)
	}

	return (
		<IonInput
			onIonBlur={updateInput}
			label="Sottotitolo"
			// counter={true}
			color="primary"
			placeholder="Inserisci un sottotitolo"
			value={newAlias}
			maxlength={35}
			onIonInput={(e) => setNewAlias(e.target.value)}
			labelPlacement="stacked"
		/>
	)
}

const TelephoneInput = ({ setToastIsOpen }) => {
	const dispatch = useDispatch()
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const user = useSelector((state) => state.user?.user) ?? {}
	const { name = '', surname = '', telephone = '' } = user
	const [present, dismiss] = useIonLoading()
	const [value, setValue] = useState(telephone)

	const updateInput = () => {
		// if (!value) return
		if (!isPossiblePhoneNumber(value) || value === telephone) {
			if (!isPossiblePhoneNumber(value))
				setToastIsOpen('Il numero di telefono inserito non è valido')
			setValue(telephone)
			return
		}

		present({
			message: 'Ti abbiamo inviato il codice di conferma via sms',
		})

		fetch(`${config.env[config.env.mode].url}/api/master-club-otp`, {
			method: 'POST',
			body: JSON.stringify({ telephone: value }),
			headers,
		})
			.then((response) => {
				if (!response.ok) throw new Error(response.message)
				return response.json()
			})
			.then((data) => {
				setModalIsOpen(true)
				dismiss()
			})
			.catch((e) => {
				dismiss()
				setToastIsOpen()
			})
	}

	const updateTelephone = (e) => {
		const token = e[0] ?? ''
		if (token?.length !== 4) {
			return
		}

		present({
			message: 'Verificando...',
		})

		const body = JSON.stringify({
			token: token,
		})
		fetch(`${config.env[config.env.mode].url}/api/master-club-otp/${value}`, {
			method: 'PATCH',
			body,
			headers,
		})
			.then((response) => {
				if (!response.ok) throw new Error(response.message)
				return response.json()
			})
			.then((data) => {
				if (data?.url) {
					setTimeout(() => {
						localStorage.removeItem('adoc_p')
						localStorage.removeItem('adoc_i')
						localStorage.removeItem('adoc_t')
						window.location = data.url
					}, 2000)
					dismiss().then(() => {
						present({
							message: 'Reindirizzando sul tuo account principale...',
						})
					})
					return
				}
				if (data.message !== 'Codice corretto') {
					dismiss()
					throw new Error(data.message)
				}

				const body = JSON.stringify({
					telephone: value,
					name,
					surname,
				})
				fetch(`${backUrl}/api/profilo-utenti`, {
					method: 'POST',
					body,
					headers,
				})
					.then((response) => {
						if (!response.ok) throw new Error('fetch error')
						return response.json()
					})
					.then((data) => {
						dispatch(
							updateUserProperty({ stateKey: 'user', key: 'telephone', value })
						)
						dismiss()
					})
					.catch((e) => {
						dismiss()
						setValue(telephone)
						setToastIsOpen()
					})
			})
			.catch((e) => {
				dismiss()
				setValue(telephone)
				setToastIsOpen()
			})
	}

	const checkValues = () => {
		if (value) {
			updateInput()
		} else {
			setValue(telephone)
		}
	}
	return (
		<>
			<IonItem
				className="no-padding-item ion-padding-vertical"
				style={{ padding: '10px 0', borderBottom: '1px solid #ddd' }}
				onMouseDown={() => {
					document.getElementById('phone-input').focus()
				}}
				lines="none">
				<label className="phone-component-label">Cellulare</label>
				<PhoneInput
					autoFocus={true}
					label="Cellulare"
					id="phone-input"
					className="ion-margin-bottom"
					placeholder="xxx xxx xxxx"
					name="PhoneNumber"
					defaultCountry="IT"
					value={value}
					onChange={setValue}
					onBlur={() => {
						checkValues()
					}}
					onKeyDown={(e) => {
						if (e.key === 'Enter') {
							checkValues()
						}
					}}
				/>
			</IonItem>
			<IonAlert
				header="Inserisci il codice che hai ricevuto"
				buttons={[
					{
						text: 'Cancella',
						role: 'cancel',
						handler: () => {
							setValue(telephone)
						},
					},
					{
						text: 'OK',
						role: 'confirm',
						handler: updateTelephone,
					},
				]}
				inputs={[
					{
						type: 'number',
						placeholder: '9999',
						label: 'Codice di sicurezza',
						maxlength: 4,
					},
				]}
				isOpen={modalIsOpen}
				onDidDismiss={() => setModalIsOpen(false)}
			/>
		</>
	)
}
const maxSizeBites = 8388800

const ProfileImageInput = ({ setToastIsOpen, setToast }) => {
	const [modalIsOpen, setModalIsOpen] = useState(false)
	const gender =
		useSelector((state) => state.user.master_club_subscriber?.sex) ?? 'male'
	const user = useSelector((state) => state.user.user) ?? {}
	const { avatar } = user
	const [avatarSelected, setAvatarSelected] = useState(avatar)

	const [uploading, setUploading] = useState(false)
	const [present, dismiss] = useIonLoading()
	const dispatch = useDispatch()

	const sendAvatar = () => {
		if (avatarSelected === avatar || avatarSelected === null) {
			return
		}

		present({
			message: 'Caricando avatar...',
		})

		setUploading(true)

		const body = JSON.stringify({
			// telephone,
			// name,
			// surname,
			avatar: avatarSelected,
		})

		fetch(`${backUrl}/api/profilo-utenti`, { method: 'POST', body, headers })
			.then((response) => {
				if (!response.ok) throw new Error('fetch error')
				return response.json()
			})
			.then((data) => {
				dispatch(
					updateUserProperty({
						stateKey: 'user',
						key: 'avatar',
						value: avatarSelected,
					})
				)
				setUploading(false)
				dismiss()
			})
			.catch((e) => {
				setToastIsOpen()
				setUploading(false)
				dismiss()
			})
			.finally(() => setModalIsOpen(false))
	}

	const updateInput = (e) => {
		if (e.target.files[0] === undefined || e.target.files.length > 1) return
		if (e.target.files[0].size > maxSizeBites) {
			setToastIsOpen('Immagine troppo grande. Puoi caricare fino 8mb')
			return
		}

		present({
			message: 'Caricando immagine...',
		})

		const avatar = e.target.files[0]
		const body = new FormData()
		body.append('user-avatars', avatar)

		fetch(`${config.env[config.env.mode].url}/api/file-upload/user-avatars`, {
			body,
			method: 'POST',
			headers: { Authorization: `Bearer ${localStorage.getItem('adoc_t')}` },
		})
			.then((response) => {
				if (!response.ok) throw new Error('fetch error')
				return response.json()
			})
			.then((data) => {
				const avatarnName = data.filename
				const body = JSON.stringify({
					// telephone,
					// name,
					// surname,
					avatar: avatarnName,
				})
				fetch(`${backUrl}/api/profilo-utenti`, {
					method: 'POST',
					body,
					headers,
				})
					.then((response) => {
						if (!response.ok) throw new Error('fetch error')
						return response.json()
					})
					.then((data) => {
						dispatch(
							updateUserProperty({
								stateKey: 'user',
								key: 'avatar',
								value: avatarnName,
							})
						)
						dismiss()
					})
					.catch((e) => {
						setToastIsOpen()
						dismiss()
					})
			})
			.catch((e) => {
				setToastIsOpen()
				dismiss()
			})
	}

	return (
		<>
			<IonItem
				button
				detail="false"
				className="no-padding-item">
				<IonInput
					onClick={() => document.getElementById('image_uploads').click()}
					readonly="true"
					color="primary"
					label="Immagine di profilo"
					placeholder="Scatta foto o seleziona una"
					labelPlacement="stacked"
					style={{
						'--placeholder-color': 'var(--ion-color-medium)',
						opacity: 1,
					}}
				/>
				<IonButton
					onClick={(e) => {
						setModalIsOpen(true)
					}}
					slot="end"
					fill="clear"
					shape="round">
					<IonIcon
						src={happy}
						color="medium"
						slot="icon-only"
					/>
				</IonButton>
			</IonItem>
			<IonModal
				keepContentsMounted={false}
				className="modal-as-alert"
				isOpen={modalIsOpen}
				onDidDismiss={() => setModalIsOpen(false)}>
				<h3 className="ion-padding-horizontal">Scegli il tuo avatar</h3>
				<IonGrid>
					<IonRow>
						{avatars[gender].map((avatar) => {
							return (
								<IonCol
									style={{ display: 'flex', justifyContent: 'center' }}
									size="3"
									key={avatar.name}>
									<IonImg
										className="rounded-ion-img choose-avatar-container"
										style={{
											borderColor:
												avatarSelected === avatar.name ? '#8BC34A' : '',
										}}
										src={avatar.image}
										onClick={() => {
											setAvatarSelected(avatar.name)
										}}
									/>
								</IonCol>
							)
						})}
					</IonRow>
				</IonGrid>
				<IonButton
					onClick={sendAvatar}
					disabled={
						uploading || avatarSelected === avatar || avatarSelected === null
					}
					color="blue"
					expand="block"
					fill="clear"
					strong={true}>
					Confermare
				</IonButton>
			</IonModal>
			<input
				id="image_uploads"
				onChange={updateInput}
				accept="image/png,image/jpeg"
				type="file"
				className="d-false"
			/>
		</>
	)
}

const DataContent = () => {
	const defaultToast = {
		message: "Ops! C'è stato un errore. Riprova più tardi.",
		open: false,
	}

	const badge = useSelector(
		(state) => state.user?.master_club_subscriber?.badge ?? null
	)

	const [toast, setToast] = useState(defaultToast)

	const setToastIsOpen = (message = defaultToast.message) => {
		setToast({
			message,
			open: true,
		})
	}
	const setToastClose = () => {
		setToast((oldToast) => {
			const newToast = { ...oldToast }
			newToast.open = false
			return newToast
		})
	}

	return (
		<div className="ion-padding personal-data">
			<IonText
				style={{ fontSize: '1.25rem', fontWeight: 'var(--font-size-black)' }}>
				Modifica tuoi dati
			</IonText>
			<NameInput setToastIsOpen={setToastIsOpen} />
			<SurnameInput setToastIsOpen={setToastIsOpen} />
			<NicknameInput setToastIsOpen={setToastIsOpen} />
			<AliasInput setToastIsOpen={setToastIsOpen} />
			<GenderInput setToastIsOpen={setToastIsOpen} />
			<BirthDayInput setToastIsOpen={setToastIsOpen} />
			<TelephoneInput setToastIsOpen={setToastIsOpen} />
			<WhereYouLiveInput setToastIsOpen={setToastIsOpen} />
			<ProfileImageInput
				setToastIsOpen={setToastIsOpen}
				setToast={setToast}
			/>
			<HeightInput setToastIsOpen={setToastIsOpen} />
			<WeightInput setToastIsOpen={setToastIsOpen} />
			<GoalsInput setToastIsOpen={setToastIsOpen} />
			<WeightGoalInput setToastIsOpen={setToastIsOpen} />
			<DietTypeInput setToastIsOpen={setToastIsOpen} />
			<SmartOrClassic setToastIsOpen={setToastIsOpen} />
			<IntestineInput setToastIsOpen={setToastIsOpen} />
			<EatingOutInput setToastIsOpen={setToastIsOpen} />
			<CookingInput setToastIsOpen={setToastIsOpen} />
			<HobbiesInput setToastIsOpen={setToastIsOpen} />
			<SleepInput setToastIsOpen={setToastIsOpen} />
			<MotivationInput setToastIsOpen={setToastIsOpen} />
			<ImpedimentsPhysicalInput setToastIsOpen={setToastIsOpen} />
			{badge && <FreeSimulate setToastIsOpen={setToastIsOpen} />}

			<IonToast
				isOpen={toast.open}
				message={toast.message}
				onDidDismiss={() => setToastClose()}
				duration={3000}
			/>
		</div>
	)
}

export default DataContent
