import {
	IonPage,
	IonContent,
	IonText,
	IonRadioGroup,
	IonRadio,
	IonButton,
	IonItem,
	IonCheckbox,
} from '@ionic/react'
import InitialConfigHeader from '../header'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setInitialConfigProperty } from '../../../redux/reducer/initialConfigSlice'
import '../InitialConfiguration.min.css'
import { useHistory } from 'react-router'

const dietsOptions = [
	{
		name: 'Onnivoro',
		description: 'Mangio di tutto',
		value: false,
	},
	{
		name: 'Vegetariano',
		description: 'Non mangio nè carne nè pesce',
		value: true,
	},
]
const TypeOfDietPage = ({ step, totalSteps }) => {
	const history = useHistory()
	const [isLoading, setIsLoading] = useState(false)
	const vegetarian = useSelector((state) => state.initialConfig.vegetarian)
	const gluten_free = useSelector((state) => state.initialConfig.gluten_free)
	const dispatch = useDispatch()
	const [error, setError] = useState('')

	const updateInput = (e) => {
		if (error !== '') setError('')
		if (e.target.value !== vegetarian)
			dispatch(
				setInitialConfigProperty({ key: 'vegetarian', value: e.target.value })
			)
	}

	const updateCheckbox = () => {
		dispatch(
			setInitialConfigProperty({ key: 'gluten_free', value: !gluten_free })
		)
	}

	const validateInput = () => {
		let isValid = true
		if (vegetarian === undefined) {
			setError("Devi selezionare un'opzione per continuare.")
			isValid = false
		} else if (!dietsOptions?.map((o) => o.value).includes(vegetarian)) {
			setError("L'opzione selezionata non è valida.")
			isValid = false
		}

		return isValid
	}

	const handleSubmit = () => {
		setIsLoading(true)
		const isInputValid = validateInput()
		if (!isInputValid) {
			setIsLoading(false)
			return
		}

		history.push(`/impostazioni-iniziali/${step + 1}`)
		setIsLoading(false)
	}
	return (
		<IonPage className="initial-configuration">
			<InitialConfigHeader
				step={step}
				totalSteps={totalSteps}
				isLoading={isLoading}
			/>

			<IonContent>
				<h1>Che tipo di alimentazione segui?</h1>
				<h6>
					Potrai cambiare questa scelta in qualsiasi momento anche
					successivamente
				</h6>
				<IonRadioGroup value={vegetarian}>
					{dietsOptions?.map((option) => {
						return (
							<IonItem
								key={option.name}
								className="no-padding-item">
								<div className="option-container">
									<IonRadio
										value={option.value}
										onClick={updateInput}>
										{option.name}
									</IonRadio>
									<div className="option-description">{option.description}</div>
								</div>
							</IonItem>
						)
					})}
				</IonRadioGroup>
				<IonItem className="no-padding-item">
					<div className="option-container">
						<IonCheckbox
							onIonChange={updateCheckbox}
							checked={gluten_free}>
							Senza glutine e lattosio
						</IonCheckbox>
					</div>
				</IonItem>
				<IonText className="error">{error}</IonText>

				<div className="buttons">
					<IonButton
						shape="round"
						onClick={handleSubmit}
						disabled={isLoading}
						color="primary"
						expand="full">
						Avanti
					</IonButton>
				</div>
			</IonContent>
		</IonPage>
	)
}

export default TypeOfDietPage
