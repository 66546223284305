import { IonButton, IonContent, IonPage } from '@ionic/react'
import MainFooter from '../../../components/mainFooter'
import MainHeader from '../../../components/headers/mainHeader'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import { getUserInfo } from '../../../redux/actions'

const HowTrialWorksPage = () => {
	const history = useHistory()
	const dispatch = useDispatch()
	dispatch(getUserInfo())
	return (
		<IonPage>
			<MainHeader
				page="WhatIsDietaSocialApp"
				title="Come funziona la prova gratuita"
			/>
			<IonContent className="ion-padding">
				<p>Ecco come funziona la prova gratuita di Dieta Social App:</p>
				<h4>Inizio Immediato</h4>
				<p>
					La tua prova gratuita inizia immediatamente e si concluderà al settimo
					giorno del piano alimentare. Questo percorso è progettato per guidarti
					attraverso una rieducazione alimentare, aiutandoti a dimagrire in modo
					sano e ad acquisire un nuovo stile di vita salutare e consapevole.
				</p>
				<h4>Apprendimento Guidato</h4>
				<p>
					È fondamentale leggere attentamente tutte le notifiche inviate dal
					nostro staff durante questo periodo. Queste notifiche sono pensate per
					guidarti nell’apprendimento dei principi della sana alimentazione,
					stimolare l’interesse per l’attività fisica e mantenere alta la
					motivazione durante l’intero percorso.
				</p>
				<h4>Accesso alle Sezioni dell’App</h4>
				<p>
					Dopo aver scelto il regime alimentare e la data di inizio del percorso
					gratuito, otterrai immediatamente l’accesso ad alcune sezioni della
					nostra app. Entrerai a far parte del gruppo “Percorso FREE”, una
					community dedicata in cui i nostri esperti condivideranno i loro
					consigli e in cui potrai conoscere altre persone con gli stessi
					obiettivi. In questo gruppo, troverai sostegno e motivazione reciproca
					per raggiungere i tuoi obiettivi. La prova gratuita ti offre
					un’opportunità unica per iniziare il tuo viaggio verso la salute e il
					benessere, supportato dalla nostra community e dalla guida esperta del
					nostro staff.
				</p>
				<IonButton onClick={() => history.push('/corso')}>Inizia Ora</IonButton>
			</IonContent>
			<MainFooter />
		</IonPage>
	)
}

export default HowTrialWorksPage
