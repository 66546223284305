import {
	IonContent,
	IonPage,
	IonIcon,
	IonTextarea,
	IonButton,
	IonLabel,
	useIonLoading,
	useIonToast,
} from '@ionic/react'
import MainFooter from '../../../components/mainFooter'
import MainHeader from '../../../components/headers/mainHeader'
import { image } from 'ionicons/icons'
import { useEffect, useRef, useState } from 'react'
import '../Post.min.css'
import {
	addPost,
	getGroup,
	getGroupPosts,
	headersDefault,
} from '../../../redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import config from '../../../settings/config'
import { useHistory } from 'react-router'
import EXIF from 'exif-js'
import { rotateImage } from '../../../services/functions'

let headers = {}

const AddPostPage = ({ match }) => {
	const history = useHistory()
	const group_id = match.params.groupId

	useEffect(() => {
		dispatch(getGroup(group_id)).then((data) => {})
	}, [])

	const { data } = useSelector((state) => state.group.getGroup)
	const [isUploading, setIsUploading] = useState(false)

	const group = data
	const isMain = group?.is_main
	const pusblishByDefault = group?.publish_by_default
	const [ionToast] = useIonToast()

	const href = isMain ? '/home' : `/gruppi/${group_id}`

	const [isTouched, setIsTouched] = useState(false)
	const [isAllValid, setAllIsValid] = useState(false)

	const markTouched = () => {
		setIsTouched(true)
	}

	const validate = (e) => {
		const conditions = [
			e.target.value.trim() !== '',
			e.target.value.length >= 3,
		]
		const status = conditions.every((condition) => condition)
			? 'valid'
			: 'invalid'
		e.target.classList.remove('ion-valid')
		e.target.classList.remove('ion-invalid')
		e.target.classList.add('ion-' + status)

		const countValidate = document.querySelectorAll('.validate').length
		const countValid = document.querySelectorAll('.validate.ion-valid').length
		const countInvalid = document.querySelectorAll('.validate.ion-invalid')

		setAllIsValid(countValidate === countValid && countInvalid.length === 0)
	}

	const contentValue = useRef(null)

	const maxSizeBites = 8388800
	const [ionLoading, hideIonLoading] = useIonLoading()

	const dispatch = useDispatch()

	const handleClickSendPostButton = () => {
		setIsUploading(true)
		const content = contentValue.current.value.trim()
		if (content === '') return

		ionLoading({
			message: 'Caricando post...',
		})

		uploadImage(loadedImage)
			.then((image) => {
				const values = {
					content,
					groups_id: [group_id],
					images: image ? [image] : null,
				}
				dispatch(addPost(values))
					.then(() => {
						dispatch(
							getGroupPosts({
								is_fixed: false,
								page: 1,
								from_main_group: false,
								group_id,
							})
						)
							.then(() => {
								contentValue.current.value = ''
								deleteLoadedImage()
								hideIonLoading()
								console.log('pusblishByDefault: ', pusblishByDefault)
								ionToast({
									message: !pusblishByDefault
										? 'I moderatori hanno preso in carico il tuo post e lo pubblicheranno al più presto'
										: 'Il tuo post è stato pubblicato',
									duration: 3000,
								})
								history.push(href)
								setIsUploading(false)
							})
							.catch((e) => {
								setIsUploading(false)
								hideIonLoading()
								ionToast({
									message:
										'Errore durante il caricamento del post. Riprova più tardi',
									duration: 3000,
								})
							})
					})
					.catch((e) => {
						setIsUploading(false)
						hideIonLoading()
						ionToast({
							message:
								'Errore durante il caricamento del post. Riprova più tardi',
							duration: 3000,
						})
					})
			})
			.catch((e) => {
				setIsUploading(false)
				hideIonLoading()

				ionToast({
					message:
						"Errore durante il caricamento dell'immagine. Riprova più tardi",
					duration: 3000,
				})
			})
	}

	useEffect(() => {
		headers = headersDefault()
	}, [])

	const uploadImage = (dataURL) => {
		return new Promise((resolve, reject) => {
			if (!dataURL) return resolve(null)

			// Extraer el tipo MIME del DataURL
			const mimeMatch = dataURL.match(/^data:(.*);base64,/)
			const extension = dataURL.match(/^data:image\/(\w+);base64,/)
			if (!mimeMatch) {
				reject(new Error('No se pudo determinar el tipo MIME de la imagen'))
				return
			}
			const mimeType = mimeMatch[1]

			fetch(dataURL)
				.then((res) => res.blob())
				.then((blob) => {
					const file = new File([blob], 'uploaded_image', {
						type: mimeType,
					})
					// const file = document.getElementById('post-upload-image').files[0]
					const body = new FormData()
					body.append('master-club-post', file)

					fetch(
						`${
							config.env[config.env.mode].url
						}/api/file-upload/master-club-post`,
						{
							method: 'POST',
							headers: {
								Authorization: `Bearer ${localStorage.getItem('adoc_t')}`,
							},
							body,
						}
					)
						.then((response) => {
							if (!response.ok) throw new Error('fetch error')
							return response.json()
						})
						.then((data) => {
							resolve(data.filename)
						})
						.catch((e) => {
							reject(e) // Reject con el error
						})
				})
		})
	}

	const [loadedImage, setLoadedImage] = useState(null)
	const selectedImage = (e) => {
		const file = e.target.files[0]

		if (file) {
			if (file.size > maxSizeBites) {
				deleteLoadedImage()
				ionToast({
					message: 'Immagine troppo grande. Puoi caricare fino 8mb',
					duration: 3000,
				})
				return
			}

			const reader = new FileReader()
			reader.onload = function (e) {
				const image = new Image()
				image.src = e.target.result
				image.onload = function () {
					EXIF.getData(image, function () {
						const orientation = EXIF.getTag(this, 'Orientation')
						console.log('orientation: ', orientation)
						const rotatedImage = rotateImage(image, orientation)
						setLoadedImage(rotatedImage.src) // Aquí almacenamos el DataURL en el estado
					})
				}
			}
			reader.readAsDataURL(file)
		}
	}

	const deleteLoadedImage = () => {
		setLoadedImage(null)
		document.getElementById('post-upload-image').value = ''
	}

	return (
		<IonPage className="add-post-page">
			<MainHeader
				page="AddPostPage"
				title="Nuovo post"
				href={href}
			/>

			<IonContent className="ion-padding">
				<div>
					<IonLabel>Immagine</IonLabel>
					{loadedImage ? (
						<div className="image-preview">
							<img
								src={loadedImage}
								alt=""
							/>
							<span onClick={deleteLoadedImage}>Elimina</span>
						</div>
					) : (
						<IonButton
							fill="clear"
							slot="start"
							color="medium"
							className="upload-image-button ion-margin-top"
							onClick={() =>
								document.getElementById('post-upload-image').click()
							}>
							<IonIcon
								size="large"
								src={image}
								slot="icon-only"
							/>
						</IonButton>
					)}
					<input
						id="post-upload-image"
						className="d-false"
						onChange={selectedImage}
						accept="image/png,image/jpeg"
						type="file"
					/>
				</div>
				<IonTextarea
					ref={contentValue}
					className={`ion-margin-top validate ${isTouched && 'ion-touched'}`}
					type="text"
					label="Scrivi qualcosa..."
					color="primary"
					labelPlacement="floating"
					rows={4}
					counter={true}
					maxlength={500}
					counterFormatter={(inputLength, maxLength) =>
						`${maxLength - inputLength} caratteri rimanenti`
					}
					errorText="Contenuto troppo corto"
					onIonInput={(event) => validate(event)}
					onIonBlur={() => markTouched()}></IonTextarea>
				<IonButton
					disabled={isUploading || !isAllValid}
					className="ion-margin-vertical"
					onClick={handleClickSendPostButton}>
					Pubblicare
				</IonButton>
			</IonContent>

			<MainFooter />
		</IonPage>
	)
}

export default AddPostPage
