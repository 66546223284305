import {
	IonButton,
	IonContent,
	IonDatetimeButton,
	IonDatetime,
	IonItem,
	IonPopover,
	IonList,
	IonPage,
	IonText,
	IonThumbnail,
	IonToast,
	IonRefresher,
	IonRefresherContent,
	IonSegment,
	IonLabel,
	IonSegmentButton,
} from '@ionic/react'
import MainFooter from '../../components/mainFooter'
import MainHeader from '../../components/headers/mainHeader'
import images from '../../assets/images/Measures/parts'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'
import { clearCache, todayStringDate } from '../../services/functions'
import config from '../../settings/config'
import { resetDiaryState, updateMeasures } from '../../redux/reducer/diarySlice'
import measures from '../../settings/measuresConfig'
import './Diary.min.css'
import { useHistory } from 'react-router'
import ProgressPage from '../Progress'

const today = todayStringDate()

const DiaryPage = ({ location }) => {
	const dispatch = useDispatch()
	const [date, setDate] = useState(today)
	const [segmentSelected, setSegmentSelected] = useState('diary')
	const popoverRef = useRef(null)

	const closePopover = () => {
		if (popoverRef.current) {
			popoverRef.current.dismiss()
		}
	}

	const history = useHistory()
	const measuresValues = useSelector((state) => state.diary.data)
	const AccountCreatedAt =
		useSelector((state) => state.user?.master_club_subscriber?.created_at) ??
		today
	const gender =
		useSelector((state) => state.user?.master_club_subscriber?.sex) ?? 'male'
	const [toastIsOpen, setToastIsOpen] = useState(false)

	useEffect(() => {
		fetch(
			`${
				config.env[config.env.mode].url
			}/api/master-club-measure-diary/${date}/fetch`,
			{
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${localStorage.getItem('adoc_t')}`,
				},
			}
		)
			.then((response) => response.json())
			.then((data) => {
				if (JSON.stringify(data) === '{}') {
					dispatch(resetDiaryState(date))
				} else {
					dispatch(updateMeasures(data))
				}
			})
			.catch(() => {
				setToastIsOpen(true)
			})
	}, [date])

	const [href, setHref] = useState(
		history.location.pathname + window.location.search
	)

	useEffect(() => {
		setHref(window.location.pathname + window.location.search)
	}, [history.location.pathname, window.location.search])

	return (
		<>
			<IonPage className="diary-page">
				<MainHeader
					title="Il mio diario"
					page="DiaryPage"
					href={href === '/diario' ? '' : '/diario'}
				/>

				<IonContent>
					<IonRefresher
						slot="fixed"
						pullFactor={0.5}
						pullMin={100}
						pullMax={200}
						onIonRefresh={clearCache}>
						<IonRefresherContent></IonRefresherContent>
					</IonRefresher>
					<div className="segment-container">
						<IonSegment
							value={segmentSelected}
							onIonChange={(e) => setSegmentSelected(e.target.value)}>
							<IonSegmentButton value="diary">
								<IonLabel>Il mio diario</IonLabel>
							</IonSegmentButton>
							<IonSegmentButton value="progress">
								<IonLabel>Progressi</IonLabel>
							</IonSegmentButton>
						</IonSegment>
					</div>

					{segmentSelected === 'diary' && (
						<>
							<IonItem
								id="datetime-button2"
								lines="full"
								style={{ pointerEvents: 'none' }}>
								<div className="date-container">
									<IonText>Giorno</IonText>
									<IonDatetimeButton
										datetime="datetime2"
										slot="end"
										style={{ pointerEvents: 'visible' }}
									/>
								</div>
							</IonItem>

							<div className="measures-container">
								<h3 className="page-title">Aggiorna le tue misure</h3>

								<IonList>
									{measures.map((measure, index) => {
										if (!measure.diary) return null
										return (
											<IonItem
												className="no-padding-item"
												key={measure.title}
												button
												detail={false}
												onClick={() =>
													history.push(
														`${window.location.pathname}/${measure.slug}`,
														'forward'
													)
												}>
												<IonThumbnail
													slot="start"
													className="soft-shadow-and-radius">
													<img src={images[gender][index]} />
												</IonThumbnail>
												<div>
													<IonText>
														<div style={{ marginBottom: '0.2rem' }}>
															{measure.title}
														</div>
													</IonText>
													<div>
														{measuresValues[measure.value] !== '0.00' ? (
															<IonText color="medium">
																{parseFloat(measuresValues[measure.value])
																	.toFixed(1)
																	.replace('.', ',') +
																	' ' +
																	measure.suffix}
															</IonText>
														) : (
															<IonButton
																size="small"
																color="primary">
																Aggiungi
															</IonButton>
														)}
													</div>
												</div>
											</IonItem>
										)
									})}
								</IonList>
							</div>

							<IonPopover
								ref={popoverRef}
								trigger="datetime-button2"
								triggerAction="click"
								keepContentsMounted
								arrow="false"
								className="withoutBackdrop"
								size="cover">
								<IonDatetime
									style={{ width: '100%' }}
									size="cover"
									value={date}
									min={AccountCreatedAt}
									max={today}
									onIonChange={(e) => {
										setDate(e.target.value)
										closePopover()
									}}
									id="datetime2"
									locale="it-IT"
									displayFormat="YYYY-MM-DD"
									presentation="date"
								/>
							</IonPopover>
							<IonToast
								isOpen={toastIsOpen}
								message="Ops! C'è stato un errore. Riprova più tardi."
								onDidDismiss={() => setToastIsOpen(false)}
								duration={3000}
							/>
						</>
					)}

					{segmentSelected === 'progress' && <ProgressPage />}
				</IonContent>

				<MainFooter />
			</IonPage>
		</>
	)
}

export default DiaryPage
