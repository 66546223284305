import {
	IonContent,
	IonImg,
	IonText,
	IonInput,
	IonButton,
	IonPage,
	IonIcon,
	IonLabel,
	IonLoading,
	IonRefresher,
	IonRefresherContent,
	IonAlert,
	useIonToast,
} from '@ionic/react'
import dietaSocialLogo from '../../assets/images/ds-logo.svg'
import dietaSocialLogoSandbox from '../../assets/images/ds-logo-sandbox.svg'
import './Login.min.css'
import { useEffect, useRef, useState } from 'react'
import { logoApple } from 'ionicons/icons'
import { isValidEMail, clearCache, _isIOS } from '../../services/functions'
import ReCAPTCHA from 'react-google-recaptcha'
import config from '../../settings/config'
import firebaseApp from '../../settings/firebaseConfig'
import {
	getAuth,
	signInWithRedirect,
	GoogleAuthProvider,
	OAuthProvider,
	FacebookAuthProvider,
	getRedirectResult,
	onAuthStateChanged,
} from 'firebase/auth'
import { useDispatch } from 'react-redux'
import { setAdoc_t, setAdoc_p } from '../../redux/reducer/userSlice'
import microsoft from './SocialLogos/microsoft.svg'
import facebook from './SocialLogos/facebook.svg'
import google from './SocialLogos/google.svg'
import { useHistory, useLocation } from 'react-router'

const LoginPage = () => {
	const logo =
		config.env.mode !== 'prod' ? dietaSocialLogoSandbox : dietaSocialLogo
	const location = useLocation()
	const params = new URLSearchParams(location.search)
	const redirectParam = params.get('redirect')

	const isIOS = _isIOS()
	const history = useHistory()
	const dispatch = useDispatch()
	const [email, setEmail] = useState('')
	const [toast] = useIonToast()
	const [emailError, setEmailError] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const isAuthCheckInProgress = useRef(false)
	const [showAlert, setShowAlert] = useState(false)
	const reCaptchaToken = useRef(null)

	const setError = (message) => {
		console.log('error')
		toast({
			message: message ?? 'Ops! C’è stato un errore. Riprova più tardi.',
			duration: 5000,
		})
	}

	const providers = {
		google: new GoogleAuthProvider(),
		apple: new OAuthProvider('apple.com'),
		facebook: new FacebookAuthProvider(),
		microsoft: new OAuthProvider('microsoft.com'),
	}

	const [isButtonDisabled, setIsButtonDisabled] = useState(false)
	const [countdown, setCountdown] = useState(59)

	useEffect(() => {
		let countdownInterval

		if (isButtonDisabled) {
			countdownInterval = setInterval(() => {
				setCountdown((prevCountdown) => prevCountdown - 1)
			}, 1000)

			if (countdown <= 0) {
				setIsButtonDisabled(false)
				setCountdown(59)
			}
		} else {
			clearInterval(countdownInterval)
		}

		return () => clearInterval(countdownInterval)
	}, [isButtonDisabled, countdown])

	const auth = getAuth(firebaseApp)

	const updateEmail = (e) => {
		if (emailError !== '') setEmailError('')
		setEmail(e.target.value)
	}
	const authCheck = async (user) => {
		if (isAuthCheckInProgress.current) return
		isAuthCheckInProgress.current = true

		if (!user.email) {
			setIsLoading(false)
			isAuthCheckInProgress.current = false
			setError('Email non trovata. Per favore, contatta il supporto.')
			return
		}
		const response = await fetch(
			`${config.env[config.env.mode].url}/api/social-login`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					token: user.accessToken,
				}),
			}
		)

		if (response.ok) {
			const data = await response.json()
			dispatch(setAdoc_t(window.atob(data.message)))
			const token = window.atob(data.message)
			localStorage.setItem('adoc_t', token)
			fetch(`${config.env[config.env.mode].url}/api/profilo-utenti/1/fetch `, {
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: 'Bearer ' + token,
				},
			})
				.then((resp) => {
					if (resp.ok) {
						return resp.json()
					} else {
						throw new Error(resp.message)
					}
				})
				.then((user) => {
					localStorage.setItem('adoc_p', JSON.stringify(user))
					dispatch(setAdoc_p(user))
					setIsLoading(false)
					return user
				})
				.then((user) => {
					const nextRoute = user?.master_club_subscriber
						? '/home'
						: '/impostazioni-iniziali/1'
					history.push(nextRoute, {
						login: true,
					})
				})
				.catch((e) => {
					setIsLoading(false)
					setError(e)
				})
		} else {
			isAuthCheckInProgress.current = false
			setIsLoading(false)
			setError('Ops! C’è stato un errore. Riprova più tardi. Err: 1001')
		}
	}

	useEffect(() => {
		const handleRedirectResult = async () => {
			try {
				const result = await getRedirectResult(auth)
				if (result && result.user) {
					// Is there a redirect user?
					authCheck(result.user)
				} else {
					setIsLoading(false)
					setError('Ops! C’è stato un errore. Riprova più tardi. Err: 1002')
				}
			} catch (error) {
				setIsLoading(false)
				setError('Ops! C’è stato un errore. Riprova più tardi. Err: 1003')
			}
		}

		onAuthStateChanged(auth, (user) => {
			user = auth.currentUser
			//Is there onAuthStateChanged user?
			if (user) {
				authCheck(user)
			}
		})

		const user = auth.currentUser

		setIsLoading(true)

		//Is there a currentUser?
		if (user) {
			authCheck(user)
		}

		if (redirectParam) {
			handleRedirectResult()
		} else {
			setIsLoading(false)
		}
	}, [])

	const handleSocialSubmit = async (provider) => {
		setIsLoading(true)
		return signInWithRedirect(auth, providers[provider])
	}

	const handleSubmit = () => {
		setEmailError('')
		if (email === '') {
			setEmailError('La mail è obbligatoria.')
		} else if (!isValidEMail(email)) {
			setEmailError('Inserisci una mail valida.')
		} else {
			setIsLoading(true)
			reCaptchaToken.current
				.executeAsync()
				.catch((err) => {
					console.log(err)
					setIsLoading(false)
					setEmailError('Si è verificato un problema, riprova più tardi.')
				})
				.then((newToken) => {
					if (!newToken) {
						setIsLoading(false)
						setEmailError('Si è verificato un problema, riprova più tardi.')
					} else {
						setIsButtonDisabled(true)
						fetch(
							`${
								config.env[config.env.mode].url
							}/api/recaptcha-verify?g-recaptcha-response=${newToken}`,
							{
								headers: {
									'Content-Type': 'application/json',
									Accept: 'application/json',
								},
							}
						)
							.catch((_error) => {
								setIsLoading(false)
								setEmailError('Si è verificato un problema, riprova più tardi.')
							})
							.then((resp) => resp.json())
							.then((json) => {
								if (json.success !== true) {
									setIsLoading(false)
									setEmailError(
										'Si è verificato un problema, riprova più tardi.'
									)
								} else {
									fetch(
										`${config.env[config.env.mode].url}/api/login-app/${email}${
											isIOS ? '?isIOS=true' : ''
										}`,
										{
											headers: {
												'Content-Type': 'application/json',
												Accept: 'application/json',
											},
										}
									)
										.catch((_) => {
											setIsLoading(false)
											setEmailError(
												"Si è verificato un problema nell'invio dell'email, riprova più tardi."
											)
										})
										.then((_) => {
											setIsLoading(false)
											setShowAlert(true)
										})
								}
							})
					}
				})
		}
	}

	const checkOTP = (otp) => {
		setIsLoading(true)
		fetch(
			`${config.env[config.env.mode].url}/api/master-club-otp/${email}/verify`,
			{
				method: 'POST',
				body: JSON.stringify({ token: otp }),
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${localStorage.getItem('adoc_t')}`,
				},
			}
		)
			.then((response) => {
				return response.json()
			})
			.then((data) => {
				if (data?.url) {
					window.location = data.url
				} else {
					setError(data.message)
					setIsLoading(false)
				}
			})
			.catch((e) => {
				setError(e.message)
				setIsLoading(false)
			})
	}

	return (
		<IonPage id="LoginPage">
			<IonContent className="ion-padding ion-text-center">
				<IonRefresher
					slot="fixed"
					pullFactor={0.5}
					pullMin={100}
					pullMax={200}
					onIonRefresh={clearCache}>
					<IonRefresherContent></IonRefresherContent>
				</IonRefresher>
				{isIOS ? (
					<IonAlert
						isOpen={showAlert}
						onDidDismiss={() => setShowAlert(false)}
						backdropDismiss={false}
						header="Inserisci il codice di verifica che hai ricevuto per email"
						buttons={[
							{
								text: 'Indietro',
								role: 'cancel',
							},
							{
								text: 'Ok',
								handler: (inputValues) => {
									const value = document.getElementById('otp-token').value
									if (value.length !== 4) return false
									checkOTP(value)
									document.getElementById('otp-token').value = ''
								},
							},
						]}
						inputs={[
							{
								type: 'number',
								placeholder: 'Codice',
								maxlength: 4,
								min: 1000,
								max: 9999,
								attributes: {
									id: 'otp-token',
								},
							},
						]}></IonAlert>
				) : (
					<IonAlert
						buttons={[
							{
								text: 'Reinvia email',
								handler: () => {
									setEmail('')
								},
							},
							{
								text: 'Ok',
								role: 'cancel',
							},
						]}
						onDidDismiss={() => setShowAlert(false)}
						isOpen={showAlert}
						header="Ti abbiamo inviato un’email per eseguire l’accesso. Se non la trovi, ti invitiamo a controllare anche nelle caselle di spam o posta indesiderata"
					/>
				)}
				<div className="container">
					<IonImg src={logo} />
					<div className="ion-padding-horizontal">
						<IonInput
							autoFocus={true}
							label="E-Mail"
							labelPlacement="stacked"
							color="primary"
							type="email"
							value={email}
							onInput={updateEmail}
						/>
					</div>
					<IonText color="danger">{emailError}</IonText>
					<IonButton
						shape="round"
						onClick={handleSubmit}
						disabled={isButtonDisabled || isLoading}
						className="ion-margin-vertical"
						color="primary"
						expand="full">
						{isButtonDisabled ? `RIPROVA IN ${countdown} SECONDI` : 'ACCEDI'}
					</IonButton>
					<div className="ion-margin ion-padding">Oppure</div>
					<IonButton
						shape="round"
						color="dark"
						fill="outline"
						expand="block"
						className="ion-margin-vertical"
						onClick={() => {
							history.push('?redirect=google')
							handleSocialSubmit('google')
						}}
						disabled={isLoading}>
						<IonIcon
							src={google}
							style={{ position: 'absolute', left: 0 }}
						/>
						<IonLabel>Accedi con Google</IonLabel>
					</IonButton>
					<IonButton
						shape="round"
						color="dark"
						fill="outline"
						expand="block"
						className="ion-margin-vertical"
						onClick={() => {
							history.push('?redirect=apple')
							handleSocialSubmit('apple')
						}}
						disabled={isLoading}>
						<IonIcon
							style={{ position: 'absolute', left: 0 }}
							icon={logoApple}
						/>
						<IonLabel>Accedi con Apple</IonLabel>
					</IonButton>
					{/* <IonButton
						shape="round"
						color="dark"
						fill="outline"
						expand="block"
						className="ion-margin-vertical"
						onClick={() => {
							history.push('?redirect=facebook')
							handleSocialSubmit('facebook')
						}}
						disabled={isLoading}>
						<IonIcon
							src={facebook}
							style={{ position: 'absolute', left: 0, color: '#3d6ad6' }}
						/>
						<IonLabel>Accedi con Facebook</IonLabel>
					</IonButton> */}
					<IonButton
						shape="round"
						color="dark"
						fill="outline"
						expand="block"
						className="ion-margin-vertical"
						onClick={() => {
							history.push('?redirect=microsoft')
							handleSocialSubmit('microsoft')
						}}
						disabled={isLoading}>
						<IonIcon
							src={microsoft}
							style={{ position: 'absolute', left: 0 }}
						/>
						<IonLabel>Accedi con Microsoft</IonLabel>
					</IonButton>
					<ReCAPTCHA
						size="invisible"
						ref={reCaptchaToken}
						sitekey="6Lf1U8ImAAAAABVtnDIPghDCnCCUngYAGsZTMcMN"
					/>
					<IonLoading
						isOpen={isLoading}
						message="Attendere prego"
					/>
				</div>
			</IonContent>
		</IonPage>
	)
}

export default LoginPage
