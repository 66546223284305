import {
	IonSegment,
	IonSegmentButton,
	IonLabel,
	useIonToast,
	IonFab,
	IonFabButton,
	IonIcon,
} from '@ionic/react'

import { useEffect, useState } from 'react'
import { SegmentGeneral } from './Segments'
import { useSelector } from 'react-redux'
import {
	selectGroupSlice,
	selectSegmentGroupSlice,
} from '../../redux/reducer/groupsSlice'
import { ImageLoader } from '../ImageLoader'
import '../../pages/Groups/Group/Group.min.css'
import { getGroupParams } from '../../services/functions'
import { add } from 'ionicons/icons'
import { useHistory } from 'react-router'

const segments = [
	{ value: 'general', label: 'generale' },
	{ value: 'fixed', label: 'in evidenza' },
]

const Group = ({
	from_main_group = false,
	group_id = null,
	// scrollValue,
	// setScrollTo,
}) => {
	// console.log('scrollValue:', scrollValue)
	const [segmentSelected, setSegmentSelected] = useState(segments[0].value)
	const fixedSelected = segmentSelected === segments[1].value
	const [firstLoaded, setFirstLoaded] = useState(false)
	const [firstLoadedFixed, setFirstLoadedFixed] = useState(false)

	const group = useSelector((state) => {
		const groupSelected = selectGroupSlice(from_main_group)
		return state.groups.getGroupPosts[groupSelected]
	})

	if (!group_id) group_id = group?.general?.data?.group?.id

	const { name, allow_subscriber_post } = useSelector((state) =>
		getGroupParams(state, group_id)
	)

	const header = group?.header
	const description = group.general.data?.group.description

	const { isLoading } = useSelector((state) => {
		const groupSelected = selectGroupSlice(from_main_group)
		const segmentSelected = selectSegmentGroupSlice(fixedSelected)
		return state.groups.getGroupPosts[groupSelected][segmentSelected]
	})

	const { telephone } = useSelector((state) => state.user.user)
	const [present] = useIonToast()
	const history = useHistory()

	return (
		<>
			{allow_subscriber_post && (
				<IonFab
					slot="fixed"
					vertical="top"
					horizontal="end"
					className="add-post-fab"
					onClick={() => {
						if (!telephone)
							return present({
								message:
									'Per interagire verifica il tuo cellulare nel tuo profilo',
								duration: 3000,
							})

						history.push('/gruppi/' + group_id + '/nuovo-post')
					}}>
					<IonFabButton
						size="medium"
						color="tertiary">
						<IonIcon icon={add}></IonIcon>
					</IonFabButton>
				</IonFab>
			)}
			{header && !isLoading && (
				<ImageLoader
					imageType="group"
					css="group-header-image"
					image={header}
				/>
			)}
			{!isLoading && description && (
				<p className="ion-padding">{description}</p>
			)}
			<div className="segment-container">
				<IonSegment
					value={segmentSelected}
					onIonChange={(e) => setSegmentSelected(e.target.value)}>
					{segments.map((segment) => (
						<IonSegmentButton
							key={segment.value}
							value={segment.value}>
							<IonLabel>{segment.label.toUpperCase()}</IonLabel>
						</IonSegmentButton>
					))}
				</IonSegment>
			</div>
			<SegmentGeneral
				is_fixed={fixedSelected}
				firstLoaded={fixedSelected ? firstLoadedFixed : firstLoaded}
				setFirstLoaded={fixedSelected ? setFirstLoadedFixed : setFirstLoaded}
				from_main_group={from_main_group}
				group_id={group_id}
			/>
		</>
	)
}

export default Group
