import { Icon } from '@iconify/react/dist/iconify.js'
import { IonToolbar, IonFooter, IonButton, IonButtons } from '@ionic/react'
import { useHistory } from 'react-router'

const MainFooter = () => {
	const history = useHistory()
	const routes = [
		{
			url: '/home',
			icon: <Icon icon="ph:house-line" />,
			iconDuotone: <Icon icon="ph:house-line-duotone" />,
			iconFill: <Icon icon="ph:house-line-fill" />,
			name: 'Home',
		},
		{
			url: '/menu',
			icon: <Icon icon="ph:notepad" />,
			iconDuotone: <Icon icon="ph:notepad-duotone" />,
			iconFill: <Icon icon="ph:notepad-fill" />,
			name: 'Menu',
		},
		{
			url: '/ricette',
			icon: <Icon icon="ph:bowl-food" />,
			iconDuotone: <Icon icon="ph:bowl-food-duotone" />,
			iconFill: <Icon icon="ph:bowl-food-fill" />,
			name: 'Ricette',
		},
		{
			url: '/diario',
			icon: <Icon icon="ph:chart-line" />,
			iconDuotone: <Icon icon="ph:chart-line-duotone" />,
			iconFill: <Icon icon="ph:chart-line-fill" />,
			name: 'Diario',
		},
		{
			url: '/multimedia',
			icon: <Icon icon="ph:monitor-play" />,
			iconDuotone: <Icon icon="ph:monitor-play-duotone" />,
			iconFill: <Icon icon="ph:monitor-play-fill" />,
			name: 'Media',
		},
		{
			url: '/multimedia#parent_group_id=78',
			icon: <Icon icon="ph:folder" />,
			iconDuotone: <Icon icon="ph:folder-duotone" />,
			iconFill: <Icon icon="ph:folder-fill" />,
			name: 'File',
			cache: true,
		},
	]

	const getIcon = (route) => {
		if (route.name === 'File') {
			return location.hash ? route.iconFill : route.icon
		} else {
			return location.pathname === route.url && !location.hash
				? route.iconFill
				: route.icon
		}
	}
	return (
		<IonFooter id="main-footer">
			<IonToolbar>
				<IonButtons className="ion-justify-content-around">
					{routes.map((route, index) => (
						<IonButton
							key={index}
							onClick={() => {
								history.push(route.url + (route.cache ? '#' + Date.now() : ''))
							}}>
							<div className="footerIcon">
								{getIcon(route)}
								<span className="capitalize-first-letter">{route.name}</span>
							</div>
						</IonButton>
					))}
					{/* <IonButton
						onClick={() => {
							history.push('/menu')
						}}>
						<div className="footerIcon">
							📋
							<span className="capitalize-first-letter">Menu</span>
						</div>
					</IonButton>
					<IonButton
						onClick={() => {
							history.push('/ricette')
						}}>
						<div className="footerIcon">
							🍝
							<span className="capitalize-first-letter">Ricette</span>
						</div>
					</IonButton>
					<IonButton
						onClick={() => {
							history.push('/diario')
						}}>
						<div className="footerIcon">
							📉
							<span className="capitalize-first-letter">Diario</span>
						</div>
					</IonButton>
					<IonButton
						onClick={() => {
							history.push('/multimedia')
						}}>
						<div className="footerIcon">
							🎬
							<span className="capitalize-first-letter">Media</span>
						</div>
					</IonButton>
					<IonButton
						onClick={() => {
							history.push('/multimedia#parent_group_id=78' + '#' + Date.now())
						}}>
						<div className="footerIcon">
							📁
							<span className="capitalize-first-letter">File</span>
						</div>
					</IonButton> */}
				</IonButtons>
			</IonToolbar>
		</IonFooter>
	)
}
export default MainFooter
