import {
	IonHeader,
	IonModal,
	IonToolbar,
	IonTitle,
	IonDatetime,
	IonButtons,
	IonButton,
	useIonToast,
	useIonLoading,
	useIonAlert,
} from '@ionic/react'
import dayjs from 'dayjs'
import env from 'react-dotenv'
import { useEffect, useRef, useState } from 'react'
import { ReactReduxContext, useDispatch, useSelector } from 'react-redux'
import { todayStringDate } from '../../services/functions'
import { getDatesToDisable, getUserInfo } from '../../redux/actions'
import config from '../../settings/config'
import { useHistory } from 'react-router'
import { NoRecords } from '../../components/NoRecords'
import SubscriptionAlert from '../SubscriptionAlert'
import ActivateNotifications from '../modals/NotificationsModal/ActivateNotifications'

const FreeRoutes = ({
	trigger,
	setIsOpen,
	modalRef,
	isModal = true,
	buttons = true,
}) => {
	const datetime = useRef(null)
	const modal = useRef(null)
	const [ionLoading, hideIonLoading] = useIonLoading()
	const [ionToast] = useIonToast()
	const [calendarDate, setCalendarDate] = useState(todayStringDate())
	const [isSubscriptionAlertOpen, setIsSubscriptionAlertOpen] = useState(false)
	const [AlertContent, setAlertContent] = useState(null)
	const history = useHistory()
	const dispatch = useDispatch()
	const isImpersonate = localStorage.getItem('adoc_i') === 'true'
	const [showNotificationsModal, setShowNotificationsModal] = useState(false)
	const [notificationAccepted, setNotificationAccepted] = useState(null)
	const [notificationSystemAccepted, setNotificationSystemAccepted] =
		useState(null)
	const [selectedDate, setSelectedDate] = useState(null)

	const setStartDate = async (date) => {
		ionLoading()

		try {
			const response = await fetch(
				`${config.env[config.env.mode].url}/api/master-club-subscriptions`,
				{
					method: 'POST',
					body: JSON.stringify({
						free_route_start_date: dayjs(date).format('YYYY-MM-DD'),
					}),
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization: `Bearer ${localStorage.getItem('adoc_t')}`,
					},
				}
			)
			const data = await response.json()

			if (data.subscription_alert) {
				setAlertContent({
					header: data.subscription_alert,
					message: '',
					hide_buttons: ['free'],
				})
				setIsSubscriptionAlertOpen(true)
			}

			if (response.ok && data.message) {
				ionToast({
					message: data.message,
					duration: 4000,
				})

				if (isModal) {
					setIsOpen(false)
					modalRef.current?.dismiss()
				}

				dispatch(getUserInfo())

				if (data.course) {
					// If there's a course then go to how course works page
					console.log('redirect to /corso')
					history.push('/aiuto/come-funziona-la-prova-gratuita')
				} else {
					history.push('/menu')
				}
			}

			hideIonLoading()
		} catch (error) {
			console.log(error)
			hideIonLoading()
		}
	}

	const cancel = () => {
		datetime.current?.cancel()
		modal.current?.dismiss()
	}
	const confirm = () => {
		datetime.current?.confirm()
		modal.current?.dismiss()
		const value = datetime.current?.value
		// setCalendarDate(value)
		setStartDate(value)
	}

	const { daysLimit, disableDates, routes, isLoading, error } = useSelector(
		(state) => state.dietPlan.getDatesToDisable
	)

	const [min, setMin] = useState(
		dayjs() < dayjs(daysLimit[0])
			? dayjs(daysLimit[0]).format('YYYY-MM-DDTHH:mm:ssZ')
			: dayjs().format('YYYY-MM-DDTHH:mm:ssZ')
	)

	// console.log('routes', routes)
	// console.log('isLoading', isLoading)

	const { master_club_subscriber } = useSelector((state) => state.user)

	const [presentAlert] = useIonAlert()

	useEffect(() => {
		if (!selectedDate) return
		setShowNotificationsModal(true)
	}, [selectedDate])

	useEffect(() => {
		if (!selectedDate) return

		if (!showNotificationsModal) {
			if (notificationSystemAccepted) {
				presentAlert({
					header: 'Attenzione',
					message:
						'Hai a disposizione ' +
						env.MAX_FREE_ROUTES +
						'. Iscrivendoti ora, avrai l’opportunità di sperimentare il nostro programma e, se lo desideri, potrai annullare la tua iscrizione entro i primi quattro giorni di dieta. In caso contrario, la tua prova verrà considerata completata. Sei pronto a iniziare il tuo percorso con noi, ' +
						selectedDate.format('dddd D MMMM YYYY') +
						'?',
					buttons: [
						{
							text: 'Annulla',
							role: 'cancel',
							handler: () => {},
						},
						{
							text: 'Si',
							role: 'confirm',
							handler: () => {
								setStartDate(selectedDate.format('YYYY-MM-DD'))
							},
						},
					],
				})
			} else if (
				notificationAccepted === false ||
				notificationSystemAccepted === false
			) {
				presentAlert({
					header: 'Ci dispiace!',
					message:
						'Per iscriverti al nostro percorso gratuito devi prima attivare le notifiche. Per farlo vai in IMPOSTAZIONI del tuo cellulare, cerca Dieta Social tra le tue applicazioni e sblocca (consenti) le notifiche.',
					buttons: [
						{
							text: 'Ok',
							role: 'confirm',
							handler: () => {},
						},
					],
				})
			}
		}
		// console.log('showNotificationsModal', showNotificationsModal)
		// console.log('notificationAccepted', notificationAccepted)
		// console.log('notificationSystemAccepted', notificationSystemAccepted)
	}, [notificationAccepted, showNotificationsModal, notificationSystemAccepted])

	useEffect(() => {
		dispatch(getDatesToDisable(true))
	}, [master_club_subscriber])

	useEffect(() => {
		// console.log('daysLimit', daysLimit)
		setMin(
			dayjs() < dayjs(daysLimit[0])
				? dayjs(daysLimit[0]).format('YYYY-MM-DDTHH:mm:ssZ')
				: dayjs().format('YYYY-MM-DDTHH:mm:ssZ')
		)
		if (daysLimit[0] === daysLimit[1]) {
			dispatch(getDatesToDisable(true))
		}
	}, [daysLimit])

	const isCalendarEnabled = (dateString) => {
		const date = new Date(dateString)
		//Disable if not monday
		if (date.getDay() !== 1) return false
		return !disableDates.some((disableDate) => {
			const disableDateObj = new Date(disableDate)
			return disableDateObj.getTime() === date.getTime()
		})
	}

	const isDateOutsideLimits = (selectedDate) => {
		const date = dayjs(selectedDate)
		const minDate = dayjs(min)
		const maxDate = dayjs(daysLimit[1])

		return date.isBefore(minDate) || date.isAfter(maxDate)
	}

	const Toolbar = () => {
		return (
			<IonToolbar style={{ '--min-height': '2.5rem' }}>
				<IonTitle
					color="gray"
					style={{
						fontSize: '1.25rem',
						textAlign: 'center',
						paddingInline: '0',
						paddingBottom: '0.5rem',
					}}>
					Scegli la data di inizio dieta
				</IonTitle>
			</IonToolbar>
		)
	}

	const StartCalendar = () => {
		return (
			<>
				{isModal ? (
					<IonHeader style={{ paddingTop: '0.5rem' }}></IonHeader>
				) : (
					<Toolbar />
				)}

				<IonDatetime
					ref={datetime}
					size="cover"
					locale="it-IT"
					firstDayOfWeek={1}
					presentation="date"
					isDateEnabled={isCalendarEnabled}
					onIonChange={(e) => {
						setCalendarDate(e.detail.value)
					}}
					value={dayjs(calendarDate).toISOString()}
					min={min}
					max={dayjs(daysLimit[1]).toISOString()}>
					{isModal && (
						<IonButtons
							mode="ios"
							slot="buttons">
							<div
								style={{
									display: 'flex',
									width: '100vw',
									paddingLeft: '1rem',
									paddingRight: '1rem',
									justifyContent: 'space-between',
								}}>
								<IonButton
									color="primary"
									slot="start"
									onClick={cancel}>
									INDIETRO
								</IonButton>
								<IonButton
									color="primary"
									slot="end"
									disabled={
										!isCalendarEnabled || isDateOutsideLimits(calendarDate)
									}
									onClick={confirm}>
									INIZIA
								</IonButton>
							</div>
						</IonButtons>
					)}
				</IonDatetime>
				{!isModal && (
					<IonButton
						color="primary"
						expand="block"
						disabled={!isCalendarEnabled || isDateOutsideLimits(calendarDate)}
						onClick={() => {
							setStartDate(calendarDate)
						}}>
						INIZIA
					</IonButton>
				)}
			</>
		)
	}

	const StartButtons = () => {
		const possibleMondays = []
		const today = dayjs()

		let minDate = dayjs(min)
		const minDateWeekday = minDate.day()
		minDate = dayjs().subtract(minDateWeekday, 'day')

		const maxDate = dayjs(daysLimit[1])
		const daysDiff = maxDate.diff(minDate, 'day')

		const enabledFreeRoutes = config.env.freeRoutes

		for (let i = 0; i < daysDiff; i++) {
			const date = minDate.add(i, 'day')
			if (date.day() === 1) {
				if (date > dayjs()) possibleMondays.push(date)
			}
		}

		// console.log('possibleMondays', possibleMondays)
		const buttons = possibleMondays.map((date) => {
			return (
				<IonButton
					key={date.format('YYYY-MM-DD')}
					color="primary"
					expand="block"
					onClick={() => {
						setSelectedDate(date)
					}}>
					{date.format('dddd D MMMM YYYY')}
				</IonButton>
			)
		})

		// console.log('enabledFreeRoutes', enabledFreeRoutes)
		// console.log('isImpersonate', isImpersonate)
		// console.log('possibleMondays.length', possibleMondays.length)
		return (
			<>
				{isModal ? (
					<IonHeader style={{ paddingTop: '0.5rem' }}></IonHeader>
				) : (
					<Toolbar />
				)}

				{isLoading ? (
					<p className="ion-text-center">
						<b>Caricando percorsi...</b>
					</p>
				) : (enabledFreeRoutes && possibleMondays.length) ||
				  (!enabledFreeRoutes && isImpersonate) ? (
					buttons
				) : (
					<>
						<NoRecords text="date" />
						<IonButton
							className="ion-margin-top ion-padding-horizontal"
							onClick={() => history.push('/prodotti')}
							expand="full"
							color="primary">
							DIVENTA PREMIUM
						</IonButton>
					</>
				)}
			</>
		)
	}

	return (
		<>
			{isModal ? (
				<IonModal
					ref={modal}
					trigger={trigger}
					initialBreakpoint={1}
					style={{ '--height': ' auto' }}
					breakpoints={[0, 1]}>
					{buttons ? <StartButtons /> : <StartCalendar />}
				</IonModal>
			) : buttons ? (
				<StartButtons />
			) : (
				<StartCalendar />
			)}

			<ActivateNotifications
				redirectHome={false}
				showNotificationsModal={showNotificationsModal}
				setShowNotificationsModal={setShowNotificationsModal}
				setNotificationAccepted={setNotificationAccepted}
				setNotificationSystemAccepted={setNotificationSystemAccepted}
			/>

			<SubscriptionAlert
				isOpen={isSubscriptionAlertOpen}
				setIsOpen={setIsSubscriptionAlertOpen}
				AlertContent={AlertContent}
			/>
		</>
	)
}

export default FreeRoutes
