import {
	IonContent,
	IonHeader,
	IonToolbar,
	IonButtons,
	IonBackButton,
	IonPage,
	IonTitle,
	IonRefresher,
	IonRefresherContent,
	IonButton,
} from '@ionic/react'

import MainFooter from '../../components/mainFooter'
import { clearCache } from '../../services/functions'

import { useHistory } from 'react-router'
import { Icon } from '@iconify/react'

const PaymentMethodProcessingPage = () => {
	const href = '/profilo/abbonamenti'
	const history = useHistory()
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton
							defaultHref={href}
							text=""></IonBackButton>
					</IonButtons>
					<IonTitle>Pagamento</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent className="ion-padding ion-text-center">
				<IonRefresher
					slot="fixed"
					pullFactor={0.5}
					pullMin={100}
					pullMax={200}
					onIonRefresh={clearCache}>
					<IonRefresherContent></IonRefresherContent>
				</IonRefresher>
				<Icon
					style={{ marginTop: '70px', color: 'var(--ion-color-primary)' }}
					icon="line-md:check-all"
					width="100"
				/>
				<h1>Processando metodo di pagamento</h1>
				<p>
					Se l’operazione è avvenuta correttamente, riceverai a breve una
					notifica di conferma.
				</p>
				<IonButton
					color="primary"
					onClick={() => {
						history.push(href)
					}}>
					Torna ai miei abbonamenti
				</IonButton>
			</IonContent>
			<MainFooter />
		</IonPage>
	)
}

export default PaymentMethodProcessingPage
