import { createAnimation } from '@ionic/react'

export const modalEnterAnimationFromTop = (baseEl) => {
	const root = baseEl.shadowRoot

	const backdropAnimation = createAnimation()
		.addElement(root.querySelector('ion-backdrop'))
		.fromTo('opacity', '0.01', 'var(--backdrop-opacity)')

	const wrapperAnimation = createAnimation()
		.addElement(root.querySelector('.modal-wrapper'))
		.easing('ease-out')
		.duration(500)
		.fromTo('transform', 'translateY(-100%)', 'translateY(0)')

	return createAnimation()
		.addElement(baseEl)
		.easing('ease-out')
		.duration(500)
		.addAnimation([backdropAnimation, wrapperAnimation])
}

export const modalLeaveAnimationToTop = (baseEl) => {
	return modalEnterAnimationFromTop(baseEl).direction('reverse')
}

export const modalEnterAnimationFromRight = (baseEl) => {
	const root = baseEl.shadowRoot

	const backdropAnimation = createAnimation()
		.addElement(root.querySelector('ion-backdrop'))
		.fromTo('opacity', '0.01', 'var(--backdrop-opacity)')

	const wrapperAnimation = createAnimation()
		.addElement(root.querySelector('.modal-wrapper'))
		.easing('ease-out')
		.duration(300)
		.fromTo('transform', 'translateX(100%)', 'translateX(0)')
		.fromTo('opacity', '1', '1')

	// const wrapperAnimation2 = createAnimation()
	//   .addElement(root.querySelector('.modal-wrapper'))
	//   .keyframes([
	//     { offset: 0, opacity: '0', transform: 'scale(0)' },
	//     { offset: 1, opacity: '0.99', transform: 'scale(1)' }
	//   ])

	return createAnimation()
		.addElement(baseEl)
		.easing('ease-out')
		.duration(300)
		.addAnimation([backdropAnimation, wrapperAnimation])
}
export const modalEnterAnimationFromLeft = (baseEl) => {
	const root = baseEl.shadowRoot

	const backdropAnimation = createAnimation()
		.addElement(root.querySelector('ion-backdrop'))
		.fromTo('opacity', '0.01', 'var(--backdrop-opacity)')

	const wrapperAnimation = createAnimation()
		.addElement(root.querySelector('.modal-wrapper'))
		.easing('ease-out')
		.duration(300)
		.fromTo('transform', 'translateX(-100%)', 'translateX(0)')
		.fromTo('opacity', '1', '1')

	return createAnimation()
		.addElement(baseEl)
		.easing('ease-out')
		.duration(300)
		.addAnimation([backdropAnimation, wrapperAnimation])
}

export const modalLeaveAnimationToRight = (baseEl) => {
	return modalEnterAnimationFromRight(baseEl).direction('reverse')
}

export const modalLeaveAnimationToLeft = (baseEl) => {
	return modalEnterAnimationFromLeft(baseEl).direction('reverse')
}

export const enterAnimation = (baseEl) => {
	const root = baseEl.shadowRoot

	const backdropAnimation = createAnimation()
		.addElement(root?.querySelector('ion-backdrop'))
		.fromTo('opacity', '0.01', 'var(--backdrop-opacity)')

	const wrapperAnimation = createAnimation()
		.addElement(root?.querySelector('.modal-wrapper'))
		.keyframes([
			{ offset: 0, opacity: '0', transform: 'scale(0)' },
			{ offset: 1, opacity: '0.99', transform: 'scale(1)' },
		])

	return createAnimation()
		.addElement(baseEl)
		.easing('ease-out')
		.duration(500)
		.addAnimation([backdropAnimation, wrapperAnimation])
}

export const leaveAnimation = (baseEl) => {
	return enterAnimation(baseEl).direction('reverse')
}
