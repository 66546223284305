import { useEffect } from 'react'
import { Route, Redirect, useLocation } from 'react-router'

const getFromStorage = () => {
	const user = !!JSON.parse(localStorage.getItem('adoc_p') ?? '{}')?.user
	const subscriber = !!JSON.parse(localStorage.getItem('adoc_p') ?? '{}')
		?.master_club_subscriber
	const adoc_t = !!localStorage.getItem('adoc_t')

	return { user, subscriber, adoc_t }
}

export const isFullAuth = () => {
	const { user, subscriber, adoc_t } = getFromStorage()
	return adoc_t && user && subscriber
}

export const isPreAuth = () => {
	const { user, subscriber, adoc_t } = getFromStorage()
	return adoc_t && user && !subscriber
}

export const userAuthStatus = () => {
	if (isFullAuth() === true) return 'full'
	if (isPreAuth() === true) return 'pre'
	return 'none'
}

export const getAuthPath = () => {
	const pathName = location.pathname
	const nonePaths = ['/', '/login']
	if (nonePaths.includes(pathName) || pathName.includes('/o/')) {
		return 'none'
	}
	if (pathName.includes('/impostazioni-iniziali')) {
		return 'pre'
	}
	return 'full'
}

export const RouteAuth = ({ component: Component, path, ...rest }) => {
	const location = useLocation()
	const pathName = location.pathname
	const userStatus = userAuthStatus()

	const authPath = getAuthPath()

	useEffect(() => {
		// console.table({
		// 	pathName,
		// 	userStatus,
		// 	authPath,
		// })
	})

	if (authPath !== userStatus) {
		if (userStatus === 'full' && pathName !== '/home') {
			console.log(pathName, 'REDIRECT TO HOME')
			return <Redirect to="/home" />
			// window.location.href = '/home'
		}
		if (userStatus === 'pre' && !pathName.includes('/impostazioni-iniziali')) {
			console.log(pathName, 'REDIRECT TO SETTINGS')
			return <Redirect to="/impostazioni-iniziali/1" />
			// window.location.href = '/impostazioni-iniziali/1'
		}
		if (userStatus === 'none' && pathName !== '/') {
			console.log(pathName, 'REDIRECT TO LOGIN')
			return <Redirect to="/" />
			// window.location.href = '/'
		}
	}
	return (
		<Route
			{...rest}
			render={(props) => {
				return <Component {...props} />
			}}
		/>
	)
}
