import {
	IonContent,
	IonPage,
	IonRefresher,
	IonRefresherContent,
} from '@ionic/react'
import './PreLogin.min.css'
import { clearCache } from '../../services/functions'
import { Swiper, SwiperSlide } from 'swiper/react'
import image1 from '../../assets/images/prelogin/1.jpg'
import dietaSocialLogoWhite from '../../assets/images/prelogin/1-logo.svg'
import dietaSocialLogo from '../../assets/images/ds-logo.svg'
import image2 from '../../assets/images/prelogin/2.jpg'
import image3 from '../../assets/images/prelogin/3.jpg'
import image4 from '../../assets/images/prelogin/4.jpg'
import image5 from '../../assets/images/prelogin/5.jpg'
import image6 from '../../assets/images/prelogin/6.jpg'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

// import required modules
import { Pagination, Navigation } from 'swiper/modules'
import { useHistory, useParams } from 'react-router'
import { useEffect } from 'react'

const PreLoginPage = () => {
	const history = useHistory()

	const { origin } = useParams()

	useEffect(() => {
		if (origin) {
			localStorage.setItem('adoc_origin', origin)
		}
	}, [origin])

	return (
		<IonPage id="pre-login-page">
			<IonContent fullscreen>
				<IonRefresher
					slot="fixed"
					pullFactor={0.5}
					pullMin={100}
					pullMax={200}
					onIonRefresh={clearCache}>
					<IonRefresherContent></IonRefresherContent>
				</IonRefresher>
			</IonContent>
			<Swiper
				pagination={true}
				navigation={true}
				modules={[Pagination, Navigation]}
				className="mySwiper"
				onSwiper={(swiper) => {
					// Configurar el evento al finalizar el swiper
					swiper.on('slideChange', () => {
						if (swiper.isEnd) {
							history.push('/login')
						}
					})
				}}>
				<SwiperSlide>
					<div className="top">
						<div className="container">
							<img
								src={dietaSocialLogoWhite}
								alt="Dieta Social Logo"
								className="logo"
							/>
						</div>
					</div>
					<div className="bottom">
						<div className="container">
							<div className="text">
								Il tuo programma <br />
								nutrizionale equilibrato, <br />e sostenibile
							</div>
							<div
								onClick={() => {
									history.push('/login')
								}}
								className="login-button">
								Hai già un account?
							</div>
						</div>
					</div>
					<img
						src={image1}
						alt="Image"
					/>
				</SwiperSlide>
				<SwiperSlide>
					<div className="top">
						<div className="container">
							Il primo social <br />
							incentrato sul <br />
							benessere.
						</div>
					</div>
					<div className="bottom">
						<div className="container">
							Entra nella nostra community, ricevi sostegno, scambia censigli e
							condividi esperienze con chi ha i tuoi stessi obietivi di salute.
						</div>
					</div>
					<img
						src={image2}
						alt="Image"
					/>
				</SwiperSlide>
				<SwiperSlide>
					<div className="top">
						<div className="container">
							<h1>Oltre 8.000 ricette per tutti i gusti.</h1>
							Prepara piatti semplici seguendo passo per passo le nostre
							indicazioni per valorizzare l{"'"}uso di ingredienti sani e
							genuini.
						</div>
					</div>
					<img
						src={image3}
						alt="Image"
					/>
				</SwiperSlide>
				<SwiperSlide>
					<div className="top">
						<div className="container">
							<h1>Menù ogni giorno diversi e per tutte le esigenze.</h1>
							Segui i nostri piani alimentari per onnivori, vegetariani, senza
							glutine, senza lattosio, per chi mangia fuori e per chi cucina per
							tutta la famiglia. Menù speciali: sblocca- metabolismo,
							linfodrenanti, digiuno consapevole...
						</div>
					</div>
					<div className="bottom">
						<div className="container">
							<img src={dietaSocialLogo} />
						</div>
					</div>
					<img
						src={image4}
						alt="Image"
					/>
				</SwiperSlide>
				<SwiperSlide>
					<div className="top">
						<div className="container">
							<h1>Lista della spesa ben organizzata e personalizzata.</h1>
							Evita gli sprechi in cucina e risparmia grazie all{"'"}acquisto
							consapevole solo degli ingredienti di cui hai bisogno.
						</div>
					</div>
					<img
						src={image5}
						alt="Image"
					/>
				</SwiperSlide>
				<SwiperSlide>
					<div className="top">
						<div className="container">
							<h1>
								Migliaia di video tutorial per il tuo nuovo stile di vita.
							</h1>
							<div className="text">
								Ritrova equilibrio e autostima grazie a consigli di psicologia e
								mental coaching. Rimettiti in forma con le lezioni di yoga,
								fitness e ginnastica posturale. Conosci gli effetti del cibo sul
								tuo corpo.
								<br />
								Impara a cucinare sano. Prenditi cura di te con i nostri
								tutorial di bellezza.
							</div>
						</div>
					</div>
					<div className="bottom">
						<div className="container">
							<img src={dietaSocialLogo} />
						</div>
					</div>
					<img
						src={image6}
						alt="Image"
					/>
				</SwiperSlide>
				<SwiperSlide></SwiperSlide>
			</Swiper>
		</IonPage>
	)
}

export default PreLoginPage
