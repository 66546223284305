import React, { useEffect, useState } from 'react'
import {
	IonContent,
	IonPage,
	IonSegment,
	IonSegmentButton,
	IonLabel,
	IonRefresher,
	IonRefresherContent,
	useIonViewDidEnter,
	useIonViewWillLeave,
	useIonViewDidLeave,
} from '@ionic/react'
import MainFooter from '../../components/mainFooter'
import MainHeader from '../../components/headers/mainHeader'
import SubscriptionsContent from './SubscriptionsContent'
import DataContent from './DataContent'
import { clearCache } from '../../services/functions'
import './Profile.min.css'

import { Redirect } from 'react-router'
import { getUserInfo } from '../../redux/actions'
import { useDispatch } from 'react-redux'

const segments = {
	data: 'dati-personali',
	subscriptions: 'abbonamenti',
}

const ProfilePage = () => {
	const defaultTab =
		Object.values(segments).find((segment) =>
			window.location.pathname.includes(segment)
		) || segments.data

	const dispatch = useDispatch()

	const [segmentSelected, setSegmentSelected] = useState(defaultTab)

	useIonViewDidEnter(() => {
		dispatch(getUserInfo())
	})

	useIonViewDidLeave(() => {
		setSegmentSelected(defaultTab)
	})

	return (
		<IonPage className="profile-page">
			<MainHeader
				page="ProfilePage"
				title="Profilo"
			/>
			<IonContent>
				<IonRefresher
					slot="fixed"
					pullFactor={0.5}
					pullMin={100}
					pullMax={200}
					onIonRefresh={clearCache}>
					<IonRefresherContent></IonRefresherContent>
				</IonRefresher>
				<div className="segment-container">
					<IonSegment
						value={defaultTab}
						onIonChange={(e) => setSegmentSelected(e.detail.value)}>
						<IonSegmentButton value={segments.data}>
							<IonLabel>DATI PERSONALI</IonLabel>
						</IonSegmentButton>
						<IonSegmentButton value={segments.subscriptions}>
							<IonLabel>ABBONAMENTI</IonLabel>
						</IonSegmentButton>
					</IonSegment>
				</div>
				<Redirect
					path="/profilo"
					to={`/profilo/${segmentSelected}`}
				/>
				{segmentSelected === segments.data && <DataContent />}
				{segmentSelected === segments.subscriptions && (
					<SubscriptionsContent setSegmentSelected={setSegmentSelected} />
				)}
			</IonContent>

			<MainFooter />
		</IonPage>
	)
}

export default ProfilePage
