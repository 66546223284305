const getMultimediaGroupsListPending = (state, action) => {
  state.getMultimediaGroupsList.isLoading = true
  state.getMultimediaGroupsList.error = null
}
const getMultimediaGroupsListFulfilled = (state, action) => {
  state.getMultimediaGroupsList.isLoading = false
  state.getMultimediaGroupsList.data = action.payload
}
const getMultimediaGroupsListRejected = (state, action) => {
  state.getMultimediaGroupsList.isLoading = false
  state.getMultimediaGroupsList.error = action.error.message
}

const getMultimediaGroupsListFunctions = { getMultimediaGroupsListPending, getMultimediaGroupsListFulfilled, getMultimediaGroupsListRejected }

const getMultimediaPostsFunctionsPending = (state, action) => {
  state.getMultimediaPosts.isLoading = true
  state.getMultimediaPosts.error = null
  const tag_id = action.meta.arg.tag_id
  if (tag_id && state.getMultimediaPosts.data) {
    state.getMultimediaPosts.data.posts = state.getMultimediaPosts.data.posts.filter(post => post.post.tags.some(tag => tag.id === tag_id))
  }
}
const getMultimediaPostsFunctionsFulfilled = (state, action) => {
  state.getMultimediaPosts.isLoading = false
  state.getMultimediaPosts.data = action.payload
}
const getMultimediaPostsFunctionsRejected = (state, action) => {
  state.getMultimediaGroupsList.isLoading = false
  state.getMultimediaPosts.error = action.error.message
}

const getMultimediaPostsFunctions = { getMultimediaPostsFunctionsPending, getMultimediaPostsFunctionsFulfilled, getMultimediaPostsFunctionsRejected }

const switchLike = (state, action) => {
  const post_id = action.meta.arg.element_id
  const post_index = state.getMultimediaPosts.data?.posts?.findIndex(post => post.id === post_id)

  if (post_index >= 0) {
    const liked_old = state.getMultimediaPosts.data?.posts[post_index].liked
    state.getMultimediaPosts.data.posts[post_index].liked = !liked_old
    state.getMultimediaPosts.data.posts[post_index].likes_count += liked_old ? -1 : 1
  }
}
const likeMultimediaPostFunctionsPending = (state, action) => {
  switchLike(state, action)
}
const likeMultimediaPostFunctionsFulfilled = () => {}
const likeMultimediaPostPostsFunctionsRejected = (state, action) => {
  switchLike(state, action)
}

const likeMultimediaPostFunctions = { likeMultimediaPostFunctionsPending, likeMultimediaPostFunctionsFulfilled, likeMultimediaPostPostsFunctionsRejected }

export { getMultimediaGroupsListFunctions, getMultimediaPostsFunctions, likeMultimediaPostFunctions }
