import { IonButton } from '@ionic/react'
import { setPost } from '../../../redux/reducer/groupsSlice'
import { processPostHTML } from '../../../services/functions'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'

export default function PostContent({
	postGroup,
	is_preview,
	from_main_group,
}) {
	const history = useHistory()
	const dispatch = useDispatch()
	const title = postGroup.post.title
	const group_id = postGroup.group_id
	const post_id = postGroup.id
	let description = postGroup.post.content
	let descriptionCutted = description ? cutString(description, ' ') : ''

	function cutString(string, cutAfterChar = '.', maxLength = 150) {
		if (string.length > maxLength) {
			//find index of first cutAfterChar after maxLength
			let index = string.indexOf(cutAfterChar, maxLength)
			//if the index is not found, return the original string
			if (index === -1) return string
			//return the string, cutting at the last space before maxLength
			return string.substring(0, index) + '...'
		} else {
			return string
		}
	}

	// Remove last word if it's a link
	if (descriptionCutted.split(' ').slice(-1).join().indexOf('http') > -1) {
		descriptionCutted = descriptionCutted.split(' ').slice(0, -1).join(' ')
	}

	const handleReadButtonClick = () => {
		dispatch(setPost({ post_group: { ...postGroup, from_main_group } }))
		history.push(`/gruppi/${group_id}/${post_id}`)
	}

	window.handleButtonLink = (e) => {
		const href = e.getAttribute('data-href')
		if (!href) return
		if (href.indexOf('.') > -1) {
			window.location.href = 'https://' + href
		} else {
			history.push('/' + e.getAttribute('data-href'))
		}
	}

	description = is_preview ? descriptionCutted : description

	return (
		<>
			<div className="ion-padding-horizontal ion-padding-bottom">
				{is_preview ? <h4>{title}</h4> : <h3>{title}</h3>}
				<div className="post-description">{processPostHTML(description)}</div>
				{is_preview && (
					<div style={{ paddingBottom: '0.5rem' }}>
						<IonButton
							className="buttonWithoutPadding"
							size="small"
							fill="clear"
							color="gray"
							strong={true}
							onClick={handleReadButtonClick}>
							LEGGI ANCORA
						</IonButton>
					</div>
				)}
			</div>
		</>
	)
}
