import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonCol,
	IonSkeletonText,
} from '@ionic/react'

export const ProductSkeleton = () => (
	<>
		<IonCol
			size="12"
			sizeMd="4">
			<IonCard className="product-card">
				<div
					style={{
						height: '212px',
						display: 'block',
					}}>
					<IonSkeletonText
						animated={true}
						style={{
							margin: '0 auto',
							width: '100%',
							height: '100%',
						}}
					/>
				</div>
				<IonCardHeader>
					<IonCardSubtitle>
						<IonSkeletonText
							animated={true}
							style={{
								width: '40%',
								height: '1rem',
							}}
						/>
					</IonCardSubtitle>
					<IonCardTitle>
						<IonSkeletonText
							animated={true}
							style={{
								width: '60%',
								height: '2rem',
							}}
						/>
					</IonCardTitle>
				</IonCardHeader>

				<IonCardContent>
					<IonSkeletonText
						animated={true}
						style={{
							height: '1rem',
						}}
					/>
					<IonSkeletonText
						animated={true}
						style={{
							height: '1rem',
						}}
					/>
				</IonCardContent>

				<IonButton fill="clear">
					<IonSkeletonText
						animated={true}
						style={{
							width: '6rem',
							height: '2rem',
						}}
					/>
				</IonButton>
			</IonCard>
		</IonCol>
	</>
)
