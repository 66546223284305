import { createAsyncThunk } from '@reduxjs/toolkit'
import { baseUrl, headersDefault } from '.'

import dayjs from 'dayjs'

import {
	orderDatesSubArrays,
	substractOneDayToEndDatesSubArrays,
	getNotIncludedDates,
	synchronizeDatetoString,
	subtractOneDayToDateString,
} from '../../services/functions'
import axios from 'axios'

export const getDietPlan = createAsyncThunk(
	'dietPlan/getDietPlan',
	async (date) => {
		const params = {}

		if (date) params.date = synchronizeDatetoString(date)

		const response = await axios.get(
			`${baseUrl}/api/master-club-route/subscriber-route`,
			{
				headers: headersDefault(),
				params,
			}
		)

		if (response.status !== 200)
			throw new Error(
				(response.message || (await response.json()).error) ?? 'GenericError'
			)

		const data = response.data

		const newDietPlan = Array(7)
			.fill()
			.map(() => [])

		data.master_club_plan.cycles.forEach(
			(food) => (newDietPlan[food.day - 1][food.meal_id - 1] = food)
		)
		const dates = [
			dayjs(data.master_club_route.start).utc(true).format('YYYY-MM-DD'),
			dayjs(data.master_club_route.end)
				.utc(true)
				.subtract(1, 'day')
				.format('YYYY-MM-DD'),
		]

		return {
			dietPlan: newDietPlan,
			dayDishes: data.master_club_plan.day_dishes,
			dates,
			foods: data.master_club_plan.foods,
			plan: data.master_club_plan.master_club_route_plan,
			route: data.master_club_route,
			shoppingList: data.master_club_plan.shoppingList,
		}
	}
)

export const getDatesToDisable = createAsyncThunk(
	'dietPlan/getDatesToDisable',
	async (includeFreeRoutes = false) => {
		const response = await fetch(
			`${baseUrl}/api/master-club-route/subscriber-routes`,
			{
				headers: headersDefault(),
			}
		)

		if (response.status !== 200)
			throw new Error(
				(response.message || (await response.json()).error) ?? 'GenericError'
			)

		const data = await response.json()

		// if (data.length === 0) throw new Error('Empty Array')

		const dietRoutes = data
			.filter(
				(dietRoute) => includeFreeRoutes || 'diet_plan_cycles' in dietRoute
			)
			.map((dietRoute) => [
				dayjs(dietRoute.route.start).format('YYYY-MM-DD'),
				dayjs(dietRoute.route.end).format('YYYY-MM-DD'),
			])

		dietRoutes.sort(orderDatesSubArrays)
		dietRoutes.forEach(substractOneDayToEndDatesSubArrays)

		if (dietRoutes.length === 0) {
			dietRoutes.push([
				dayjs().format('YYYY-MM-DD'),
				dayjs().format('YYYY-MM-DD'),
			])
		}

		const minDate = dietRoutes[0][0] + ' 00:00:00'
		const maxDate = dietRoutes[dietRoutes.length - 1][1] + ' 23:59:59'

		const routesToDisable = getNotIncludedDates(dietRoutes)

		return {
			routes: data,
			daysLimit: [minDate, maxDate],
			disableDates: routesToDisable,
		}
	}
)

export const shoppingListChecks = createAsyncThunk(
	'dietPlan/shoppingListChecks',
	async (values) => {
		const response = await fetch(
			`${baseUrl}/api/master-club-shopping-list-checks`,
			{
				method: 'POST',
				body: JSON.stringify(values),
				headers: headersDefault(),
			}
		)

		if (response.status !== 200)
			throw new Error(
				(response.message || (await response.json()).error) ?? 'GenericError'
			)

		return await response.json()
	}
)

export const deleteShoppingListCheck = createAsyncThunk(
	'dietPlan/deleteShoppingListCheck',
	async (id) => {
		const response = await fetch(
			`${baseUrl}/api/master-club-shopping-list-checks/${id}`,
			{
				method: 'DELETE',
				headers: headersDefault(),
			}
		)

		console.log('response', response)
		if (response.status !== 200)
			throw new Error(
				(response.message || (await response.json()).error) ?? 'GenericError'
			)

		return await response.json()
	}
)
