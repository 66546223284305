import {
	Chart,
	DoughnutController,
	ArcElement,
	Tooltip,
	Legend,
} from 'chart.js'
import { useEffect, useRef } from 'react'
import { NoRecords } from '../../components/NoRecords'

Chart.register(DoughnutController, ArcElement, Tooltip, Legend)
const wellnessData = {
	labels: ["Un po' giu", 'Così così', 'Bene', 'Felice', 'Alla grande'],
	datasets: [
		{
			data: [1, 2, 3, 4, 5],
			backgroundColor: ['#e91e63', '#ff9800', '#ffc107', '#cddc39', '#4caf50'],
			hoverBackgroundColor: [
				'#e91e63',
				'#ff9800',
				'#ffc107',
				'#cddc39',
				'#4caf50',
			],
		},
	],
}

const wellnessOptions = {
	plugins: {
		legend: {
			labels: {
				color: '#495057',
			},
		},
	},
}

const WellnessContent = ({ wellness }) => {
	const chartRef = useRef(null)

	useEffect(() => {
		if (wellness.reduce((a, c) => a + c) === 0) return

		if (chartRef.current) {
			chartRef.current.destroy()
		}
		wellnessData.datasets[0].data = [...wellness]

		chartRef.current = new Chart(document.getElementById('wellness-chart'), {
			type: 'doughnut',
			data: wellnessData,
			options: wellnessOptions,
		})
	}, [wellness])

	console.log('wellness', wellness.length)

	return (
		<>
			{wellness.length === 0 ? (
				<NoRecords text="dati" />
			) : (
				<canvas
					className="ion-margin-top"
					id="wellness-chart"
				/>
			)}
		</>
	)
}

export default WellnessContent
