import {
	IonContent,
	IonHeader,
	IonToolbar,
	IonButtons,
	IonBackButton,
	IonPage,
	IonTitle,
	IonRefresher,
	IonRefresherContent,
	useIonToast,
	useIonViewDidEnter,
} from '@ionic/react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import MainFooter from '../../components/mainFooter'
import { clearCache } from '../../services/functions'
import { useHistory, useLocation } from 'react-router'
import { useDispatch } from 'react-redux'
import { createOrder, updatePaymentMethod } from '../../redux/actions'
import { useEffect, useState } from 'react'
import CheckoutForm from './CheckoutForm'
import { PaymentSkeleton } from '../../components/skeletons/PaymentSkeleton'

const PaymentsPage = () => {
	const href = '/profilo/abbonamenti'

	const location = useLocation()
	const dispatch = useDispatch()
	const [ionToast] = useIonToast()
	const history = useHistory()
	// const [terms, setTerms] = useState(false)
	console.log('location.state', location.state)

	const [stripePromise, setStripePromise] = useState(null)
	const [options, setOptions] = useState(null)
	const [successUrl, setSuccessUrl] = useState(null)
	const [productPriceFormatted, setProductPriceFormatted] = useState('')
	const [setupOrPayment, setSetupOrPayment] = useState('')

	useEffect(() => {
		setProductPriceFormatted(location.state?.productPriceFormatted)
	}, [location.state])

	useIonViewDidEnter(() => {
		if (!location.state?.title || !location.state?.type) {
			history.push(href)
		}

		if (location.state.type === 'product_payment') {
			if (
				!location.state?.create_order_url ||
				!location.state?.product_id ||
				!location.state?.subscriber_id
			) {
				history.push(href)
			}

			dispatch(createOrder(location.state))
				.then((r) => {
					const response = r.payload
					if (response.is_paid) {
						history.push(href)
						ionToast({
							message:
								'Ci vorrà qualche minuto per attivare il tuo abbonamento',
							duration: 2000,
						})
					} else {
						setSetupOrPayment(
							response.clientSecret.startsWith('pi_') ? 'payment' : 'setup'
						)
						setSuccessUrl(response.successUrl)
						setStripePromise(loadStripe(response.stripeApiKey))
						setOptions({
							clientSecret: response.clientSecret,
						})
					}
				})
				.catch((error) => {
					console.error('Error fetching products:', error)
					ionToast({
						message: "Errore durante la creazione dell'ordine",
						duration: 2000,
					})
					history.push(href)
				})
		} else if (location.state.type === 'payment_method') {
			dispatch(updatePaymentMethod())
				.then((r) => {
					const response = r.payload
					setSuccessUrl(response.successUrl)
					setStripePromise(loadStripe(response.stripeApiKey))
					setOptions({
						clientSecret: response.client_secret,
					})
				})
				.catch((error) => {
					console.error('Error fetching products:', error)
					ionToast({
						message: 'Errore caricando il metodo di pagamento',
					})
					history.push(href)
				})
		} else {
			history.push(href)
		}
	})

	// console.log('stripePromise', stripePromise)
	// console.log('options', options)
	// console.log('successUrl', successUrl)

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start">
						<IonBackButton
							defaultHref={href}
							text=""></IonBackButton>
					</IonButtons>
					<IonTitle>{location.state?.title}</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent className="ion-padding">
				<IonRefresher
					slot="fixed"
					pullFactor={0.5}
					pullMin={100}
					pullMax={200}
					onIonRefresh={clearCache}>
					<IonRefresherContent></IonRefresherContent>
				</IonRefresher>
				{stripePromise ? (
					<Elements
						stripe={stripePromise ?? null}
						options={options ?? null}>
						{productPriceFormatted && (
							<CheckoutForm
								successUrl={successUrl}
								productPriceFormatted={productPriceFormatted}
								setupOrPayment={setupOrPayment}
							/>
						)}
					</Elements>
				) : (
					<PaymentSkeleton />
				)}
			</IonContent>
			<MainFooter />
		</IonPage>
	)
}

export default PaymentsPage
