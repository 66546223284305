import { createAsyncThunk } from '@reduxjs/toolkit'
import { baseUrl, headersDefault } from '.'
import axios from 'axios'

export const getMultimediaPosts = createAsyncThunk(
	'multimediaGroups/getMultimediaPosts',
	async (functionParams) => {
		const { page = 1, group_id, search_title, tag_id } = functionParams ?? {}
		const params = {
			page,
			group_id,
			...(search_title && { search_title }),
			...(tag_id && { tag_id }),
		}

		const response = await axios.get(
			`${baseUrl}/api/master-club-post-groups/list`,
			{
				headers: headersDefault(),
				params,
			}
		)

		return response.data
	}
)

export const getMultimediaGroupsList = createAsyncThunk(
	'multimediaGroups/getMultimediaGroupsList',
	async () => {
		const params = { type: 'multimedia' }

		const response = await axios.get(`${baseUrl}/api/master-club-groups/list`, {
			headers: headersDefault(),
			params,
		})

		return response.data
	}
)

export const likeMultimediaPost = createAsyncThunk(
	'multimediaGroups/likeMultimediaPost',
	async ({ element_id }) => {
		const body = {
			element_type: 'App\\Models\\MasterClubPostGroup',
			element_id,
		}

		const response = await axios.post(
			`${baseUrl}/api/master-club-likes`,
			body,
			{
				headers: headersDefault(),
			}
		)

		return response.data
	}
)
