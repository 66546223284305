import {
	IonPage,
	IonContent,
	IonText,
	IonRadioGroup,
	IonRadio,
	IonButton,
	IonItem,
} from '@ionic/react'
import InitialConfigHeader from '../header'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setInitialConfigExtraProperty } from '../../../redux/reducer/initialConfigSlice'
import '../InitialConfiguration.min.css'
import { useHistory } from 'react-router'

const options = [
	'Regolare',
	'Stitichezza',
	'Diarrea',
	'Irregolare',
	'Alvo alterno',
]

const IntestinalFuncionalityPage = ({ step, totalSteps }) => {
	const history = useHistory()
	const [isLoading, setIsLoading] = useState(false)
	const intestine = useSelector((state) => state.initialConfig.extras.intestine)
	const dispatch = useDispatch()
	const [error, setError] = useState('')

	const updateInput = (e) => {
		if (error !== '') setError('')
		if (e.target.value !== intestine)
			dispatch(
				setInitialConfigExtraProperty({
					key: 'intestine',
					value: e.target.value,
				})
			)
	}

	const validateInput = () => {
		let isValid = true
		if (intestine === '') {
			setError("Devi selezionare un'opzione per continuare.")
			isValid = false
		} else if (!options.includes(intestine)) {
			setError("L'opzione selezionata non è valida.")
			isValid = false
		}

		return isValid
	}

	const handleSubmit = () => {
		setIsLoading(true)
		const isInputValid = validateInput()
		if (!isInputValid) {
			setIsLoading(false)
			return
		}
		history.push(`/impostazioni-iniziali/${step + 1}`)
		setIsLoading(false)
	}

	const handleSkip = (e) => {
		setIsLoading(true)
		dispatch(setInitialConfigExtraProperty({ key: 'intestine', value: '' }))
		history.push(`/impostazioni-iniziali/${step + 1}`)
		setIsLoading(false)
	}

	return (
		<IonPage className="initial-configuration">
			<InitialConfigHeader
				step={step}
				totalSteps={totalSteps}
				isLoading={isLoading}
			/>

			<IonContent>
				<h1>{"Com'è la tua funzionalità intestinale?"}</h1>
				<IonRadioGroup value={intestine}>
					{options.map((option, index) => {
						return (
							<IonItem
								key={index}
								className="no-padding-item">
								<IonRadio
									value={option}
									onClick={updateInput}>
									{option}
								</IonRadio>
							</IonItem>
						)
					})}
				</IonRadioGroup>
				<IonText className="error">{error}</IonText>
				<div className="buttons">
					<IonButton
						shape="round"
						onClick={handleSkip}
						disabled={isLoading}
						color="light-gray"
						expand="full">
						<IonText color="white">Salta</IonText>
					</IonButton>
					<IonButton
						shape="round"
						onClick={handleSubmit}
						disabled={isLoading}
						color="primary"
						expand="full">
						Avanti
					</IonButton>
				</div>
			</IonContent>
		</IonPage>
	)
}

export default IntestinalFuncionalityPage
