import { Icon } from '@iconify/react'
import {
	IonAvatar,
	IonButton,
	IonIcon,
	IonItem,
	IonSkeletonText,
} from '@ionic/react'
import { chatboxEllipses } from 'ionicons/icons'

export const PostSkeleton = () => (
	<>
		<div className="ion-padding">
			<IonItem
				lines="full"
				className="no-padding-item">
				<IonAvatar slot="start">
					<IonSkeletonText animated={true} />
				</IonAvatar>
				<div style={{ minHeight: '4rem', paddingTop: '1rem' }}>
					<IonSkeletonText
						animated={true}
						style={{ width: '5.5rem' }}
					/>
					<IonSkeletonText
						animated={true}
						style={{ width: '4rem' }}
					/>
				</div>
			</IonItem>
			<IonSkeletonText
				animated={true}
				style={{ height: '1.25rem', marginTop: '1.5rem', width: '10rem' }}
			/>

			<IonSkeletonText
				animated={true}
				color="medium"
				style={{
					height: '0.9rem',
					marginTop: '1rem',
					marginBottom: '1.5rem',
					width: '7rem',
				}}
			/>
		</div>
		<div style={{ maxWidth: '450px', margin: '0px auto' }}>
			<IonSkeletonText
				animated={true}
				style={{ width: ' 100%', paddingTop: '56.25%' }}
			/>
		</div>

		<IonItem
			lines="full"
			className="group-footer-buttons">
			<div
				slot="start"
				style={{ display: 'flex', alignItems: 'end' }}>
				<IonButton
					className="buttonWithoutPadding"
					style={{
						'--box-shadow': 'none',
						'--background': 'white',
						margin: 0,
						height: '24px',
					}}>
					<IonIcon
						style={{ fontSize: 24 }}
						slot="icon-only"
						src={chatboxEllipses}
						color="light-gray"
					/>
				</IonButton>
				{
					<div style={{ marginLeft: '2px', marginBottom: '2px', opacity: 0 }}>
						0
					</div>
				}
			</div>
			<div
				slot="end"
				style={{ display: 'flex', alignItems: 'end' }}>
				<div style={{ display: 'flex', alignItems: 'end' }}>
					<IonButton
						className="buttonWithoutPadding"
						style={{
							'--box-shadow': 'none',
							'--background': 'white',
							margin: 0,
							height: '24px',
						}}>
						<Icon
							icon="bxs:like"
							width="24"
							className={'like-icon'}
						/>
					</IonButton>
					<div style={{ marginLeft: '2px', marginBottom: '1px', opacity: 0 }}>
						0
					</div>
				</div>
			</div>
		</IonItem>
	</>
)
