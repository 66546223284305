const getNewComment = args => {
  return {
    id: args.id,
    element_type: 'App\\Models\\MasterClubPostGroup',
    element_id: args.element_id,
    content: args.content,
    is_enabled: true,
    is_checked: false,
    created_at: args.created_at,
    likes_count: 0,
    reports_count: 0,
    replies_count: 0,
    liked: false,
    created_by: {
      id: args.user.id,
      name: args.user.name,
      surname: args.user.surname,
      avatar: args.user.avatar,
      fullname: args.user.fullname
    }
  }
}

const getNewReply = args => {
  return {
    id: args.id,
    element_type: 'App\\Models\\MasterClubComment',
    element_id: args.element_id,
    content: args.content,
    is_enabled: true,
    is_checked: false,
    created_at: args.created_at,
    likes_count: 0,
    reports_count: 0,
    liked: false,
    created_by: {
      id: args.user.id,
      name: args.user.name,
      surname: args.user.surname,
      avatar: args.user.avatar,
      fullname: args.user.fullname
    }
  }
}

function createFakeComment(state, action) {
  const newComment = getNewComment(action.meta.arg)
  state.getPost.data.comments.data.unshift(newComment)
}

function updateRepliesCountComment(state, action, value) {
  state.getCommentAndReplies.data.replies.total += value
  const commentInPostIndex = state.getPost.data?.comments?.data?.findIndex(comment => comment.id === action.meta.arg.element_id)

  if (commentInPostIndex >= 0) {
    state.getPost.data.comments.data[commentInPostIndex].replies_count += value
  }
}

const increaseRepliesCountComment = (state, action) => updateRepliesCountComment(state, action, 1)
const decreaseRepliesCountComment = (state, action) => updateRepliesCountComment(state, action, -1)

function createFakeReply(state, action) {
  const newReply = getNewReply(action.meta.arg)
  state.getCommentAndReplies.data.replies.data.unshift(newReply)
}

function replaceFakeReplyforReal(state, action) {
  const newReplyIndex = state.getCommentAndReplies.data.replies.data.findIndex(reply => reply.id === action.meta.arg.id)
  state.getCommentAndReplies.data.replies.data[newReplyIndex] = { ...action.payload.comment, is_enabled: true, is_checked: false, likes_count: 0, reports_count: 0 }
}

function deleteFakeReply(state, action) {
  state.getCommentAndReplies.data.replies.data = state.getCommentAndReplies.data.replies.data.filter(reply => reply.id !== action.meta.arg.id)
}

export const replyCommentFunctions = { createFakeReply, replaceFakeReplyforReal, deleteFakeReply, increaseRepliesCountComment, decreaseRepliesCountComment }
export default { createFakeComment }
