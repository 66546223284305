import {
	IonButton,
	IonButtons,
	IonCheckbox,
	IonContent,
	IonHeader,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonList,
	IonListHeader,
	IonModal,
	IonText,
	IonThumbnail,
	IonTitle,
	IonToggle,
	IonToolbar,
	useIonAlert,
	useIonToast,
} from '@ionic/react'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'
import {
	deleteShoppingListCheck,
	getDietPlan,
	shoppingListChecks,
} from '../../redux/actions'
import { FoodSkeletonItem } from '../../components/skeletons/FoodSkeletonItem'
import { NoRecords } from '../../components/NoRecords'
import CourseAlert from '../../components/CourseAlert'
import { useHistory } from 'react-router'
import { decodeHtmlEntities } from '../../services/functions'
import { Icon } from '@iconify/react/dist/iconify.js'
import { eye, lockClosed } from 'ionicons/icons'
import FoodPropertiesModal from './FoodPropertiesModal'

const ShoppingListContent = ({ planData, setPlanData, planIsLoading }) => {
	const shoppingList = planData?.shoppingList
	const foods = planData?.foods
	const history = useHistory()
	const dispatch = useDispatch()
	const [showModal, setShowModal] = useState(false)
	const [foodProperties, setFoodProperties] = useState(null)
	const [toast] = useIonToast()

	const { data, isLoading, error } = useSelector(
		(state) => state.course.getCourse
	)

	const dataIsEmpty = !data
		? true
		: Object.keys(data).length === 0 && data.constructor === Object
	const shoppingListDisabled = dataIsEmpty
		? false
		: !data?.unlocked?.shopping_list

	const [presentAlert] = useIonAlert()

	useEffect(() => {
		const courseAlertConfig = CourseAlert(() => history.push('/corso'))
		if (shoppingListDisabled) {
			presentAlert(courseAlertConfig)
		}
	}, [shoppingListDisabled])

	const handleItemChange = async (
		item_name,
		is_checked = false,
		food_id = null
	) => {
		const values = {
			route_plan_id: planData.plan.id,
			food_id,
			item_name,
			is_checked,
		}

		try {
			const response = await dispatch(shoppingListChecks(values))

			if (response.error) {
				throw new Error(response.error.message)
			}

			const newPlanData = { ...planData }
			const newShoppingList = { ...newPlanData.shoppingList }

			// Loop through keys and update is_checked
			Object.keys(newShoppingList).forEach((key) => {
				newShoppingList[key] = newShoppingList[key].map((item) => {
					// console.log('key', key)
					if (
						(food_id && item.id === food_id) ||
						(!food_id && item.name === item_name)
					) {
						// console.log('item', item)
						// item.is_checked = is_checked
						return { ...item, is_checked: is_checked }
					}
					return item
				})
			})

			newPlanData.shoppingList = newShoppingList
			setPlanData(newPlanData)

			return response.payload
		} catch (error) {
			console.log('error', error)
			toast({
				message: "Errore durante l'aggiornamento",
				duration: 3000,
			})
			return false
		}
	}

	const handleAddElement = async () => {
		const input = document.getElementById('shopping-list-input').value.trim()
		if (!input) {
			return
		}

		handleItemChange(input).then((payload) => {
			if (payload) {
				const data = [
					{
						check_id: payload.id,
						name: payload.item_name,
						is_checked: false,
					},
				]

				setPlanData((prevState) => {
					const shoppingList = { ...prevState.shoppingList }
					const key = 'Altro'
					if (shoppingList[key]) {
						shoppingList[key] = [...shoppingList[key], ...data]
					} else {
						shoppingList[key] = data
					}
					return { ...prevState, shoppingList }
				})

				document.getElementById('shopping-list-input').value = ''
			}
		})
	}

	const handleDeleteElement = async (check_id) => {
		try {
			return await dispatch(deleteShoppingListCheck(check_id)).then(
				(response) => {
					const newPlanData = { ...planData }
					const newShoppingList = { ...newPlanData.shoppingList }
					const key = 'Altro'
					newShoppingList[key] = newShoppingList[key].filter(
						(item) => item.check_id !== check_id
					)

					//if Altro is empty, remove it from the shoppingList
					if (newShoppingList[key].length === 0) {
						delete newShoppingList[key]
					}

					newPlanData.shoppingList = newShoppingList
					setPlanData(newPlanData)

					return response.payload
				}
			)
		} catch (error) {
			console.log('error', error)
			toast({
				message: "Errore durante l'eliminazione",
				duration: 3000,
			})
			return false
		}
	}

	// useEffect(() => {
	// 	console.log('planData', planData)
	// }, [planData])

	const [labelText, setLabelText] = useState(
		'Aggiungi "altro" alla Lista della Spesa'
	)

	const handleFocus = () => {
		setLabelText('Clicca, scrivi e aggiungi')
	}

	const handleBlur = () => {
		setLabelText('Aggiungi "altro" alla Lista della Spesa')
	}

	return (
		!shoppingListDisabled && (
			<div style={{ overflowY: 'auto' }}>
				<div
					style={{
						padding: '10px',
						marginTop: '20px',
						display: 'flex',
						flexDirection: 'row',
					}}>
					<IonInput
						label={labelText}
						labelPlacement="floating"
						fill="outline"
						id="shopping-list-input"
						maxlength={40}
						onFocus={handleFocus}
						onBlur={handleBlur}
						style={{ marginRight: '20px', paddingLeft: '20px' }}></IonInput>
					<IonButton
						style={{ margin: '0 0px 0 -7px' }}
						fill="solid"
						slot="end"
						onClick={() => {
							handleAddElement()
						}}>
						Aggiungi
					</IonButton>
				</div>
				<IonList className="shopping-list">
					{planIsLoading ? ( // Render Ionic skeleton loading when loading is true
						<>
							<FoodSkeletonItem />
							<FoodSkeletonItem />
							<FoodSkeletonItem />
							<FoodSkeletonItem />
							<FoodSkeletonItem />
						</>
					) : shoppingList &&
					  typeof shoppingList === 'object' &&
					  Object.keys(shoppingList).length > 0 ? (
						Object.keys(shoppingList)
							.sort((a, b) =>
								a.localeCompare(b, undefined, {
									numeric: true,
									sensitivity: 'base',
								})
							)
							.map((key) => {
								const item = shoppingList[key]
								return (
									<IonList key={key}>
										<IonListHeader>
											<h2>{key}</h2>
										</IonListHeader>
										{Object.keys(item).map((key2) => {
											const item2 = item[key2]
											const food = foods?.find((food) => food.id === item2.id)

											return (
												<IonItem
													key={key2}
													button={true}
													detail={false}>
													<IonThumbnail
														className="radius"
														color="medium"
														slot="start">
														<img
															src={(() => {
																return food
																	? `${food.storage_path}${food?.homemade_name?.image_url}`
																	: ''
															})()}
															onError={(e) => {
																e.target.parentElement.style.display = 'none'
															}}
														/>
													</IonThumbnail>
													{item2.name}{' '}
													{food?.homemade_name
														?.description_nutritional_properties && (
														<span>
															<Icon
																icon="ph:info-duotone"
																width="20"
																height="20"
																style={{
																	color: 'var(--ion-color-primary)',
																	width: '20px !important',
																	height: '20px !important',
																	margin: '5px',
																}}
																onClick={() => {
																	setFoodProperties({
																		name: food?.homemade_name.name,
																		image_url: `${food?.storage_path}${food?.homemade_name?.image_url}`,
																		description:
																			food?.homemade_name
																				?.description_nutritional_properties,
																	})
																	setShowModal(true)
																}}
															/>
														</span>
													)}
													{!item2?.id && (
														<IonButton
															// style={{ marginRight: '10px' }}
															slot="end"
															onClick={() => {
																if (item2?.check_id)
																	handleDeleteElement(item2.check_id)
															}}>
															Eliminare
														</IonButton>
													)}
													<IonCheckbox
														slot="end"
														checked={item2.is_checked}
														onIonChange={(e) => {
															// console.log('item2', item2)
															handleItemChange(
																item2.name,
																e.target.checked,
																item2.id
															)
														}}></IonCheckbox>
													{/* {JSON.stringify(item2)} */}
												</IonItem>
											)
										})}
									</IonList>
								)
							})
					) : (
						<NoRecords />
					)}
				</IonList>
				<FoodPropertiesModal
					showModal={showModal}
					setShowModal={setShowModal}
					foodProperties={foodProperties}
				/>
			</div>
		)
	)
}

export default ShoppingListContent
