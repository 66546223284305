import { IonButton, IonContent, IonPage } from '@ionic/react'
import React, { Component } from 'react'
import MainHeader from '../../components/headers/mainHeader'
import { Icon } from '@iconify/react'
import MainFooter from '../../components/mainFooter'
import config from '../../settings/config'
import dietaSocialLogo from '../../assets/images/ds-logo.svg'
import dietaSocialLogoSandbox from '../../assets/images/ds-logo-sandbox.svg'

class ErrorBoundary extends Component {
	constructor(props) {
		super(props)
		this.state = { hasError: false }
		this.userIsFullLogged =
			localStorage.getItem('adoc_t') && localStorage.getItem('adoc_p')
	}

	static getDerivedStateFromError(error) {
		return { hasError: true, error }
	}

	componentDidCatch(error, errorInfo) {
		console.error('Error :', error, errorInfo)
	}

	render() {
		if (this.state.hasError) {
			return (
				<IonPage>
					{this.state.userIsFullLogged && (
						<MainHeader
							page="ErrorPage"
							title="Errore"
						/>
					)}
					{!this.state.userIsFullLogged && (
						<img
							src={
								config.env.mode !== 'prod'
									? dietaSocialLogoSandbox
									: dietaSocialLogo
							}
							alt="Dieta Social Logo"
							className="logo"
							style={{ maxWidth: '300px', margin: '100px auto 0 auto' }}
						/>
					)}

					<IonContent className="no-records ion-padding">
						<Icon
							style={{ marginTop: '70px' }}
							icon="iconoir:ev-plug-error"
							width="100"
						/>
						<h1>Ops! Abbiamo trovato un errore</h1>

						<IonButton color="primary">
							<a
								style={{
									margin: 0,
									padding: 0,
									textDecoration: 'none',
									color: 'white',
								}}
								href="../"
								className="ion-margin ion-color ion-color-primary md button button-solid ion-activatable ion-focusable">
								Torna
							</a>
						</IonButton>
					</IonContent>

					{this.state.userIsFullLogged && <MainFooter />}
				</IonPage>
			)
		}

		return this.props.children
	}
}

export default ErrorBoundary
