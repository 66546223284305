import { IonContent, IonPage } from '@ionic/react'
import MainFooter from '../../../components/mainFooter'
import MainHeader from '../../../components/headers/mainHeader'
import dietaSocialLogo from '../../../assets/images/ds-logo.svg'

const WhatIsPage = () => {
	return (
		<IonPage>
			<MainHeader
				page="WhatIsDietaSocialApp"
				title="Che cos’è Dieta Social App"
			/>
			<IonContent className="ion-padding">
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<img
						src={dietaSocialLogo}
						alt="Dieta Social Logo"
						width={250}
					/>
				</div>
				<h3>
					Dieta Social App: La rivoluzione della sana alimentazione e del
					benessere
				</h3>
				<p>
					Dieta Social App è molto più di una semplice applicazione; è il primo
					social network verticale dedicato alla promozione della sana e
					corretta alimentazione. Nato nel 2015, il progetto Dieta Social ha già
					aiutato oltre 300.000 persone a raggiungere il loro peso forma in modo
					salutare. Il nostro approccio innovativo si basa su un nuovo metodo
					scientifico che si allontana dal tradizionale calcolo delle calorie,
					ponendo al centro dell’attenzione le molecole degli alimenti, cioè i
					nutrienti.
				</p>
				<p>
					Ma Dieta Social è molto di più di un’app di monitoraggio alimentare. È
					una comunità attiva di individui dedicata al miglioramento della loro
					salute e del loro stile di vita. La forza di Dieta Social sta
					nell’unione di due aspetti chiave: il metodo scientifico e l’aspetto
					sociologico. Siamo convinti che condividere un obiettivo aiuti a
					raggiungerlo più rapidamente, ed è per questo motivo che abbiamo
					creato un social network proprietario. Qui, le persone con obiettivi
					simili possono connettersi, imparare insieme, condividere esperienze e
					sostenersi reciprocamente lungo il percorso verso la salute e il
					benessere.
				</p>
				<p>
					Dieta Social App è il luogo dove la scienza e la comunità si
					incontrano per migliorare la vita delle persone attraverso la sana
					alimentazione e il benessere. Entra a far parte di questa rivoluzione
					oggi stesso e inizia il tuo viaggio verso una vita più sana e felice
					con Dieta Social App.
				</p>
			</IonContent>
			<MainFooter />
		</IonPage>
	)
}

export default WhatIsPage
