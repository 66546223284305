import { IonPage, IonContent, IonButton, IonRange } from '@ionic/react'
import InitialConfigHeader from '../header'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setInitialConfigProperty } from '../../../redux/reducer/initialConfigSlice'
import '../InitialConfiguration.min.css'
import measures from '../../../settings/measuresConfig'
import { useHistory } from 'react-router'

const Weightpage = ({ step, totalSteps }) => {
	const history = useHistory()
	const measureIndex = measures.findIndex(
		(measure) => measure.value === 'weight'
	)
	const [isLoading, setIsLoading] = useState(false)
	const initial_weight = useSelector(
		(state) =>
			state.initialConfig.initial_weight || measures[measureIndex].initialValue
	)
	const dispatch = useDispatch()

	const updateInput = (e) => {
		dispatch(
			setInitialConfigProperty({ key: 'initial_weight', value: e.detail.value })
		)
	}

	const handleSubmit = () => {
		setIsLoading(true)
		history.push(`/impostazioni-iniziali/${step + 1}`)
		setIsLoading(false)
	}

	const handleDecrease = () => {
		const newValue = initial_weight - measures[measureIndex].step
		dispatch(
			setInitialConfigProperty({
				key: 'initial_weight',
				value: Math.max(newValue, measures[measureIndex].min),
			})
		)
	}

	const handleIncrease = () => {
		const newValue = initial_weight + measures[measureIndex].step
		dispatch(
			setInitialConfigProperty({
				key: 'initial_weight',
				value: Math.min(newValue, measures[measureIndex].max),
			})
		)
	}

	return (
		<IonPage className="initial-configuration">
			<InitialConfigHeader
				step={step}
				totalSteps={totalSteps}
				isLoading={isLoading}
			/>

			<IonContent>
				<h1>Qual è il tuo peso attuale?</h1>
				<IonRange
					min={measures[measureIndex].min}
					max={measures[measureIndex].max}
					step={measures[measureIndex].step}
					pinFormatter={(value) =>
						value.toFixed(2).replace('.', ',') +
						' ' +
						measures[measureIndex].suffix
					}
					pin={true}
					className="measure-range"
					onIonChange={updateInput}
					value={initial_weight}
				/>
				<div className="measure-buttons">
					<IonButton
						shape="round"
						onClick={handleDecrease}
						disabled={isLoading}
						color="primary">
						-
					</IonButton>
					<IonButton
						shape="round"
						onClick={handleIncrease}
						disabled={isLoading}
						color="primary">
						+
					</IonButton>
				</div>
				<div className="buttons">
					<IonButton
						shape="round"
						onClick={handleSubmit}
						disabled={isLoading}
						color="primary"
						expand="full">
						Avanti
					</IonButton>
				</div>
			</IonContent>
		</IonPage>
	)
}

export default Weightpage
