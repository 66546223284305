import './InitialConfiguration.min.css'
import dsPictogram from '../../assets/images/ds-pictogram.svg'
import {
	IonBackButton,
	IonButtons,
	IonHeader,
	IonImg,
	IonProgressBar,
	IonTitle,
	IonToolbar,
} from '@ionic/react'

const InitialConfigHeader = ({
	isLoading,
	step,
	totalSteps,
	setPageSelected = null,
	pagesBack = 1,
}) => {
	return (
		<IonHeader>
			<IonToolbar>
				<IonButtons slot="start">
					{step > 1 && !setPageSelected && (
						<IonBackButton
							disabled={isLoading || step === totalSteps}
							defaultHref={`/impostazioni-iniziali/${
								step - 1
							}`}></IonBackButton>
					)}
					{step > 1 && setPageSelected && (
						<IonBackButton
							disabled={isLoading}
							onClick={(e) => {
								setPageSelected((oldPage) => oldPage - pagesBack)
							}}></IonBackButton>
					)}
					{step === 1 && <div style={{ width: 48 }}></div>}
				</IonButtons>
				<IonTitle>{`Step ${step} da ${totalSteps}`}</IonTitle>
				<IonButtons slot="end">
					<IonImg
						className="header-ds-pictogram"
						src={dsPictogram}
					/>
				</IonButtons>

				<IonProgressBar
					color="primary"
					value={step / totalSteps}
				/>
			</IonToolbar>
		</IonHeader>
	)
}

export default InitialConfigHeader
