import React, { useEffect, useState } from 'react'
import { IonButton, IonContent, IonModal } from '@ionic/react'
import installApplication from '../../../assets/images/install-application.png'
import installIos from '../../../assets/images/install-ios.gif'
import Bowser from 'bowser'
import { Icon } from '@iconify/react'
import { useLocation } from 'react-router'
import {
	_isDietaSocialInstalled,
	_isIOS,
	_isStandalone,
} from '../../../services/functions'
import config from '../../../settings/config'
import CustomVimeoPlayer from '../../CustomVimeoPlayer'

const InstallApplicationModal = () => {
	const location = useLocation()
	const isRoot = location.pathname === '/' || location.pathname.includes('/o/')
	const isImpersonate = localStorage.getItem('adoc_i') === 'true'
	const inAppBrowser = localStorage.getItem('adoc_origin')
	const isLocalhost = window.location.hostname === 'localhost'
	const mandatoryInstall = config.env.mandatoryInstall

	const disableInstallation = isImpersonate || !mandatoryInstall || inAppBrowser

	const [installPromptEvent, setInstallPromptEvent] = useState(null)

	const [openModal, setOpenModal] = useState(false)

	const [progress, setProgress] = useState(0)

	const browser = Bowser.getParser(window.navigator.userAgent)
	const isChromium = !!window.chrome
	const isBrave = !!navigator.brave

	const browserName = isBrave ? 'Brave' : browser.getBrowserName()
	const platformType = browser.getPlatformType()

	const isMobile = platformType === 'mobile'
	const isDesktop = platformType === 'desktop'

	const isChrome = browserName === 'Chrome' && !isBrave

	const isDesktopEdge = browser.satisfies({
		desktop: { 'Microsoft Edge': '>=0' },
	})

	// const isSamsung =
	// 	browser.satisfies({
	// 		mobile: { 'Samsung Internet': '>=0' },
	// 	}) || browserName === 'Samsung Internet for Android'

	const isSamsungInternet = browser.satisfies({
		mobile: { 'Samsung Internet': '>=12.2' },
	})

	const isDesktopChrome =
		!!browser.satisfies({ desktop: { chrome: '>=0' } }) && !isBrave
	const isDesktopSafari = !!browser.satisfies({ desktop: { safari: '>=0' } })
	const isMobileIOS = _isIOS()
	const isMobileChrome =
		!!browser.satisfies({ mobile: { chrome: '>=0' } }) && !isBrave

	const isBrowserCompatible =
		isDesktopEdge ||
		isDesktopChrome ||
		// isDesktopSafari ||
		isMobileIOS ||
		isMobileChrome ||
		isSamsungInternet

	const isPwaCompatible =
		'serviceWorker' in navigator && 'PushManager' in window

	const canOpenWithLink = isChrome && isMobile

	const isProduction = config.env?.forceProduction
		? true
		: process.env.NODE_ENV === 'production'

	const isInstallable = () =>
		isProduction &&
		// isPwaCompatible && NOT WORKING IN IOS
		isBrowserCompatible &&
		_isStandalone() === false

	const RenderDetails = () => {
		return (
			<div className="details">
				<p>isBrave: {isBrave.toString()}</p>
				<p>Standalone: {_isStandalone().toString()}</p>
				<p>isInstallable: {isInstallable().toString()}</p>
				<p>isMobileIOS: {isMobileIOS.toString()}</p>
				<p>isBrowserCompatible: {isBrowserCompatible.toString()}</p>
				<p>isDesktopSafari: {isDesktopSafari.toString()}</p>
			</div>
		)
	}

	function showNativeInstall() {
		if (installPromptEvent) {
			installPromptEvent.prompt()
			installPromptEvent.userChoice.then((choice) => {
				if (choice.outcome === 'accepted') {
					//Installing or installed flow
				} else {
					console.log('dismissed')
				}
			})
		} else if (isMobileIOS) {
			console.log('///// setIsIOSOpen(true)')
			setOpenModal('iOS')
		} else {
			console.log('not supported to install')
			console.log('/////0 setIncompatibilityOpen(true)')
			setOpenModal('incompatibility')
		}
	}

	// useEffect(() => {
	// 	console.log('openModal', openModal)
	// }, [openModal])

	useEffect(() => {
		if (isRoot || !isInstallable() || disableInstallation) return
		console.log('///// setInstallOpen(true)')
		setOpenModal('install')
	}, [location.pathname, installPromptEvent])

	const installingApp = () => {
		console.log('///// setInstallingIsOpen(true)')
		setOpenModal('installing')
		let currentProgress = 0
		const interval = setInterval(() => {
			currentProgress += 1
			setProgress(currentProgress)

			if (currentProgress === 25) {
				clearInterval(interval)
				console.log('///// setIsInstalledOpen(true)')
				setOpenModal('installed')
			}
		}, 1000)
	}

	const checkInstalledApp = async () => {
		if (disableInstallation) return
		try {
			const installed = await _isDietaSocialInstalled()
			if (installed && _isStandalone() === false) {
				console.log('///// setIsInstalledOpen(true)')
				setOpenModal('installed')
			}
		} catch (error) {
			console.error('Error:', error)
		}
	}

	useEffect(() => {
		// console.log('isRoot', isRoot)
		// console.log('isPwaCompatible', isPwaCompatible)
		// console.log('isMobile', isMobile)
		// console.log('isChromium', isChromium)
		// console.log('isBrave', isBrave)
		// console.log('isChrome', isChrome)
		// console.log(`The current browser name is "${browser.getBrowserName()}"`)
		// console.log(`The current platform is "${browser.getPlatformType()}"`)
		// console.log('////')
		// console.log('isProduction', isProduction)
		// console.log('isBrowserCompatible', isBrowserCompatible)
		// console.log('isStandalone', _isStandalone())
		// console.log('isInstallable', isInstallable())

		//If not installed and not installable or not compatible
		if (
			_isStandalone() === false &&
			!isInstallable() &&
			isProduction &&
			!inAppBrowser
		) {
			console.log('/////1 setIncompatibilityOpen(true)')
			setOpenModal('incompatibility')
		}

		//If can install then save the event
		if ('BeforeInstallPromptEvent' in window) {
			window.addEventListener('beforeinstallprompt', (event) => {
				event.preventDefault()
				setInstallPromptEvent(event)
			})
		}

		if (isMobile && isInstallable()) {
			console.log('checkInstalledApp()')
			//If can check if is installed then close
			checkInstalledApp()

			//When starts to install the app
			window.addEventListener('appinstalled', (event) => {
				console.log('------ appinstalled')
				installingApp()
			})
		}
	}, [])

	//Desktop check if is installed
	useEffect(() => {
		let intervalId
		let count = 0

		const checkAndSetModal = () => {
			count++
			if (count === 1 || disableInstallation) return
			if (_isStandalone()) {
				console.log('///// setIsInstalledOpen(false)')
				setOpenModal(false)
			} else if (!isRoot && isInstallable() && !!installPromptEvent === false) {
				console.log('///// setIsInstalledOpen(true)')
				setOpenModal('installed')
			}
		}

		if (isDesktop) {
			intervalId = setInterval(checkAndSetModal, 2000)
			checkAndSetModal()
		}

		return () => {
			if (intervalId) {
				clearInterval(intervalId)
			}
		}
	}, [isRoot, installPromptEvent])

	return (
		<>
			<IonModal
				className="priority-modal"
				isOpen={openModal === 'incompatibility'}
				backdropDismiss={false}>
				<IonContent>
					<div className="ion-padding">
						<div className="icon-wrapper flex-center">
							<Icon
								icon="emojione:warning"
								width="70"
							/>
						</div>
						<h3 className="flex-center">Avviso</h3>
						{/* <RenderDetails /> */}
						<p>
							L’app Dieta Social non è pienamente compatibile con il browser
							attualmente in uso. E’ necessario impostare Google Chrome (app
							scaricabile dallo store) come tuo browser predefinito (nel video
							in basso vedrai come fare) e solo successivamente potrai cliccare
							sul link che ti abbiamo inviato per accedere all’App.
						</p>
					</div>
					<CustomVimeoPlayer video={916733863} />
				</IonContent>
			</IonModal>

			<IonModal
				className="modal-as-alert regular-layout priority-modal"
				isOpen={openModal === 'install'}
				backdropDismiss={false}>
				<div className="ion-padding">
					<div className="icon-wrapper">
						<img
							src={installApplication}
							className="icon"
						/>
					</div>
					<div className="title">Installa App</div>
					{/* <RenderDetails /> */}
					<div className="description">
						Per utilizzare correttamente questa applicazione e garantire il suo
						funzionamento, è necessaria l’installazione.
					</div>
				</div>
				<div className="button">
					<IonButton
						onClick={() => showNativeInstall()}
						color="blue"
						expand="block"
						fill="clear"
						strong={true}>
						INSTALLA
					</IonButton>
				</div>
			</IonModal>

			<IonModal
				className="ios-guide-layout priority-modal"
				isOpen={openModal === 'iOS'}
				backdropDismiss={false}>
				<IonContent>
					<div className="ion-padding">
						<h4 className="title">Guida installazione</h4>
						<div className="description">
							Sempre da <b>Safari</b> devi cliccare su
							<Icon
								className="icon"
								icon="material-symbols:ios-share"
							/>
							e poi su <b>Aggiungi alla schermata Home</b>
						</div>
					</div>
					<CustomVimeoPlayer video={916735165} />
				</IonContent>
			</IonModal>

			<IonModal
				className="modal-as-alert regular-layout priority-modal"
				isOpen={openModal === 'installing'}
				backdropDismiss={false}>
				<div className="ion-padding">
					<div className="icon-wrapper">
						<Icon
							icon="line-md:downloading-loop"
							className="icon"
						/>
					</div>
					<div className="title">Installando App</div>
					<div className="description">Attendere prego</div>
					<div className="progress-wrapper">
						<div
							className="bar"
							style={{
								width: `${(progress / 25) * 100}%`,
							}}></div>
					</div>
				</div>
			</IonModal>

			<IonModal
				className="modal-as-alert regular-layout priority-modal"
				isOpen={openModal === 'installed'}
				backdropDismiss={false}>
				<div className="ion-padding">
					<div className="icon-wrapper">
						<Icon
							icon="ph:seal-check-duotone"
							className="icon"
						/>
					</div>
					<div className="title">App installata</div>
					<div className="description">
						Per una migliore esperienza consigliamo di aprire l’app
					</div>
				</div>
				{canOpenWithLink && (
					<div className="button">
						<IonButton
							href={config.env[config.env.mode].app}
							target="_blank"
							color="blue"
							expand="block"
							fill="clear"
							strong={true}>
							Passa all’APP
						</IonButton>
					</div>
				)}
			</IonModal>
		</>
	)
}

export default InstallApplicationModal
