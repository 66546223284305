import { IonContent, IonImg, IonPage, useIonLoading } from '@ionic/react'
import { setAdoc_p, setAdoc_t } from '../../../redux/reducer/userSlice'
import dietaSocialLogo from '../../../assets/images/ds-logo.svg'
import config from '../../../settings/config'
import { useDispatch } from 'react-redux'
import { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'

const GetTokenPage = ({ match, impersonate }) => {
	const dispatch = useDispatch()
	const token = match.params.token
	const history = useHistory()
	const [ionLoading, hideIonLoading] = useIonLoading()

	useEffect(() => {
		ionLoading({
			message: 'Attendere prego...',
		})
		let decodedToken
		try {
			decodedToken = window.atob(
				token.includes('&') ? token.split('&')[0] : token
			)
		} catch (error) {
			hideIonLoading()
			history.push('/login')
			return
		}

		fetch(`${config.env[config.env.mode].url}/api/profilo-utenti/1/fetch `, {
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${decodedToken}`,
			},
		})
			.then((resp) => {
				if (!resp.ok) throw new Error(resp.message)
				return resp.json()
			})
			.then((user) => {
				hideIonLoading()
				localStorage.setItem('adoc_t', decodedToken)
				localStorage.setItem('adoc_p', JSON.stringify(user))
				if (impersonate) localStorage.setItem('adoc_i', 'true')
				dispatch(setAdoc_p(user))
				dispatch(setAdoc_t(decodedToken))
				return user
			})
			.then((user) => {
				hideIonLoading()
				const nextRoute = user?.master_club_subscriber
					? '/home'
					: '/impostazioni-iniziali/1'
				history.push(nextRoute, {
					login: true,
				})
			})
			.catch((_) => {
				hideIonLoading()
				history.push('/login')
			})
	}, [])

	return (
		<IonPage className="loginPage">
			<IonContent fullscreen>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '75vh',
					}}>
					<IonImg src={dietaSocialLogo} />
				</div>
			</IonContent>
		</IonPage>
	)
}

export default GetTokenPage
