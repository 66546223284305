import {
	IonItem,
	IonText,
	IonDatetime,
	IonDatetimeButton,
	IonSegment,
	IonSegmentButton,
	IonLabel,
	IonPopover,
	IonToast,
} from '@ionic/react'
import { useEffect, useState } from 'react'
import { todayStringDate } from '../../services/functions'
import DiarioContentFull from './DiarioContentFull'
import DiarioContentSpecific from './DiarioContentSpecific'
import WellnessContent from './WellnessContent'
import { useSelector } from 'react-redux'
import config from '../../settings/config'
import dayjs from 'dayjs'
import measures from '../../settings/measuresConfig'

const segments = {
	diario: 'diario',
	wellness: 'wellness',
}

const wellness = [0, 0, 0, 0, 0]

function getFetchOptions(dates) {
	const backUrl = config.env[config.env.mode].url
	const query =
		'?' +
		new URLSearchParams({
			start: dates[0],
			end: dates[1],
		})
	const headers = {
		Authorization: `Bearer ${localStorage.getItem('adoc_t')}`,
		'Content-Type': 'application/json',
		Accept: 'application/json',
	}
	return { backUrl, query, headers }
}

const today = todayStringDate()

const ProgressPage = ({ location }) => {
	const accountCreatedAt = useSelector(
		(state) => dayjs(state.user?.master_club_subscriber?.created_at) ?? today
	).format('YYYY-MM-DD')

	console.log(accountCreatedAt)
	const [toastIsOpen, setToastIsOpen] = useState(false)
	const [measuresData, setMeasuresData] = useState(measures)
	const [wellnessData, setWellnessData] = useState(wellness)
	const [segmentSelected, setSegmentSelected] = useState(segments.diario)
	const [dateStart, setDateStart] = useState(accountCreatedAt)
	const [dateEnd, setDateEnd] = useState(todayStringDate())
	const measure = new URLSearchParams(window.location.search).get('measure')

	useEffect(() => {
		const { backUrl, query, headers } = getFetchOptions([dateStart, dateEnd])

		const fetch1 = fetch(
			`${backUrl}/api/master-club-wellness-index/list${query}`,
			{ headers }
		)
		const fetch2 = fetch(
			`${backUrl}/api/master-club-measure-diary/list${query}`,
			{ headers }
		)

		Promise.all([fetch1, fetch2])
			.then((responses) => {
				const response1 = responses[0]
				const response2 = responses[1]

				const response1json = response1.json()
				const response2json = response2.json()

				Promise.all([response1json, response2json])
					.then((datas) => {
						const data1 = datas[0]

						const newWellnessData = [0, 0, 0, 0, 0]
						data1.forEach((w) => {
							newWellnessData[w.index - 1]++
						})
						setWellnessData(newWellnessData)

						const data2 = datas[1]

						const newMeasures = []
						measures.forEach((measure) => {
							if (!measure.diary) return null
							measure.labels = []
							measure.values = []
							newMeasures.push(measure)
						})

						data2.forEach((day) => {
							for (const property in day) {
								if (day[property] !== '0.00') {
									for (const measure of newMeasures) {
										if (measure.value === property) {
											measure.values.push(day[property])
											measure.labels.push(day.date)
										}
									}
								}
							}
						})

						setMeasuresData(newMeasures)
					})
					.catch(() => {
						setToastIsOpen(true)
					})
			})
			.catch(() => {
				setToastIsOpen(true)
			})
	}, [dateStart, dateEnd])

	return (
		<>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
					maxHeight: '100%',
				}}>
				<div>
					<IonItem
						lines="full"
						id="datetime-start"
						style={{ pointerEvents: 'none' }}>
						<div
							style={{
								display: 'flex',
								width: '100%',
								justifyContent: 'space-between',
								alignItems: 'center',
								paddingLeft: '1rem',
								paddingRight: '1rem',
							}}>
							<IonText>Dal</IonText>
							<IonDatetimeButton
								datetime="datetime-start-button"
								slot="end"
								style={{ pointerEvents: 'visible' }}
							/>
						</div>
					</IonItem>
					<IonItem
						lines="full"
						id="datetime-end"
						style={{ pointerEvents: 'none' }}>
						<div
							style={{
								display: 'flex',
								width: '100%',
								justifyContent: 'space-between',
								alignItems: 'center',
								paddingLeft: '1rem',
								paddingRight: '1rem',
							}}>
							<IonText>Al</IonText>
							<IonDatetimeButton
								datetime="datetime-end-button"
								slot="end"
								style={{ pointerEvents: 'visible' }}
							/>
						</div>
					</IonItem>
					<IonSegment
						onIonChange={(e) => setSegmentSelected(e.target.value)}
						value={segmentSelected}>
						<IonSegmentButton value={segments.diario}>
							<IonLabel>LE MIE MISURE</IonLabel>
						</IonSegmentButton>
						{/* <IonSegmentButton value={segments.wellness}>
							<IonLabel>BENESSERE</IonLabel>
						</IonSegmentButton> */}
					</IonSegment>
				</div>
				{segmentSelected === segments.diario &&
					(measure === null ? (
						<DiarioContentFull measures={measuresData} />
					) : (
						<DiarioContentSpecific
							measureTitle={measure}
							measures={measuresData}
						/>
					))}
				{segmentSelected === segments.wellness && (
					<WellnessContent wellness={wellnessData} />
				)}
			</div>

			<IonPopover
				trigger="datetime-start"
				triggerAction="click"
				keepContentsMounted
				arrow="false"
				className="withoutBackdrop"
				size="cover">
				<IonDatetime
					style={{ width: '100%' }}
					size="cover"
					min={accountCreatedAt}
					max={today}
					id="datetime-start-button"
					locale="it-IT"
					displayFormat="YYYY-MM-DD"
					presentation="date"
					value={dateStart}
					onIonChange={(e) => {
						setDateStart(e.target.value)
						if (e.target.value > dateEnd) setDateEnd(e.target.value)
					}}
				/>
			</IonPopover>
			<IonPopover
				trigger="datetime-end"
				triggerAction="click"
				keepContentsMounted
				arrow="false"
				className="withoutBackdrop"
				size="cover">
				<IonDatetime
					style={{ width: '100%' }}
					size="cover"
					min={accountCreatedAt}
					max={today}
					id="datetime-end-button"
					locale="it-IT"
					displayFormat="YYYY-MM-DD"
					presentation="date"
					value={dateEnd}
					onIonChange={(e) => {
						setDateEnd(e.target.value)
						if (e.target.value < dateStart) setDateStart(e.target.value)
					}}
				/>
			</IonPopover>
			<IonToast
				isOpen={toastIsOpen}
				message="Ops! C'è stato un errore. Riprova più tardi."
				onDidDismiss={() => setToastIsOpen(false)}
				duration={3000}
			/>
		</>
	)
}

export default ProgressPage
