import { IonItem, IonSkeletonText } from '@ionic/react'

export const FoodSkeletonItem = () => {
	return (
		<IonItem detail="false">
			<div className="item-text">
				<div className="food-container">
					<div>
						<IonSkeletonText
							animated={true}
							style={{ width: '100px' }}
						/>
					</div>
					<div>
						<IonSkeletonText
							animated={true}
							style={{ width: '200px' }}
						/>
					</div>
				</div>
			</div>
		</IonItem>
	)
}
