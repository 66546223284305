import { useEffect } from 'react'
import { createSocketConnection } from '../socketService'

function listen(callBack, channel, event) {
	window.Echo.channel(channel).listen(event, (payload) => {
		callBack(payload)
	})

	return function cleanUp() {
		window.Echo.leaveChannel(channel)
	}
}

export const useSocket = ({ type, callBack }) => {
	const adoc_p = localStorage.getItem('adoc_p')

	if (!adoc_p) return

	const channelKey = JSON.parse(adoc_p)?.user.channel_key

	if (!channelKey) return

	useEffect(() => {
		createSocketConnection()
		switch (type) {
			case 'NOTIFICATION_CREATED': {
				return listen(
					callBack,
					`notifications.${channelKey}`,
					'NotificationCreated'
				)
			}
		}
	})
}
