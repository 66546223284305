import React, { useEffect, useState } from 'react'
import {
	IonSegmentButton,
	IonContent,
	IonDatetime,
	IonItem,
	IonSegment,
	IonPopover,
	IonPage,
	IonText,
	IonLabel,
	IonButton,
	useIonViewDidEnter,
	IonBadge,
	IonRefresher,
	IonRefresherContent,
	useIonLoading,
} from '@ionic/react'
import MainFooter from '../../components/mainFooter'
import MainHeader from '../../components/headers/mainHeader'
import MenuContent from './MenuContent'
import { clearCache } from '../../services/functions'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { getCourse, getDatesToDisable, getDietPlan } from '../../redux/actions'
import './Menu.min.css'

import SubscriptionAlert from '../../components/SubscriptionAlert'
import ShoppingListContent from './ShoppingListContent'
import RecipesContent from './RecipesContent'
import { useLocation } from 'react-router'

const MenuPage = () => {
	const [segmentSelected, setSegmentSelected] = useState('menu')
	const [isOpen, setIsOpen] = useState(false)
	const location = useLocation()
	const isNextWeek = location.state?.isNextWeek ?? false
	const [ionLoading, hideIonLoading] = useIonLoading()

	const { daysLimit, disableDates, routes } = useSelector(
		(state) => state.dietPlan.getDatesToDisable
	)

	const subscriptionName =
		useSelector(
			(state) =>
				state.user?.master_club_subscriber?.subscription?.subscription_type
					?.name
		) ?? null

	const { master_club_subscriber } = useSelector((state) => state.user)

	const vegetarian = master_club_subscriber?.vegetarian
		? 'VEGETARIANO'
		: 'ONNIVORO'
	const keepWeight = master_club_subscriber?.keep_weight
		? 'MANTENIMENTO'
		: 'DIMAGRIMENTO'
	const glutenFree = master_club_subscriber?.gluten_free ? 'LGF' : ''

	const dietType = vegetarian + ' ' + glutenFree

	useEffect(() => {
		//Order by start date asc
		// console.log('routes', routes)
		if (routes.length === 0) return
		const orderedRoutes = [...routes]
		orderedRoutes.sort((a, b) =>
			dayjs(a.route.start).diff(dayjs(b.route.start))
		)

		let start

		for (const el of orderedRoutes) {
			start = dayjs(el.route.start).toISOString()

			//Make this week default week if route exists
			if (
				el?.diet_plan_cycles &&
				dayjs(el.route.start).toISOString() <= dayjs().toISOString() &&
				dayjs(el.route.end).toISOString() >= dayjs().toISOString()
			) {
				start = dayjs(el.route.start).toISOString()
				break
			}
		}

		if (!isNextWeek) setCalendarDate(start)
	}, [routes])

	const [calendarDate, setCalendarDate] = useState(
		isNextWeek ? dayjs().add(1, 'week').toISOString() : dayjs().toISOString()
	)
	const [week, setWeek] = useState(null)
	const [planData, setPlanData] = useState(null)
	const [planIsLoading, setPlanIsLoading] = useState(true)
	const [isMenuEnabled, setIsMenuEnabled] = useState(false)

	useEffect(() => {
		if (planIsLoading) {
			ionLoading({
				message: 'Attendere prego...',
			})
		} else {
			hideIonLoading()
		}
	}, [planIsLoading])

	useEffect(() => {
		setIsMenuEnabled(isDateEnabled(dayjs(calendarDate).format('YYYY-MM-DD')))

		const selectedDate = dayjs(calendarDate)
		const thisWeekStart = dayjs().startOf('week')
		const thisWeekEnd = dayjs().endOf('week')
		const nextWeekStart = dayjs().add(1, 'week').startOf('week')
		const nextWeekEnd = dayjs().add(1, 'week').endOf('week')

		const isThisWeek =
			thisWeekStart <= selectedDate && thisWeekEnd >= selectedDate
		const isNextWeek =
			nextWeekStart <= selectedDate && nextWeekEnd >= selectedDate

		const result = isThisWeek ? 'this' : isNextWeek ? 'next' : null

		setPlanIsLoading(true) // Set loading to true when data fetching starts
		dispatch(getDietPlan(new Date(calendarDate)))
			.then((response) => {
				response = response.payload
				setPlanData(response ?? null)
			})
			.catch((error) => {
				console.error('Error fetching diet plan:', error)
			})
			.finally(() => {
				setPlanIsLoading(false) // Set loading to false when data fetching is done
			})

		if (result !== week) setWeek(result)
	}, [calendarDate])

	// useEffect(() => {
	// 	console.log('isMenuEnabled', isMenuEnabled)
	// }, [isMenuEnabled])

	const handleThisNextWeek = (week) => {
		const date =
			dayjs()
				[week === 'this' ? 'startOf' : 'add'](
					week === 'this' ? 'week' : 1,
					'week'
				)
				.format('YYYY-MM-DD') + 'T00:00:00.000Z'

		//This date will create calendar dates list
		setCalendarDate(date)
	}
	const dispatch = useDispatch()

	const route = routes.find(
		(el) => calendarDate >= el.route.start && calendarDate <= el.route.end
	)

	const storage_path = route?.route.storage_path
	const filepath = route ? route?.filename : null

	let filename = filepath
	if (filepath) {
		filename = filepath.split('_').pop() //Delete prefix
		filename = filename.split('.').shift() //Delete extension
		filename = filename.replaceAll('-', ' ').trim() //Replace - with space
	}

	const { data, isLoading, error } = useSelector(
		(state) => state.course.getCourse
	)

	const dataIsEmpty = !data
		? true
		: Object.keys(data).length === 0 && data.constructor === Object
	const shoppingListDisabled =
		isLoading || error
			? true
			: dataIsEmpty
			? false
			: !data?.unlocked?.shopping_list

	useIonViewDidEnter(() => {
		setSegmentSelected('menu')
		//dispatch(getCourse())
		dispatch(getDatesToDisable())
	})

	const updateSegment = (e) => {
		setSegmentSelected(e.target.value)
	}

	//ISO FORMAT BUT NEUTRAL TIMEZONE
	const minDate = dayjs(daysLimit[0]).format('YYYY-MM-DDTHH:mm:ssZ')
	const maxDate = dayjs(daysLimit[1]).format('YYYY-MM-DDTHH:mm:ssZ')

	const isDateEnabled = (dateString, debug = false) => {
		const date = new Date(dateString)
		const minDate = new Date(daysLimit[0])
		const maxDate = new Date(daysLimit[1])

		if (debug) {
			console.log('dateString', dateString)
			console.log('minDate', minDate)
			console.log('maxDate', maxDate)
		}
		if (minDate > date || maxDate < date) return false
		return !disableDates.some((disableDate) => {
			const disableDateObj = new Date(disableDate)
			return disableDateObj.getTime() === date.getTime()
		})
	}

	const segments = {
		menu: 'Menu',
		recipes: 'Ricette',
		shoppingList: 'Lista Spesa',
	}

	return (
		<>
			<IonPage className="menu-page">
				<MainHeader
					page="MenuPage"
					title={segments[segmentSelected]}
				/>
				<IonContent>
					<IonRefresher
						slot="fixed"
						pullFactor={0.5}
						pullMin={100}
						pullMax={200}
						onIonRefresh={clearCache}>
						<IonRefresherContent></IonRefresherContent>
					</IonRefresher>
					<div className="segment-container">
						<IonItem
							lines="none"
							id="datetime-button"
							style={{ pointerEvents: 'none' }}>
							<div className="calendar-container">
								<IonText>Settimana del </IonText>
								<IonButton
									style={{
										pointerEvents:
											subscriptionName === 'premium' ? 'visible' : 'none',
									}}>
									{dayjs(calendarDate).startOf('week').format('D MMMM YYYY')}
								</IonButton>
							</div>
						</IonItem>
						<div className="flex-center diet-type ion-margin-bottom">
							<IonButton
								color={week === 'this' ? 'tertiary' : 'tertiary'}
								fill={week === 'this' ? 'solid' : 'outline'}
								onClick={() => {
									handleThisNextWeek('this')
								}}>
								IN CORSO
							</IonButton>
							<IonButton
								color={week === 'next' ? 'tertiary' : 'tertiary'}
								fill={week === 'next' ? 'solid' : 'outline'}
								onClick={() => {
									handleThisNextWeek('next')
								}}>
								PROSSIMA
							</IonButton>
						</div>
						<div className="flex-center diet-type">
							<IonBadge>{dietType}</IonBadge>
							<IonBadge color="blue">{keepWeight}</IonBadge>
						</div>
						<IonSegment
							onIonChange={updateSegment}
							value={segmentSelected}>
							<IonSegmentButton value="menu">
								<IonLabel>MENU</IonLabel>
							</IonSegmentButton>
							{/* //if calendardate not in daysLimit disable shoppingList and recipes */}
							<IonSegmentButton
								value="recipes"
								disabled={!isMenuEnabled}>
								<IonLabel>RICETTE</IonLabel>
							</IonSegmentButton>
							<IonSegmentButton
								value="shoppingList"
								disabled={!isMenuEnabled}
								className={shoppingListDisabled ? 'opacity-50' : ''}>
								<IonLabel>LISTA SPESA</IonLabel>
							</IonSegmentButton>
						</IonSegment>
					</div>
					{segmentSelected === 'menu' && (
						<>
							<MenuContent
								date={calendarDate}
								disableDates={disableDates}
								daysLimit={daysLimit}
								route={route}
								week={week}
								planData={planData}
								planIsLoading={planIsLoading}
							/>
							{filepath && (
								<div className="download-menu-file">
									<a
										href={storage_path + filepath}
										download={filepath.split('_').pop()}
										target="_blank"
										rel="noopener noreferrer">
										<IonButton>{filename}</IonButton>
									</a>
								</div>
							)}
						</>
					)}
					{segmentSelected === 'recipes' && (
						<RecipesContent
							planData={planData}
							planIsLoading={planIsLoading}
						/>
					)}
					{segmentSelected === 'shoppingList' && (
						<ShoppingListContent
							planData={planData}
							setPlanData={setPlanData}
							planIsLoading={planIsLoading}
						/>
					)}
				</IonContent>

				<MainFooter />
			</IonPage>
			<IonPopover
				trigger="datetime-button"
				triggerAction="click"
				keepContentsMounted
				arrow="false"
				className="withoutBackdrop"
				size="cover">
				<IonDatetime
					style={{ width: '100%' }}
					size="cover"
					id="datetime"
					locale="it-IT"
					firstDayOfWeek={1}
					displayFormat="YYYY-MM-DD"
					presentation="date"
					value={dayjs(calendarDate).toISOString()}
					onIonChange={(e) => {
						setCalendarDate(e.target.value)
					}}
					isDateEnabled={isDateEnabled}
					min={minDate}
					max={maxDate}
				/>
			</IonPopover>
			{/* <RoutesModal
				isOpen={isOpen}
				setIsOpen={setIsOpen}
			/> */}
			<SubscriptionAlert
				isOpen={isOpen}
				setIsOpen={setIsOpen}
			/>
		</>
	)
}

export default MenuPage
