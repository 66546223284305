import {
	IonPage,
	IonItem,
	IonContent,
	IonText,
	IonButton,
	useIonLoading,
} from '@ionic/react'
import { setInitialConfigProperty } from '../../../redux/reducer/initialConfigSlice'
import InitialConfigHeader from '../header'
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import PhoneInput from '../../../components/PhoneInput'
import 'react-phone-number-input/style.css'

import { useDispatch, useSelector } from 'react-redux'
import config from '../../../settings/config'
import { useState } from 'react'

import '../InitialConfiguration.min.css'

import { useHistory } from 'react-router'
import { setAdoc_p } from '../../../redux/reducer/userSlice'

const PhoneNumberPage = ({ step, totalSteps, setPageSelected }) => {
	const history = useHistory()
	const initialConfig = useSelector((state) => state.initialConfig)
	const phone = useSelector((state) => state.initialConfig.telephone)
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState('')
	const dispatch = useDispatch()
	const [value, setValue] = useState(phone)
	const [present, dismiss] = useIonLoading()

	const handleSubmit = (e) => {
		setIsLoading(true)
		if (!isPossiblePhoneNumber(value)) {
			setIsLoading(false)
			setError('Il numero di telefono inserito non è valido')
			return
		}

		fetch(`${config.env[config.env.mode].url}/api/master-club-otp`, {
			method: 'POST',
			body: JSON.stringify({ telephone: value }),
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStorage.getItem('adoc_t')}`,
			},
		})
			.then((response) => {
				if (!response.ok) throw new Error(error.message || 'Unknown error')
				dispatch(setInitialConfigProperty({ key: 'telephone', value }))
				setIsLoading(false)
				setPageSelected(2)
			})
			.catch((e) => {
				setIsLoading(false)
				if (e.message && e.message !== 'Unknown error') {
					setError(e.message)
				} else {
					setError('Si è verificato un problema, riprova più tardi.')
				}
			})
	}

	const updateUserInfo = async () => {
		present({
			message: 'Caricando informazioni...',
		})
		const backUrl = config.env[config.env.mode].url
		const headers = {
			Authorization: `Bearer ${localStorage.getItem('adoc_t')}`,
			'Content-Type': 'application/json',
			Accept: 'application/json',
		}
		const { name, surname, nickname, vegetarian, gluten_free, extras, sex } =
			initialConfig

		const body1 = JSON.stringify({
			name,
			surname,
		})

		const body2 = JSON.stringify({
			sex,
			vegetarian,
			gluten_free,
			extras: {
				goals: extras.goals,
			},
			...(nickname && { nickname }),
		})

		try {
			const response1 = await fetch(`${backUrl}/api/profilo-utenti`, {
				method: 'POST',
				body: body1,
				headers,
			})
			if (!response1.ok) throw new Error('Error in fetch1')
			const data1 = await response1.json()

			const response2 = await fetch(
				`${backUrl}/api/master-club-subscribers/1`,
				{
					method: 'PATCH',
					body: body2,
					headers,
				}
			)
			if (!response2.ok) throw new Error('Error in fetch2')
			const data2 = await response2.json()

			if (
				data1.message === 'Succefully updated' &&
				data2.message === 'Succefully updated'
			) {
				const response3 = await fetch(`${backUrl}/api/profilo-utenti/1/fetch`, {
					headers,
				})
				if (!response3.ok) throw new Error('Error in fetch3')
				const data3 = await response3.json()
				localStorage.setItem('adoc_p', JSON.stringify(data3))
				dispatch(setAdoc_p(data3))
				setIsLoading(false)
				setError('')
				history.push(`/impostazioni-iniziali/${step + 1}`)
				// console.log('REDIRECT TO HOME!!!!!!!!!')
				// history.push('/home', {
				// 	firstLogin: true,
				// 	showNotificationsModal: true,
				// })
			} else {
				setIsLoading(false)
				setError('Si è verificato un problema, riprova più tardi.')
			}
			dismiss()
		} catch (e) {
			console.log(e)
			dismiss()
			setIsLoading(false)
			setError('Si è verificato un problema, riprova più tardi.')
		}
	}

	const handleSkip = (e) => {
		setIsLoading(true)
		dispatch(setInitialConfigProperty({ key: 'telephone', value: '' }))
		history.push(`/impostazioni-iniziali/${step + 1}`)
		// updateUserInfo()
	}

	return (
		<IonPage className="initial-configuration">
			<InitialConfigHeader
				step={step}
				totalSteps={totalSteps}
				isLoading={isLoading}
			/>

			<IonContent>
				<h1>Fatti riconoscere!</h1>
				<h6>
					Se hai un abbonamento attivo o se in passato hai già fatto parte della
					nostra grande famiglia, inserisci il tuo numero di cellulare!
				</h6>

				<IonItem
					className="no-padding-item ion-margin-top"
					lines="none"
					onMouseDown={(e) => {
						document.getElementById('phone-input').focus()
					}}
					style={{ paddingBottom: '10px', borderBottom: '1px solid #ddd' }}>
					<label className="phone-component-label">Cellulare</label>
					<PhoneInput
						autoFocus={true}
						label="Cellulare"
						id="phone-input"
						placeholder="xxx xxx xxxx"
						name="PhoneNumber"
						defaultCountry="IT"
						value={value}
						onChange={setValue}
					/>
				</IonItem>
				<IonText className="error">{error}</IonText>

				<div className="buttons">
					{/* <IonButton
						shape="round"
						onClick={handleSkip}
						disabled={isLoading}
						color="light-gray"
						expand="full">
						<IonText color="white">Salta</IonText>
					</IonButton> */}
					<IonButton
						shape="round"
						onClick={handleSubmit}
						disabled={isLoading}
						color="primary"
						expand="full">
						Avanti
					</IonButton>
				</div>
			</IonContent>
		</IonPage>
	)
}

export default PhoneNumberPage
