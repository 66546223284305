import { Icon } from '@iconify/react'
export const NoRecords = ({ text = 'pubblicazioni' }) => {
	return (
		<div className="no-records">
			<Icon
				icon="streamline:interface-setting-zoom-area-zoom-magnifier-square-area"
				width="75"
			/>
			<p>Ancora non ci sono {text}</p>
		</div>
	)
}
