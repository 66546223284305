import { IonAlert } from '@ionic/react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

const SubscriptionAlert = ({ isOpen, setIsOpen, AlertContent = null }) => {
	const history = useHistory()
	const subscriptionName =
		useSelector(
			(state) =>
				state.user?.master_club_subscriber?.subscription?.subscription_type
					?.name
		) ?? null

	const buttons = []

	if (subscriptionName !== 'premium')
		buttons.push({
			text: 'DIVENTA PREMIUM',
			cssClass: 'ion-button primary',
			handler: () => {
				history.push('/prodotti')
			},
		})

	if (!subscriptionName && !AlertContent?.hide_buttons?.includes('free'))
		buttons.push({
			text: 'PROVA PER 7 GIORNI',
			cssClass: 'ion-button blue',
			handler: () => {
				history.push('/percorso-gratuito')
			},
		})

	buttons.push({
		text: 'Indietro',
	})

	return (
		<IonAlert
			className="subscription-alert"
			isOpen={isOpen}
			header={
				AlertContent?.header ??
				'Contenuti riservati agli utenti registrati o premium'
			}
			message={
				AlertContent?.message ??
				'Sblocca le funzionalità extra grazie al completamento del tuo profilo, la sottoscrizione di un abbonamento oppure iscrivendoti ad un percorso gratuito'
			}
			buttons={buttons}
			onDidDismiss={() => setIsOpen(false)}></IonAlert>
	)
}

export default SubscriptionAlert
