import {
	IonPage,
	IonContent,
	IonText,
	IonButton,
	IonItem,
	IonCheckbox,
	IonLabel,
} from '@ionic/react'
import InitialConfigHeader from '../header'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setInitialConfigExtraProperty } from '../../../redux/reducer/initialConfigSlice'
import '../InitialConfiguration.min.css'
import { useHistory } from 'react-router'

const goalsOptions = [
	'Lettura',
	'Scrittura',
	'Pittura',
	'Disegno',
	'Fotografia',
	'Giardinaggio',
	'Cucina',
	'Cucito',
	'Lavori di bricolage',
	'Puzzle',
	'Giocare a giochi da tavolo',
	'Suonare uno strumento musicale',
	'Escursionismo',
	'Campeggio',
	'Corsa',
	'Yoga',
	'Nuoto',
	'Ciclismo',
	'Danza',
	'Sci o snowboard',
	'Pesca',
	'Birdwatching',
	'Giochi di carte',
	'Falegnameria',
	'Modellismo',
	'Escursioni in barca',
	'Collezionismo (timbri, monete, francobolli, etc.)',
	'Cinefilia (guardare film e analizzarli)',
	'Fare volontariato',
	'Praticare arti marziali',
	'Realizzare gioielli fatti a mano',
]

const HobbyPage = ({ step, totalSteps }) => {
	const history = useHistory()
	const [isLoading, setIsLoading] = useState(false)
	const hobbies = useSelector((state) => state.initialConfig.extras.hobbies)
	const dispatch = useDispatch()
	const [error, setError] = useState('')

	const updateInput = (e) => {
		if (error !== '') setError('')
		const newHobbies = [...hobbies]

		if (e.target.checked) {
			newHobbies.push(e.target.value)
		} else {
			newHobbies.splice(newHobbies.indexOf(e.target.value), 1)
		}
		dispatch(
			setInitialConfigExtraProperty({ key: 'hobbies', value: newHobbies })
		)
	}

	const validateInput = () => {
		let isValid = true
		if (!hobbies.every((option) => goalsOptions.includes(option))) {
			setError('Almeno una delle opzioni selezionate non è valida.')
			isValid = false
		}

		return isValid
	}

	const handleSubmit = () => {
		setIsLoading(true)
		const isInputValid = validateInput()
		if (!isInputValid) {
			setIsLoading(false)
			return
		}
		history.push(`/impostazioni-iniziali/${step + 1}`)
		setIsLoading(false)
	}

	const handleSkip = (e) => {
		setIsLoading(true)
		dispatch(setInitialConfigExtraProperty({ key: 'hobbies', value: '' }))
		history.push(`/impostazioni-iniziali/${step + 1}`)
		setIsLoading(false)
	}

	return (
		<IonPage className="initial-configuration">
			<InitialConfigHeader
				step={step}
				totalSteps={totalSteps}
				isLoading={isLoading}
			/>

			<IonContent>
				<h1>Interessi/hobby</h1>
				<div className="hobbies-scroller">
					{goalsOptions.map((option, index) => {
						return (
							<IonItem
								className="no-padding-item"
								key={index}>
								<IonCheckbox
									onIonChange={updateInput}
									value={option}
									checked={hobbies.includes(option)}>
									<IonLabel className="white-space-normal">{option}</IonLabel>
								</IonCheckbox>
							</IonItem>
						)
					})}
				</div>
				<IonText className="error">{error}</IonText>
				<div className="buttons">
					<IonButton
						shape="round"
						onClick={handleSkip}
						disabled={isLoading}
						color="light-gray"
						expand="full">
						<IonText color="white">Salta</IonText>
					</IonButton>
					<IonButton
						shape="round"
						onClick={handleSubmit}
						disabled={isLoading}
						color="primary"
						expand="full">
						Avanti
					</IonButton>
				</div>
			</IonContent>
		</IonPage>
	)
}

export default HobbyPage
