import {
	IonPage,
	IonContent,
	IonText,
	IonRadioGroup,
	IonRadio,
	IonButton,
	IonItem,
	IonLabel,
} from '@ionic/react'
import InitialConfigHeader from '../header'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setInitialConfigExtraProperty } from '../../../redux/reducer/initialConfigSlice'
import '../InitialConfiguration.min.css'
import { useHistory } from 'react-router'

const options = [
	'Si, ho bisogno di tenere alta la motivazione',
	'No, se mi pongo un obiettivo riesco a raggiungerlo da solo/a',
	'Alcune volte ne sento il bisogno',
]
const MotivationPage = ({ step, totalSteps }) => {
	const history = useHistory()
	const [isLoading, setIsLoading] = useState(false)
	const motivation = useSelector(
		(state) => state.initialConfig.extras.motivation
	)
	const dispatch = useDispatch()
	const [error, setError] = useState('')

	const updateInput = (value) => {
		if (error !== '') setError('')
		if (value !== motivation)
			dispatch(setInitialConfigExtraProperty({ key: 'motivation', value }))
	}

	const validateInput = () => {
		let isValid = true
		if (motivation === '') {
			setError("Devi selezionare un'opzione per continuare.")
			isValid = false
		} else if (!options.includes(motivation)) {
			setError("L'opzione selezionata non è valida.")
			isValid = false
		}

		return isValid
	}

	const handleSubmit = () => {
		setIsLoading(true)
		const isInputValid = validateInput()
		if (!isInputValid) {
			setIsLoading(false)
			return
		}
		history.push(`/impostazioni-iniziali/${step + 1}`)
		setIsLoading(false)
	}

	const handleSkip = (e) => {
		setIsLoading(true)
		dispatch(setInitialConfigExtraProperty({ key: 'motivation', value: '' }))
		history.push(`/impostazioni-iniziali/${step + 1}`)
		setIsLoading(false)
	}

	return (
		<IonPage className="initial-configuration">
			<InitialConfigHeader
				step={step}
				totalSteps={totalSteps}
				isLoading={isLoading}
			/>

			<IonContent>
				<h1>
					Per raggiungere i tuoi obiettivi hai bisogno di essere motivato/a?
				</h1>

				<IonRadioGroup value={motivation}>
					{options.map((option, index) => {
						return (
							<IonItem
								onClick={() => updateInput(option)}
								key={index}
								className="no-padding-item">
								<IonRadio value={option}>
									<IonLabel className="white-space-normal">{option}</IonLabel>
								</IonRadio>
							</IonItem>
						)
					})}
				</IonRadioGroup>
				<IonText className="error">{error}</IonText>
				<div className="buttons">
					<IonButton
						shape="round"
						onClick={handleSkip}
						disabled={isLoading}
						color="light-gray"
						expand="full">
						<IonText color="white">Salta</IonText>
					</IonButton>
					<IonButton
						shape="round"
						onClick={handleSubmit}
						disabled={isLoading}
						color="primary"
						expand="full">
						Avanti
					</IonButton>
				</div>
			</IonContent>
		</IonPage>
	)
}

export default MotivationPage
