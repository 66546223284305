import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  toast: null
}

const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    setToast: (state, action) => {
      state.toast = action.payload
    },
    showToastGenericError: state => {
      state.toast = [
        {
          severity: 'error',
          summary: 'Informazioni non salvate',
          detail: 'Abbiamo riscontrato un errore. Riprova più tardi',
          life: 3000
        }
      ]
    },
    showToastGenericSuccess: state => {
      state.toast = [
        {
          severity: 'success',
          summary: 'Informazioni non salvate',
          detail: 'Abbiamo riscontrato un errore. Riprova più tardi',
          life: 3000
        }
      ]
    },
    showToastGenericWarning: state => {
      state.toast = [
        {
          severity: 'warn',
          summary: 'Informazioni non salvate',
          detail: 'Abbiamo riscontrato un errore. Riprova più tardi',
          life: 3000
        }
      ]
    }
  }
})

export const { setToast, showToastGenericError, showToastGenericSuccess, showToastGenericWarning } = toastSlice.actions
export default toastSlice.reducer
