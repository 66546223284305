import {
	IonContent,
	IonItem,
	IonList,
	IonPage,
	IonToggle,
	IonIcon,
	IonPopover,
	IonBadge,
} from '@ionic/react'
import MainFooter from '../../components/mainFooter'
import MainHeader from '../../components/headers/mainHeader'
import { informationOutline } from 'ionicons/icons'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { getPushSettings, pushUserSettings } from '../../redux/actions'
import './NotificationsConfig.min.css'
import ActivateNotifications from '../../components/modals/NotificationsModal/ActivateNotifications'
import BlockedNotifications from '../../components/modals/NotificationsModal/BlockedNotifications'

const NotificationsConfigPage = () => {
	const handleToggleChange = (index, value) => {
		const updatedSettings = pushSettingsData.map((config, i) =>
			i === index ? { ...config, user_setting: value } : config
		)
		dispatch(pushUserSettings(updatedSettings))
	}

	const dispatch = useDispatch()
	const pushSettings = useSelector((state) => state.pushSettings)

	useEffect(() => {
		dispatch(getPushSettings())
	}, [])

	const pushSettingsData = pushSettings.getPushSettings.data || []

	const [showNotificationsModal, setShowNotificationsModal] = useState(false)
	const [showBlockedNotificationsModal, setShowBlockedNotificationsModal] =
		useState(false)
	const notificationPermission =
		'Notification' in window ? window?.Notification?.permission : 'default'

	const permissionStates = {
		color: 'light',
		text: 'Non Attive',
	}

	const [appNotificationsEnabled, setAppNotificationsEnabled] = useState(
		JSON.parse(localStorage.getItem('adoc_app_n'))
	)

	useEffect(() => {
		localStorage.setItem('adoc_app_n', appNotificationsEnabled)
	}, [appNotificationsEnabled])

	if (notificationPermission === 'granted') {
		permissionStates.color = 'primary'
		permissionStates.text = 'Attivate'
	}

	if (notificationPermission === 'denied') {
		permissionStates.color = 'danger'
		permissionStates.text = 'Disattivate'
	}

	const notificationState = (
		<IonBadge
			onClick={() => {
				if (notificationPermission === 'default') {
					setShowNotificationsModal(true)
				} else if (notificationPermission === 'denied') {
					setShowBlockedNotificationsModal(true)
				}
			}}
			style={{ marginBottom: '-3px', cursor: 'pointer' }}
			color={permissionStates.color}>
			{permissionStates.text}
		</IonBadge>
	)

	const subscription =
		useSelector((state) => state.user?.master_club_subscriber?.subscription) ??
		null

	const appNotificationState = (
		<IonBadge
			onClick={() => {
				if (subscription?.subscription_type?.name === 'premium')
					setAppNotificationsEnabled(!appNotificationsEnabled)
			}}
			style={{ marginBottom: '-3px', cursor: 'pointer' }}
			color={appNotificationsEnabled ? 'primary' : 'danger'}>
			{appNotificationsEnabled ? 'Attivate' : 'Disattivate'}
		</IonBadge>
	)

	return (
		<IonPage>
			<MainHeader
				page="NotificationsConfigPage"
				title={'Imposta notifiche'}
				appNotificationsEnabled={appNotificationsEnabled}
				// CustomComponent={notificationState}
			/>

			<IonContent id="notifications-config-page">
				<ActivateNotifications
					redirectHome={false}
					showNotificationsModal={showNotificationsModal}
					setShowNotificationsModal={setShowNotificationsModal}
				/>
				<BlockedNotifications
					showBlockedNotificationsModal={showBlockedNotificationsModal}
					setShowBlockedNotificationsModal={setShowBlockedNotificationsModal}
				/>
				<IonList>
					<IonItem>
						<label className="ion-margin-end">Notifiche di sistema:</label>{' '}
						{notificationState}
					</IonItem>
					{/* {subscription?.subscription_type?.name === 'premium' && (
						<IonItem>
							<label className="ion-margin-end ">Notifiche dell’app:</label>{' '}
							{appNotificationState}
						</IonItem>
					)} */}
					{pushSettingsData.map((config, i) => (
						<IonItem
							lines="full"
							key={config.id}>
							<div className="config-name">{config.name}</div>
							<div className="popover-wrapper">
								<IonPopover
									trigger={'click-trigger-' + config.id}
									triggerAction="click">
									<IonContent>
										<div className="ion-padding">{config.description}</div>
									</IonContent>
								</IonPopover>
								{/* <div
									id={'click-trigger-' + config.id}
									className="icon-click-zone">
									<div
										color="light-gray"
										className="icon-wrapper">
										<IonIcon
											slot="icon-only"
											color="light"
											icon={informationOutline}
										/>
									</div>
								</div> */}
							</div>
							<IonToggle
								slot="end"
								id={'toggle-' + config.id}
								disabled={subscription?.subscription_type?.name !== 'premium'}
								checked={config.user_setting}
								onIonChange={(e) =>
									handleToggleChange(i, e.detail.checked)
								}></IonToggle>
						</IonItem>
					))}
				</IonList>
			</IonContent>

			<MainFooter />
		</IonPage>
	)
}

export default NotificationsConfigPage
