import {
	IonPage,
	IonContent,
	IonText,
	IonButton,
	IonSelect,
	IonSelectOption,
} from '@ionic/react'
import { setInitialConfigProperty } from '../../../redux/reducer/initialConfigSlice'
import InitialConfigHeader from '../header'
import { useDispatch, useSelector } from 'react-redux'
import config from '../../../settings/config'
import { useEffect, useState } from 'react'
import '../InitialConfiguration.min.css'
import { useHistory } from 'react-router'

const WhereYouLivePage = ({ step, totalSteps }) => {
	const history = useHistory()
	const [provincesOptions, setProvincesOptions] = useState({
		data: null,
		isLoading: true,
		error: false,
	})
	const [citiesOptions, setCitiesOptions] = useState({
		data: null,
		isLoading: true,
		error: false,
	})
	const province_id = useSelector((state) => state.initialConfig.province_id)
	const city_id = useSelector((state) => state.initialConfig.city_id)
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState({ province: '', city: '' })
	const dispatch = useDispatch()

	useEffect(() => {
		if (province_id) searchCitiesOptions(province_id)
		if (provincesOptions.data) return
		setProvincesOptions({ data: null, isLoading: true, error: false })

		fetch(
			`${
				config.env[config.env.mode].url
			}/api/postal-info/list?service=province&method=get`,
			{
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${localStorage.getItem('adoc_t')}`,
				},
			}
		)
			.then((resp) => {
				if (!resp.ok) throw new Error(resp.message)
				return resp.json()
			})
			.then((data) =>
				setProvincesOptions({ data, isLoading: true, error: false })
			)
			.catch((e) =>
				setProvincesOptions({ data: null, isLoading: false, error: true })
			)
	}, [])

	const updateProvinceInput = (e) => {
		if (error.province !== '') {
			setError((oldError) => {
				const newError = { ...oldError }
				newError.province = ''
				return newError
			})
		}
		if (e.target.value !== province_id) {
			dispatch(
				setInitialConfigProperty({ key: 'province_id', value: e.target.value })
			)
			searchCitiesOptions(e.target.value)
		}
	}

	const updateCityInput = (e) => {
		if (error.city !== '') {
			setError((oldError) => {
				const newError = { ...oldError }
				newError.city = ''
				return newError
			})
		}
		if (e.target.value !== city_id)
			dispatch(
				setInitialConfigProperty({ key: 'city_id', value: e.target.value })
			)
	}

	const searchCitiesOptions = (province_id) => {
		setCitiesOptions({ data: null, isLoading: true, error: false })

		fetch(
			config.env[config.env.mode].url +
				'/api/postal-info/list?service=city&method=get&province_id=' +
				province_id,
			{
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${localStorage.getItem('adoc_t')}`,
				},
			}
		)
			.then((response) => {
				if (!response.ok) throw new Error(response.message)
				return response.json()
			})
			.then((data) =>
				setCitiesOptions({ data, isLoading: false, error: false })
			)
			.catch((e) =>
				setCitiesOptions({ data: null, isLoading: false, error: true })
			)
	}

	const validateInput = () => {
		let isValid = true
		if (typeof province_id !== 'number') {
			setError((oldError) => {
				const newError = { ...oldError }
				newError.province = 'Devi selezionare una provincia per continuare.'
				return newError
			})
			isValid = false
		} else if (!provincesOptions.data?.map((p) => p.id).includes(province_id)) {
			setError((oldError) => {
				const newError = { ...oldError }
				newError.province = 'La provincia selezionata non è valida.'
				return newError
			})
			isValid = false
		}

		if (typeof city_id !== 'number') {
			setError((oldError) => {
				const newError = { ...oldError }
				newError.city = 'Devi selezionare una città per continuare.'
				return newError
			})
			isValid = false
		} else if (!citiesOptions.data?.map((c) => c.id).includes(city_id)) {
			setError((oldError) => {
				const newError = { ...oldError }
				newError.city = 'La città selezionata non è valida.'
				return newError
			})
			isValid = false
		}
		return isValid
	}

	const handleSubmit = () => {
		setIsLoading(true)
		const isInputValid = validateInput()
		if (!isInputValid) {
			setIsLoading(false)
			return
		}

		history.push(`/impostazioni-iniziali/${step + 1}`)
		setIsLoading(false)
	}

	return (
		<IonPage className="initial-configuration">
			<InitialConfigHeader
				step={step}
				totalSteps={totalSteps}
				isLoading={isLoading}
			/>

			<IonContent>
				<h1>Dove vivi?</h1>
				<h6>
					Questa informazione potrà esserti utile per scoprire se ci sono
					partecipanti ai nostri percorsi nella tua città
				</h6>

				<IonSelect
					value={province_id}
					label="Provincia"
					label-placement="stacked"
					cancelText="Cancella"
					onIonChange={updateProvinceInput}
					placeholder="Selezionare">
					{provincesOptions.data?.map((provinceOption) => {
						return (
							<IonSelectOption
								key={provinceOption.id}
								value={provinceOption.id}>
								{provinceOption.name}
							</IonSelectOption>
						)
					})}
				</IonSelect>
				<IonText className="error">{error.province}</IonText>
				<IonSelect
					value={city_id}
					label="Città"
					onIonChange={updateCityInput}
					label-placement="stacked"
					cancelText="Cancella"
					placeholder="Selezionare">
					{citiesOptions.data?.map((cityOption) => {
						return (
							<IonSelectOption
								key={cityOption.id}
								value={cityOption.id}>
								{cityOption.name}
							</IonSelectOption>
						)
					})}
				</IonSelect>

				<IonText className="error">{error.city}</IonText>

				<div className="buttons">
					<IonButton
						shape="round"
						onClick={handleSubmit}
						disabled={isLoading}
						color="primary"
						expand="full">
						Avanti
					</IonButton>
				</div>
			</IonContent>
		</IonPage>
	)
}

export default WhereYouLivePage
