import React, { useEffect, useRef, useState } from 'react'
import VimeoPlayer from '@vimeo/player'
import { useLocation } from 'react-router'
import { useIonViewDidEnter, useIonViewWillLeave } from '@ionic/react'

export default function CustomVimeoPlayer({
	video,
	post_id = 1,
	autoplay = false,
	isWatching = null,
	isOpen = true,
	overlay,
	only_post,
	setHasPlayed,
}) {
	const video_id = `vimeo-player${video}-${post_id}${
		overlay ? '-overlay' : ''
	}${only_post ? '-only_post' : ''}`
	const playerRef = useRef(null)
	const [isPlaying, setIsPlaying] = useState(false)
	const [forcePause, setForsePause] = useState(false)
	const [isLeaving, setIsLeaving] = useState(false)
	const [videoWidth, setVideoWidth] = useState(null)
	const [videoHeight, setVideoHeight] = useState(null)

	const location = useLocation()

	useEffect(() => {
		let player = null

		const handleFullscreenChange = () => {
			if (playerRef.current) {
				playerRef.current.getFullscreen().then((isFullscreen) => {
					if (isFullscreen) {
						const videoOrientation =
							videoWidth > videoHeight ? 'portrait' : 'landscape'

						screen.orientation
							.lock(videoOrientation)
							.then(() => {
								// console.log(`locked to landscape`)
							})
							.catch((error) => {
								console.log(error)
							})
					} else {
						if (screen.orientation.unlock) {
							screen.orientation.unlock()
						}
					}
				})
			}
		}

		const handlePlay = () => {
			setIsPlaying(true)
			setHasPlayed(true)
		}

		const handlePause = () => {
			setForsePause(true)
			setIsPlaying(false)
		}

		const fetchVideoInfo = async () => {
			const accessToken = '1a0d3068fe3802f40df7db7031448313'

			fetch(`https://api.vimeo.com/videos/${video}`, {
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			})
				.then((response) => response.json())
				.then((data) => {
					setVideoWidth(data.width)
					setVideoHeight(data.height)
				})
				.catch((error) =>
					console.error('Error al obtener información del video', error)
				)
		}

		if (!video || !isOpen) return

		player = new VimeoPlayer(video_id, {
			id: video,
		})

		playerRef.current = player
		playerRef.current.on('fullscreenchange', handleFullscreenChange)
		playerRef.current.on('play', handlePlay)
		playerRef.current.on('pause', handlePause)

		playerRef.current.ready().then(() => {
			const iframe = playerRef.current.element
			if (iframe) {
				iframe.setAttribute(
					'allow',
					'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
				)
			}
		})

		fetchVideoInfo()

		return () => {
			if (playerRef.current) {
				playerRef.current.off('fullscreenchange', handleFullscreenChange)
				playerRef.current.off('play', handlePlay)
				playerRef.current.off('pause', handlePause)
			}
		}
	}, [video, isOpen])

	useEffect(() => {
		if (!playerRef.current) return
		// console.log('isWatching', isWatching)
		if (isLeaving || isWatching === false) {
			console.log('pause')
			if (isPlaying) playerRef.current.pause()
		} else if (autoplay && isWatching === true) {
			console.log('play')
			if (!isPlaying && !forcePause) playerRef.current.play()
		}
	}, [isWatching, isPlaying, isLeaving])

	useIonViewDidEnter(() => {
		setIsLeaving(false)
	})
	useIonViewWillLeave(() => {
		setIsLeaving(true)
	})

	if (!video) return null

	return (
		<div
			className="videoPlayer"
			style={{
				paddingTop: `${100 / (videoWidth / videoHeight)}%`,
			}}
			id={video_id}
			ref={playerRef}
		/>
	)
}
