import { useState, useRef } from 'react'
import iconLoading from '../../assets/images/icon-loading.svg'
import dsPictogram from '../../assets/images/ds-pictogram.svg'
import imageNotFound from '../../assets/icons/image-not-found.svg'
import userImageNotFound from '../../assets/icons/user-image-not-found.svg'
import chefHat from '../../assets/icons/chef-hat.svg'

export const ImageLoader = ({
	imageType = 'default',
	image,
	css = '',
	zoom = false,
	styles = {},
}) => {
	const [enableZoom, setEnableZoom] = useState(zoom)

	let notFoundImage = imageNotFound

	switch (imageType) {
		case 'user':
			notFoundImage = userImageNotFound
			break
		case 'recipe':
			notFoundImage = chefHat
			break
	}

	const [loadingAvatar, setLoadingAvatar] = useState(true)
	const [loadingImg, setLoadingImg] = useState(iconLoading)
	css = !image ? css + ' no-image' : css
	image = image || notFoundImage
	const [src, setSrc] = useState(image)
	const [style, setStyle] = useState(styles)

	const img = (
		<img
			className={css + ' fade d-' + !loadingAvatar}
			src={src}
			style={style}
			onLoad={({ e }) => {
				setLoadingAvatar(false)
			}}
			onError={(e) => {
				setEnableZoom(false)
				setSrc(notFoundImage)
				if (imageType === 'group') {
					setStyle({ display: 'none' })
				} else {
					setStyle({ width: '100%', maxWidth: '300px' })
				}
			}}
		/>
	)

	return (
		<>
			<img
				src={loadingImg}
				className={css + ' image-loader d-' + loadingAvatar}
			/>
			{image && (
				<>
					{enableZoom ? (
						<span className={enableZoom ? 'swiper-zoom-container' : ''}>
							{img}
						</span>
					) : (
						img
					)}
				</>
			)}
		</>
	)
}
