import { IonButton, IonContent, IonPage } from '@ionic/react'
import MainFooter from '../../components/mainFooter'
import MainHeader from '../../components/headers/mainHeader'
import { Icon } from '@iconify/react'
import { isFullAuth } from '../../services/RouteAuth'
import config from '../../settings/config'
import dietaSocialLogo from '../../assets/images/ds-logo.svg'
import dietaSocialLogoSandbox from '../../assets/images/ds-logo-sandbox.svg'

const NotFoundPage = () => {
	const logged = isFullAuth()

	const logo =
		config.env.mode !== 'prod' ? dietaSocialLogoSandbox : dietaSocialLogo

	return (
		<IonPage>
			{logged && (
				<MainHeader
					page="NotFoundPage"
					title="Pagina non trovata"
				/>
			)}
			{!logged && (
				<img
					src={logo}
					alt="Dieta Social Logo"
					className="logo"
					style={{ maxWidth: '300px', margin: '100px auto 0 auto' }}
				/>
			)}
			<IonContent className="no-records ion-padding">
				<Icon
					style={{ marginTop: '70px' }}
					icon="iconoir:ev-plug-error"
					width="100"
				/>
				<h1>Ops! non troviamo quello che cerchi</h1>
				<IonButton
					color="primary"
					onClick={() => {
						window.history.back()
					}}>
					Torna indietro
				</IonButton>
			</IonContent>
			{isFullAuth && <MainFooter />}
		</IonPage>
	)
}

export default NotFoundPage
