import React, { useState, useEffect } from 'react'
import {
	IonItem,
	IonText,
	IonCard,
	IonCardHeader,
	IonCardTitle,
	IonCardContent,
	IonList,
	IonIcon,
	IonSegment,
	IonSegmentButton,
} from '@ionic/react'
import recipeIcons from './icons'
import Ingredients from './Ingredients'
import Procedure from './Procedure'
import config from '../../settings/config'
import { ImageLoader } from '../ImageLoader'

const segments = { ingredients: 'ingredients', procedure: 'procedure' }

const Recipe = ({ recipeId, cycleId = null }) => {
	const [segmentSelected, setSegmentSelected] = useState(segments.ingredients)
	const [recipeData, setRecipeData] = useState(null)

	const query = cycleId ? `?cycleId=${cycleId}` : ''

	useEffect(() => {
		if (!isNaN(recipeId)) {
			const fetchRecipe = async () => {
				try {
					const response = await fetch(
						`${
							config.env[config.env.mode].url
						}/api/ricette/${recipeId}/fetch${query}`,
						{
							method: 'GET',
							headers: {
								Accept: 'application/json',
								'Content-Type': 'application/json',
								Authorization: `Bearer ${localStorage.getItem('adoc_t')}`,
							},
						}
					)
					const data = await response.json()
					setRecipeData(data)
				} catch (error) {
					console.log(error)
				}
			}

			fetchRecipe()
		}
	}, [recipeId])

	const stats = [
		{
			stat: 'Difficoltà',
			value: recipeData?.difficulty || 'Caricando...',
			class: 'capitalize-first-letter',
			icon: recipeIcons[0],
		},
		{
			stat: 'Porzioni',
			value: recipeData?.portion || 'Caricando...',
			icon: recipeIcons[1],
		},
		{
			stat: 'Preparazione',
			value: (recipeData?.preparation_time ?? 0) + ' minuti',
			icon: recipeIcons[2],
		},
		{
			stat: 'Cottura',
			value: (recipeData?.cooking_time ?? 0) + ' minuti',
			icon: recipeIcons[3],
		},
		{
			stat: 'Riposo',
			value: (recipeData?.resting_time ?? 0) + ' minuti',
			icon: recipeIcons[4],
		},
	]

	const recipeImage = recipeData?.images[0]?.url

	return (
		<div style={{ overflowY: 'auto' }}>
			<IonCard>
				{recipeImage && (
					<ImageLoader
						image={
							recipeImage
								? recipeImage.includes('http')
									? recipeImage
									: config.env[config.env.mode].url + recipeImage
								: null
						}
						imageType="recipe"
						styles={{
							width: '100%',
							height: '50vw',
							maxHeight: '300px',
							objectFit: recipeImage ? 'cover' : 'contain',
						}}
					/>
				)}
				<IonCardHeader>
					<IonCardTitle
						style={{
							fontSize: '1.25rem',
							fontWeight: 'var(--font-size-black)',
						}}>
						{recipeData?.name || 'Caricando...'}
					</IonCardTitle>
				</IonCardHeader>
				<IonCardContent>
					<IonList>
						{recipeData &&
							stats.map((stat, index) => (
								<IonItem
									key={index}
									lines={index === stats.length - 1 ? 'none' : 'inset'}>
									<IonIcon
										slot="start"
										src={stat.icon}
										size="large"
									/>
									<IonText>
										<div style={{ fontWeight: 'var(--font-size-black)' }}>
											{stat.stat}:
										</div>
									</IonText>
									<IonText
										className={stat.class || ''}
										style={{
											marginLeft: '2px',
										}}>
										{stat.value}
									</IonText>
								</IonItem>
							))}
					</IonList>
				</IonCardContent>
			</IonCard>
			<IonSegment
				value={segmentSelected}
				onIonChange={(e) => setSegmentSelected(e.target.value)}>
				<IonSegmentButton value="ingredients">INGREDIENTI</IonSegmentButton>
				<IonSegmentButton value="procedure">PROCEDIMENTO</IonSegmentButton>
			</IonSegment>

			<div className="recipe-content">
				{segmentSelected === 'ingredients' && (
					<Ingredients
						recipeData={recipeData}
						fromPlan={!!cycleId}
					/>
				)}

				{segmentSelected === 'procedure' && (
					<Procedure recipeData={recipeData} />
				)}
			</div>
		</div>
	)
}

export default Recipe
