import { IonContent, IonPage } from '@ionic/react'
import MainFooter from '../../../components/mainFooter'
import MainHeader from '../../../components/headers/mainHeader'
import MenuContent from './MenuContent'
import dayjs from 'dayjs'
// import { setDayType } from '../../redux/reducer/menuDaySlice'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'

const DayMenuPage = ({ match }) => {
	const date = match.params.day
	const menuDay = useSelector((state) => state.menuDay)

	const location = useLocation()
	const planData = location.state?.planData
	const planIsLoading = location.state?.planIsLoading
	const nextWeekMonday = dayjs().add(1, 'week').startOf('week')
	const nextWeekSunday = nextWeekMonday.add(6, 'day')
	const isNextWeek =
		dayjs(date).add(1, 'hour').isAfter(nextWeekMonday) &&
		dayjs(date).subtract(1, 'hour').isBefore(nextWeekSunday)

	return (
		<>
			<IonPage>
				<MainHeader
					page="DayMenuPage"
					href={'/menu'}
					hrefParams={{ isNextWeek: isNextWeek }}
					forceHref={true}
					title={
						'Menu ' +
						dayjs(date).format('D MMMM YYYY') +
						(menuDay.type ? ' (' + menuDay.type + ')' : '')
					}
				/>
				<IonContent>
					<MenuContent
						date={date}
						planData={planData}
						planIsLoading={planIsLoading}
					/>
				</IonContent>

				<MainFooter />
			</IonPage>
		</>
	)
}

export default DayMenuPage
