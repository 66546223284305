import {
	IonPage,
	IonContent,
	IonText,
	IonRadioGroup,
	IonRadio,
	IonButton,
	IonItem,
} from '@ionic/react'
import InitialConfigHeader from '../header'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setInitialConfigProperty } from '../../../redux/reducer/initialConfigSlice'
import { isValidGender } from '../../../services/functions'
import '../InitialConfiguration.min.css'
import { useHistory } from 'react-router'

const GenderPage = ({ step, totalSteps }) => {
	const history = useHistory()
	const [isLoading, setIsLoading] = useState(false)
	const sex = useSelector((state) => state.initialConfig.sex)
	const dispatch = useDispatch()
	const [error, setError] = useState('')

	const updateInput = (e) => {
		if (error !== '') setError('')
		if (e.target.value !== sex)
			dispatch(setInitialConfigProperty({ key: 'sex', value: e.target.value }))
	}

	const validateInput = () => {
		let isValid = true
		if (sex === '') {
			setError('É necessario inserire un sesso.')
			isValid = false
		} else if (!isValidGender(sex)) {
			setError('Il sesso inserito non è valido.')
			isValid = false
		}

		return isValid
	}

	const handleSubmit = () => {
		setIsLoading(true)
		const isInputValid = validateInput()
		if (!isInputValid) {
			setIsLoading(false)
			return
		}

		history.push(`/impostazioni-iniziali/${step + 1}`)
		setIsLoading(false)
	}
	return (
		<IonPage className="initial-configuration">
			<InitialConfigHeader
				step={step}
				totalSteps={totalSteps}
				isLoading={isLoading}
			/>

			<IonContent>
				<h1>Sesso</h1>
				<IonRadioGroup value={sex}>
					<IonItem className="no-padding-item">
						<IonRadio
							value="male"
							onClick={updateInput}>
							Maschio
						</IonRadio>
					</IonItem>
					<IonItem className="no-padding-item">
						<IonRadio
							value="female"
							onClick={updateInput}>
							Femmina
						</IonRadio>
					</IonItem>
				</IonRadioGroup>
				<IonText className="error">{error}</IonText>

				<div className="buttons">
					<IonButton
						shape="round"
						onClick={handleSubmit}
						disabled={isLoading}
						color="primary"
						expand="full">
						Avanti
					</IonButton>
				</div>
			</IonContent>
		</IonPage>
	)
}

export default GenderPage
