import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonCol,
	IonGrid,
	IonItem,
	IonList,
	IonRow,
	useIonAlert,
	useIonLoading,
	useIonToast,
} from '@ionic/react'
import { useSelector, useDispatch } from 'react-redux'
import dayjs from 'dayjs' // Import dayjs
import 'dayjs/locale/it' // Import Italian locale for dayjs
import relativeTime from 'dayjs/plugin/relativeTime' // Import relativeTime plugin for dayjs
import dietaSocial from './dietaSocial.png'
import FreeSubscriptionImage from './free-subscription.jpg'
import {
	cancelAtPeriodEnd,
	getUserInfo,
	importSubscription,
} from '../../redux/actions'
import { useHistory } from 'react-router'
import { upperFirst } from '../../services/functions'
import { NoRecords } from '../../components/NoRecords'
import { ImageLoader } from '../../components/ImageLoader'
dayjs.extend(relativeTime) // Extend dayjs with the relativeTime plugin
dayjs.locale('it') // Set the locale to Italian

const Subscription = ({ subscription, is_active_subscription }) => {
	const dispatch = useDispatch()
	const [ionLoading, hideIonLoading] = useIonLoading()
	const [ionToast] = useIonToast()
	const history = useHistory()

	const handleCancelAtPeriodEnd = (subscriptionId, value) => {
		value = !!value // Convert to boolean
		const action = value ? 'disattivazione' : 'attivazione'
		console.log(value)
		console.log(action)
		console.log(subscriptionId, value)
		ionLoading(upperFirst(action) + ' in corso...')

		dispatch(cancelAtPeriodEnd({ subscriptionId, value }))
			.then((result) => {
				if (result.error) {
					console.error('Error fetching:', result.error)
					ionToast({
						message: 'Errore durante la ' + action,
						duration: 3000,
					})
				} else {
					console.log(result.payload)

					dispatch(getUserInfo()).then((result) => {
						ionToast({
							message: upperFirst(action) + ' avvenuta con successo',
							duration: 3000,
						})
					})
				}
				hideIonLoading()
			})
			.catch((error) => {
				console.error('Error fetching:', error)
				ionToast({
					message: 'Errore durante la ' + action,
					duration: 3000,
				})
				hideIonLoading()
			})
	}

	const [presentAlert] = useIonAlert()

	const formattedStartDate = dayjs(subscription.start_date).format(
		'D MMMM YYYY'
	)
	const formattedEndDate = dayjs(subscription.end_date).format('D MMMM YYYY')

	const passed15Days = dayjs(subscription.start_date).add(15, 'day') < dayjs()
	const ended = dayjs(subscription.end_date) < dayjs()

	const subscriptionName = subscription.subscription_type.name
	const isFree = subscriptionName === 'free'
	const product = subscription.latest_order?.product
	const cover_url = product?.cover_url
		? product.storage_path + product.cover_url
		: null

	if (!subscription.is_enabled) return null
	// if (isFree && !subscription.is_enabled) return null

	return (
		<IonCol
			size="12"
			sizeMd="4">
			<IonCard
				id={`card-${subscription.id}`}
				className="subscription-card">
				{/* <ImageLoader
					css="subscription-image"
					image={isFree ? FreeSubscriptionImage : cover_url}
				/> */}
				<IonCardHeader>
					<IonCardTitle>{subscription.name}</IonCardTitle>
				</IonCardHeader>
				<IonCardContent>
					<IonList>
						<IonItem>
							<div className="item">Tipo di abbonamento:</div>
							<IonButton
								size="small"
								color={subscription.subscription_type.color}>
								{subscriptionName}
							</IonButton>
						</IonItem>
						{(product || isFree) && (
							<IonItem>
								<div className="item">Prodotto:</div>
								{(product && product.name) ?? (isFree && 'Percorso gratuito')}
							</IonItem>
						)}
						<IonItem>
							<div className="item">Numero:</div>
							10000{subscription.id}
						</IonItem>
						{/* <IonItem>
							<div className="item">Stato:</div>
							<IonButton
								size="small"
								color={subscription.is_enabled ? 'primary' : 'warning'}>
								{subscription.is_enabled ? 'Attivo' : 'Inattivo'}
							</IonButton>
						</IonItem> */}
						<IonItem>
							<div className="item"> Data inizio:</div>
							{formattedStartDate}
						</IonItem>
						<IonItem>
							<div className="item">Data fine:</div>
							{formattedEndDate}
						</IonItem>
					</IonList>
					{subscription.is_enabled && !ended && passed15Days && !isFree && (
						<div className="ion-padding-top ion-text-center">
							<IonButton
								color="light"
								onClick={() => {
									const params = {
										title: 'Aggiornare metodo di pagamento',
										type: 'payment_method',
									}
									history.push('/metodo-di-pagamento', params)
								}}>
								Aggiorna metodo di pagamento
							</IonButton>
							{subscription.can_cancel_at_period_end && (
								<IonButton
									fill="clear"
									color="gray"
									onClick={() =>
										presentAlert({
											header:
												dayjs(subscription.next_billing_at) > dayjs()
													? 'Disattiva rinnovo'
													: 'Riativare rinnovo',
											message:
												dayjs(subscription.next_billing_at) > dayjs()
													? 'Confermi che non vuoi più rinovare?'
													: 'Confermi che vuoi riattivare il rinnovo?',
											buttons: [
												{
													text: 'Cancella',
													role: 'cancel',
													handler: () => {},
												},
												{
													text: 'Si, confermo',
													role: 'confirm',
													handler: () => {
														handleCancelAtPeriodEnd(
															subscription.id,
															subscription.next_billing_at
														)
													},
												},
											],
										})
									}>
									{dayjs(subscription.next_billing_at) > dayjs()
										? 'Disattiva rinnovo'
										: 'Riattiva rinnovo'}
								</IonButton>
							)}
						</div>
					)}
				</IonCardContent>
			</IonCard>
		</IonCol>
	)
}

const SubscriptionsContent = ({ setSegmentSelected }) => {
	const currentDate = dayjs()
	const subscriber = useSelector((state) => state.user.master_club_subscriber)
	const subscriptions = subscriber?.subscriptions ?? []
	const subscriptionName =
		subscriber?.subscription?.subscription_type?.name ?? null
	const history = useHistory()
	const [ionToast] = useIonToast()
	const [ionLoading, hideIonLoading] = useIonLoading()
	const dispatch = useDispatch()

	const handleImportSubscription = (e) => {
		ionLoading({
			message: 'Importazione in corso...',
		})

		dispatch(importSubscription())
			.then((result) => {
				if (result.error) {
					console.error('Error fetching:', result.error)
					ionToast({
						message: result.payload.message || 'Errore durante l’importazione',
						duration: 3000,
					})
				} else {
					ionToast({
						message:
							result.payload.message || 'Abbonamento importato con successo',
						duration: 3000,
					})
					dispatch(getUserInfo())
				}
				hideIonLoading()
			})
			.catch((error) => {
				console.error('Error fetching:', error)
				ionToast({
					message: 'Errore durante l’importazione',
					duration: 3000,
				})
				hideIonLoading()
			})
	}

	return (
		<div className="subscription-content">
			{subscriptions.length ? (
				<>
					<h3 className="ion-margin-start">Controlla i tuoi abbonamenti</h3>
					<IonGrid>
						<IonRow>
							{subscriptions.map((subscription) => {
								const startDate = dayjs(subscription.start_date)
								const endDate = dayjs(subscription.end_date)
								const isBetweenDates =
									currentDate.isAfter(startDate) &&
									currentDate.isBefore(endDate)
								return (
									<Subscription
										key={subscription.id}
										subscription={subscription}
										is_active_subscription={isBetweenDates}
									/>
								)
							})}
						</IonRow>
					</IonGrid>
				</>
			) : (
				<>
					<div className="ion-text-center ion-margin">
						<NoRecords text="abbonamenti" />
					</div>
				</>
			)}
			<div className="ion-text-center ion-margin">
				<IonButton
					onClick={handleImportSubscription}
					expand="full"
					fill="clear"
					color="danger">
					Non trovi il tuo abbonamento? <br />
					Clicca qui
				</IonButton>
			</div>
			<div className="ion-text-center ion-margin-bottom">
				{subscriptionName !== 'premium' && (
					<IonButton
						className="ion-margin-bottom ion-padding-horizontal"
						onClick={() => history.push('/prodotti')}
						expand="full"
						color="primary">
						DIVENTA PREMIUM
					</IonButton>
				)}
				{!subscriptionName && (
					<IonButton
						className="ion-margin-bottom ion-padding-horizontal"
						onClick={() => history.push('/percorso-gratuito')}
						expand="full"
						color="blue">
						PROVA PER 7 GIORNI
					</IonButton>
				)}
			</div>
		</div>
	)
}

export default SubscriptionsContent
