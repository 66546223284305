import { createSlice } from '@reduxjs/toolkit'
import { getGroup } from '../actions'

const initialState = {
	getGroup: {
		data: null,
		isLoading: false,
		error: false,
	},
}

const groupSlice = createSlice({
	name: 'group',
	initialState,
	reducers: {
		resetGroupError: (state) => {
			state.getGroup.error = false
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getGroup.pending, (state) => {
			state.getGroup.isLoading = true
			state.getGroup.error = false
		})
		builder.addCase(getGroup.fulfilled, (state, action) => {
			state.getGroup.isLoading = false
			state.getGroup.error = false
			state.getGroup.data = action.payload
		})
		builder.addCase(getGroup.rejected, (state, action) => {
			state.getGroup.isLoading = false
			state.getGroup.error = action.error.message
		})
	},
})

export const { resetGroupError } = groupSlice.actions
export default groupSlice.reducer
