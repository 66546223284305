import {
	IonPage,
	IonCol,
	IonContent,
	IonText,
	IonImg,
	IonButton,
	IonGrid,
	IonRow,
} from '@ionic/react'
import { setInitialConfigProperty } from '../../../redux/reducer/initialConfigSlice'
import { useDispatch, useSelector } from 'react-redux'
import avatars from '../../../assets/avatars'
import { useState } from 'react'
import '../InitialConfiguration.min.css'
import InitialConfigHeader from '../header'
import { useHistory } from 'react-router'

const SelectAnAvatarPage = ({ step, totalSteps, setPageSelected }) => {
	const history = useHistory()
	const [avatarSelected, setAvatarSelected] = useState(null)
	const genderAux = useSelector((state) => state.initialConfig.sex)
	const gender = genderAux === '' ? 'male' : genderAux
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState('')
	const dispatch = useDispatch()

	const selectAvatar = (name) => {
		if (error !== '') setError('')
		setAvatarSelected(name)
	}

	const handleSubmit = () => {
		setIsLoading(true)
		if (avatarSelected === null) {
			setError('Devi selezionare un avatar')
		} else {
			dispatch(
				setInitialConfigProperty({ key: 'avatar', value: avatarSelected })
			)
			history.push(`/impostazioni-iniziali/${step + 1}`)
		}

		setIsLoading(false)
	}
	return (
		<IonPage className="initial-configuration">
			<InitialConfigHeader
				step={step}
				totalSteps={totalSteps}
				isLoading={isLoading}
				setPageSelected={setPageSelected}
				pagesBack={3}
			/>

			<IonContent>
				<h1>Scegli il tuo avatar</h1>
				<h6>
					Deciderai in un secondo momento se la vorrai rendere visibile agli
					altri
				</h6>
				<IonText className="error">{error}</IonText>

				<IonGrid>
					<IonRow>
						{avatars[gender].map((avatar) => {
							let styles = { cursor: 'pointer', borderRadius: '100%' }
							if (avatarSelected === avatar.name)
								styles = {
									...styles,
									borderColor: '#8BC34A',
									borderWidth: '5px',
									borderStyle: 'solid',
								}
							return (
								<IonCol
									size="3"
									key={avatar.name}>
									<IonImg
										className="rounded-ion-img"
										style={styles}
										src={avatar.image}
										onClick={() => {
											selectAvatar(avatar.name)
										}}
									/>
								</IonCol>
							)
						})}
					</IonRow>
				</IonGrid>

				<div className="buttons">
					<IonButton
						shape="round"
						onClick={handleSubmit}
						disabled={isLoading}
						expand="full">
						AVANTI
					</IonButton>
				</div>
			</IonContent>
		</IonPage>
	)
}

export default SelectAnAvatarPage
