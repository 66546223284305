import { useEffect } from 'react'
import { useLocation } from 'react-router'
import { getNotificationListUnread, getUserInfo } from '../../redux/actions'
import { getAuthPath } from '../../services/RouteAuth'
import { useDispatch, useSelector } from 'react-redux'

const FirstLoad = () => {
	const dispatch = useDispatch()
	const pathname = useLocation().pathname

	const unreadNotificationsList = useSelector(
		(state) => state.notifications.getNotificationListUnread
	)

	useEffect(() => {
		if (getAuthPath() === 'full' && unreadNotificationsList.data === null) {
			// dispatch(getUserInfo())
			dispatch(getNotificationListUnread())
		}
	}, [pathname])
}

export default FirstLoad
