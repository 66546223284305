import {
	IonContent,
	IonPage,
	IonButton,
	IonGrid,
	IonRow,
	IonCol,
	useIonAlert,
	useIonViewDidEnter,
	IonSkeletonText,
} from '@ionic/react'
import MainHeader from '../../../components/headers/mainHeader'
import MainFooter from '../../../components/mainFooter'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	completePill,
	getMultimediaPosts,
	getPills,
} from '../../../redux/actions'
import { PostMultimedia } from '../../../components/MultimediaInPost'
import ErrorToast from '../../../components/ErrorToast'
import { MultimediaCardSkeleton } from '../../../components/skeletons/MultimediaCardSkeleton'
import { Swiper, SwiperSlide } from 'swiper/react'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'

import { Pagination, HashNavigation } from 'swiper/modules'
import PostFooter from '../../../components/Post/PostFooter'
import PostContent from '../../../components/Post/PostContent'
import { Icon } from '@iconify/react'
import { useHistory, useLocation } from 'react-router'
import FixedElementPortal from '../../../components/FixedElementPortal'
import { unlockTexts } from '../../../services/functions'
import '../Course.min.css'

const MultimediaCard = ({
	postGroup,
	swiperRef,
	isFirstSlide,
	isLastSlide,
	index,
	unlockModules,
	nextDisabled,
	setNextDisabled,
}) => {
	if (swiperRef.current === null) return null
	const swiperInstance = swiperRef.current.swiper
	const [hasPlayed, setHasPlayed] = useState(false)
	const [isWatching, setIsWatching] = useState(false)
	const [completedPill, setCompletedPill] = useState(null)
	const [isPill, setIsPill] = useState(false)
	const { data, isLoading, error } = useSelector(
		(state) => state.course.getPills
	)

	// useIonViewDidEnter(() => {
	// 	if (swiperInstance) swiperInstance.slideTo(0)
	// })

	const dispatch = useDispatch()
	const history = useHistory()

	useEffect(() => {
		if (data !== null && data !== undefined) {
			const pill = data.find((pill) => pill.post_id === postGroup.post_id)
			// console.log('pill', pill)
			if (pill !== undefined) {
				setIsPill(true)
				setCompletedPill(pill.is_completed)
				// console.log('completedPill', pill.is_completed)
			}
		}
	}, [data])

	const handleSetCompletedPill = (value) => {
		setCompletedPill(value)
		setNextDisabled(true)
		dispatch(
			completePill({ post_id: postGroup.post_id, is_completed: value })
		).then(() => {
			// dispatch(getPills())
		})
	}

	const [presentAlert] = useIonAlert()

	useEffect(() => {
		const activeIndex = swiperInstance.activeIndex
		setIsWatching(activeIndex === index)
		// console.log('activeIndex', activeIndex)
	}, [swiperInstance.activeIndex])

	return (
		<>
			<div className="post-item enabled-true lesson-card">
				<PostMultimedia
					autoplay={true}
					postGroup={postGroup}
					only_post={false}
					isWatching={isWatching}
					setHasPlayed={setHasPlayed}
				/>

				<PostContent
					postGroup={postGroup}
					is_preview={false}
					from_main_group={false}
				/>

				<PostFooter
					postGroup={postGroup}
					comments={false}
					reports={false}
					likes={false}
					date={false}
					classes="hideHr"
					is_preview={true}
					is_multimedia={true}
				/>
			</div>
			<FixedElementPortal
				targetId="lesson-page-footer-portal"
				id="lesson-page-footer-portal-start">
				<IonGrid className={isWatching ? 'd-block' : 'd-none'}>
					<IonRow>
						<IonCol className="flex-right">
							{!isFirstSlide && (
								<IonButton
									size="small"
									color="light"
									onClick={() => swiperInstance.slidePrev()}>
									<Icon
										icon="ooui:arrow-next-rtl"
										width="24"
									/>
								</IonButton>
							)}
						</IonCol>
						<IonCol className="flex-center">
							{isLoading && completedPill === null && (
								<IonButton
									size="small"
									color="light">
									<IonSkeletonText style={{ width: '100px' }}></IonSkeletonText>
								</IonButton>
							)}
							{completedPill !== null &&
								isPill &&
								(!completedPill ? (
									<IonButton
										size="small"
										color="warning"
										disabled={nextDisabled}
										onClick={() => handleSetCompletedPill(true)}>
										Segna come completata
									</IonButton>
								) : (
									<IonButton
										size="small"
										disabled={nextDisabled}
										onClick={() => handleSetCompletedPill(false)}>
										Completata!
									</IonButton>
								))}
						</IonCol>
						<IonCol className="flex-left">
							{(completedPill || !isPill) && (
								<IonButton
									size="small"
									onClick={() => {
										console.log('isLastSlide', isLastSlide)
										const unlockedText = unlockModules
											? 'Hai sbloccato: ' + unlockModules + '!'
											: ''
										if (isLastSlide) {
											presentAlert({
												header: 'Congratulazioni!',
												subHeader: 'Modulo completato!',
												message:
													unlockedText +
													' Ora puoi passare al modulo successivo!',
												buttons: [
													{
														text: 'Ok andiamo!',
														cssClass: 'ion-button blue',
														handler: () => {
															history.push('/corso')
														},
													},
												],
											})
										} else {
											swiperInstance.slideNext()
										}
									}}>
									<Icon
										icon="ooui:arrow-next-ltr"
										width="24"
									/>
								</IonButton>
							)}
						</IonCol>
					</IonRow>
				</IonGrid>
			</FixedElementPortal>
		</>
	)
}

const LessonPage = ({ match }) => {
	const [isLastSlide, setIsLastSlide] = useState(false)
	const [isFirstSlide, setIsFirstSlide] = useState(true)

	const { data, isLoading, error } = useSelector(
		(state) => state.multimediaGroups.getMultimediaPosts
	)

	const courseLoading = useSelector((state) => state.course.getCourse).isLoading

	const lessons = useSelector((state) => state.course.getCourse)?.data
		?.lessons_asc

	const lesson = lessons?.find(
		(lesson) => lesson.id === Number(match.params.lessonId)
	)

	const [nextDisabled, setNextDisabled] = useState(false)

	// console.log('lessons', lessons)
	// console.log('lesson', lesson)

	const unlockModules = unlockTexts(lesson?.unlocks) || ''

	// console.log(unlockModules)

	const groupId = lesson?.group_id || null
	const href = '/corso'
	const dispatch = useDispatch()

	const isDataCorrect = data !== null && data?.group?.id === Number(groupId)

	useEffect(() => {
		if (swiperRef.current && swiperRef.current.swiper) {
			const swiperInstance = swiperRef.current.swiper
			swiperInstance.slideTo(0)
			setIsFirstSlide(true)
			setIsLastSlide(false)
		}
		if (groupId) {
			dispatch(getPills())
			dispatch(getMultimediaPosts({ group_id: groupId, page: 'all' }))
		}
	}, [groupId, match.params])

	const handleSlideChange = () => {
		const swiperInstance = swiperRef.current.swiper
		const isLastSlide = swiperInstance.isEnd
		const isFirstSlide = swiperInstance.isBeginning
		setIsLastSlide(isLastSlide)
		setIsFirstSlide(isFirstSlide)
		// dispatch(getPills())
	}

	//When setNextDisabled true, set it false after 10 seconds
	useEffect(() => {
		console.log('nextDisabled', nextDisabled)
		if (nextDisabled) {
			const timer = setTimeout(() => {
				setNextDisabled(false)
			}, 10000)
			return () => clearTimeout(timer)
		}
	}, [nextDisabled])

	const swiperRef = useRef(null)

	// useIonViewDidEnter(() => {
	// 	if (swiperRef.current && swiperRef.current.swiper) {
	// 		const swiperInstance = swiperRef.current.swiper
	// 		swiperInstance.slideTo(0)
	// 		setIsFirstSlide(swiperInstance.isBeginning)
	// 		setIsLastSlide(swiperInstance.isEnd)
	// 	}
	// })

	return (
		<>
			<IonPage className="course-page">
				<MainHeader
					page="LessonPage"
					title={data?.group?.name || 'Percorso free'}
					href={href}
					forceHref={href}
				/>
				<IonContent id="lesson-page">
					<Swiper
						ref={swiperRef}
						autoHeight={true}
						pagination={{
							type: 'progressbar',
						}}
						hashNavigation={{
							watchState: true,
						}}
						onSlideChange={() => handleSlideChange()}
						modules={[Pagination, HashNavigation]}
						className="mySwiper">
						{(isLoading || courseLoading) && !error && (
							<MultimediaCardSkeleton />
						)}
						{error && <ErrorToast />}
						{isDataCorrect &&
							!isLoading &&
							!error &&
							data.posts.map((postGroup, index) => (
								<SwiperSlide
									data-hash={'pillola-' + (index + 1)}
									key={postGroup.id}
									className="swiper-no-swiping"
									style={{ position: 'relative' }}>
									<MultimediaCard
										postGroup={postGroup}
										index={index}
										swiperRef={swiperRef}
										isFirstSlide={isFirstSlide}
										isLastSlide={isLastSlide}
										unlockModules={unlockModules}
										nextDisabled={nextDisabled}
										setNextDisabled={setNextDisabled}
									/>
								</SwiperSlide>
							))}
					</Swiper>
				</IonContent>
				<div id="lesson-page-footer-portal"></div>
				<MainFooter />
			</IonPage>
		</>
	)
}

export default LessonPage
