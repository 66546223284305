import {
	IonPage,
	IonContent,
	IonText,
	IonRadioGroup,
	IonRadio,
	IonButton,
	IonItem,
} from '@ionic/react'
import InitialConfigHeader from '../header'
import { useState } from 'react'
import '../InitialConfiguration.min.css'
import { useDispatch } from 'react-redux'
import { setInitialConfigProperty } from '../../../redux/reducer/initialConfigSlice'
import { useHistory } from 'react-router'

const options = [
	{ name: 'Si, la carico ora', nextPage: 2 },
	{ name: 'Scelgo un avatar', nextPage: 4 },
]

const WantToInstertAPicturePage = ({ step, totalSteps, setPageSelected }) => {
	const history = useHistory()
	const dispatch = useDispatch()
	const [isLoading, setIsLoading] = useState(false)
	const [optionSelected, setOptionSelected] = useState('')
	const [error, setError] = useState('')

	const updateInput = (value) => {
		if (error !== '') setError('')
		if (value !== optionSelected) setOptionSelected(value)
	}

	const validateInput = () => {
		let isValid = true
		if (optionSelected === '') {
			setError("Devi selezionare un'opzione per continuare.")
			isValid = false
		} else if (!options.map((o) => o.name).includes(optionSelected)) {
			setError("L'opzione selezionata non è valida.")
			isValid = false
		}

		return isValid
	}

	const handleSkip = () => {
		if (error !== '') setError('')
		dispatch(setInitialConfigProperty({ key: 'avatar', value: null }))
		history.push(`/impostazioni-iniziali/${step + 1}`)
	}
	const handleSubmit = () => {
		setIsLoading(true)
		const isInputValid = validateInput()
		if (!isInputValid) {
			setIsLoading(false)
			return
		}

		const index = options.findIndex((option) => option.name === optionSelected)

		setPageSelected(options[index].nextPage)
		setIsLoading(false)
	}
	return (
		<IonPage className="initial-configuration">
			<InitialConfigHeader
				step={step}
				totalSteps={totalSteps}
				isLoading={isLoading}
			/>

			<IonContent>
				<h1>Vuoi inserire ora la tua foto?</h1>
				<h6>
					Deciderai in un secondo momento se la vorrai rendere visibile agli
					altri
				</h6>

				<IonRadioGroup value={optionSelected}>
					{options.map((option, index) => {
						return (
							<IonItem
								onClick={() => updateInput(option.name)}
								key={index}
								className="no-padding-item">
								<IonRadio value={option.name}>{option.name}</IonRadio>
							</IonItem>
						)
					})}
				</IonRadioGroup>
				<IonText className="error">{error}</IonText>

				<div className="buttons">
					<IonButton
						shape="round"
						onClick={handleSkip}
						disabled={isLoading}
						color="light-gray"
						expand="full">
						<IonText color="white">Salta</IonText>
					</IonButton>
					<IonButton
						shape="round"
						onClick={handleSubmit}
						disabled={isLoading}
						color="primary"
						expand="full">
						Avanti
					</IonButton>
				</div>
			</IonContent>
		</IonPage>
	)
}

export default WantToInstertAPicturePage
