import {
	IonButton,
	IonThumbnail,
	IonContent,
	IonItem,
	IonList,
	IonPage,
	IonSkeletonText,
	IonText,
	IonRefresher,
	IonRefresherContent,
	useIonViewDidEnter,
	useIonToast,
} from '@ionic/react'

import './Groups.css'
import MainFooter from '../../components/mainFooter'
import MainHeader from '../../components/headers/mainHeader'
import { useEffect, useState } from 'react'
import RoutesModal from '../../components/modals/RoutesModal'
import { useDispatch, useSelector } from 'react-redux'
import { getGroupsList } from '../../redux/actions'
import dsPictogram from '../../assets/images/ds-pictogram.svg'
import ErrorToast from '../../components/ErrorToast'
import { clearCache } from '../../services/functions'
import { useHistory } from 'react-router'

import { ImageLoader } from '../../components/ImageLoader'
import SubscriptionAlert from '../../components/SubscriptionAlert'

const LoadingGroup = () => (
	<IonItem className="no-padding-item">
		<IonThumbnail slot="start">
			<IonSkeletonText
				animated={true}
				style={{ borderRadius: '0.9rem' }}
			/>
		</IonThumbnail>
		<div style={{ width: '100%' }}>
			<IonSkeletonText
				animated={true}
				style={{ width: '7rem' }}
			/>
			<div style={{ display: 'flex' }}>
				<IonSkeletonText
					animated={true}
					style={{
						width: '4rem',
						height: '18px',
						marginRight: '1rem',
						borderRadius: '0.2rem',
					}}
				/>
				<IonSkeletonText
					animated={true}
					style={{ width: '5rem', height: '18px', borderRadius: '0.2rem' }}
				/>
			</div>
		</div>
	</IonItem>
)
const LoadingRender = () => (
	<IonList>
		{[...Array(10)].map((_, index) => (
			<LoadingGroup key={index} />
		))}
	</IonList>
)
const ErrorRender = () => <ErrorToast />

const DataRender = ({ data }) => {
	const subscription_type = useSelector(
		(state) =>
			state.user?.master_club_subscriber?.subscription?.subscription_type
	)
	const badge = useSelector(
		(state) => state.user?.master_club_subscriber?.badge ?? null
	)

	const subscription_id = subscription_type?.id ?? 3
	const subscriptionName = subscription_type?.name ?? null
	const [isOpen, setIsOpen] = useState(false)
	const history = useHistory()
	const [wrongSubscriptionToast] = useIonToast()

	const handleGroupClick = (
		group,
		subscription_id,
		setIsOpen,
		history,
		wrongSubscriptionToast
	) => {
		const subscriptionNames = group.subscription_types
			.map((item, i, arr) => {
				const name = item.name.toUpperCase()
				if (i === arr.length - 2) return name + ' e '
				if (i === arr.length - 1) return name
				return ''
			})
			.join('')

		const isUnrestricted = group.subscription_types?.length === 0
		const hasSubscription = group.subscription_types?.some(
			(subscription_type) => subscription_type.id === subscription_id
		)

		isUnrestricted || hasSubscription || badge
			? history.push(window.location.pathname + '/' + group.id)
			: subscriptionName !== 'premium'
			? setIsOpen(true)
			: wrongSubscriptionToast({
					message: 'Questo contenuto è esclusivo per ' + subscriptionNames,
					duration: 3000,
			  })
	}

	return (
		<>
			<IonList>
				{data.length > 0 &&
					data.map((group) => (
						<IonItem
							className="no-padding-item"
							key={group.id}
							button
							detail={false}
							onClick={() => {
								handleGroupClick(
									group,
									subscription_id,
									setIsOpen,
									history,
									wrongSubscriptionToast
								)
							}}>
							<IonThumbnail slot="start">
								<ImageLoader
									image={group.storage_path + group.group_image}
									css="soft-shadow-and-radius"
								/>
							</IonThumbnail>
							<div className="ion-padding-top">
								<IonText>{group.name}</IonText>
								<div>
									{group.subscription_types.length > 0 ? (
										group.subscription_types.map((subscription) => (
											<IonButton
												key={subscription.id}
												size="small"
												className="group-label"
												color={subscription.color}>
												{subscription.name.toUpperCase()}
											</IonButton>
										))
									) : (
										<IonButton
											size="small"
											color="yellow"
											className="group-label">
											Per tutti
										</IonButton>
									)}
								</div>
							</div>
						</IonItem>
					))}
			</IonList>
			{/* <RoutesModal
				isOpen={isOpen}
				setIsOpen={setIsOpen}
			/> */}
			<SubscriptionAlert
				isOpen={isOpen}
				setIsOpen={setIsOpen}
			/>
		</>
	)
}

const GroupsPage = () => {
	const dispatch = useDispatch()

	const {
		data: groups,
		isLoading,
		error,
	} = useSelector((state) => state.groups.getGroupsList)

	useIonViewDidEnter(() => {
		dispatch(getGroupsList())
	})

	return (
		<>
			<IonPage className="groups-page">
				<MainHeader
					title="Community"
					page="GroupsPage"
				/>

				<IonContent className="ion-padding">
					<IonRefresher
						slot="fixed"
						pullFactor={0.5}
						pullMin={100}
						pullMax={200}
						onIonRefresh={clearCache}>
						<IonRefresherContent></IonRefresherContent>
					</IonRefresher>
					<IonText color="primary">Gruppi disponibili</IonText>
					<h3>Connettiti, condividi e partecipa ai nostri gruppi</h3>
					{groups !== null && !isLoading && !error && (
						<DataRender data={groups} />
					)}
					{isLoading && !error && <LoadingRender />}
					{error && <ErrorRender />}
				</IonContent>

				<MainFooter />
			</IonPage>
		</>
	)
}

export default GroupsPage
