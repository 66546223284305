import {
	IonContent,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonPage,
	IonRefresher,
	IonRefresherContent,
	useIonViewDidEnter,
} from '@ionic/react'
import MainHeader from '../../components/headers/mainHeader'
import Post from '../../components/Post'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'
import { getPost } from '../../redux/actions'
import Comment from '../../components/Comments'
import { clearCache, randId } from '../../services/functions'
import ErrorToast from '../../components/ErrorToast'
import { PostSkeleton } from '../../components/skeletons/PostSkeleton'
import { CommentSkeleton } from '../../components/skeletons/CommentSkeleton'
import { InsertComment } from '../../components/InsertComment'

const LoadingRender = ({ post }) => (
	<>
		{!post && <PostSkeleton />}
		<CommentSkeleton in_post={true} />
		<CommentSkeleton in_post={true} />
	</>
)
const ErrorRender = () => <ErrorToast />
const DataRender = (props) => (
	<Post
		is_preview={false}
		{...props}
	/>
)

const PostPage = ({ match }) => {
	const contentRef = useRef(null)
	const dispatch = useDispatch()
	const [page, setPage] = useState(1)
	const [comments, setComments] = useState([])
	const [isInfiniteDisabled, setInfiniteDisabled] = useState(false)

	const loadMoreComments = (event) => {
		if (!isLoading) {
			setPage((prevPage) => prevPage + 1)
			event.target.complete()
		} else {
			setInfiniteDisabled(true)
		}
	}

	const { groupId, postId } = match.params

	useEffect(() => {
		dispatch(getPost({ postId, page })).then((data) => {
			setComments((prevComments) => [
				...prevComments,
				...data.payload.comments.data,
			])
		})
	}, [page, dispatch, postId])

	const { data, isLoading, error } = useSelector(
		(state) => state.groups.getPost
	)
	const isMain = data?.group?.is_main

	const href = isMain ? '/home' : `/gruppi/${groupId}`

	return (
		<>
			<IonPage>
				<MainHeader
					href={href}
					title={'Post' + (data?.group?.name ? ' di ' + data.group.name : '')}
					page="PostPage"
				/>
				<IonContent ref={contentRef}>
					<IonRefresher
						slot="fixed"
						pullFactor={0.5}
						pullMin={100}
						pullMax={200}
						onIonRefresh={clearCache}>
						<IonRefresherContent></IonRefresherContent>
					</IonRefresher>
					{data !== null && !error && (
						<>
							<DataRender
								postGroup={{ ...data.post_group, groupId }}
								from_main_group={false}
								only_post={true}
							/>
							{comments.map((comment) => (
								<Comment
									classes="ion-padding-start"
									key={comment.id ?? randId()}
									comment={comment}
									in_post={true}
									match={match}
								/>
							))}
							<IonInfiniteScroll
								threshold="100px"
								disabled={isInfiniteDisabled}
								onIonInfinite={(e) => loadMoreComments(e)}>
								<IonInfiniteScrollContent
									loadingSpinner="bubbles"
									loadingText="Caricando altri commenti..."></IonInfiniteScrollContent>
							</IonInfiniteScroll>
						</>
					)}
					{isLoading && !error && <LoadingRender post={!!data?.post_group} />}
					{error && <ErrorRender />}
				</IonContent>
				{data?.comments && (
					<InsertComment
						element_id={data.post_group.id}
						element_type="post"
						contentRef={contentRef}
						setComments={setComments}
					/>
				)}
			</IonPage>
		</>
	)
}

export default PostPage
