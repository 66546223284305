import { createAsyncThunk } from '@reduxjs/toolkit'
import { baseUrl, headersDefault } from '.'
import axios from 'axios'

export const getGroupPosts = createAsyncThunk(
	'groups/getGroupPosts',
	async ({ page = 1, is_fixed = false, from_main_group = true, group_id }) => {
		const params = {
			page,
			is_fixed,
			...(from_main_group ? { from_main_group } : { group_id }),
		}

		const response = await axios.get(
			`${baseUrl}/api/master-club-post-groups/list`,
			{
				headers: headersDefault(),
				params,
			}
		)

		return response.data
	}
)

export const likePost = createAsyncThunk(
	'groups/likePost',
	async ({ element_id, is_fixed = false, from_main_group = true }) => {
		const body = {
			element_type: 'App\\Models\\MasterClubPostGroup',
			element_id,
		}

		const response = await axios.post(
			`${baseUrl}/api/master-club-likes`,
			body,
			{
				headers: headersDefault(),
			}
		)

		return response.data
	}
)

export const publishPostGroup = createAsyncThunk(
	'groups/publishPostGroup',
	async ({ post_group_id, status_id, denied_reason }) => {
		const response = await fetch(
			`${baseUrl}/api/master-club-post-groups/${post_group_id}`,
			{
				body: JSON.stringify({ status_id, denied_reason }),
				method: 'PATCH',
				headers: headersDefault(),
			}
		)

		return response
	}
)

export const getGroup = createAsyncThunk('groups/getGroup', async (groupId) => {
	const response = await axios.get(
		`${baseUrl}/api/master-club-groups/${groupId}/fetch`,
		{
			headers: headersDefault(),
		}
	)

	return response.data
})

export const getGroupsList = createAsyncThunk(
	'groups/getGroupsList',
	async () => {
		const params = { type: 'post' }

		const response = await axios.get(`${baseUrl}/api/master-club-groups/list`, {
			headers: headersDefault(),
			params,
		})

		return response.data
	}
)

export const getPost = createAsyncThunk(
	'groups/getPost',
	async ({ postId, page }) => {
		const response = await axios.get(
			`${baseUrl}/api/master-club-post-groups/${postId}/fetch?page=${page}`,
			{
				headers: headersDefault(),
			}
		)

		return response.data
	}
)

export const addPost = createAsyncThunk('groups/addPost', async (body) => {
	const response = await axios.post(`${baseUrl}/api/master-club-posts`, body, {
		headers: headersDefault(),
	})

	return response.data
})

export const likeComment = createAsyncThunk(
	'groups/likeComment',
	async ({ element_id }) => {
		const body = { element_type: 'App\\Models\\MasterClubComment', element_id }

		const response = await axios.post(
			`${baseUrl}/api/master-club-likes`,
			body,
			{
				headers: headersDefault(),
			}
		)

		return response.data
	}
)

const commentElement = async ({ element_type, element_id, content, image }) => {
	const body = { element_type, element_id, content, image }
	const response = await axios.post(
		`${baseUrl}/api/master-club-comments`,
		body,
		{
			headers: headersDefault(),
		}
	)

	return response.data
}

export const commentPost = createAsyncThunk(
	'groups/commentPost',
	async (params) =>
		await commentElement({
			...params,
			element_type: 'App\\Models\\MasterClubPostGroup',
		})
)

export const replyComment = createAsyncThunk(
	'groups/replyComment',
	async (params) =>
		await commentElement({
			...params,
			element_type: 'App\\Models\\MasterClubComment',
		})
)

export const getCommentAndReplies = createAsyncThunk(
	'groups/getCommentAndReplies',
	async ({ commentId, page = 1 }) => {
		const url = `${baseUrl}/api/master-club-comments/${commentId}/fetch`

		const params = { page }
		const headers = headersDefault()
		const config = { headers, params }

		const response = await axios.get(url, config)

		return response.data
	}
)
