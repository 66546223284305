import { createSlice } from '@reduxjs/toolkit'
import { getMultimediaGroupsList, getMultimediaPosts, likeMultimediaPost } from '../../actions'
import { getMultimediaGroupsListFunctions, getMultimediaPostsFunctions, likeMultimediaPostFunctions } from './functions'

const { getMultimediaGroupsListPending, getMultimediaGroupsListFulfilled, getMultimediaGroupsListRejected } = getMultimediaGroupsListFunctions
const { getMultimediaPostsFunctionsPending, getMultimediaPostsFunctionsFulfilled, getMultimediaPostsFunctionsRejected } = getMultimediaPostsFunctions
const { likeMultimediaPostFunctionsPending, likeMultimediaPostFunctionsFulfilled, likeMultimediaPostPostsFunctionsRejected } = likeMultimediaPostFunctions

const initialState = {
  getMultimediaGroupsList: {
    isLoading: false,
    error: null,
    data: null
  },
  getMultimediaPosts: {
    isLoading: false,
    error: null,
    data: null
  }
}

const multimediaGroupsSlice = createSlice({
  name: 'multimediaGroups',
  initialState,
  reducers: {
    setPost: (state, action) => {
      state.getPost.data = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(getMultimediaGroupsList.pending, getMultimediaGroupsListPending)
    builder.addCase(getMultimediaGroupsList.fulfilled, getMultimediaGroupsListFulfilled)
    builder.addCase(getMultimediaGroupsList.rejected, getMultimediaGroupsListRejected)

    builder.addCase(getMultimediaPosts.pending, getMultimediaPostsFunctionsPending)
    builder.addCase(getMultimediaPosts.fulfilled, getMultimediaPostsFunctionsFulfilled)
    builder.addCase(getMultimediaPosts.rejected, getMultimediaPostsFunctionsRejected)

    builder.addCase(likeMultimediaPost.pending, likeMultimediaPostFunctionsPending)
    builder.addCase(likeMultimediaPost.fulfilled, likeMultimediaPostFunctionsFulfilled)
    builder.addCase(likeMultimediaPost.rejected, likeMultimediaPostPostsFunctionsRejected)
  }
})

export const { setPost, setComment } = multimediaGroupsSlice.actions
export default multimediaGroupsSlice.reducer
