import { Icon } from '@iconify/react'
import { IonButton, IonModal } from '@ionic/react'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
	_isIOS,
	_isStandalone,
	clearCache,
	NotificationService,
} from '../../../services/functions'
import { userAuthStatus } from '../../../services/RouteAuth'
import Bowser from 'bowser'

const BlockedNotifications = ({
	showBlockedNotificationsModal,
	setShowBlockedNotificationsModal,
}) => {
	const isLocal = window.location.hostname === 'localhost'
	// console.log('!_isStandalone() && !isLocal', !_isStandalone() && !isLocal)
	// if (!_isStandalone() && !isLocal) return null
	// console.log('BlockedNotifications')
	// const [isOpen, setIsOpen] = useState(false)
	const subscription_type = useSelector(
		(state) =>
			state.user?.master_club_subscriber?.subscription?.subscription_type
	)
	const subscriptionName = subscription_type?.name ?? null
	const userStatus = userAuthStatus()
	// const notificationPermission = window?.Notification?.permission

	// const showBlockedNotificationsModal =
	// 	userStatus === 'full' &&
	// 	notificationPermission === 'denied' &&
	// 	subscriptionName !== 'premium'

	const browser = Bowser.getParser(window.navigator.userAgent)
	const isChromium = !!window.chrome
	const isBrave = !!navigator.brave
	const browserName = isBrave ? 'Brave' : browser.getBrowserName()
	const platformType = browser.getPlatformType()

	const isMobile = platformType === 'mobile'
	const isDesktop = platformType === 'desktop'

	const isDesktopSafari = !!browser.satisfies({
		desktop: { safari: '>=0' },
	})
	const isMobileIOS = _isIOS()

	// console.log('showBlockedNotificationsModal', showBlockedNotificationsModal)
	// useEffect(() => {
	// 	console.table({
	// 		notificationPermission,
	// 		subscriptionName,
	// 		userStatus,
	// 		showBlockedNotificationsModal,
	// 	})

	// 	if (showBlockedNotificationsModal) {
	// 		setIsOpen(showBlockedNotificationsModal)
	// 	}
	// }, [])

	const handleVerifySubscription = async () => {
		clearCache()
		// const permission = Notification.permission

		// console.table({
		// 	permission,
		// })

		// if (permission === 'denied' || permission === 'granted') {
		// 	await NotificationService.requestPermission()

		// 	const functionStatus = NotificationService.getPermission()

		// 	console.table({
		// 		permission,
		// 		functionStatus,
		// 	})

		// 	if (permission !== functionStatus) {
		// 		setIsOpen(functionStatus === 'granted')
		// 	} else {
		// 		clearCache()
		// 	}
		// }
	}

	const unblockAndroid = (
		<ul style={{ listStyleType: 'decimal' }}>
			<li>
				<b>Trova l’icona dell’app</b> Cerca l’icona sulla schermata principale
				Android o tra le app installate, dopodicché toccala e tieni premuta.
			</li>
			<li>
				<b>Seleziona ‘‘Notifiche’’ o ‘‘Info dell’app’’:</b> Nel menu che appare,
				cerca un’opzione come ‘‘Notifiche’’ o ‘‘Info dell’app’’
			</li>
			<li>
				<b>Attiva le Notifiche:</b> All’interno delle informazioni dell’app o
				delle opzioni di notifica, cerca un’interruttore o un’opzione che ti
				permetta di attivare le notifiche.
			</li>
		</ul>
	)

	const unblockIOS = (
		<ul style={{ listStyleType: 'decimal' }}>
			<li>
				<b>Trova l’icona dell’app</b> Cerca l’icona sulla schermata principale
				del tuo dispositivo, dopodicché toccala e tieni premuta.
			</li>
			<li>
				<b>Seleziona ‘‘Notifiche’’:</b> Cerca e tocca un’opzione come
				‘‘Notifiche’’ nel menu che appare.
			</li>
			<li>
				<b>Abilita le Notifiche: </b> All’interno delle opzioni di notifica,
				attiva l’interruttore per consentire le notifiche.
			</li>
		</ul>
	)

	const unblockDesktop = (
		<ul style={{ listStyleType: 'decimal' }}>
			<li>
				<b>Cerca i tre puntini in alto a destra:</b> Se ci clicchi sopra si apre
				un menu a discesa. Clicca su ‘‘Informazioni app’’.
			</li>
			<li>
				<b>Trova la voce ‘‘Notifiche’’:</b> Attiva le notifiche facendo click
				sopra l’interruttore.
			</li>
		</ul>
	)

	return (
		<IonModal
			className="modal-as-alert priority-modal"
			isOpen={showBlockedNotificationsModal}
			canDismiss={true}
			onDidDismiss={() => setShowBlockedNotificationsModal(false)}>
			<div className="ion-padding">
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<Icon
						icon="solar:notification-lines-remove-bold-duotone"
						style={{
							width: '120px',
							height: '120px',
							color: 'var(--ion-color-primary)',
						}}
					/>
				</div>
				<h3>Per usare l’app consigliamo di sbloccare le notifiche</h3>
				<p>
					Riteniamo fondamentale la comunicazione con la nostra community.
					<br />
					Prova a riattivare le notifiche per continuare ad usare l’app.
				</p>

				{isMobile && !_isIOS() && unblockAndroid}
				{isMobile && _isIOS() && unblockIOS}
				{isDesktop && unblockDesktop}

				<IonButton
					expand="full"
					strong={true}
					onClick={() => {
						setShowBlockedNotificationsModal(false)
						// handleVerifySubscription()
					}}>
					Le ho già sbloccate
				</IonButton>
			</div>
		</IonModal>
	)
}

export default BlockedNotifications
