import {
	IonPage,
	IonContent,
	IonText,
	IonButton,
	IonItem,
	IonCheckbox,
} from '@ionic/react'
import InitialConfigHeader from '../header'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setInitialConfigExtraProperty } from '../../../redux/reducer/initialConfigSlice'
import '../InitialConfiguration.min.css'
import { useHistory } from 'react-router'

const goalsOptions = [
	'Nessuno',
	'Dolore alla schiena',
	'Dolore alle ginocchia',
	'Dolore agli arti superiori (braccia)',
	'Dolore agli arti inferiori (gambe)',
	'Mobilità molto ridotta',
]

const ImpedimentsPhysicalPage = ({ step, totalSteps }) => {
	const history = useHistory()
	const [isLoading, setIsLoading] = useState(false)
	const physical_impediments = useSelector(
		(state) => state.initialConfig.extras.physical_impediments
	)
	const dispatch = useDispatch()
	const [error, setError] = useState('')

	const updateInput = (e) => {
		if (error !== '') setError('')
		let newPhysicalImpediments = [...physical_impediments]

		if (e.target.checked) {
			if (e.target.value === 'Nessuno') {
				newPhysicalImpediments = ['Nessuno']
			} else {
				if (newPhysicalImpediments.includes('Nessuno')) {
					newPhysicalImpediments.splice(
						newPhysicalImpediments.indexOf('Nessuno'),
						1
					)
				}
				newPhysicalImpediments.push(e.target.value)
			}
		} else {
			newPhysicalImpediments.splice(
				newPhysicalImpediments.indexOf(e.target.value),
				1
			)
		}
		dispatch(
			setInitialConfigExtraProperty({
				key: 'physical_impediments',
				value: newPhysicalImpediments,
			})
		)
	}

	const validateInput = () => {
		let isValid = true
		if (physical_impediments.length === 0) {
			setError("Devi selezionare almeno un'opzione per continuare.")
			isValid = false
		} else if (
			!physical_impediments.every((option) => goalsOptions.includes(option))
		) {
			setError('Almeno una delle opzioni selezionate non è valida.')
			isValid = false
		}

		return isValid
	}

	const handleSubmit = () => {
		setIsLoading(true)
		const isInputValid = validateInput()
		if (!isInputValid) {
			setIsLoading(false)
			return
		}
		history.push(`/impostazioni-iniziali/${step + 1}`)
		setIsLoading(false)
	}

	const handleSkip = (e) => {
		setIsLoading(true)
		dispatch(
			setInitialConfigExtraProperty({ key: 'physical_impediments', value: [] })
		)
		history.push(`/impostazioni-iniziali/${step + 1}`)
		setIsLoading(false)
	}

	return (
		<IonPage className="initial-configuration">
			<InitialConfigHeader
				step={step}
				totalSteps={totalSteps}
				isLoading={isLoading}
			/>

			<IonContent>
				<h1>Hai impedimenti fisici?</h1>
				{goalsOptions.map((option, index) => {
					return (
						<IonItem
							className="no-padding-item"
							key={index}>
							<IonCheckbox
								onIonChange={updateInput}
								value={option}
								checked={physical_impediments.includes(option)}>
								<span className="ion-text-wrap">{option}</span>
							</IonCheckbox>
						</IonItem>
					)
				})}
				<IonText className="error">{error}</IonText>

				<div className="buttons">
					<IonButton
						shape="round"
						onClick={handleSkip}
						disabled={isLoading}
						color="light-gray"
						expand="full">
						<IonText color="white">Salta</IonText>
					</IonButton>
					<IonButton
						shape="round"
						onClick={handleSubmit}
						disabled={isLoading}
						color="primary"
						expand="full">
						Avanti
					</IonButton>
				</div>
			</IonContent>
		</IonPage>
	)
}

export default ImpedimentsPhysicalPage
