import React, { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'

const FixedElementPortal = ({ children, targetId }) => {
	const containerRef = useRef(document.createElement('div'))

	useEffect(() => {
		const targetElement = document.getElementById(targetId)
		if (!targetElement) {
			console.error(`No se encontró el elemento con ID: ${targetId}`)
			return
		}

		targetElement.appendChild(containerRef.current)

		return () => {
			targetElement.removeChild(containerRef.current)
		}
	}, [targetId])

	return ReactDOM.createPortal(children, containerRef.current)
}

export default FixedElementPortal
