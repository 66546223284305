import {
	IonPage,
	IonContent,
	IonText,
	IonButton,
	IonDatetime,
} from '@ionic/react'
import InitialConfigHeader from '../header'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setInitialConfigProperty } from '../../../redux/reducer/initialConfigSlice'
import '../InitialConfiguration.min.css'
import { getMinimumLegalAgeDate } from '../../../services/functions'
import { useHistory } from 'react-router'

import Bowser from 'bowser'

const BirthDatePage = ({ step, totalSteps }) => {
	const history = useHistory()
	const [error, setError] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const birth_date = useSelector((state) => state.initialConfig.birth_date)
	const dispatch = useDispatch()

	const browser = Bowser.getParser(window.navigator.userAgent)
	const platformType = browser.getPlatformType()
	const isMobile = platformType === 'mobile'

	const minimumLegalAgeDate = getMinimumLegalAgeDate()
	const updateInput = (e) => {
		if (error !== '') setError('')
		dispatch(
			setInitialConfigProperty({
				key: 'birth_date',
				value: e.detail.value.slice(0, 10),
			})
		)
	}

	const validateInput = () => {
		let isValid = true
		if (birth_date === null) {
			setError('È necessario inserire la tua data di nascita.')
			isValid = false
		} else if (minimumLegalAgeDate < birth_date) {
			setError('Devi avere almeno 18 anni.')
			isValid = false
		}

		return isValid
	}

	const handleSubmit = () => {
		setIsLoading(true)
		const isInputValid = validateInput()
		if (!isInputValid) {
			setIsLoading(false)
			return
		}

		history.push(`/impostazioni-iniziali/${step + 1}`)
		setIsLoading(false)
	}

	const DateTime = () => {
		return (
			<IonDatetime
				id="datetime"
				locale="it-IT"
				value={birth_date}
				preferWheel={isMobile}
				onIonChange={updateInput}
				displayFormat="YYYY-MM-DD"
				max={minimumLegalAgeDate}
				presentation="date"
			/>
		)
	}

	return (
		<IonPage className="initial-configuration">
			<InitialConfigHeader
				step={step}
				totalSteps={totalSteps}
				isLoading={isLoading}
			/>

			<IonContent className="default-padding">
				<div className="ion-padding-horizontal">
					<h1>Qual è la tua data di nascita?</h1>
					<h6>
						Utilizzeremo questo dato per stabilire con esattezza il tuo peso
						forma
					</h6>
				</div>
				<DateTime />
				<div className="ion-padding-horizontal">
					<IonText className="error">{error}</IonText>
					<div className="buttons">
						<IonButton
							shape="round"
							onClick={handleSubmit}
							disabled={isLoading}
							color="primary"
							expand="full">
							Avanti
						</IonButton>
					</div>
				</div>
			</IonContent>
		</IonPage>
	)
}

export default BirthDatePage
