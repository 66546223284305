import {
	IonContent,
	IonMenu,
	IonLabel,
	IonHeader,
	IonToolbar,
	IonItem,
	IonMenuToggle,
	IonButton,
	useIonAlert,
	useIonToast,
	useIonLoading,
} from '@ionic/react'
import env from 'react-dotenv'
import dietaSocialLogo from '../../assets/images/ds-logo.svg'
import dietaSocialLogoSandbox from '../../assets/images/ds-logo-sandbox.svg'
import menuRoutes from './menuRoutes.json'
import { Icon } from '@iconify/react'
import config from '../../settings/config'
import { useHistory, useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import {
	cancelFreeSubscription,
	getCourse,
	getUserInfo,
} from '../../redux/actions'
import { useEffect, useState } from 'react'
import { userAuthStatus } from '../../services/RouteAuth'
import ActivateNotifications from '../modals/NotificationsModal/ActivateNotifications'
import { isEmptyObj } from '../../services/functions'

const MainMenu = () => {
	const history = useHistory()
	const pathname = useLocation().pathname

	const [userStatus, setUserStatus] = useState(null)

	const [showNotificationsModal, setShowNotificationsModal] = useState(false)

	useEffect(() => {
		console.log('pathname', pathname)
		setUserStatus(userAuthStatus())

		const permission =
			'Notification' in window ? Notification?.permission : 'default'
		// console.log('permission', permission)
		if (permission === 'default') {
			const subscriptionType = JSON.parse(
				localStorage.getItem('adoc_p') ?? '{}'
			)?.master_club_subscriber?.subscription?.subscription_type?.name

			const tries = subscriptionType === 'premium' ? 51 : 21
			let adoc_nav_count = localStorage.getItem('adoc_nav_count') ?? tries

			adoc_nav_count--
			localStorage.setItem('adoc_nav_count', adoc_nav_count)
			if (adoc_nav_count < 1) {
				localStorage.setItem('adoc_nav_count', tries)
				setShowNotificationsModal(true)
			}
		}
	}, [pathname])

	const [presentAlert] = useIonAlert()
	const [ionLoading, hideIonLoading] = useIonLoading()
	const [toast] = useIonToast()
	const dispatch = useDispatch()

	const { data, isLoading, error } = useSelector(
		(state) => state.course.getCourse
	)
	const logo =
		config.env.mode !== 'prod' ? dietaSocialLogoSandbox : dietaSocialLogo

	const subscriber =
		useSelector((state) => state.user?.master_club_subscriber) ?? null

	const subscription = subscriber?.subscription

	const subscriptionName = subscription?.subscription_type?.name

	useEffect(() => {
		if (userStatus === 'full' && pathname !== '/corso') {
			console.log('getCourse')
			dispatch(getCourse())
		}
	}, [pathname, userStatus])

	const isDayLocked = (dayNumber) => {
		//if data is an empty object
		if (isEmptyObj(data) && (subscriber?.subscription_days_count ?? 0 <= 4)) {
			console.log('isDayLocked', true)
			return true
		}

		const dataIsEmpty = !data
			? true
			: Object.keys(data).length === 0 && data.constructor === Object
		const result =
			isLoading || error
				? true
				: dataIsEmpty
				? false
				: !data?.unlocked?.menu_days.includes(dayNumber.toString())

		return result
	}

	const handleCancelFreeSubscription = async () => {
		presentAlert({
			header: 'Annulla prova gratuita',
			message:
				'Ti ricordiamo che ogni utente ha diritto a ' +
				env.MAX_FREE_ROUTES +
				'. Se decidi di interromperla ora, potrai attivare la tua prova in un momento successivo a tua scelta. Vuoi procedere con l’annullamento?',
			buttons: [
				{
					text: 'Cancella',
					role: 'cancel',
					handler: () => {},
				},
				{
					text: 'Si, confermo',
					role: 'confirm',
					handler: handleConfirmCancelFreeSubscription,
				},
			],
		})
	}

	const handleConfirmCancelFreeSubscription = async () => {
		ionLoading()

		dispatch(cancelFreeSubscription())
			.then((result) => {
				if (result.error) {
					toast({
						message: result.payload.message,
						duration: 3000,
						color: 'danger',
					})
				} else {
					toast({
						message: result.payload.message,
						duration: 3000,
					})

					dispatch(getCourse())
					dispatch(getUserInfo())
					history.push('/home')
				}

				hideIonLoading()
			})
			.catch((error) => {
				console.error('Error fetching:', error)
				toast({
					message: "Errore durante l'annullamento della prova gratuita",
					duration: 3000,
					color: 'danger',
				})
				hideIonLoading()
			})
	}

	return (
		<IonMenu
			id="main-menu"
			contentId="main-content"
			swipe-gesture={false}>
			<IonHeader>
				<IonToolbar>
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<img
							style={{
								maxHeight: '5rem',
								paddingTop: '0.5rem',
								paddingBottom: '0.5rem',
							}}
							src={logo}
							alt="Dieta Social Logo"
						/>
					</div>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonMenuToggle>
					{menuRoutes.map((route) => {
						if (
							route.subscriptions &&
							!route.subscriptions.includes(subscriptionName)
						)
							return null

						if (route.course && isEmptyObj(data)) return null

						return (
							<IonItem
								key={route.title}
								button
								onClick={() =>
									history.push(
										route.url + (route.cache ? '#' + Date.now() : ''),
										{
											self: route.url.includes(pathname),
										}
									)
								}
								lines={route.hideLines || route.url === pathname ? 'none' : ''}
								className={
									route.className +
									' ' +
									(route.url === pathname ? 'menuRouteSelected' : 'menuRoute')
								}
								style={{ color: '#646464' }}>
								<Icon
									style={{ width: '24px', height: '24px' }}
									icon={route.icon}
									slot="start"
								/>
								<IonLabel style={{ fontSize: '1.10rem' }}>
									{route.title}
								</IonLabel>
							</IonItem>
						)
					})}
					<div className="ion-margin-top ion-padding-top">
						{subscriptionName !== 'premium' && (
							<IonButton
								className="ion-margin-top ion-padding-horizontal"
								onClick={() => history.push('/prodotti')}
								expand="full"
								color="primary">
								DIVENTA PREMIUM
							</IonButton>
						)}
						{!subscriptionName && (
							<IonButton
								className="ion-margin-top ion-padding-horizontal"
								onClick={() => history.push('/percorso-gratuito')}
								expand="full"
								color="blue">
								PROVA PER 7 GIORNI
							</IonButton>
						)}
						{subscriptionName === 'free' && isDayLocked(6) && (
							<IonButton
								className="ion-margin-top ion-padding-horizontal"
								onClick={handleCancelFreeSubscription}
								expand="full"
								color="warning">
								ANNULLA PROVA GRATUITA
							</IonButton>
						)}
					</div>
				</IonMenuToggle>
				<ActivateNotifications
					redirectHome={false}
					showNotificationsModal={showNotificationsModal}
					setShowNotificationsModal={setShowNotificationsModal}
				/>
			</IonContent>
		</IonMenu>
	)
}

export default MainMenu
