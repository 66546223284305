import { useState } from 'react'
import PhoneNumberPage from './PhoneNumberPage'
import PhoneOtpPage from './PhoneOtpPage'

const PhonePages = props => {
  const [pageSelected, setPageSelected] = useState(1)

  if (pageSelected === 1) return <PhoneNumberPage {...props} setPageSelected={setPageSelected} />
  if (pageSelected === 2) return <PhoneOtpPage {...props} setPageSelected={setPageSelected} />
}

export default PhonePages
