import { IonButton, IonContent, IonPage } from '@ionic/react'
import React, { useRef } from 'react'
import { useLocation } from 'react-router-dom'
import MainHeader from '../../components/headers/mainHeader'

const ScreenlockPage = () => {
	const location = useLocation()
	console.log('location', location)
	const videoRef = useRef(null)

	const lockLandscape = () => {
		screen.orientation
			.lock('landscape')
			.then(() => {
				console.log(`locked to landscape`)
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const lockPortrait = () => {
		screen.orientation
			.lock('portrait')
			.then(() => {
				console.log(`locked to portrait`)
			})
			.catch((error) => {
				console.log(error)
			})
	}

	const unlockOrientation = () => {
		if (screen.orientation.unlock) {
			screen.orientation.unlock()
		}
	}

	const playVideo = () => {
		if (videoRef.current) {
			const elem = videoRef.current
			if (elem.requestFullscreen) {
				elem.requestFullscreen()
				lockLandscape()
				elem.play()
			}
		}
	}

	return (
		<IonPage>
			<MainHeader
				title="ScreenLockTest"
				page="ScreenLockTest"
			/>

			<IonContent>
				<IonButton onClick={lockPortrait}>Lock orientation</IonButton>
				<IonButton onClick={playVideo}>Play on fullscreen</IonButton>

				<video
					ref={videoRef}
					src="https://www.w3schools.com/html/mov_bbb.mp4"
				/>

				<IonButton onClick={unlockOrientation}>Unlock orientation</IonButton>
			</IonContent>
		</IonPage>
	)
}

export default ScreenlockPage
