import {
	IonBadge,
	IonButton,
	IonButtons,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonLabel,
	IonModal,
	IonPage,
	IonRefresher,
	IonRefresherContent,
	IonRow,
	IonSegment,
	IonSegmentButton,
	IonText,
	IonTitle,
	IonToolbar,
	useIonToast,
} from '@ionic/react'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useRef, useState } from 'react'
import { getProducts } from '../../redux/actions'
import { ProductSkeleton } from '../../components/skeletons/ProductSkeleton'
import { ImageLoader } from '../../components/ImageLoader'
import { useHistory } from 'react-router'
import { Icon } from '@iconify/react'
import { NoRecords } from '../../components/NoRecords'

import MainHeader from '../../components/headers/mainHeader'
import MainFooter from '../../components/mainFooter'
import { clearCache } from '../../services/functions'
import './Products.min.css'

const ProductsPage = () => {
	const dispatch = useDispatch()
	const history = useHistory()
	const [toast] = useIonToast()
	const [products, setProducts] = useState(null)
	const modal = useRef(null)
	const [confirmCardModal, setConfirmCardModal] = useState(false)
	const [selectedParams, setSelectedParams] = useState(null)
	const subscriber = useSelector((state) => state.user.master_club_subscriber)

	useEffect(() => {
		dispatch(getProducts())
			.then((response) => {
				setProducts(response.payload)
			})
			.catch((error) => {
				console.error('Error fetching products:', error)
				toast({
					message: "Errore durante la creazione dell'ordine",
					duration: 2000,
				})
			})
	}, [])

	const ProductGrid = ({ is_highlighted, billing_monthly, modal_grid }) => {
		const filterProducts = (productsData = []) => {
			return productsData.filter((product) => {
				const isHighlightedMatch =
					is_highlighted === undefined ||
					product.is_highlighted === is_highlighted

				const billingMonthlyMatch =
					billing_monthly === undefined ||
					product.billing_monthly === billing_monthly

				return isHighlightedMatch && billingMonthlyMatch
			})
		}

		return (
			<IonGrid>
				<IonRow>
					{!products?.data ? (
						[...Array(6)].map((_, i) => {
							return <ProductSkeleton key={i}></ProductSkeleton>
						})
					) : filterProducts(products.data).length ? (
						filterProducts(products.data)
							.sort((a, b) => (a.duration <= b.duration ? 1 : -1))
							.map((product) => {
								const productMonths = Math.round(product.duration / 30)

								const productPrice =
									product?.discount !== undefined
										? parseFloat(product.price.amount) -
										  parseFloat(product.discount.amount)
										: parseFloat(product.price.amount)

								const productPriceFormatted = productPrice.toLocaleString(
									'es-ES',
									{
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									}
								)

								const monthlyAmount = productPrice / productMonths

								const monthlyAmountFormatted = monthlyAmount.toLocaleString(
									'es-ES',
									{
										minimumFractionDigits: 2,
										maximumFractionDigits: 2,
									}
								)
								return (
									<IonCol
										size="12"
										sizeMd={modal_grid ? '6' : '4'}
										key={product.id}>
										<IonCard className="product-card">
											{/* <div
												className="ion-margin-start ion-margin-top"
												style={{
													position: 'absolute',
													top: '0',
													left: '0',
													zIndex: '1',
												}}>
												{product.duration === 364 && (
													<IonBadge
														color="light"
														className="">
														<Icon
															icon="ic:round-star"
															color="#fcd53f"
															width="17"
															height="17"
															style={{
																marginBottom: '-2px',
																marginRight: '2px',
															}}
														/>{' '}
														Risparmia di più
													</IonBadge>
												)}
												{product.auto_renewing && (
													<IonBadge
														style={{ display: 'block' }}
														color="light">
														<Icon
															icon="dashicons:update"
															width="16"
															height="16"
															style={{
																marginBottom: '-2px',
																marginRight: '2px',
															}}
														/>
														Ricorrente
													</IonBadge>
												)}
											</div> */}
											<ImageLoader image={product.cover_url} />
											<IonCardHeader>
												{product.is_highlighted && 'Il piu venduto'}
												<IonCardTitle>{product.title}</IonCardTitle>
											</IonCardHeader>
											<IonCardContent>{product.description}</IonCardContent>
											<IonButton
												// fill="clear"
												// color="light"
												onClick={() => {
													const params = {
														create_order_url: products.create_order_url,
														product_id: product.id,
														subscriber_id: subscriber.id,
														productPriceFormatted: productPriceFormatted + ' €',
														title: 'Pagamento',
														type: 'product_payment',
													}
													if (subscriber.card_last4) {
														setSelectedParams(params)
														setConfirmCardModal(true)
													} else {
														modal.current?.dismiss()
														history.push('/pagamento', params)
													}
												}}
												className="ion-margin"
												expand="full">
												<h3
													className="ion-no-margin"
													style={{ padding: '10px' }}>
													{!product.billing_monthly && productMonths > 1 ? (
														<>{monthlyAmountFormatted}€ al mese</>
													) : (
														<>{productPriceFormatted}€ al mese</>
													)}
												</h3>
											</IonButton>
											<b className="flex-center ion-margin">
												Ora paghi {productPriceFormatted}€
											</b>
										</IonCard>
									</IonCol>
								)
							})
					) : (
						<IonCol size="12">
							<NoRecords text="prodotti" />
						</IonCol>
					)}
				</IonRow>
			</IonGrid>
		)
	}

	const ProductModal = () => {
		const [segmentSelected, setSegmentSelected] = useState(false)

		return (
			<IonModal
				ref={modal}
				trigger="open-modal">
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonButton onClick={() => modal.current?.dismiss()}>
								Indietro
							</IonButton>
						</IonButtons>
						<IonTitle>Tutti gli abbonamenti</IonTitle>
					</IonToolbar>
				</IonHeader>

				<IonContent className="segment-container">
					<IonSegment
						className="products-segment"
						value={segmentSelected}
						onIonChange={(e) => setSegmentSelected(e.detail.value)}>
						<IonSegmentButton value={false}>
							<IonLabel>Unica soluzione</IonLabel>
						</IonSegmentButton>
						<IonSegmentButton value={true}>
							<IonLabel>Pagamenti mensili</IonLabel>
						</IonSegmentButton>
					</IonSegment>

					<ProductGrid
						billing_monthly={segmentSelected}
						modal_grid={true}
					/>
				</IonContent>
			</IonModal>
		)
	}

	const ConfirmCardModal = () => {
		return (
			<IonModal isOpen={confirmCardModal}>
				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonButton onClick={() => setConfirmCardModal(false)}>
								Indietro
							</IonButton>
						</IonButtons>
						<IonTitle>Confermare addebito?</IonTitle>
					</IonToolbar>
				</IonHeader>

				<IonContent className="ion-padding">
					<p>
						L’importo di {selectedParams?.productPriceFormatted} sarà addebitato
						sulla carta che finisce in {subscriber.card_last4}.
					</p>
					<IonButton
						color="primary"
						fill="solid"
						expand="full"
						onClick={() => {
							setConfirmCardModal(false)
							history.push('/pagamento', selectedParams)
						}}>
						Confermo
					</IonButton>
				</IonContent>
			</IonModal>
		)
	}

	// 	return (
	// 		<IonPage className="products-page">
	// 			<MainHeader
	// 				page="ProductsPage"
	// 				title="Prodotti"
	// 			/>
	// 			<IonContent>
	// 				<div className="ion-padding ion-text-center">
	// 					<Icon
	// 						icon="ic:twotone-report-problem"
	// 						width="5em"
	// 						height="5em"
	// 						color="#fcd53f"
	// 					/>
	// 					<h3>
	// 						Ops! I nostri prodotti al momento non sono disponibili.
	// 						<br /> Stiamo lavorando per riprendere il servizio al piu presto.
	// 					</h3>
	// 				</div>
	// 			</IonContent>
	// 			<MainFooter />
	// 		</IonPage>
	// 	)
	// }

	return (
		<IonPage className="products-page">
			<MainHeader
				page="ProductsPage"
				title="Prodotti"
			/>
			<IonContent>
				<IonRefresher
					slot="fixed"
					pullFactor={0.5}
					pullMin={100}
					pullMax={200}
					onIonRefresh={clearCache}>
					<IonRefresherContent></IonRefresherContent>
				</IonRefresher>
				<div className="ion-padding">
					<h3>Con un abbonamento Premium avrai ogni settimana:</h3>
					<ul className="checks">
						<li>
							<Icon icon="mdi:check-bold" /> Aggiornamenti e approfondimenti
							sulla scienza dell’alimentazione e sul benessere
						</li>
						<li>
							<Icon icon="mdi:check-bold" /> Schemi alimentari dimagranti e/o di
							mantenimento
						</li>
						<li>
							<Icon icon="mdi:check-bold" /> Ricette gourmand, ricette veloci e
							piatti unici
						</li>
						<li>
							<Icon icon="mdi:check-bold" /> Menù speciali e prime colazioni
						</li>
					</ul>
					<h4>Ed in più:</h4>
					<ul className="checks">
						<li>
							<Icon icon="mdi:check-bold" /> Accesso completo all’App
						</li>
						<li>
							<Icon icon="mdi:check-bold" /> Supporto psicologico e
							motivazionale di gruppo
						</li>
						<li>
							<Icon icon="mdi:check-bold" /> Video tutorial di cucina sana,
							fitness, yoga e cura del corpo
						</li>
						<li>
							<Icon icon="mdi:check-bold" /> Accesso esclusivo ai gruppi di
							discussione dedicati agli abbonati
						</li>
						<li>
							<Icon icon="mdi:check-bold" /> Assistenza personalizzata
						</li>
						<li>
							<Icon icon="mdi:check-bold" /> Partecipazione alle dirette con gli
							esperti e agli eventi ufficiali
						</li>
					</ul>
					<b>
						Scegli la durata e la modalità di pagamento (rateizzato oppure in
						un’unica soluzione)…
					</b>
				</div>

				<ProductGrid is_highlighted={true} />

				<IonButton
					id="open-modal"
					expand="block"
					className="ion-margin">
					<h4
						className="ion-no-margin"
						style={{ padding: '5px' }}>
						Tutti gli abbonamenti
					</h4>
				</IonButton>
				<ProductModal />
				<ConfirmCardModal />
			</IonContent>
			<MainFooter />
		</IonPage>
	)
}

export default ProductsPage
