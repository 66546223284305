import config from '../../settings/config'

export const baseUrl = config.env[config.env.mode].url
export const baseNode = config.env[config.env.mode].node

export const headersDefault = () => {
	return {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		Authorization: `Bearer ${localStorage.getItem('adoc_t')}`,
	}
}

export * from './userActions'
export * from './groupsActions'
export * from './courseActions'
export * from './dietPlanActions'
export * from './homeGroupActions'
export * from './pushSettingsActions'
export * from './subscriptionsActions'
export * from './NotificationsActions'
export * from './multimediaGroupsActions'
export * from './clearDataActions'
