import { register } from 'timeago.js'

function itLocale(number, index, totalSec) {
	return [
		['Poco fa', 'fra poco'], // 1 sec
		['Poco fa', 'fra poco'], // 2-59 sec
		['Poco fa', 'fra poco'], // 1 min
		['Poco fa', 'fra poco'], //	2-59 min
		['1h', 'fra 1h'], // 1h
		['%sh', 'fra %s ore'], // 2-24h
		['1g', 'fra 1g'], // 1 day
		['%sg', 'fra %sg'], // 2-29 days
		['1s', 'fra 1s'], // 1 week
		['%ss', 'fra %ss'], // 2-3 weeks
		['1m', 'fra 1m'], // 1 month
		['%sm', 'fra %sm'], // 2-11 months
		['1a', 'fra un a'], // 1 year
		['%sa', 'fra %sa'], // 2+ years
	][index]
}

register('itLocale', itLocale)

export default itLocale
