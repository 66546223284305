import React, { useEffect, useRef, useState } from 'react'
import { IonButton, IonModal, IonText } from '@ionic/react'
import activeNotifications from './ActiveNotifications.png'
import {
	_isStandalone,
	urlBase64ToUint8Array,
} from '../../../services/functions'
import { PUBLIC_KEY } from '../../../serviceWorkerRegistration'
import { useDispatch, useSelector } from 'react-redux'
import { postNotificationsSubscription } from '../../../redux/actions'
import { updateActiveNotifications } from '../../../redux/reducer/NotificationsSlice'
import { Icon } from '@iconify/react'
import BlockedNotifications from './BlockedNotifications'
import { useHistory } from 'react-router'

const ActivateNotifications = ({
	firstTime = false,
	redirectHome = true,
	setNotificationAccepted,
	showNotificationsModal = true,
	setShowNotificationsModal,
	setNotificationSystemAccepted,
}) => {
	const activeNotificationsState = useSelector(
		(state) => state.notifications.activeNotifications
	)
	const [isOpen, setIsOpen] = useState(false)
	const modal = useRef(null)

	const [deniedNotifications, setDeniedNotifications] = useState(false)
	const dispatch = useDispatch()

	const isLocal = window.location.hostname === 'localhost'

	useEffect(() => {
		// console.log('showNotificationsModal', showNotificationsModal)
		if (showNotificationsModal) setIsOpen(showNotificationsModal)
	}, [showNotificationsModal])

	useEffect(() => {
		// console.log('isOpen', isOpen)
		if (!isOpen && setShowNotificationsModal) setShowNotificationsModal(false)
	}, [isOpen])

	useEffect(() => {
		navigator.serviceWorker.getRegistration().then((registration) => {
			if (!registration) {
				console.log('Service Worker registration not found.')
				return
			}
			registration.pushManager
				.permissionState({
					userVisibleOnly: true,
					applicationServerKey: urlBase64ToUint8Array(PUBLIC_KEY),
				})
				.then((state) => {
					// console.log('state', state)
					if (state !== 'granted' && state !== 'denied') {
						if (showNotificationsModal === true) {
							setIsOpen(true)

							console.log('updateActiveNotifications 3', state)
							dispatch(updateActiveNotifications(state))
						}
					}
					if (state === 'denied') {
						// setDeniedNotifications(true)
						if (setNotificationSystemAccepted)
							setNotificationSystemAccepted(false)
						setIsOpen(false)
					}
					if (state === 'granted') {
						if (setNotificationSystemAccepted)
							setNotificationSystemAccepted(true)
						setIsOpen(false)
					}
				})
		})
	}, [activeNotificationsState, showNotificationsModal])

	const setPushNotificationsState = () => {
		navigator.serviceWorker.getRegistration().then((registration) => {
			registration?.pushManager
				.subscribe({
					userVisibleOnly: true,
					applicationServerKey: urlBase64ToUint8Array(PUBLIC_KEY),
				})
				.catch(() =>
					registration.pushManager
						.permissionState({
							userVisibleOnly: true,
							applicationServerKey: urlBase64ToUint8Array(PUBLIC_KEY),
						})
						.then((state) => {
							console.log('updateActiveNotifications 1', state)
							dispatch(updateActiveNotifications(state))
						})
				)
				.then((subscription) => {
					if (subscription) {
						registration.pushManager
							.permissionState({
								userVisibleOnly: true,
								applicationServerKey: urlBase64ToUint8Array(PUBLIC_KEY),
							})
							.then((state) => {
								console.log('updateActiveNotifications 2', subscription)
								dispatch(updateActiveNotifications(state))
								dispatch(postNotificationsSubscription(subscription))
							})
					}
				})
		})
	}

	const handleNotificationResponse = (accepted) => {
		if (accepted) {
			setPushNotificationsState()
		}

		setIsOpen(false)
		if (setNotificationAccepted) setNotificationAccepted(accepted)
	}

	return (
		<>
			<IonModal
				className="modal-as-alert"
				isOpen={isOpen}
				canDismiss={!isOpen}>
				<div className="ion-padding">
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<Icon
							icon="solar:notification-unread-lines-bold-duotone"
							style={{
								width: '120px',
								height: '120px',
								color: 'var(--ion-color-primary)',
							}}
						/>
					</div>
					{/* <h3>Attiva le notifiche per migliorare la tua esperienza</h3> */}
					<p>
						Per rimanere aggiornato su “mi piace”, commenti, video degli
						esperti, nuovi menù e ricette, consenti a Dieta Social di inviarti
						notifiche!
					</p>
					<IonButton
						className="ion-margin-bottom"
						expand="full"
						strong={true}
						onClick={() => handleNotificationResponse(true)}>
						OK
					</IonButton>
					<IonButton
						expand="full"
						fill="clear"
						onClick={() => handleNotificationResponse(false)}>
						Non voglio essere aggiornato
					</IonButton>
				</div>
			</IonModal>
			{/* {deniedNotifications && <BlockedNotifications />} */}
		</>
	)
}

export default ActivateNotifications
