import { createAsyncThunk } from '@reduxjs/toolkit'
import { baseUrl, headersDefault } from '.'
import axios from 'axios'

export const getHomeGroupPosts = createAsyncThunk(
	'homeGroup/getHomeGroupPosts',
	async ({ page = 1, is_fixed = false }) => {
		const params = { page, is_fixed, from_main_group: true }

		const response = await axios.get(
			`${baseUrl}/api/master-club-post-groups/list`,
			{
				headers: headersDefault(),
				params,
			}
		)

		return response.data
	}
)

export const likeHomePost = createAsyncThunk(
	'homeGroup/likeHomePost',
	async ({ element_id, is_fixed = false }) => {
		const body = {
			element_type: 'App\\Models\\MasterClubPostGroup',
			element_id,
		}

		const response = await axios.post(
			`${baseUrl}/api/master-club-likes`,
			body,
			{
				headers: headersDefault(),
			}
		)

		return response.data
	}
)
