import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'
import { setupIonicReact } from '@ionic/react'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { Provider } from 'react-redux'
import store from './redux/store/'

// eslint-disable-next-line no-unused-vars
import * as timeAgoConfig from './settings/timeAgoConfig'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Fonts */
// import './settings/theme/fonts.min.css'

/* Theme variables */
import './settings/theme/variables.min.css'

/* styles globals */
import './settings/theme/globalStyles.min.css'

setupIonicReact()

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	<Provider store={store}>
		{/* <StrictMode> */}
		<App />
		{/* </StrictMode> */}
	</Provider>
)

serviceWorkerRegistration.register()
