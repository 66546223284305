import { IonContent, IonPage, useIonViewDidEnter } from '@ionic/react'
import MainHeader from '../../components/headers/mainHeader'
import Comment from '../../components/Comments'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef } from 'react'
import { getCommentAndReplies } from '../../redux/actions'
import ErrorToast from '../../components/ErrorToast'
import { CommentSkeleton } from '../../components/skeletons/CommentSkeleton'
import { InsertComment } from '../../components/InsertComment'
import { randId } from '../../services/functions'

const LoadingRender = ({ comment }) => (
	<>
		{!comment && <CommentSkeleton />}
		<CommentSkeleton is_reply={true} />
		<CommentSkeleton is_reply={true} />
	</>
)

const CommentPage = ({ match }) => {
	const dispatch = useDispatch()
	useIonViewDidEnter(() => {
		dispatch(getCommentAndReplies({ commentId }))
	})

	const contentRef = useRef(null)
	const commentId = match.params.commentId
	const href = window.location.pathname.slice(0, -1 * (commentId.length + 1))

	const {
		isLoading,
		error,
		data: { comment, replies },
	} = useSelector((state) => state.groups.getCommentAndReplies)

	return (
		<>
			<IonPage>
				<MainHeader
					href={href}
					title="Commenti"
					page="CommentsPage"
				/>
				<IonContent ref={contentRef}>
					{comment && (
						<Comment
							comment={{
								...comment,
								...(replies && { replies_count: replies.total }),
							}}
							in_post={false}
							match={match}
						/>
					)}
					{replies &&
						replies.data.map((reply) => (
							<Comment
								key={reply.id ?? randId()}
								comment={reply}
								in_post={false}
								is_reply={true}
								match={match}
							/>
						))}
					{error && <ErrorToast />}
					{isLoading && <LoadingRender comment={!!comment} />}
				</IonContent>
				{replies?.data && (
					<InsertComment
						element_id={commentId}
						element_type="comment"
						contentRef={contentRef}
					/>
				)}
			</IonPage>
		</>
	)
}

export default CommentPage
