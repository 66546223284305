import femaleimage1 from './female/image1.svg'
import femaleimage2 from './female/image2.svg'
import femaleimage3 from './female/image3.svg'
import femaleimage4 from './female/image4.svg'
import femaleimage5 from './female/image5.svg'
import femaleimage6 from './female/image6.svg'

import maleimage1 from './male/image1.svg'
import maleimage2 from './male/image2.svg'
import maleimage3 from './male/image3.svg'
import maleimage4 from './male/image4.svg'
import maleimage5 from './male/image5.svg'
import maleimage6 from './male/image6.svg'

const images = {
	male: [
		maleimage1,
		maleimage2,
		maleimage3,
		maleimage4,
		maleimage5,
		maleimage6,
	],
	female: [
		femaleimage1,
		femaleimage2,
		femaleimage3,
		femaleimage4,
		femaleimage5,
		femaleimage6,
	],
}

export default images
