import {
	IonBadge,
	IonItem,
	IonList,
	useIonAlert,
	useIonViewDidEnter,
} from '@ionic/react'
import {
	daysOfTheWeek,
	getWeekDays,
	monthsOfTheYear,
} from '../../services/functions'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import dayjs from 'dayjs'
import { setDayType } from '../../redux/reducer/menuDaySlice'
import { useHistory } from 'react-router'
import SubscriptionAlert from '../../components/SubscriptionAlert'
import CourseAlert from '../../components/CourseAlert'
import { getCourse } from '../../redux/actions'

const MenuContent = ({
	date,
	disableDates,
	daysLimit,
	route,
	planData,
	planIsLoading,
}) => {
	const history = useHistory()
	const [isOpen, setIsOpen] = useState(false)
	const dispatch = useDispatch()

	const { data, isLoading, error } = useSelector(
		(state) => state.course.getCourse
	)

	// useIonViewDidEnter(() => {
	// 	dispatch(getCourse())
	// })

	const [presentAlert] = useIonAlert()

	const subscriber = useSelector((state) => state.user.master_club_subscriber)

	const { keep_weight, vegetarian, gluten_free } = subscriber

	const subscription = subscriber?.subscription

	const subscriptionName = subscription?.subscription_type?.name ?? null

	const showDayType = !vegetarian && !keep_weight

	const zeroPad = (num, places) => String(num).padStart(places, '0')

	const loadMenuDetails = (day, typeText, isLocked) => {
		if (isLocked) {
			const courseAlertConfig = CourseAlert(() => history.push('/corso'))
			presentAlert(courseAlertConfig)
			return
		}
		dispatch(setDayType({ value: typeText }))
		day.month = zeroPad(day.month + 1, 2)
		day.day = zeroPad(day.day, 2)
		history.push(
			`${window.location.pathname}/${day.year}-${day.month}-${day.day}`,
			{ planData, planIsLoading }
		)
	}

	const isCalendarDisabled = (dateString) => {
		let date = dayjs(dateString).format('YYYY-MM-DD')
		let min = dayjs(daysLimit[0]).add(2, 'hour').format('YYYY-MM-DD')
		let max = dayjs(daysLimit[1]).add(2, 'hour').format('YYYY-MM-DD')
		//date.setHours(date.getHours() + 2) //Add GMT+2
		//Add GMT+2 to date
		// max = dayjs(max).add(2, 'hour')
		// console.log('min', min)
		// console.log('max', max)
		// console.log('date', date)

		let result = true
		// console.log(date)
		// console.log(max)
		if (date >= min && date <= max && planData?.dietPlan) {
			result = disableDates.some((disableDate) => {
				const disableDateObj = dayjs(disableDate)
				return disableDateObj.format('YYYY-MM-DD') === date
			})
		}

		return result
	}

	const isDayLocked = (dayNumber) => {
		const dataIsEmpty = !data
			? true
			: Object.keys(data).length === 0 && data.constructor === Object
		const result =
			isLoading || error
				? true
				: dataIsEmpty
				? false
				: !data?.unlocked?.menu_days.includes(dayNumber.toString())

		return result
	}

	const week = getWeekDays(date)

	return (
		<>
			<div style={{ overflowY: 'auto' }}>
				<IonList
					style={{ paddingBottom: 0 }}
					onClick={() => {
						if (!subscription) {
							setIsOpen(true)
						}
					}}>
					{week.map((day, index) => {
						const listDay = `${day.year}-${day.month + 1}-${day.day}`
						const isToday = listDay === dayjs().format('YYYY-MM-DD')
						const type = planData?.dietPlan[index][0]?.type ?? null
						let typeText = ''
						let typeColor = ''
						if (showDayType && subscriptionName !== 'free') {
							switch (type) {
								// case 'free':
								// 	typeText = 'Libero'
								// 	typeColor = 'medium'
								// 	break
								case 'proteic':
									typeText = 'Proteico'
									typeColor = 'blue'
									break
								case 'glycidic':
									typeText = 'Glucidico'
									typeColor = 'tertiary'
									break
							}
						}

						//Block days if is free and is not unlocked
						return (
							<IonItem
								button
								disabled={isCalendarDisabled(listDay)}
								onClick={() => {
									loadMenuDetails(day, typeText, isDayLocked(index + 1))
								}}
								today={isToday}
								className={
									'menu-item' + (isDayLocked(index + 1) && ' opacity-50')
								}
								key={index}
								lines={index === week.length - 1 ? 'none' : 'inset'}>
								{planIsLoading
									? 'Caricando giorno...'
									: `${daysOfTheWeek[index]} ${day.day} ${
											monthsOfTheYear[day.month]
									  } `}

								{type && showDayType && (
									<IonBadge
										color={typeColor}
										style={{ marginLeft: '5px' }}>
										{typeText}
									</IonBadge>
								)}
							</IonItem>
						)
					})}
				</IonList>
			</div>
			<SubscriptionAlert
				isOpen={isOpen}
				setIsOpen={setIsOpen}
			/>
		</>
	)
}

export default MenuContent
