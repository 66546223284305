import { createSlice } from '@reduxjs/toolkit'
import { getMinimumLegalAgeDate } from '../../services/functions'

const initialState = {
	birth_date: null,
	freeOrPaid: 'free',
	initial_weight: 60,
	keep_weight: false,
	gluten_free: false,
	province_id: null,
	vegetarian: undefined,
	nickname: null,
	alias: null,
	days_as_subscriber: 0,
	subscription_days_count: 0,
	card_last4: null,
	badge: {},
	city_id: null,
	telephone: '',
	surname: '',
	avatar: '',
	avatarFile: null,
	image: '',
	height: 100,
	name: '',
	sex: '',
	simulate_free: '',
	is_smart: false,
	extras: {
		physical_impediments: [],
		weight_goal: 60,
		eating_out: '',
		motivation: '',
		intestine: '',
		cooking: '',
		hobbies: [],
		sleep: '',
		goals: [],
	},
}

const initialConfigSlice = createSlice({
	name: 'initialConfig',
	initialState,
	reducers: {
		setInitialConfigProperty: (state, action) => {
			state[action.payload.key] = action.payload.value
		},
		setInitialConfigExtraProperty: (state, action) => {
			state.extras[action.payload.key] = action.payload.value
		},
	},
})

export const { setInitialConfigProperty, setInitialConfigExtraProperty } =
	initialConfigSlice.actions
export default initialConfigSlice.reducer
