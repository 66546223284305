import { createAsyncThunk } from '@reduxjs/toolkit'
import { baseUrl, headersDefault } from '.'
import { clearCache } from '../../services/functions'
import dayjs from 'dayjs'

export const getClearData = createAsyncThunk(
	'getClearData',
	async (history) => {
		const now = dayjs().format('YYYY-MM-DD HH:mm:ss')
		if (!localStorage.getItem('adoc_clear_cache')) {
			localStorage.setItem('adoc_clear_cache', now)
		}
		if (!localStorage.getItem('adoc_clear_local_storage')) {
			localStorage.setItem('adoc_clear_local_storage', now)
		}
		const clear_cache = localStorage.getItem('adoc_clear_cache')
		const clear_local_storage = localStorage.getItem('adoc_clear_local_storage')
		const response = await fetch(
			`${baseUrl}/api/master-club-clear-data-logs/list`,
			{
				headers: headersDefault(),
			}
		)

		if (response.status !== 200)
			throw new Error(response.message ?? 'GenericError')

		const data = await response.json()

		const willClearLocalStorage =
			data.clear_local_storage && data.created_at > clear_local_storage
		const willClearCache = data.clear_cache && data.created_at > clear_cache

		if (willClearLocalStorage) {
			localStorage.setItem('adoc_clear_local_storage', data.created_at)
			// console.log('adoc_clear_local_storage', data.created_at)
			history.replace('/logout')
		}

		if (willClearCache) {
			localStorage.setItem('adoc_clear_cache', data.created_at)
			// console.log('adoc_clear_cache', data.created_at)
			clearCache(null)
		}

		return data
	}
)
