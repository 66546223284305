import {
	IonButton,
	IonCard,
	IonCardHeader,
	IonCol,
	IonGrid,
	IonItem,
	IonRow,
	IonText,
} from '@ionic/react'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { headersDefault } from '../../redux/actions'
import config from '../../settings/config'
import { updateUserProperty } from '../../redux/reducer/userSlice'

const DietButtons = ({ profile = false }) => {
	const backUrl = config.env[config.env.mode].url
	const dispatch = useDispatch()
	const { master_club_subscriber } = useSelector((state) => state.user)

	const subscriptionName =
		useSelector(
			(state) =>
				state.user?.master_club_subscriber?.subscription?.subscription_type
					?.name
		) ?? null

	const [vegetarian, setVegetarian] = useState(
		master_club_subscriber?.vegetarian || false
	)
	const [glutenFree, setGlutenFree] = useState(
		master_club_subscriber?.gluten_free || false
	)

	const [keep_weight, setKeepWeight] = useState(
		master_club_subscriber?.keep_weight || false
	)

	const v = vegetarian ? 'VEGETARIANO' : 'ONNIVORO'
	const vColor = vegetarian ? 'primary' : 'blue'
	const k = keep_weight ? 'MANTENIMENTO' : 'DIMAGRIMENTO'
	const kColor = keep_weight ? 'blue' : 'primary'
	const g = glutenFree ? 'SI' : 'NO'
	const gColor = glutenFree ? 'primary' : 'light'

	const handleGlfClick = () => {
		setGlutenFree(!glutenFree)
	}

	const handleVegetarianClick = () => {
		setVegetarian(!vegetarian)
	}

	const handleKeepWeightClick = () => {
		setKeepWeight(!keep_weight)
	}

	useEffect(() => {
		updateInput()
	}, [glutenFree, vegetarian, keep_weight])

	useEffect(() => {
		if (subscriptionName !== 'premium' && keep_weight) {
			setKeepWeight(false)
		}
	}, [])

	const updateInput = () => {
		const body = JSON.stringify({
			...{ vegetarian },
			...{ keep_weight },
			...{ gluten_free: glutenFree },
		})
		fetch(`${backUrl}/api/master-club-subscribers/1`, {
			method: 'PATCH',
			body,
			headers: headersDefault(),
		})
			.then((response) => {
				if (!response.ok) throw new Error('fetch error')
				return response.json()
			})
			.then((data) => {
				dispatch(
					updateUserProperty({
						stateKey: 'master_club_subscriber',
						key: 'gluten_free',
						value: glutenFree,
					})
				)

				dispatch(
					updateUserProperty({
						stateKey: 'master_club_subscriber',
						key: 'vegetarian',
						value: vegetarian,
					})
				)
			})
			.catch((e) => console.log(e))
	}

	const Profile = (
		<IonGrid className="ion-margin-vertical ion-no-padding">
			<IonRow>
				<IonCol
					sizeSm="4"
					sizeXs="12">
					<IonButton
						onClick={handleVegetarianClick}
						className="disable-click"
						color={vColor}>
						{v}
					</IonButton>
					<IonText
						color="medium"
						style={{ display: 'block' }}>
						<div className="label">Tipo di alimentazione</div>
					</IonText>
				</IonCol>
				<IonCol
					sizeSm="4"
					sizeXs="12">
					<IonButton
						onClick={handleKeepWeightClick}
						className="disable-click"
						disabled={subscriptionName !== 'premium'}
						color={kColor}>
						{k}
					</IonButton>
					<IonText
						color="medium"
						style={{ display: 'block' }}>
						<div className="label">Obiettivo</div>
					</IonText>
				</IonCol>
				<IonCol
					sizeSm="4"
					sizeXs="12">
					<IonButton
						onClick={handleGlfClick}
						className="disable-click"
						color={gColor}>
						{g}
					</IonButton>
					<IonText
						color="medium"
						style={{ display: 'block' }}>
						<div className="label">Senza glutine e lattosio</div>
					</IonText>
				</IonCol>
			</IonRow>
		</IonGrid>
	)

	const Card = (
		<IonCard>
			<IonCardHeader>Le tue preferenze alimentari</IonCardHeader>
			<IonItem>
				<b>Tipo di alimentazione:</b>
				<IonButton
					onClick={handleVegetarianClick}
					color={vColor}>
					{v}
				</IonButton>
			</IonItem>
			<IonItem>
				<b>Obiettivo:</b>
				<IonButton
					onClick={handleKeepWeightClick}
					disabled={subscriptionName !== 'premium'}
					color={kColor}>
					{k}
				</IonButton>
			</IonItem>
			<IonItem>
				<b>Senza glutine e lattosio:</b>
				<IonButton
					onClick={handleGlfClick}
					color={gColor}>
					{g}
				</IonButton>
			</IonItem>
		</IonCard>
	)

	return profile ? Profile : Card
}

export default DietButtons
