import { createAsyncThunk } from '@reduxjs/toolkit'
import { baseUrl, headersDefault } from '.'

export const getUserInfo = createAsyncThunk('user/getUserInfo', async () => {
	const response = await fetch(`${baseUrl}/api/profilo-utenti/1/fetch`, {
		headers: headersDefault(),
	})

	if (response.status !== 200)
		throw new Error(response.message ?? 'GenericError')

	const data = await response.json()
	return data
})

export const postWellnessIndex = createAsyncThunk(
	'user/postWellnessIndex',
	async (feeling) => {
		const response = await fetch(`${baseUrl}/api/master-club-wellness-index`, {
			method: 'POST',
			body: JSON.stringify({ index: feeling }),
			headers: headersDefault(),
		})

		if (response.status !== 200)
			throw new Error(response.message ?? 'GenericError')

		const data = await response.json()
		return data
	}
)

export const postNotificationsSubscription = createAsyncThunk(
	'user/postNotificationsSubscription',
	async (subscription) => {
		const response = await fetch(
			`${baseUrl}/api/master-club-push-subscriptions`,
			{
				method: 'POST',
				body: JSON.stringify(subscription),
				headers: headersDefault(),
			}
		)

		if (response.status !== 200)
			throw new Error(
				(response.message || (await response.json())) ?? 'GenericError'
			)

		return 'ok'
	}
)

export const reportProblem = createAsyncThunk(
	'user/reportProblem',
	async ({ message }) => {
		const response = await fetch(
			`${baseUrl}/api/master-club-subscribers/report-problem`,
			{
				body: JSON.stringify({ message }),
				method: 'POST',
				headers: headersDefault(),
			}
		)

		if (response.status !== 200)
			throw new Error(
				(response.message || (await response.json()).error) ?? 'GenericError'
			)

		return await response.json()
	}
)

export const importSubscription = createAsyncThunk(
	'user/importSubscription',
	async () => {
		const response = await fetch(
			`${baseUrl}/api/master-club-subscribers/import-subscription`,
			{
				method: 'POST',
				headers: headersDefault(),
			}
		)

		if (response.status !== 200)
			throw new Error(
				(response.message || (await response.json()).error) ?? 'GenericError'
			)

		return await response.json()
	}
)
