import {
	IonPage,
	IonContent,
	IonText,
	IonRadioGroup,
	IonRadio,
	IonButton,
	IonItem,
	IonLabel,
} from '@ionic/react'
import InitialConfigHeader from '../header'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setInitialConfigExtraProperty } from '../../../redux/reducer/initialConfigSlice'
import '../InitialConfiguration.min.css'
import { useHistory } from 'react-router'

const options = [
	'Si, mi piace e dedico molto tempo alla cucina',
	'Mi piace cucinare ma non ho tempo',
	'Non mi piace cucinare ma lo faccio quotidianamente',
	'Acquisto cibo pronto da asporto, anche online',
	'Uso spesso cibi pronti',
]

const CookingPreferencesPage = ({ step, totalSteps }) => {
	const history = useHistory()
	const [isLoading, setIsLoading] = useState(false)
	const cooking = useSelector((state) => state.initialConfig.extras.cooking)
	const dispatch = useDispatch()
	const [error, setError] = useState('')

	const updateInput = (value) => {
		if (error !== '') setError('')
		if (value !== cooking)
			dispatch(setInitialConfigExtraProperty({ key: 'cooking', value }))
	}

	const validateInput = () => {
		let isValid = true
		if (cooking === '') {
			setError("Devi selezionare un'opzione per continuare.")
			isValid = false
		} else if (!options.includes(cooking)) {
			setError("L'opzione selezionata non è valida.")
			isValid = false
		}

		return isValid
	}

	const handleSubmit = () => {
		setIsLoading(true)
		const isInputValid = validateInput()
		if (!isInputValid) {
			setIsLoading(false)
			return
		}
		history.push(`/impostazioni-iniziali/${step + 1}`)
		setIsLoading(false)
	}

	const handleSkip = (e) => {
		setIsLoading(true)
		dispatch(setInitialConfigExtraProperty({ key: 'cooking', value: '' }))
		history.push(`/impostazioni-iniziali/${step + 1}`)
		setIsLoading(false)
	}

	return (
		<IonPage className="initial-configuration">
			<InitialConfigHeader
				step={step}
				totalSteps={totalSteps}
				isLoading={isLoading}
			/>

			<IonContent>
				<h1>Ti piace cucinare?</h1>

				<IonRadioGroup value={cooking}>
					{options.map((option, index) => {
						return (
							<IonItem
								onClick={() => updateInput(option)}
								key={index}
								className="no-padding-item">
								<IonRadio value={option}>
									<IonLabel className="white-space-normal">{option}</IonLabel>
								</IonRadio>
							</IonItem>
						)
					})}
				</IonRadioGroup>
				<IonText className="error">{error}</IonText>

				<div className="buttons">
					<IonButton
						shape="round"
						onClick={handleSkip}
						disabled={isLoading}
						color="light-gray"
						expand="full">
						<IonText color="white">Salta</IonText>
					</IonButton>
					<IonButton
						shape="round"
						onClick={handleSubmit}
						disabled={isLoading}
						color="primary"
						expand="full">
						Avanti
					</IonButton>
				</div>
			</IonContent>
		</IonPage>
	)
}

export default CookingPreferencesPage
