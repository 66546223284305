/* eslint-disable no-unused-vars */
import NamePage from './steps/NamePage'
import GenderPage from './steps/GenderPage'
import GoalsPage from './steps/GoalsPage'
import TypeOfDietPage from './steps/TypeOfDietPage'
import NicknamePage from './steps/NicknamePage'
import PhonePages from './steps/PhonePages'
import WeightPage from './steps/WeightPage'
import WeightGoalsPage from './steps/WeightGoalsPage'
import HeightPage from './steps/HeightPage'
import BirthDatePage from './steps/BirthDatePage'
import AbdominalCircumferencePage from './steps/AbdominalCircumferencePage'
import HipCircunferencePace from './steps/HipCircunferencePace'
import WaistCircumferencePage from './steps/WaistCircumferencePage'
import AvatarPages from './steps/AvatarPages'
import ImpedimentsPhysicalPage from './steps/ImpedimentsPhysicalPage'
import SleepDurationPage from './steps/SleepDurationPage'
import IntestinalFuncionalityPage from './steps/IntestinalFuncionalityPage'
import WhereYouLivePage from './steps/WhereYouLivePage'
import CookingPreferencesPage from './steps/CookingPreferencesPage'
import EatingOutPage from './steps/EatingOutPage'
import MotivationPage from './steps/MotivationPage'
import HobbyPage from './steps/HobbyPage'
import WeHaveFinished from './steps/WeHaveFinished'

const steps = [
	NamePage,
	GenderPage,
	GoalsPage,
	TypeOfDietPage,
	NicknamePage,
	PhonePages,
	WeHaveFinished,
	WeightPage,
	WeightGoalsPage,
	HeightPage,
	BirthDatePage,
	AbdominalCircumferencePage,
	HipCircunferencePace,
	WaistCircumferencePage,
	AvatarPages,
	ImpedimentsPhysicalPage,
	SleepDurationPage,
	IntestinalFuncionalityPage,
	WhereYouLivePage,
	CookingPreferencesPage,
	EatingOutPage,
	MotivationPage,
	HobbyPage,
]

export default steps
