import firebaseApp from '../../settings/firebaseConfig'
import { getAuth, signOut } from 'firebase/auth'
import { useEffect } from 'react'
import { useIonLoading } from '@ionic/react'
import { useHistory } from 'react-router'

// Define a functional component for the logout route
const LogoutRoute = () => {
	const history = useHistory()
	const auth = getAuth(firebaseApp)
	const [ionLoading, ionLoadingHide] = useIonLoading()

	useEffect(() => {
		ionLoading({
			message: 'Chiudendo sessione...',
		})
		signOut(auth).finally(() => {
			ionLoadingHide()
			localStorage.removeItem('adoc_p')
			localStorage.removeItem('adoc_i')
			localStorage.removeItem('adoc_t')
			localStorage.removeItem('adoc_origin')
			localStorage.removeItem('adoc_nav_count')
			// history.replace('/')
			window.location.href = '/'
		})
	}, [])

	return null // This component doesn't render anything
}

export default LogoutRoute
