import { createSlice } from '@reduxjs/toolkit'
import { getCourse, getPills } from '../actions'

const initialState = {
	getCourse: {
		data: null,
		isLoading: false,
		error: false,
	},
	getPills: {
		data: null,
		isLoading: false,
		error: false,
	},
}

const courseSlice = createSlice({
	name: 'course',
	initialState,
	reducers: {
		resetCourseError: (state) => {
			state.getCourse.error = false
		},
		resetPillsError: (state) => {
			state.getPills.error = false
		},
	},
	extraReducers: (builder) => {
		// getCourse
		builder.addCase(getCourse.pending, (state) => {
			state.getCourse.isLoading = true
			state.getCourse.error = false
		})
		builder.addCase(getCourse.fulfilled, (state, action) => {
			state.getCourse.isLoading = false
			state.getCourse.error = false
			state.getCourse.data = action.payload
		})
		builder.addCase(getCourse.rejected, (state, action) => {
			state.getCourse.isLoading = false
			state.getCourse.error = action.error.message
		})

		// getPills
		builder.addCase(getPills.pending, (state) => {
			state.getPills.isLoading = true
			state.getPills.error = false
		})
		builder.addCase(getPills.fulfilled, (state, action) => {
			state.getPills.isLoading = false
			state.getPills.error = false
			state.getPills.data = action.payload
		})
		builder.addCase(getPills.rejected, (state, action) => {
			state.getPills.isLoading = false
			state.getPills.error = action.error.message
		})
	},
})

export const { resetCourseError, resetPillsError } = courseSlice.actions
export default courseSlice.reducer
