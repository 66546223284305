import {
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonSkeletonText,
} from '@ionic/react'

export const MultimediaCardSkeleton = () => (
	<IonCard style={{ boxShadow: 'none' }}>
		<div style={{ maxWidth: '450px', margin: '0px auto' }}>
			<IonSkeletonText
				animated={true}
				style={{ width: ' 100%', paddingTop: '56.25%' }}
			/>
		</div>
		<IonCardHeader>
			<div style={{ paddingInline: '0.5rem' }}>
				<div
					style={{ marginTop: '1rem', marginBottom: '1rem', display: 'flex' }}>
					<IonSkeletonText
						animated={true}
						style={{ width: '3.5rem', height: '18px', marginRight: '1rem' }}
					/>
					<IonSkeletonText
						animated={true}
						style={{ width: '4rem', height: '18px', marginRight: '1rem' }}
					/>
					<IonSkeletonText
						animated={true}
						style={{ width: '5.5rem', height: '18px' }}
					/>
				</div>
				<IonCardTitle
					color="dark"
					style={{ fontWeight: 'var(--font-size-black)' }}>
					<IonSkeletonText
						animated={true}
						style={{ width: '8rem', height: '1.2rem' }}
					/>
				</IonCardTitle>
			</div>
		</IonCardHeader>

		<IonCardContent>
			<div style={{ fontSize: '1rem', paddingInline: '0.5rem' }}>
				<IonSkeletonText
					animated={true}
					style={{ width: 'min(30rem, 100%)' }}
				/>
			</div>
		</IonCardContent>
	</IonCard>
)
