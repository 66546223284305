import {
	IonButton,
	IonSkeletonText,
	IonContent,
	IonItem,
	IonList,
	IonPage,
	IonText,
	IonThumbnail,
	IonRefresher,
	IonRefresherContent,
	useIonViewDidEnter,
	useIonToast,
	IonModal,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonButtons,
} from '@ionic/react'
import MainFooter from '../../components/mainFooter'
import MainHeader from '../../components/headers/mainHeader'
import { useEffect, useRef, useState } from 'react'
import ErrorToast from '../../components/ErrorToast'
import { useDispatch, useSelector } from 'react-redux'
import { getMultimediaGroupsList } from '../../redux/actions'

import { checkOverflow, clearCache } from '../../services/functions'
import { ImageLoader } from '../../components/ImageLoader'
import { useHistory } from 'react-router'

import SubscriptionAlert from '../../components/SubscriptionAlert'

const LoadingGroup = () => (
	<IonItem className="no-padding-item">
		<IonThumbnail slot="start">
			<IonSkeletonText
				animated={true}
				style={{ borderRadius: '0.9rem' }}
			/>
		</IonThumbnail>
		<div style={{ width: '100%' }}>
			<IonSkeletonText
				animated={true}
				style={{ width: '7rem' }}
			/>
			<div style={{ display: 'flex' }}>
				<IonSkeletonText
					animated={true}
					style={{
						width: '4rem',
						height: '18px',
						marginRight: '1rem',
						borderRadius: '0.2rem',
					}}
				/>
				<IonSkeletonText
					animated={true}
					style={{ width: '5rem', height: '18px', borderRadius: '0.2rem' }}
				/>
			</div>
		</div>
	</IonItem>
)
const LoadingRender = () => (
	<IonList>
		{[...Array(10)].map((_, index) => (
			<LoadingGroup key={index} />
		))}
	</IonList>
)
const ErrorRender = () => <ErrorToast />

const DataRender = ({ data }) => {
	const subscription_type = useSelector(
		(state) =>
			state.user?.master_club_subscriber?.subscription?.subscription_type
	)
	const subscription_id = subscription_type?.id ?? 0
	const subscriptionName = subscription_type?.name ?? null

	const [isOpen, setIsOpen] = useState(false)
	const [isGroupFoldersOpen, setIsGroupFoldersOpen] = useState(false)
	const [indexGroup, setIndexGroup] = useState(null)
	const history = useHistory()
	const [wrongSubscriptionToast] = useIonToast()

	const params = history.location.state

	const modalParams =
		params?.self !== false ? [data, window.location.hash] : [data]

	useEffect(() => {
		const hash = window.location.hash
		if (hash) {
			const group = data.find(
				(group) => group.id === parseInt(hash.replace('#parent_group_id=', ''))
			)
			if (group) {
				const index = data.indexOf(group)
				handleGroupClick(group, index)
			}
		}
	}, modalParams)

	const handleGroupClick = (group, index) => {
		const subscriptionNames = group.subscription_types
			.map((item, i, arr) => {
				const name = item.name.toUpperCase()
				if (i === arr.length - 2) return name + ' e '
				if (i === arr.length - 1) return name
				return ''
			})
			.join('')
		group.subscription_types?.length === 0 ||
		group.subscription_types?.some(
			(subscription_type) => subscription_type.id === subscription_id
		)
			? (() => {
					setIndexGroup(index)
					setIsGroupFoldersOpen(true)
					history.push(
						history.location.pathname + '#parent_group_id=' + group.id
					)
			  })()
			: subscriptionName !== 'premium'
			? setIsOpen(true)
			: wrongSubscriptionToast({
					message: 'Questo contenuto è esclusivo per ' + subscriptionNames,
					duration: 3000,
			  })
	}

	const titleElementRef = useRef(null)

	useEffect(() => {
		if (isGroupFoldersOpen) {
			setTimeout(() => {
				checkOverflow(titleElementRef)
			}, 5000)
		}
	}, [isGroupFoldersOpen])

	return (
		<>
			<IonList>
				{data.map((group, index) => (
					<IonItem
						className="no-padding-item"
						key={group.id}
						button
						detail={false}
						onClick={() => handleGroupClick(group, index)}>
						<IonThumbnail
							slot="start"
							className="soft-shadow-and-radius">
							<ImageLoader image={group.storage_path + group.group_image} />
						</IonThumbnail>
						<div className="ion-padding-top">
							<IonText>{group.name}</IonText>
							<div>
								{group.subscription_types.length > 0 ? (
									group.subscription_types.map((subscription) => (
										<IonButton
											key={subscription.id}
											size="small"
											className="group-label"
											color={subscription.color}>
											{subscription.name.toUpperCase()}
										</IonButton>
									))
								) : (
									<IonButton
										size="small"
										color="yellow"
										className="group-label">
										Per tutti
									</IonButton>
								)}
							</div>
						</div>
					</IonItem>
				))}
			</IonList>
			<IonModal
				isOpen={isGroupFoldersOpen}
				onDidDismiss={() => setIsGroupFoldersOpen(false)}>
				<IonContent>
					<IonHeader>
						<IonToolbar>
							<IonTitle>
								<div
									className="ellipsis-title"
									ref={titleElementRef}>
									{typeof indexGroup === 'number' ? data[indexGroup]?.name : ''}
								</div>
							</IonTitle>
							<IonButtons slot="end">
								<IonButton onClick={() => setIsGroupFoldersOpen(false)}>
									Chiudi
								</IonButton>
							</IonButtons>
						</IonToolbar>
					</IonHeader>
					<IonList>
						{typeof indexGroup === 'number' &&
							data[indexGroup]?.child_groups.map((subGroup) => (
								<IonItem
									className="pointer"
									key={subGroup.id}
									onClick={() => {
										setIsGroupFoldersOpen(false)
										history.push(
											window.location.pathname + '/' + subGroup.id,
											'forward'
										)
									}}>
									{subGroup.name}
								</IonItem>
							))}
					</IonList>
				</IonContent>
			</IonModal>

			{/* <RoutesModal
				isOpen={isOpen}
				setIsOpen={setIsOpen}
			/> */}
			<SubscriptionAlert
				isOpen={isOpen}
				setIsOpen={setIsOpen}
			/>
		</>
	)
}

const MultimediaPage = () => {
	const { data, isLoading, error } = useSelector(
		(state) => state.multimediaGroups.getMultimediaGroupsList
	)
	const dispatch = useDispatch()

	useIonViewDidEnter(() => {
		// if (data !== null) return
		dispatch(getMultimediaGroupsList())
	})

	return (
		<>
			<IonPage className="multimedia-groups-page">
				<MainHeader
					page="MultimediaPage"
					title="Multimedia"
				/>

				<IonContent className="ion-padding">
					<IonRefresher
						slot="fixed"
						pullFactor={0.5}
						pullMin={100}
						pullMax={200}
						onIonRefresh={clearCache}>
						<IonRefresherContent></IonRefresherContent>
					</IonRefresher>
					<IonText color="primary">Cartelle disponibili</IonText>
					<h3>
						Esplora e impara attraverso i video educativi dei nostri esperti
					</h3>

					{data && !isLoading && !error && <DataRender data={data} />}
					{isLoading && !error && <LoadingRender />}
					{error && <ErrorRender />}
				</IonContent>

				<MainFooter />
			</IonPage>
		</>
	)
}

export default MultimediaPage
