import {
	IonButton,
	IonAlert,
	IonContent,
	IonFooter,
	IonHeader,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonPage,
	IonRefresher,
	IonRefresherContent,
	useIonToast,
	IonBadge,
	useIonViewDidEnter,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonToolbar,
} from '@ionic/react'
import MainHeader from '../../components/headers/mainHeader'
import MainFooter from '../../components/mainFooter'
import funnel from '../../assets/icons/funnel.svg'
import { sendSharp } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getMultimediaPosts, likeMultimediaPost } from '../../redux/actions'
import { PostMultimedia } from '../../components/MultimediaInPost'
import ErrorToast from '../../components/ErrorToast'
import { clearCache, processPostHTML } from '../../services/functions'
import { MultimediaCardSkeleton } from '../../components/skeletons/MultimediaCardSkeleton'
import { Icon } from '@iconify/react'

import './MultimediaGroup.min.css'
import PostFooter from '../../components/Post/PostFooter'

const LoadingRender = () => (
	<>
		<MultimediaCardSkeleton />
		<MultimediaCardSkeleton />
		<MultimediaCardSkeleton />
	</>
)

const MultimediaCard = ({ postGroup }) => {
	const dispatch = useDispatch()
	const { liked, likes_count, id: post_id } = postGroup
	const insidePost = postGroup.post
	const { title, content, tags } = insidePost

	const { telephone } = useSelector((state) => state.user.user)
	const [present] = useIonToast()

	const likeHandleClick = () => {
		if (!telephone)
			return present({
				message: 'Per interagire verifica il tuo cellulare nel tuo profilo',
				duration: 3000,
			})
		dispatch(likeMultimediaPost({ element_id: post_id }))
	}

	window.handleButtonLink = (e) => {
		const href = e.getAttribute('data-href')
		if (!href) return
		if (href.indexOf('.') > -1) {
			window.location.href = 'https://' + href
		} else {
			history.push('/' + e.getAttribute('data-href'))
		}
	}

	return (
		<div className="post-item enabled-true ion-margin-top">
			<PostMultimedia
				postGroup={postGroup}
				only_post={false}
			/>

			<div className="ion-padding">
				<div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
					{tags.map((tag) => (
						<IonBadge
							key={tag.id}
							style={{ fontSize: '0.9em', marginRight: '0.5rem' }}
							color="blue">
							<IonLabel className="capitalize-first-letter">
								{tag.name}
							</IonLabel>
						</IonBadge>
					))}
				</div>
				<h3 className="ion-margin-top">{title}</h3>
				<div
					className="post-description"
					style={{ fontSize: '1.2em' }}>
					{processPostHTML(content)}
				</div>
			</div>

			<PostFooter
				postGroup={postGroup}
				comments={false}
				reports={false}
				is_multimedia={true}
			/>
		</div>
	)
}

const FilerTags = ({ groupId, tagData, setTagData, searchValue }) => {
	const tags = useSelector(
		(state) => state.multimediaGroups.getMultimediaPosts.data?.tags
	)

	const tagSelected = tagData ? tags[tagData.index] : null

	const dispatch = useDispatch()
	return (
		<>
			<div
				style={{
					paddingInline: '2rem',
					paddingTop: '1rem',
					paddingBottom: '1rem',
					display: 'flex',
					justifyContent: 'center',
				}}>
				<IonButton
					id="alert_trigger_multimedia_group"
					strong={true}
					shape="round">
					<IonIcon
						slot="start"
						src={funnel}
					/>
					{tagSelected ? tagSelected.name : 'FILTRARE'}
				</IonButton>
			</div>
			<IonAlert
				header="Filtrare Tag"
				onWillDismiss={(e) => {
					const data = e.detail.data?.values
					const isConfirm = e.detail.role === 'confirm'
					if (isConfirm) {
						const functionParams = {
							group_id: groupId,
							...(searchValue && { search_title: searchValue }),
						}
						if (data.value === 'nessuno') {
							setTagData(null)
						} else {
							functionParams.tag_id = data.value
							setTagData(data)
						}
						dispatch(getMultimediaPosts(functionParams))
					}
				}}
				trigger="alert_trigger_multimedia_group"
				buttons={[
					{
						text: 'CANCELLA',
						role: 'cancel',
					},
					{
						text: 'OK',
						role: 'confirm',
					},
				]}
				inputs={
					tags
						? [
								{
									label: 'Nessuno',
									type: 'radio',
									value: { value: 'nessuno' },
								},
								...tags.map((tag, index) => {
									return {
										label: tag.name,
										type: 'radio',
										value: { value: tag.id, index },
									}
								}),
						  ]
						: []
				}
			/>
		</>
	)
}

const SearchFile = ({ groupId, tagData, searchValue, setSearchValue }) => {
	const dispatch = useDispatch()
	const handleOnIonInput = (e) => {
		const value = e.target.value
		if (value === '') {
			setSearchValue(null)
		} else {
			setSearchValue(e.target.value)
		}
	}

	const handleInputClick = () => {
		const functionParams = {
			group_id: groupId,
			...(tagData && { tag_id: tagData.value }),
			...(searchValue && { search_title: searchValue }),
		}
		dispatch(getMultimediaPosts(functionParams))
	}

	return (
		<IonFooter className="input-footer">
			<IonItem lines="none">
				<IonInput
					onIonInput={handleOnIonInput}
					value={searchValue}
					placeholder="Cerca un file"
				/>
				<IonButton
					fill="clear"
					shape="round"
					slot="end"
					color="medium"
					onClick={handleInputClick}>
					<IonIcon
						src={sendSharp}
						slot="icon-only"
					/>
				</IonButton>
			</IonItem>
		</IonFooter>
	)
}

const MultimediaGroupPage = ({ match }) => {
	const [page, setPage] = useState(1)
	const [allData, setAllData] = useState({ posts: [] })
	const tags = useSelector(
		(state) => state.multimediaGroups.getMultimediaPosts.data?.tags
	)
	const [tagData, setTagData] = useState(null)
	const [searchValue, setSearchValue] = useState(null)
	const { data, isLoading, error } = useSelector(
		(state) => state.multimediaGroups.getMultimediaPosts
	)

	const groupId = match.params.groupId

	const dispatch = useDispatch()

	useIonViewDidEnter(() => {
		loadData()
	})

	const loadData = (event = null) => {
		dispatch(getMultimediaPosts({ group_id: groupId, page })).then(() => {
			setPage(page + 1)
			event && event.target.complete()
		})
	}

	useEffect(() => {
		const posts = data?.posts
			? [...data.posts, ...allData.posts]
			: allData.posts
		setAllData({
			...data,
			posts: posts,
		})
	}, [data])

	const href = match.url.slice(0, -1 * (groupId.length + 1))

	const firstLoading = isLoading && page === 1
	console.log('allData', allData)
	return (
		<>
			<IonPage>
				<MainHeader
					page="MultimediaGroupPage"
					title={
						!firstLoading && data?.group?.name
							? data?.group?.name
							: 'Multimedia'
					}
					href={href}
				/>
				<IonToolbar style={{ zIndex: 0 }}>
					{tags && tags.length > 0 && (
						<IonHeader className="ion-no-border">
							<FilerTags
								groupId={groupId}
								searchValue={searchValue}
								tagData={tagData}
								setTagData={setTagData}
							/>
						</IonHeader>
					)}
				</IonToolbar>
				<IonContent id="multimedia-group-page">
					<IonRefresher
						slot="fixed"
						pullFactor={0.5}
						pullMin={100}
						pullMax={200}
						onIonRefresh={clearCache}>
						<IonRefresherContent></IonRefresherContent>
					</IonRefresher>
					{!error &&
						!(isLoading && page === 1) &&
						allData?.posts?.map((postGroup) => (
							<MultimediaCard
								key={postGroup.id}
								postGroup={postGroup}
							/>
						))}
					<IonInfiniteScroll
						onIonInfinite={(e) => loadData(e)}
						threshold="100px">
						<IonInfiniteScrollContent loadingText="Caricando altri posts..."></IonInfiniteScrollContent>
					</IonInfiniteScroll>
					{isLoading && !error && <LoadingRender />}
					{error && <ErrorToast />}
				</IonContent>
				<IonToolbar>
					<SearchFile
						groupId={groupId}
						tagData={tagData}
						searchValue={searchValue}
						setSearchValue={setSearchValue}
					/>
				</IonToolbar>
				<MainFooter />
			</IonPage>
		</>
	)
}

export default MultimediaGroupPage
