import {
	IonPage,
	IonInput,
	IonContent,
	IonText,
	IonButton,
	useIonLoading,
} from '@ionic/react'
import { useDispatch, useSelector } from 'react-redux'
import config from '../../../settings/config'
import { useEffect, useState } from 'react'

import '../InitialConfiguration.min.css'
import InitialConfigHeader from '../header'
import { setInitialConfigProperty } from '../../../redux/reducer/initialConfigSlice'
import { useHistory } from 'react-router'

import { setAdoc_p } from '../../../redux/reducer/userSlice'

const PhoneOtpPage = ({ step, totalSteps, setPageSelected }) => {
	const history = useHistory()
	const [tokenOtp, setTokenOtp] = useState('')
	const [present, dismiss] = useIonLoading()
	const initialConfig = useSelector((state) => state.initialConfig)
	const freeOrPaid = useSelector((state) => state.initialConfig.freeOrPaid)
	const telephone = useSelector((state) => state.initialConfig.telephone)
	const [isLoading, setIsLoading] = useState(false)
	const [timeToResend, setTimeToResend] = useState(30)
	const [error, setError] = useState('')
	const dispatch = useDispatch()
	const updateInput = (e) => {
		if (error !== '') setError('')
		setTokenOtp(e.target.value)
	}

	useEffect(() => {
		// updateUserInfo()
	}, [])

	useEffect(() => {
		if (timeToResend === 0) return
		const interval = setInterval(() => {
			setTimeToResend(timeToResend - 1)
		}, 1000)
		return () => clearInterval(interval)
	}, [timeToResend])

	const validateInputs = () => {
		let isValid = true

		if (tokenOtp === '') {
			setError('Devi inserire il codice inviato')
			isValid = false
		} else {
			if (!/^\d{4}$/.test(tokenOtp)) {
				setError('Il codice inserito non è valido')
				isValid = false
			}
		}

		return isValid
	}

	const handleSubmit = (e) => {
		setIsLoading(true)
		const areInputsValid = validateInputs()
		if (!areInputsValid) {
			setIsLoading(false)
			return
		}

		present({
			message: 'Verificando...',
		})

		const queryIsSubscriber = freeOrPaid === 'paid' ? '&isSubscriber=true' : ''
		fetch(
			`${
				config.env[config.env.mode].url
			}/api/master-club-otp/${telephone}?import=true${queryIsSubscriber}`,
			{
				method: 'PATCH',
				body: JSON.stringify({ token: tokenOtp }),
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${localStorage.getItem('adoc_t')}`,
				},
			}
		)
			.then((response) => {
				return response.json()
			})
			.then((data) => {
				if (data?.url) {
					setTimeout(() => {
						localStorage.removeItem('adoc_p')
						localStorage.removeItem('adoc_i')
						localStorage.removeItem('adoc_t')
						window.location = data.url
					}, 2000)
					dismiss().then(() => {
						present({
							message: 'Reindirizzando sul tuo account principale...',
						})
					})
					return
				}
				if (data.message !== 'Codice corretto') {
					setError(data.message)
					setIsLoading(false)
					dismiss()
					return
				}

				dismiss()
				history.push(`/impostazioni-iniziali/${step + 1}`)
				// console.log('REDIRECT TO HOME!!!!!!!!!')
				// history.push('/home', {
				// 	firstLogin: true,
				// 	showNotificationsModal: true,
				// })
			})
			.catch((e) => {
				setIsLoading(false)
				dismiss()
				if (e.message && e.message !== 'Unknown error') {
					setError(e.message)
				} else {
					setError('Si è verificato un problema, riprova più tardi.')
				}
			})
	}

	const handleResend = (e) => {
		setIsLoading(true)
		setTimeToResend(30)
		fetch(`${config.env[config.env.mode].url}/api/master-club-otp`, {
			method: 'POST',
			body: JSON.stringify({ telephone }),
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStorage.getItem('adoc_t')}`,
			},
		})
			.then((response) => {
				setIsLoading(false)
			})
			.catch((e) => {
				setIsLoading(false)
				if (e.message && e.message !== 'Unknown error') {
					setError(e.message)
				} else {
					setError('Si è verificato un problema, riprova più tardi.')
				}
			})
	}

	const updateUserInfo = async () => {
		present({
			message: 'Caricando informazioni...',
		})
		const backUrl = config.env[config.env.mode].url
		const headers = {
			Authorization: `Bearer ${localStorage.getItem('adoc_t')}`,
			'Content-Type': 'application/json',
			Accept: 'application/json',
		}
		const { name, surname, nickname, vegetarian, gluten_free, extras, sex } =
			initialConfig

		const body1 = JSON.stringify({
			name,
			surname,
		})

		const body2 = JSON.stringify({
			sex,
			vegetarian,
			gluten_free,
			extras: {
				goals: extras.goals,
			},
			...(nickname && { nickname }),
		})

		try {
			const response1 = await fetch(`${backUrl}/api/profilo-utenti`, {
				method: 'POST',
				body: body1,
				headers,
			})
			if (!response1.ok) throw new Error('Error in fetch1')
			const data1 = await response1.json()

			const response2 = await fetch(
				`${backUrl}/api/master-club-subscribers/1`,
				{
					method: 'PATCH',
					body: body2,
					headers,
				}
			)
			if (!response2.ok) throw new Error('Error in fetch2')
			const data2 = await response2.json()

			if (
				data1.message === 'Succefully updated' &&
				data2.message === 'Succefully updated'
			) {
				const response3 = await fetch(`${backUrl}/api/profilo-utenti/1/fetch`, {
					headers,
				})
				if (!response3.ok) throw new Error('Error in fetch3')
				const data3 = await response3.json()
				localStorage.setItem('adoc_p', JSON.stringify(data3))
				dispatch(setAdoc_p(data3))
				setIsLoading(false)
				setError('')
			} else {
				setIsLoading(false)
				setError('Si è verificato un problema, riprova più tardi.')
			}
			dismiss()
		} catch (e) {
			dismiss()
			setIsLoading(false)
			setError('Si è verificato un problema, riprova più tardi.')
		}
	}

	const handleSkip = (e) => {
		setIsLoading(true)
		dispatch(setInitialConfigProperty({ key: 'telephone', value: '' }))
		history.push(`/impostazioni-iniziali/${step + 1}`)
		// console.log('REDIRECT TO HOME!!!!!!!!!')
		// history.push('/home', {
		// 	firstLogin: true,
		// 	showNotificationsModal: true,
		// })
	}

	return (
		<IonPage className="initial-configuration">
			<InitialConfigHeader
				step={step}
				totalSteps={totalSteps}
				isLoading={isLoading}
				setPageSelected={setPageSelected}
				pagesBack={1}
			/>

			<IonContent>
				<h1 style={{ fontWeight: 'bold', color: '#222428' }}>
					Inserisci il codice inviato tramite SMS
				</h1>
				<h6>Questo renderà più sicuro il tuo account</h6>

				<IonInput
					autoFocus={true}
					type="number"
					inputmode="numeric"
					label={'Codice di sicurezza inviato al ' + telephone}
					placeholder="XXXX"
					name="PhoneNumber"
					color="primary"
					labelPlacement="stacked"
					fill="solid"
					value={tokenOtp}
					onInput={updateInput}
					maxlength={4}
				/>
				<IonText className="error">{error}</IonText>
				<div className="buttons">
					{/* {error && (
						<IonButton
							shape="round"
							onClick={handleSkip}
							disabled={isLoading}
							color="light-gray"
							expand="full">
							<IonText color="white">Salta verifica</IonText>
						</IonButton>
					)} */}
					<IonButton
						shape="round"
						onClick={handleResend}
						disabled={timeToResend > 0 || isLoading}
						color="light-gray"
						expand="full">
						<IonText color="white">
							Reinviare {timeToResend > 0 && 'in ' + timeToResend}
						</IonText>
					</IonButton>
					<IonButton
						shape="round"
						disabled={isLoading}
						onClick={handleSubmit}
						color="primary"
						expand="full">
						Avanti
					</IonButton>
				</div>
			</IonContent>
		</IonPage>
	)
}

export default PhoneOtpPage
