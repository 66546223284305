import {
	IonButton,
	IonButtons,
	IonHeader,
	IonTitle,
	IonToolbar,
	IonMenuButton,
	IonIcon,
	IonBadge,
	IonAvatar,
	useIonModal,
	IonBackButton,
} from '@ionic/react'
import { arrowBack, notificationsOutline } from 'ionicons/icons'
import { useSelector } from 'react-redux'
import UserModal from '../../modals/UserModal'
import avatarDefault from '../../../assets/avatars/avatarDefault.png'

import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import avatars from '../../../assets/avatars'
import { checkOverflow } from '../../../services/functions'
import { enterAnimation, leaveAnimation } from '../../../services/animations'
import { useHistory } from 'react-router'
import config from '../../../settings/config'
import dietaSocialLogo from '../../../assets/images/ds-logo.svg'
import dietaSocialLogoSandbox from '../../../assets/images/ds-logo-sandbox.svg'
import { Icon } from '@iconify/react/dist/iconify.js'

const MainHeader = ({
	title,
	href,
	forceHref = false,
	hrefParams = {},
	CustomComponent,
	appNotificationsEnabled = null,
}) => {
	const logo =
		config.env.mode !== 'prod' ? dietaSocialLogoSandbox : dietaSocialLogo

	const user = useSelector((state) => state.user?.user)
	const avatar = useSelector((state) => state.user?.user?.avatar)
	const history = useHistory()

	const storage_path = useSelector((state) => state.user?.user?.storage_path)

	const avatarImage = avatar?.startsWith('avatar')
		? avatars.avatarsByNumber[avatar]
		: `${storage_path}${avatar}`

	const subscription =
		useSelector((state) => state.user?.master_club_subscriber?.subscription) ??
		null

	const unreadNotificationsList = useSelector(
		(state) => state.notifications.getNotificationListUnread
	)

	useEffect(() => {
		if (!user) return
		if (localStorage.getItem('adoc_app_n') === null)
			localStorage.setItem('adoc_app_n', true)
	}, [])

	const titleElementRef = useRef(null)

	useLayoutEffect(() => {
		setTimeout(() => {
			checkOverflow(titleElementRef)
		}, 5000)

		window.addEventListener('resize', checkOverflow(titleElementRef))

		return () => {
			window.removeEventListener('resize', checkOverflow(titleElementRef))
		}
	}, [])

	const [present, dismiss] = useIonModal(UserModal, {
		history: useHistory(),
		onDismiss: () => dismiss(),
	})

	const openModal = () => {
		present({
			component: UserModal,
			enterAnimation,
			leaveAnimation,
			id: 'user-modal',
		})
	}

	const disabledByPremium =
		subscription?.subscription_type?.name === 'premium' &&
		JSON.parse(localStorage.getItem('adoc_app_n')) === false

	const [showAppNotifications, setShowAppNotifications] = useState(
		!disabledByPremium
	)

	useEffect(() => {
		if (appNotificationsEnabled !== null)
			setShowAppNotifications(appNotificationsEnabled)
	}, [appNotificationsEnabled])

	return (
		<>
			<IonHeader id="main-header">
				<IonToolbar>
					<IonButtons slot="start">
						{href ? (
							forceHref ? (
								<IonButton onClick={() => history.push(href, hrefParams)}>
									<IonIcon
										icon={arrowBack}
										className="pointer"
										style={{ fontSize: '1.5rem' }}
										size="medium"
									/>
								</IonButton>
							) : (
								<IonBackButton
									defaultHref={href}
									text=""
								/>
							)
						) : (
							<IonMenuButton
								color="dark"
								autoHide={false}
							/>
						)}
					</IonButtons>
					<IonButtons slot="start">
						<img
							style={{
								height: '48px',
								marginLeft: '10px',
							}}
							src={logo}
							alt="Dieta Social Logo"
						/>
					</IonButtons>
					<IonButtons slot="end">
						<IonButton
							color="dark"
							fill="clear"
							style={{ width: '60px' }}
							onClick={() => {
								history.push('/gruppi')
							}}>
							<span
								style={{
									marginTop: '0px',
									fontSize: '36px',
									color: 'var(--ion-color-primary)',
								}}>
								<Icon
									icon={
										location.pathname.includes('/gruppi')
											? 'ph:users-three-fill'
											: 'ph:users-three'
									}
								/>
							</span>
							<span className="capitalize-first-letter icon-text">Gruppi</span>
						</IonButton>
						{showAppNotifications && (
							<IonButton
								color="dark"
								fill="clear"
								style={{ width: '60px' }}
								onClick={() => {
									history.push('/notifiche')
								}}>
								<IonIcon
									icon={notificationsOutline}
									size="large"
								/>
								<span className="capitalize-first-letter icon-text">
									Notifiche
								</span>
								{unreadNotificationsList.quantity !== 0 && (
									<IonBadge
										color="danger"
										className="notification-badge">
										<div className="notification-quantity">
											{unreadNotificationsList.quantity}
										</div>
									</IonBadge>
								)}
							</IonButton>
						)}
						<IonButton
							fill="clear"
							id="open-user-modal"
							onClick={() => openModal()}
							expand="block">
							<IonAvatar>
								<img src={avatar ? avatarImage : avatarDefault} />
								{/* <ImageLoader
									imageType="user"
									css="subscription-image"
									image={avatar ? avatarImage : avatarDefault}
								/> */}
							</IonAvatar>
							<div
								className={
									'header-avatar-label ' +
									(subscription?.subscription_type?.color ?? 'light')
								}>
								{(
									subscription?.subscription_type?.name ?? 'Guest'
								).toUpperCase()}
							</div>
						</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
		</>
	)
}

export default MainHeader
