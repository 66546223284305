import { useDispatch, useSelector } from 'react-redux'
import { rotateImage, todayFullStringDate } from '../../services/functions'
import { useRef, useEffect, useState } from 'react'
import {
	IonButton,
	IonFooter,
	IonIcon,
	IonItem,
	IonTextarea,
	IonToast,
	useIonLoading,
	useIonToast,
} from '@ionic/react'
import { image, sendSharp } from 'ionicons/icons'
import {
	commentPost,
	getPost,
	headersDefault,
	replyComment,
} from '../../redux/actions'
import config from '../../settings/config'
import EXIF from 'exif-js'
import { useHistory } from 'react-router'

let headers = {}

export const InsertComment = ({
	element_id,
	element_type,
	contentRef,
	setComments,
}) => {
	const fullUser = useSelector((state) => state.user.user)
	const user = {
		name: fullUser.name,
		surname: fullUser.surname,
		avatar: fullUser.avatar,
		id: fullUser.id,
		fullname: fullUser.fullname ?? '',
	}
	const inputValue = useRef(null)
	const dispatch = useDispatch()
	const { telephone } = useSelector((state) => state.user.user)
	const [ionToast] = useIonToast()
	const history = useHistory()

	const handleClickSendCommentButton = () => {
		if (!telephone)
			return ionToast({
				message: 'Per interagire verifica il tuo cellulare nel tuo profilo',
				duration: 3000,
			})
		const content = inputValue.current.value.trim()
		const image = document.getElementById('comment-upload-image').files[0]
		if (content === '' && image === undefined) return
		uploadImage()
			.then((image) => {
				const values = {
					content,
					element_id,
					user,
					image,
					created_at: todayFullStringDate(),
				}

				element_type === 'comment'
					? dispatch(replyComment(values))
					: dispatch(commentPost(values)).then(() => {
							dispatch(getPost({ postId: element_id, page: 1 })).then(
								(data) => {
									setComments((prevComments) => [
										...data.payload.comments.data,
										...prevComments,
									])
								}
							)
					  })

				inputValue.current.value = ''
				deleteLoadedImage()
				// Scroll to the top using the contentRef prop
				if (contentRef && contentRef.current) {
					contentRef.current.scrollToTop(500) // Adjust the duration as needed
				}
			})
			.catch((e) => {
				setToastIsOpen(e)
			})
	}

	const handleKeyUp = (e) => {
		if (e.ctrlKey && e.key === 'Enter') {
			handleClickSendCommentButton()
		}
	}

	const maxSizeBites = 8388800
	const [present, dismiss] = useIonLoading()

	const defaultToast = {
		message: "Ops! C'è stato un errore. Riprova più tardi.",
		open: false,
	}

	const [toast, setToast] = useState(defaultToast)

	const setToastIsOpen = (message = defaultToast.message) => {
		setToast({
			message,
			open: true,
		})
	}

	useEffect(() => {
		headers = headersDefault()
	}, [])

	const uploadImage = () => {
		return new Promise((resolve, reject) => {
			const image = document.getElementById('comment-upload-image').files[0]
			if (!loadedImage || image === undefined) resolve(null)

			if (image.size > maxSizeBites) {
				const e = new Error('Immagine troppo grande. Puoi caricare fino 8mb')
				reject(e)
			}

			present({
				message: 'Caricando immagine...',
			})

			const body = new FormData()

			body.append('master-club-comment', image)

			fetch(
				`${
					config.env[config.env.mode].url
				}/api/file-upload/master-club-comment`,
				{
					body,
					method: 'POST',
					headers: {
						Authorization: `Bearer ${localStorage.getItem('adoc_t')}`,
					},
				}
			)
				.then((response) => {
					if (!response.ok) throw new Error('fetch error')
					return response.json()
				})
				.then((data) => {
					resolve(data.filename)
					dismiss()
				})
				.catch((e) => {
					setToastIsOpen(true)
					dismiss()
					reject(e) // Reject with the error
				})
		})
	}

	const [loadedImage, setLoadedImage] = useState(null)
	const selectedImage = (e) => {
		const file = e.target.files[0]

		if (file) {
			if (file.size > maxSizeBites) {
				deleteLoadedImage()
				setToastIsOpen('Immagine troppo grande. Puoi caricare fino 8mb')
				return
			}

			const reader = new FileReader()
			reader.onload = function (e) {
				const image = new Image()
				image.src = e.target.result
				image.onload = function () {
					EXIF.getData(image, function () {
						const orientation = EXIF.getTag(this, 'Orientation')
						console.log('orientation: ', orientation)
						const rotatedImage = rotateImage(image, orientation)
						setLoadedImage(rotatedImage.src)
					})
				}
			}
			reader.readAsDataURL(file)
		}
	}

	const deleteLoadedImage = () => {
		setLoadedImage(null)
		document.getElementById('comment-upload-image').value = ''
	}

	return (
		<>
			<IonFooter className="ion-padding-vertical input-footer">
				<IonItem lines="none">
					<IonButton
						fill="clear"
						shape="round"
						color="medium"
						onClick={() =>
							document.getElementById('comment-upload-image').click()
						}>
						<IonIcon
							src={image}
							slot="icon-only"
						/>
					</IonButton>
					<input
						id="comment-upload-image"
						onChange={selectedImage}
						className="d-false"
						accept="image/png,image/jpeg"
						type="file"
					/>
					<IonTextarea
						ref={inputValue}
						className="comment-input"
						placeholder="Inserisci un commento"
						autoGrow={true}
						onKeyUp={handleKeyUp}></IonTextarea>
					<IonButton
						fill="clear"
						shape="round"
						color="medium"
						onClick={handleClickSendCommentButton}>
						<IonIcon
							src={sendSharp}
							slot="icon-only"
						/>
					</IonButton>
				</IonItem>
				{loadedImage && (
					<div
						className="comment-image-preview"
						onClick={deleteLoadedImage}>
						<img
							src={loadedImage}
							alt=""
						/>
						<span>Elimina</span>
					</div>
				)}
			</IonFooter>
			<IonToast
				isOpen={toast.open}
				message={toast.message}
				duration={3000}
			/>
		</>
	)
}
