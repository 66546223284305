import React from 'react'
import { IonButton, IonModal, IonText, useIonToast } from '@ionic/react'
import { baseUrl, headersDefault } from '../../../redux/actions'
import { useSelector } from 'react-redux'
import { Icon } from '@iconify/react'

const ReportModal = ({ setIsOpen, isOpen, element_id, type, setReported }) => {
	const { telephone } = useSelector((state) => state.user.user)
	const [ionToast] = useIonToast()
	const reportComment = () => {
		if (!telephone)
			return ionToast({
				message: 'Per interagire verifica il tuo cellulare nel tuo profilo',
				duration: 3000,
			})
		setReported(true)
		const url = `${baseUrl}/api/master-club-reports`
		const headers = headersDefault()

		const queryParams = new URLSearchParams({
			element_type:
				type === 'comment'
					? 'App\\Models\\MasterClubComment'
					: 'App\\Models\\MasterClubPostGroup',
			element_id,
		})

		fetch(`${url}?${queryParams}`, {
			headers,
			method: 'POST',
		})
			.finally(() => {
				setIsOpen(false)
			})
			.catch((err) => {
				console.log(err)
				setReported(false)
			})
	}
	return (
		<IonModal
			className="modal-as-alert"
			isOpen={isOpen}
			onDidDismiss={() => setIsOpen(false)}>
			<div style={{ padding: '1.5rem' }}>
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<Icon
						icon="line-md:bell-twotone-alert-loop"
						style={{
							width: '200px',
							height: '200px',
							color: '#ffc107',
						}}
					/>
				</div>
				<div
					style={{
						fontSize: '20px',
						fontWeight: 'var(--font-size-black)',
						marginTop: '2rem',
						marginBottom: '1rem',
					}}>
					Segnalare contenuto
				</div>
				<IonText color="medium">
					<div style={{ fontSize: '16px', marginTop: '2rem' }}>
						{
							'Crediamo che la piattaforma debba essere un luogo di rispetto. Ecco perché, se trovi un contenuto offensivo, puoi segnalarlo. Il tuo feedback ci aiuterà a mantenere la piattaforma un luogo sicuro e inclusivo per tutti.'
						}
					</div>
				</IonText>
			</div>
			<div style={{ paddingInline: '1.5rem', paddingBottom: '0.5rem' }}>
				<IonButton
					onClick={reportComment}
					color="blue"
					expand="block"
					fill="clear"
					strong={true}>
					SEGNALA
				</IonButton>
			</div>
		</IonModal>
	)
}

export default ReportModal
