import { IonPage, IonContent, IonText, IonButton } from '@ionic/react'
import { setInitialConfigProperty } from '../../../redux/reducer/initialConfigSlice'
import { useDispatch } from 'react-redux'
import { useState } from 'react'
import '../InitialConfiguration.min.css'
import InitialConfigHeader from '../header'

const maxSizeBites = 8388800

const UploadAnImagePage = ({ step, setPageSelected, totalSteps }) => {
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState('')
	const dispatch = useDispatch()

	const updateInput = (e) => {
		setIsLoading(true)
		let isValid = true
		if (e.target.files[0] === undefined) {
			if (error !== '') setError('')
			isValid = false
		} else if (e.target.files.length > 1) {
			setError('Devi caricare un solo file')
			isValid = false
		} else if (e.target.files[0].size > maxSizeBites) {
			setError('Il file supera la dimensione massima di 1 MB')
			isValid = false
		} else if (error !== '') setError('')

		if (isValid) {
			const reader = new FileReader()
			reader.onloadend = () => {
				const dataURL = reader.result
				dispatch(
					setInitialConfigProperty({ key: 'avatarFile', value: dataURL })
				)
			}
			reader.readAsDataURL(e.target.files[0])
		} else {
			dispatch(setInitialConfigProperty({ key: 'avatarFile', value: null }))
		}

		if (isValid) setPageSelected((oldPage) => oldPage + 1)
		setIsLoading(false)
	}

	return (
		<IonPage className="initial-configuration">
			<InitialConfigHeader
				step={step}
				totalSteps={totalSteps}
				isLoading={isLoading}
				setPageSelected={setPageSelected}
				pagesBack={1}
			/>

			<IonContent>
				<h1>Vuoi inserire ora la tua foto?</h1>
				<h6>
					Deciderai in un secondo momento se la vorrai rendere visibile agli
					altri
				</h6>

				<input
					id="image_uploads"
					onChange={updateInput}
					accept="image/png,image/jpeg"
					type="file"
					className="d-false"
				/>
				<IonText className="error">{error}</IonText>

				<div className="buttons">
					<IonButton
						color="blue"
						shape="round"
						onClick={() => document.getElementById('image_uploads').click()}
						disabled={isLoading}
						expand="full">
						SELEZIONA IL FILE
					</IonButton>
				</div>
			</IonContent>
		</IonPage>
	)
}

export default UploadAnImagePage
