import {
	IonContent,
	IonPage,
	IonRefresher,
	IonRefresherContent,
} from '@ionic/react'
import MainFooter from '../../../components/mainFooter'
import MainHeader from '../../../components/headers/mainHeader'
import Recipe from '../../../components/Recipe'
import { clearCache } from '../../../services/functions'

const RecipeMenuPage = ({ match }) => {
	const { recipeId, cycleId } = match.params
	const href = match.url.slice(0, -1 * (recipeId.length + 1))

	return (
		<IonPage>
			<MainHeader
				page="RecipePage"
				href={href}
				title="Ricetta"
			/>
			<IonContent>
				<IonRefresher
					slot="fixed"
					pullFactor={0.5}
					pullMin={100}
					pullMax={200}
					onIonRefresh={clearCache}>
					<IonRefresherContent></IonRefresherContent>
				</IonRefresher>
				<Recipe
					recipeId={recipeId}
					cycleId={cycleId}
				/>
			</IonContent>

			<MainFooter />
		</IonPage>
	)
}

export default RecipeMenuPage
