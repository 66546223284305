import {
	IonAlert,
	IonButton,
	IonIcon,
	IonItem,
	IonText,
	useIonToast,
} from '@ionic/react'
import { likeComment, likePost, publishPostGroup } from '../../../redux/actions'
import { Icon } from '@iconify/react'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { setComment, setPost } from '../../../redux/reducer/groupsSlice'
import { chatboxEllipses, warning } from 'ionicons/icons'
import { useEffect, useState } from 'react'
import ReportModal from '../../modals/ReportModal'
import { format } from 'timeago.js'

export default function PostFooter({
	match,
	postGroup = null,
	comment = null,
	is_reply,
	from_main_group = true,
	is_preview = false,
	only_post = false,
	classes = '',
	comments = true,
	likes = true,
	reports = true,
	date = true,
	is_multimedia = false,
}) {
	const history = useHistory()
	const dispatch = useDispatch()
	const [present] = useIonToast()
	const [reportIsOpen, setReportIsOpen] = useState(false)
	const [deniedReasonIsOpen, setDeniedReasonIsOpen] = useState(false)

	const user = useSelector((state) => state.user)
	const userId = user.id
	const telephone = user.user.telephone
	const currentUserSubscriber = user?.master_club_subscriber

	const element = postGroup || comment
	const element_id = element.id
	const comment_count = element.comments_count

	const is_enabled = postGroup?.status?.name === 'Pubblicato'
	const post_id = postGroup ? element.post.id : null
	const post_group_id = postGroup ? element.id : null

	const replies_count = element.replies_count

	const updatedAt = postGroup ? element.post.updated_at : element.updated_at
	const createdBy = postGroup
		? element.post.created_by_user_id
		: element.created_by_user_id
	const timeAgo = format(updatedAt, 'itLocale')
	const [reported, setReported] = useState(element.reported)
	const [liked, setLiked] = useState(element.liked)
	const [likesCount, setLikesCount] = useState(element.likes_count || 0)

	const commentId = match?.params?.commentId

	const commentHandleClick = () => {
		if (postGroup) {
			if (!is_preview) return
			const group_id = postGroup?.group_id
			dispatch(setPost({ post_group: { ...postGroup, from_main_group } }))
			history.push(`/gruppi/${group_id}/${element_id}`)
		}
		if (comment) {
			// if (!commentId) return
			dispatch(setComment(comment))
			history.push(window.location.pathname + '/' + element_id, 'forward')
		}
	}

	const likeResponse = (res) => {
		if (res) {
			setLiked(!liked)
			setLikesCount(liked ? likesCount - 1 : likesCount + 1)
		}
	}

	const likeHandleClick = () => {
		if (userId === element.created_by.id) return
		if (!telephone)
			return present({
				message: 'Per interagire verifica il tuo cellulare nel tuo profilo',
				duration: 3000,
			})

		if (postGroup) {
			dispatch(
				likePost({
					element_id,
					is_fixed: postGroup.post.is_fixed,
					from_main_group,
					only_post,
				})
			).then((res) => {
				likeResponse(res)
			})
		}

		if (comment) {
			dispatch(likeComment({ element_id: comment.id, is_reply })).then(
				(res) => {
					likeResponse(res)
				}
			)
		}
	}
	const handlePostStatus = (status_id, denied_reason = null) => {
		dispatch(
			publishPostGroup({
				post_group_id,
				status_id,
				denied_reason,
			})
		).then((res) => {
			if (res.payload.status === 200) {
				return present({
					message: status_id === 2 ? 'Post pubblicato' : 'Post rifiutato',
					duration: 3000,
				}).then(() => {
					location.reload()
				})
			}
		})
	}

	const handleDeniedReason = (e) => {
		const denied_reason = e.message
		if (denied_reason) {
			handlePostStatus(3, denied_reason)
		}
	}

	const isCommentDisabled =
		!!(postGroup && !is_preview) || !!(comment && commentId)
	const showComment =
		(comments && postGroup && is_preview) || (comment && !is_reply)
	return (
		<>
			{postGroup && !is_enabled && currentUserSubscriber?.badge && (
				<>
					<IonButton
						className="force-enabled ion-margin-horizontal"
						onClick={() => handlePostStatus(2)}>
						Pubblicare
					</IonButton>
					<IonButton
						className="force-enabled ion-margin-horizontal"
						color="danger"
						onClick={() => setDeniedReasonIsOpen(true)}>
						Rifiutare
					</IonButton>
					<IonAlert
						header="Motivo del rifiuto"
						className="report-problem-alert"
						isOpen={deniedReasonIsOpen}
						buttons={[
							{
								text: 'Annulla',
								role: 'cancel',
							},
							{
								text: 'Invia',
								role: 'confirm',
								handler: (e) => {
									handleDeniedReason(e)
								},
							},
						]}
						inputs={[
							{
								name: 'message',
								id: 'denied-post-reason',
								type: 'textarea',
								placeholder: 'Scrivi un messaggio per l’utente',
							},
						]}></IonAlert>
				</>
			)}
			<IonItem
				lines="none"
				className={'post-footer ' + classes}>
				<div
					className="post-time-ago"
					slot="start">
					{date && <IonText color="gray">{timeAgo}</IonText>}
				</div>
				<div
					slot="end"
					className="right-buttons">
					{showComment && (
						<div
							className={
								'comment-button' + (isCommentDisabled ? ' disable-click' : '')
							}
							onClick={() => commentHandleClick()}>
							<IonIcon
								src={chatboxEllipses}
								color="light-gray"
								slot="icon-only"
							/>
							<IonText
								color="gray"
								count={comment_count ?? replies_count}>
								{comment_count ?? replies_count}
							</IonText>
						</div>
					)}
					{likes && (
						<div className={'like-button liked-' + liked}>
							<Icon
								icon={is_multimedia ? 'bxs:star' : 'mdi:heart'}
								width="24"
								onClick={likeHandleClick}
							/>
							{!is_multimedia && (
								<IonText
									color="gray"
									count={likesCount}>
									{likesCount}
								</IonText>
							)}
						</div>
					)}
					{reports && userId !== element.created_by.id && (
						<div
							className={'reported-button reported-' + reported}
							onClick={() => {
								if (!reported) setReportIsOpen(true)
							}}>
							<IonIcon src={warning} />
						</div>
					)}
				</div>
				{userId !== element.created_by.id && (
					<ReportModal
						type={postGroup ? 'post' : 'comment'}
						element_id={element_id}
						isOpen={reportIsOpen}
						setIsOpen={setReportIsOpen}
						setReported={setReported}
					/>
				)}
			</IonItem>
		</>
	)
}
