import { createAsyncThunk } from '@reduxjs/toolkit'
import { baseUrl, headersDefault } from '.'
import axios from 'axios'

export const getNotificationList = createAsyncThunk(
	'notification/getNotificationList',
	async (page = 1) => {
		const params = {
			filter: 'all',
			fullLayout: true,
			app: true,
			page,
		}

		const response = await axios.get(`${baseUrl}/api/centro-notifiche/list`, {
			headers: headersDefault(),
			params,
		})

		if (response.status !== 200) {
			const errorObject = { status: response.status, message: response.message }
			throw errorObject
			// throw new Error((response.message || (await response.json()).error) ?? 'GenericError')
		}

		return response.data
	}
)

export const getNotificationListUnread = createAsyncThunk(
	'notification/getNotificationListUnread',
	async () => {
		const params = {
			filter: 'unread',
			fullLayout: false,
			app: true,
		}
		const response = await axios.get(`${baseUrl}/api/centro-notifiche/list`, {
			headers: headersDefault(),
			params,
		})

		if (response.status !== 200) {
			const errorObject = { status: response.status, message: response.message }
			throw errorObject
		}

		return response.data
	}
)

export const notificationReading = createAsyncThunk(
	'notification/notificationReading',
	async (notification) => {
		const params = {
			app: true,
		}

		const response = await axios.get(
			`${baseUrl}/api/centro-notifiche/${notification.id}/fetch`,
			{
				headers: headersDefault(),
				params,
			}
		)

		if (response.status !== 200) {
			const errorObject = { status: response.status, message: response.message }
			throw errorObject
		}
	}
)

export const incrementUnreadQuantity = createAsyncThunk(
	'notifications/incrementUnreadQuantity',
	async (_, { getState }) => {
		const currentState = getState()
		const currentQuantity =
			currentState.notifications.getNotificationListUnread.quantity
		const newQuantity = currentQuantity + 1

		return newQuantity
	}
)
