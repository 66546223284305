import { createSlice } from '@reduxjs/toolkit'
import { getDietPlan, getDatesToDisable } from '../actions'
import utc from 'dayjs/plugin/utc'
import dayjs from 'dayjs'

dayjs.extend(utc)

const today = dayjs().utc(true).format('YYYY-MM-DD')

const todayLessAWeek = dayjs().utc(true).subtract(7, 'day').format('YYYY-MM-DD')
const todayPlusAWeek = dayjs().utc(true).add(7, 'day').format('YYYY-MM-DD')
const defaultDisabled = []
let day = todayLessAWeek
while (day <= todayPlusAWeek) {
	defaultDisabled.push(day)
	day = dayjs(day).add(1, 'day').format('YYYY-MM-DD')
}

const initialState = {
	dietPlan: null,
	isLoading: false,
	error: false,
	dates: [null, null],
	getDatesToDisable: {
		isLoading: false,
		error: false,
		daysLimit: [today, today],
		disableDates: defaultDisabled,
		routes: [],
	},
}

const dietPlanSlice = createSlice({
	name: 'dietPlan',
	initialState,
	reducers: {
		resetGetDietPlanError: (state) => {
			state.error = false
		},
		resetGetDatesToDisable: (state) => {
			state.getDatesToDisable.error = false
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getDietPlan.pending, (state) => {
			state.isLoading = true
			state.error = false
			state.dates = initialState.dates
			state.dietPlan = initialState.dietPlan
			state.shoppingList = initialState.shoppingList
			state.foods = initialState.foods
		})
		builder.addCase(getDietPlan.fulfilled, (state, action) => {
			state.isLoading = false
			state.error = false
			state.dates = action.payload.dates
			state.dietPlan = action.payload.dietPlan
			state.shoppingList = action.payload.shoppingList
			state.foods = action.payload.foods
		})
		builder.addCase(getDietPlan.rejected, (state, action) => {
			state.isLoading = false
			state.error = action.error.message
		})
		builder.addCase(getDatesToDisable.pending, (state) => {
			state.getDatesToDisable.isLoading = true
			state.getDatesToDisable.error = false
		})
		builder.addCase(getDatesToDisable.fulfilled, (state, action) => {
			state.getDatesToDisable.isLoading = false
			state.getDatesToDisable.error = false
			state.getDatesToDisable.daysLimit = action.payload.daysLimit
			state.getDatesToDisable.disableDates = action.payload.disableDates
			state.getDatesToDisable.routes = action.payload.routes
		})
		builder.addCase(getDatesToDisable.rejected, (state, action) => {
			state.getDatesToDisable.isLoading = false
			state.getDatesToDisable.error = action.error.message
		})
	},
})

export const { resetGetDietPlanError, resetGetDatesToDisable } =
	dietPlanSlice.actions
export default dietPlanSlice.reducer
