import { IonToast } from '@ionic/react'
import { useEffect, useState } from 'react'

const messageDefault = "Ops! C'è stato un errore. Riprova più tardi."

const ErrorToast = ({
	message = messageDefault,
	duration = 5000,
	...otherProps
}) => {
	const [isOpen, setIsOpen] = useState(false)

	useEffect(() => {
		setTimeout(() => setIsOpen(true), 200)
	}, [])

	return (
		<IonToast
			{...otherProps}
			message={message}
			duration={duration}
			isOpen={isOpen}
			onDidDismiss={() => setIsOpen(false)}
		/>
	)
}

export default ErrorToast
