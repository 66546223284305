import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { Redirect, Switch, useHistory, useLocation } from 'react-router'
import config from './settings/config'

import './pages/InitialConfiguration/InitialConfiguration.min.css'

import PreLoginPage from './pages/PreLogin'
import HomePage from './pages/home'
import LoginPage from './pages/Login'
import steps from './pages/InitialConfiguration'
import GetTokenPage from './pages/Login/getToken'
import GetTokenAdminPage from './pages/Login/getToken/GetTokenAdmin'
import GroupsPage from './pages/Groups'
import GroupPage from './pages/Groups/Group'
import MainMenu from './components/mainMenu'
import LogoutRoute from './components/Logout'
import MultimediaPage from './pages/Multimedia'
import MultimediaGroupPage from './pages/MultimediaGroup'
import NotificationsPage from './pages/Notifications'
import NotificationsConfigPage from './pages/NotificationsConfig'
import DiaryPage from './pages/Diary'
import DiaryMeasurePage from './pages/DiaryMeasure'
import RecipesPage from './pages/Recipes'
import RecipePage from './pages/Recipes/Recipe'
import MenuPage from './pages/Menu'
import DayMenuPage from './pages/Menu/day'
import RecipeMenuPage from './pages/Menu/RecipeMenu'
import PostPage from './pages/Post'
import CommentPage from './pages/Comment'
import ProgressPage from './pages/Progress'
import ProfilePage from './pages/Profile'
import ProductsPage from './pages/Products'
import PaymentsPage from './pages/Payments'

import RedirectToHome from './components/RedirectToHome'
import InstallApplicationModal from './components/modals/InstallApplicationModal'
import AddPostPage from './pages/Post/AddPost'
import ErrorBoundary from './pages/ErrorBoundary'

import NotFoundPage from './pages/404'
import PaymentSuccessPage from './pages/Payments/PaymentSuccessPage'
import PaymentProcessingPage from './pages/Payments/PaymentProcessingPage'
import PaymentMethodProcessingPage from './pages/Payments/PaymentMethodProcessingPage'
import ScreenlockPage from './pages/Screenlock'
import StartFreeRoutePage from './pages/StartFreeRoute'
import WhatIsPage from './pages/Help/WhatIs'
import HowToUsePage from './pages/Help/HowToUse'
import HowTrialWorksPage from './pages/Help/HowTrialWorks'
import BlockedNotifications from './components/modals/NotificationsModal/BlockedNotifications'
import { RouteAuth } from './services/RouteAuth'
// import ActivateNotifications from './components/modals/NotificationsModal/ActivateNotifications'
import LessonPage from './pages/Course/Lesson'
import CoursePage from './pages/Course'
import { useDispatch } from 'react-redux'
import { incrementUnreadQuantity } from './redux/actions'

import { useSocket } from './services/customHooks/socketHook.js'
import FirstLoad from './components/FirstLoad/index.js'

const App = () => {
	setupIonicReact({
		swipeGesture: false,
		swipeBackEnabled: false,
		// mode: 'ios',
	})

	const history = useHistory()
	const dispatch = useDispatch()

	const notificationCreated = (notification) => {
		console.log('notificationCreated', notification)
		dispatch(incrementUnreadQuantity())
	}

	useSocket({
		type: 'NOTIFICATION_CREATED',
		callBack: (payload) => {
			notificationCreated(payload)
		},
	})

	useEffect(() => {
		if (config.env.mode !== 'local') {
			//Make mandatory to do all steps at once
			if (!location.pathname.startsWith('/impostazioni-iniziali/')) return
			if (location.pathname === '/impostazioni-iniziali/1') return

			if (!history) {
				location.href = '/impostazioni-iniziali/1'
			}
		}
	}, [history])

	return (
		<IonApp>
			<ErrorBoundary>
				<IonReactRouter>
					<FirstLoad />
					<RedirectToHome />
					{/* <ClearData /> */}
					<InstallApplicationModal />
					<MainMenu />
					<Switch>
						<IonRouterOutlet
							id="main-content"
							swipe-gesture={false}>
							<RouteAuth
								path="/"
								exact={true}
								component={PreLoginPage}
							/>
							<RouteAuth
								path="/o/:origin"
								exact={true}
								component={PreLoginPage}
							/>
							<RouteAuth
								path="/login"
								component={LoginPage}
								exact={true}
							/>
							<Route
								path="/login/:token"
								component={GetTokenPage}
								exact={true}
							/>
							<Route
								path="/impersonate/:token"
								component={GetTokenAdminPage}
								exact={true}
							/>

							{steps.map((DynamicComponent, index) => {
								const step = index + 1
								return (
									<RouteAuth
										key={location.key + step}
										path={'/impostazioni-iniziali/' + step}
										exact={true}
										component={() => (
											<DynamicComponent
												step={step}
												totalSteps={7 /*step <= 6 ? 6 : 23*/}
											/>
										)}
									/>
								)
							})}

							<Redirect
								exact={true}
								from="/impostazioni-iniziali"
								to="/impostazioni-iniziali/1"
							/>
							<RouteAuth
								path="/home"
								component={HomePage}
								exact={true}
							/>
							<RouteAuth
								path="/screenlock"
								component={ScreenlockPage}
								exact={true}
							/>
							<RouteAuth
								path="/gruppi"
								component={GroupsPage}
								exact={true}
							/>
							<RouteAuth
								path="/gruppi/:groupId"
								component={GroupPage}
								exact={true}
							/>
							<RouteAuth
								path="/gruppi/:groupId/:postId"
								component={PostPage}
								exact={true}
							/>
							<RouteAuth
								path="/gruppi/:groupId/nuovo-post"
								component={AddPostPage}
								exact={true}
							/>
							<RouteAuth
								path="/gruppi/:groupId/:postId/:commentId"
								component={CommentPage}
								exact={true}
							/>
							<RouteAuth
								path="/multimedia"
								component={MultimediaPage}
								exact={true}
							/>
							<RouteAuth
								path="/multimedia/:groupId"
								component={MultimediaGroupPage}
								exact={true}
							/>
							<RouteAuth
								path="/corso"
								component={CoursePage}
								exact={true}
							/>
							<RouteAuth
								path="/corso/lezione/:lessonId"
								component={LessonPage}
								exact={true}
							/>
							<RouteAuth
								path="/profilo"
								component={ProfilePage}
								exact={true}
							/>
							<RouteAuth
								path="/percorso-gratuito"
								component={StartFreeRoutePage}
								exact={true}
							/>
							<RouteAuth
								path="/prodotti"
								component={ProductsPage}
								exact={true}
							/>
							<RouteAuth
								path="/profilo/*"
								component={ProfilePage}
								exact={true}
							/>
							<RouteAuth
								path="/aiuto/che-cos-e-dieta-social-app"
								component={WhatIsPage}
								exact={true}
							/>
							<RouteAuth
								path="/aiuto/come-utilizzare-dieta-social-app"
								component={HowToUsePage}
								exact={true}
							/>
							<RouteAuth
								path="/aiuto/come-funziona-la-prova-gratuita"
								component={HowTrialWorksPage}
								exact={true}
							/>
							<RouteAuth
								path="/pagamento"
								component={PaymentsPage}
								exact={true}
							/>
							<RouteAuth
								path="/metodo-di-pagamento"
								component={PaymentsPage}
								exact={true}
							/>
							<RouteAuth
								path="/metodo-di-pagamento/processando"
								component={PaymentMethodProcessingPage}
								exact={true}
							/>
							<RouteAuth
								path="/pagamento/successo"
								component={PaymentSuccessPage}
								exact={true}
							/>
							<RouteAuth
								path="/pagamento/processando"
								component={PaymentProcessingPage}
								exact={true}
							/>
							<RouteAuth
								path="/progressi"
								component={ProgressPage}
								exact={true}
							/>
							<RouteAuth
								path="/diario/:measureSlug"
								component={DiaryMeasurePage}
							/>
							<RouteAuth
								path="/diario"
								component={DiaryPage}
								exact={true}
							/>
							<RouteAuth
								path="/menu/:day/:food/:recipeId"
								component={RecipeMenuPage}
							/>
							<RouteAuth
								path="/menu/:day/:food/:recipeId/:cycleId"
								component={RecipeMenuPage}
							/>
							<RouteAuth
								path="/menu/:day"
								component={DayMenuPage}
								exact={true}
							/>
							<RouteAuth
								path="/menu"
								component={MenuPage}
								exact={true}
							/>
							<RouteAuth
								path="/ricette"
								component={RecipesPage}
								exact={true}
							/>
							<RouteAuth
								path="/ricette/:recipeId"
								component={RecipePage}
								exact={true}
							/>
							<RouteAuth
								path="/ricette/:recipeId/:cycleId"
								component={RecipePage}
								exact={true}
							/>
							<RouteAuth
								path="/imposta-notifiche"
								component={NotificationsConfigPage}
								exact={true}
							/>
							<RouteAuth
								path="/notifiche"
								component={NotificationsPage}
								exact={true}
							/>
							<RouteAuth
								path="/logout"
								exact={true}
								component={LogoutRoute}
							/>
							<Route
								exact={false}
								component={NotFoundPage}
							/>
						</IonRouterOutlet>
					</Switch>
				</IonReactRouter>
			</ErrorBoundary>
		</IonApp>
	)
}

export default App
