import { IonText } from '@ionic/react'
import { useEffect, useRef } from 'react'
import Chart from 'chart.js/auto'
import dayjs from 'dayjs'
import { NoRecords } from '../../components/NoRecords'

const MeasueresNames = [
	'Peso corporeo',
	'Circonferenza collo',
	'Circonferenza ombelicale',
	'Circonferenza fianchi',
	'Circonferenza coscia',
	'Circonferenza braccio',
]

const measureOptions = {
	maintainAspectRatio: false,
	aspectRatio: 0.6,
	plugins: {
		legend: {
			display: false,
			labels: {
				color: '#495057',
			},
		},
	},
	scales: {
		x: {
			ticks: {
				color: '#495057',
			},
			grid: {
				color: '#ebedef',
			},
		},
		y: {
			ticks: {
				color: '#495057',
			},
			grid: {
				color: '#ebedef',
			},
		},
	},
}

const measureData = {
	labels: [],
	datasets: [
		{
			sufix: 'kg',
			data: [],
			fill: false,
			borderColor: '#6366f1',
			tension: 0.4,
		},
	],
}

const DiarioContentSpecific = ({ measureTitle = '', measures }) => {
	const chartRef = useRef(null)
	const title = MeasueresNames.includes(measureTitle)
		? measureTitle
		: MeasueresNames[0]
	measureData.datasets[0].label = title
	const measure = measures.filter((measure) => measure.title === title)[0]

	useEffect(() => {
		if (measure.labels.length === 0) return
		const formattedLabels = measure.labels.map((date) =>
			dayjs(date).format('DD/MM/YY')
		)

		measureData.datasets[0].data = [...measure.values]
		measureData.labels = formattedLabels

		if (chartRef.current) {
			chartRef.current.destroy()
		}
		chartRef.current = new Chart(document.getElementById('measure-chart'), {
			type: 'line',
			data: measureData,
			options: measureOptions,
		})
	}, [measures])

	return (
		<>
			<h4 className="ion-text-center">{title}</h4>

			{measure.labels.length === 0 ? (
				<NoRecords text="misure" />
			) : (
				<canvas id="measure-chart" />
			)}
		</>
	)
}

export default DiarioContentSpecific
