import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import config from '../../../settings/config'
import { headersDefault } from '../../../redux/actions'
import { updateUserProperty } from '../../../redux/reducer/userSlice'
import { IonAlert, IonItem, IonSelect, IonSelectOption } from '@ionic/react'

const DietTypeInput = ({ setToastIsOpen = null }) => {
	const backUrl = config.env[config.env.mode].url

	const dispatch = useDispatch()
	const { vegetarian, gluten_free, keep_weight, subscription } = useSelector(
		(state) => state.user.master_club_subscriber
	)

	const [newVegetarian, setNewVegetarian] = useState(vegetarian)
	const [newGlutenFree, setNewGlutenFree] = useState(gluten_free)
	const [newKeepWeight, setNewKeepWeight] = useState(keep_weight)

	const updateInput = (value) => {
		const vegetarianChanged = newVegetarian !== vegetarian
		const glutenFreeChanged = newGlutenFree !== gluten_free
		const keepWeightChanged = newKeepWeight !== keep_weight
		if (!vegetarianChanged && !glutenFreeChanged && !keepWeightChanged) return

		const body = JSON.stringify({
			...(glutenFreeChanged && { gluten_free: newGlutenFree }),
			...(vegetarianChanged && { vegetarian: newVegetarian }),
			...(keepWeightChanged && { keep_weight: newKeepWeight }),
		})
		fetch(`${backUrl}/api/master-club-subscribers/1`, {
			method: 'PATCH',
			body,
			headers: headersDefault(),
		})
			.then((response) => {
				if (!response.ok) throw new Error('fetch error')
				return response.json()
			})
			.then((data) => {
				glutenFreeChanged &&
					dispatch(
						updateUserProperty({
							stateKey: 'master_club_subscriber',
							key: 'gluten_free',
							value: newGlutenFree,
						})
					)
				vegetarianChanged &&
					dispatch(
						updateUserProperty({
							stateKey: 'master_club_subscriber',
							key: 'vegetarian',
							value: newVegetarian,
						})
					)
				keepWeightChanged &&
					dispatch(
						updateUserProperty({
							stateKey: 'master_club_subscriber',
							key: 'keep_weight',
							value: newKeepWeight,
						})
					)
			})
			.catch((e) => setToastIsOpen())
	}

	const inputOptions = [
		{
			name: 'omnivore',
			type: 'checkbox',
			label: 'Onnivoro',
			value: !newVegetarian,
			checked: !newVegetarian,
			handler: (e) => {
				setNewVegetarian(false)
			},
		},
		{
			name: 'vegetarian',
			type: 'checkbox',
			label: 'Vegetariano',
			value: newVegetarian,
			checked: newVegetarian,
			handler: (e) => {
				setNewVegetarian(true)
			},
		},
		{
			name: 'gluten_free',
			type: 'checkbox',
			label: 'Senza glutine e lattosio',
			value: newGlutenFree,
			checked: newGlutenFree,
			handler: (e) => {
				setNewGlutenFree(!newGlutenFree)
			},
		},
	]

	if (subscription) {
		inputOptions.push({
			name: 'keep_weight',
			type: 'checkbox',
			label: 'Mantenimento',
			value: newKeepWeight,
			checked: newKeepWeight,
			handler: (e) => {
				setNewKeepWeight(!newKeepWeight)
			},
		})
	}

	return (
		<>
			<IonItem
				button
				detail="false"
				id="present-alert-TypeOfDietInput"
				className="no-padding-item">
				<IonSelect
					cancelText="Cancella"
					disabled="true"
					multiple={true}
					value={[
						newVegetarian ? 'vegetarian' : 'omnivore',
						newGlutenFree && 'gluten_free',
						newKeepWeight && 'keep_weight',
					]}
					label="Che tipo di alimentazione segui?"
					placeholder="Selezionare"
					labelPlacement="stacked"
					style={{
						'--placeholder-color': 'var(--ion-color-medium)',
						opacity: 1,
					}}>
					<IonSelectOption value="omnivore">Onnivoro</IonSelectOption>
					<IonSelectOption value="vegetarian">Vegetariano</IonSelectOption>
					<IonSelectOption value="keep_weight">Mantenimento</IonSelectOption>
					<IonSelectOption value="gluten_free">
						Senza glutine e lattosio
					</IonSelectOption>
				</IonSelect>
			</IonItem>
			<IonAlert
				trigger="present-alert-TypeOfDietInput"
				header="Che tipo di alimentazione segui?"
				inputs={inputOptions}
				buttons={[
					{
						text: 'Cancella',
						role: 'cancel',
						handler: () => {
							if (gluten_free && newGlutenFree) setNewGlutenFree(gluten_free)
							if (vegetarian && newVegetarian) setNewVegetarian(vegetarian)
							if (keep_weight && newKeepWeight) setNewVegetarian(keep_weight)
						},
					},
					{
						text: 'OK',
						role: 'confirm',
						handler: updateInput,
					},
				]}
			/>
		</>
	)
}

export default DietTypeInput
