const measures = [
	{
		order: 1,
		value: 'weight',
		title: 'Peso corporeo',
		suffix: 'kg',
		slug: 'peso-corporeo',
		step: 0.1,
		min: 35,
		max: 150,
		initialValue: 70,
		diary: true,
	},
	{
		order: 2,
		value: 'neck_circumference',
		title: 'Circonferenza collo',
		suffix: 'cm',
		slug: 'circonferenza-collo',
		step: 0.5,
		min: 25,
		max: 60,
		initialValue: 30,
		diary: true,
	},
	{
		order: 3,
		value: 'umbilical_circumference',
		title: 'Circonferenza ombelicale',
		suffix: 'cm',
		slug: 'circonferenza-ombelicale',
		step: 0.5,
		min: 50,
		max: 150,
		initialValue: 80,
		diary: true,
	},
	{
		order: 4,
		value: 'hips_circumference',
		title: 'Circonferenza fianchi',
		suffix: 'cm',
		slug: 'circonferenza-fianchi',
		step: 0.5,
		min: 50,
		max: 150,
		initialValue: 100,
		diary: true,
	},
	{
		order: 5,
		value: 'middle_thigh_circumference',
		title: 'Circonferenza coscia',
		suffix: 'cm',
		slug: 'circonferenza-coscia',
		step: 0.5,
		min: 30,
		max: 80,
		initialValue: 50,
		diary: true,
	},
	{
		order: 6,
		value: 'arm_circumference',
		title: 'Circonferenza braccio',
		suffix: 'cm',
		slug: 'circonferenza-braccio',
		step: 0.5,
		min: 20,
		max: 50,
		initialValue: 30,
		diary: true,
	},
	{
		order: 7,
		value: 'abdominal_circumference',
		title: 'Circonferenza addominale',
		suffix: 'cm',
		slug: 'circonferenza-addominale',
		step: 0.5,
		min: 50,
		max: 150,
		initialValue: 80,
		diary: false,
	},
	{
		order: 8,
		value: 'waist_circumference',
		title: 'Circonferenza vita',
		suffix: 'cm',
		slug: 'circonferenza-vita',
		step: 0.5,
		min: 50,
		max: 150,
		initialValue: 90,
		diary: false,
	},
	{
		order: 9,
		value: 'height',
		title: 'Altezza',
		suffix: 'cm',
		slug: 'altezza',
		step: 1,
		min: 130,
		max: 250,
		initialValue: 170,
		diary: false,
	},
]

export default measures
