const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
const customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)

export function areArraysEquals(arr1, arr2) {
	if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
		return false
	}

	if (arr1.length !== arr2.length) {
		return false
	}

	const sortedArr1 = arr1.slice().sort()
	const sortedArr2 = arr2.slice().sort()

	for (let i = 0; i < sortedArr1.length; i++) {
		if (sortedArr1[i] !== sortedArr2[i]) {
			return false
		}
	}

	return true
}

export function addOneDayToDateString(dateString) {
	return dayjs(dateString).add(1, 'day').format('YYYY-MM-DD')
}
export function subtractOneDayToDateString(dateString) {
	return dayjs(dateString).subtract(1, 'day').format('YYYY-MM-DD')
}

export function synchronizeDatetoDate(date) {
	if (!(date instanceof Date)) {
		throw new Error('The param must be an instance of Date')
	}

	const fechaRoma = dayjs(date).utc().tz('Europe/Rome')
	const fechaFormateada = fechaRoma.format('YYYY-MM-DD HH:mm:ss')

	return new Date(fechaFormateada)
}

export function synchronizeDatetoString(date) {
	if (!(date instanceof Date)) {
		throw new Error('The param must be an instance of Date')
	}

	const formattedDate = dayjs(date).startOf('day').format('YYYY-MM-DD')

	return formattedDate
}

export function synchronizeDatetoFullString(date) {
	if (!(date instanceof Date)) {
		throw new Error('El parámetro debe ser una instancia de Date')
	}

	const formattedDate = dayjs(date).format('YYYY-MM-DD HH:mm:ss')

	return formattedDate
}

export function stringDatetoDate(dateString) {
	const formattedDate = `${dateString}T00:00:00Z`

	const date = new Date(formattedDate)

	return date
}

export function stringDateIsLess(fecha1, fecha2) {
	const fechaObj1 = new Date(fecha1)
	const fechaObj2 = new Date(fecha2)
	return fechaObj1 < fechaObj2
}

export function dateIsLess(fecha1, fecha2) {
	return fecha1 < fecha2
}

export function todayDate() {
	return synchronizeDatetoDate(new Date())
}

export function todayStringDate() {
	return synchronizeDatetoString(new Date())
}

export function todayFullStringDate() {
	return synchronizeDatetoFullString(new Date())
}

// const fechaArray = [
//   ['2023-06-11', '2023-06-17'],
//   ['2023-06-25', '2023-07-01'],
//   ['2023-07-09', '2023-07-15']
// ]

export function getNotIncludedDates(dateRanges) {
	const parsedDates = []

	for (let i = 0; i < dateRanges.length - 1; i++) {
		const end = dayjs(dateRanges[i][1])
		const start = dayjs(dateRanges[i + 1][0])

		if (end.isValid() && start.isValid() && end.isBefore(start)) {
			let current = end.add(1, 'day')

			while (current.isBefore(start)) {
				parsedDates.push(current.format('YYYY-MM-DD'))
				current = current.add(1, 'day')
			}
		}
	}

	return parsedDates
}

export function orderDatesSubArrays(dietRoute1, dietRoute2) {
	if (dietRoute1[0] < dietRoute2[0]) return -1
	if (dietRoute1[0] > dietRoute2[0]) return 1
	return 0
}

export function substractOneDayToEndDatesSubArrays([, end], index, datesArray) {
	datesArray[index][1] = dayjs(end).subtract(1, 'day').format('YYYY-MM-DD')
}

export function isValidEMail(email) {
	return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)
}

export function getStep(route) {
	const parts = route.split('/')
	const lastPart = parts.pop()

	return parseInt(lastPart)
}

export const randId = () => {
	return Math.random().toString(36).substr(2, 9)
}

export function isValidName(name) {
	return /^(([a-zA-Z]|[À-ÖØ-öø-ÿ Ÿœ]){3,30})$/.test(name)
}

export function isValidNickname(nickname) {
	return /^([\w.-]){4,30}$/.test(nickname)
}

export function isValidGender(sex) {
	return ['male', 'female'].includes(sex)
}

export function getMinimumLegalAgeDate() {
	const fechaActual = new Date()
	const fechaMinimaMayorEdad = new Date(
		fechaActual.getFullYear() - 18,
		fechaActual.getMonth(),
		fechaActual.getDate() + 1
	)
	const date = fechaMinimaMayorEdad.toISOString().substring(0, 10)
	return date
}

export function dataURLtoBlob(dataURL) {
	const arr = dataURL.split(',')
	const mime = arr[0].match(/:(.*?);/)[1]
	const bstr = atob(arr[1])
	let n = bstr.length
	const u8arr = new Uint8Array(n)
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n)
	}
	return new Blob([u8arr], { type: mime })
}

export function getMondayDate(date) {
	const dateObj = new Date(date)

	const dayOfWeek = dateObj.getDay()

	if (dayOfWeek === 0) {
		dateObj.setDate(dateObj.getDate() - 6)
	} else {
		dateObj.setDate(dateObj.getDate() - dayOfWeek + 1)
	}

	return dateObj.toISOString().slice(0, 10)
}

export const daysOfTheWeek = [
	'Lunedì',
	'Martedì',
	'Mercoledì',
	'Giovedì',
	'Venerdì',
	'Sabato',
	'Domenica',
]

export const monthsOfTheYear = [
	'gennaio',
	'febbraio',
	'marzo',
	'aprile',
	'maggio',
	'giugno',
	'luglio',
	'agosto',
	'settembre',
	'ottobre',
	'novembre',
	'dicembre',
]

export function getWeekDays(date) {
	const dateObj = dayjs(getMondayDate(date))

	const daysOfWeek = [
		dateObj,
		dateObj.add(1, 'day'),
		dateObj.add(2, 'day'),
		dateObj.add(3, 'day'),
		dateObj.add(4, 'day'),
		dateObj.add(5, 'day'),
		dateObj.add(6, 'day'),
	]

	const formattedDaysOfWeek = daysOfWeek.map((day) => {
		return { day: day.date(), month: day.month(), year: day.year() }
	})

	return formattedDaysOfWeek
}

export function getDayAsObject(date) {
	const dateObj = dayjs(date)
	return { day: dateObj.date(), month: dateObj.month(), year: dateObj.year() }
}

export function getWeekIndex(date) {
	const dateObj = dayjs(date)

	const dayOfWeek = dateObj.day()
	if (dayOfWeek === 6) {
		return 0
	} else {
		return dayOfWeek + 1
	}
}

export function urlBase64ToUint8Array(base64String) {
	const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
	const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')

	const rawData = window.atob(base64)
	const outputArray = new Uint8Array(rawData.length)

	for (let i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i)
	}
	return outputArray
}

/* USER AGENT FUNCTIONS */

export function _isMobileDevice() {
	return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
		navigator.userAgent
	)
}

export function _isIOS() {
	return /iPhone|iPad|iPod/.test(window.navigator.userAgent)
}

// Expresiones regulares para navegadores populares
const desktopBrowsers = [
	/Chrome/,
	/Firefox/,
	/Safari/,
	/Edge/,
	/Opera/,
	/MSIE/,
	/Brave/,
]

const mobileBrowsers = [
	/SamsungBrowser/,
	/Mobile.*Safari/,
	/Android/,
	/Mobile.*Chrome|CriOS/, // Agregamos CriOS, que es común en agentes de usuario de Chrome en iOS
	/Brave/,
]

// Verificar el agente de usuario al inicio de la ejecución
const userAgent = navigator.userAgent

export function _detectBrowser() {
	// Verify if the user is using a mobile device
	const isMobile = /Mobile/.test(userAgent)

	let browser = 'Unknown'
	let browserType = 'Unknown'

	if (isMobile) {
		browserType = 'Mobile'
		mobileBrowsers.some((regex) => {
			if (regex.test(userAgent)) {
				browser = regex.toString().match(/\/(.*)\//)[1]
				return true // Break the loop
			}
			return false
		})
	} else {
		browserType = 'Desktop'
		desktopBrowsers.some((regex) => {
			if (regex.test(userAgent)) {
				browser = regex.toString().match(/\/(.*)\//)[1]
				return true // Break the loop
			}
			return false
		})
	}

	return { browser, browserType }
}

/* USER AGENT FUNCTIONS */

export function _isStandalone() {
	if ('serviceWorker' in navigator && 'caches' in window) {
		if (
			window.matchMedia('(display-mode: standalone)').matches ||
			window.navigator.standalone === true
		) {
			return true
		}
	}

	return false
}

export async function _isDietaSocialInstalled() {
	try {
		const relatedApps = await navigator.getInstalledRelatedApps()
		return relatedApps.length > 0
	} catch (error) {
		console.error('Error:', error)
		return false
	}
}

export function clearCache(event) {
	if (navigator.onLine) {
		caches.keys().then(function (cacheNames) {
			cacheNames.forEach(function (cacheName) {
				caches.delete(cacheName)
			})
		})
		console.log('Cache cleared')
		window.location.reload()
	} else {
		setTimeout(() => clearCache(), 1000)
		event?.detail.complete()
		console.log('No connection. Retrying in 1 second')
	}
}

export function checkOverflow(titleElementRef) {
	if (titleElementRef.current) {
		const isOverflowing =
			titleElementRef.current.scrollWidth > titleElementRef.current.clientWidth

		if (isOverflowing) {
			titleElementRef.current.classList.add('banner-title')
			titleElementRef.current.classList.remove('ellipsis-title')
		} else {
			titleElementRef.current.classList.add('ellipsis-title')
			titleElementRef.current.classList.remove('banner-title')
		}
	}
}

export function upperFirst(string) {
	return string.charAt(0).toUpperCase() + string.slice(1)
}

export function processPostHTML(text) {
	if (!text) return ''
	text = text
		.replace(
			/<a\s+[^>]*href=['"][^'"]*https:\/\/[^'"]*['"][^>]*>/g,
			(match) => {
				return match.replace(/https:\/\//g, '')
			}
		)
		.replace(
			/<a\s+[^>]*href=/g,
			'<ion-button onClick="handleButtonLink(this)" data-href='
		)
		.replace(/<\/a>/g, '</ion-button>')

	// Regular expression to find links in the text

	const linkRegex = /\bhttps:\/\/[^\s<>]+\b/gi

	// Replace each link with the <a> tag
	text = text.replace(linkRegex, (link) => {
		return `<a href="${link}" target="_blank" rel="noopener noreferrer">${link}</a>`
	})

	// Use React to render the text with links as an HTML component
	return <div dangerouslySetInnerHTML={{ __html: text }} />
}

export const NotificationService = (() => {
	let _permission = 'default'

	const requestPermission = async () => {
		_permission = await Notification.requestPermission()
	}

	const getPermission = () => _permission

	return {
		requestPermission,
		getPermission,
	}
})()

export const unlockTexts = (unlocks) => {
	let menu_days = unlocks?.menu_days ? [...unlocks.menu_days] : []
	if (unlocks?.shopping_list) menu_days?.unshift(0)

	return menu_days
		?.map((day) => {
			const days = [
				'Lista della spesa',
				'Menù del Lunedì',
				'Menù del Martedì',
				'Menù del Mercoledì',
				'Menù del Giovedì',
				'Menù del Venerdì',
				'Menù del Sabato',
				'Menù della Domenica',
			]
			return days[day]
		})
		.join(', ')
}

export const getGroupParams = (state, group_id) => {
	const { main, not_main } = state.groups.getGroupPosts
	const { fixed: main_fixed, general: main_general } = main
	const { fixed: not_main_fixed, general: not_main_general } = not_main

	const groups = [main_fixed, main_general, not_main_fixed, not_main_general]

	const foundGroup = groups.find(
		(group) => group?.data?.group?.id === Number(group_id)
	)

	return foundGroup ? foundGroup.data.group : {}
}

export const rotateImage = (image, orientation) => {
	const canvas = document.createElement('canvas')
	const ctx = canvas.getContext('2d')
	let width = image.width
	let height = image.height

	// if (orientation === 6 || orientation === 8) {
	// 	canvas.width = height
	// 	canvas.height = width
	// } else {
	// 	canvas.width = width
	// 	canvas.height = height
	// }

	canvas.width = width
	canvas.height = height

	switch (orientation) {
		case 2:
			// Flip image horizontally - The image is mirrored along its vertical axis.
			ctx.transform(-1, 0, 0, 1, width, 0)
			break
		case 3:
			// Rotate image 180 degrees - The image is upside down.
			ctx.transform(-1, 0, 0, -1, width, height)
			break
		case 4:
			// Flip image vertically - The image is mirrored along its horizontal axis.
			ctx.transform(1, 0, 0, -1, 0, height)
			break
		case 5:
			// Rotate 90 degrees clockwise and flip vertically - This is a combination of rotating the image 90 degrees to the right and then flipping it along its horizontal axis.
			ctx.transform(0, 1, 1, 0, 0, 0)
			break
		// case 6:
		// 	// Rotate 90 degrees clockwise - The image was captured with the camera rotated 90 degrees from its normal orientation, typically this means the device was held vertically. This is common for portrait photos.
		// 	ctx.transform(0, 1, -1, 0, height, 0)
		// 	break
		case 7:
			// Rotate 90 degrees clockwise and flip horizontally - Similar to case 5, but the image is flipped along its vertical axis after being rotated.
			ctx.transform(0, -1, -1, 0, height, width)
			break
		case 8:
			// Rotate 90 degrees counterclockwise - The image was captured with the camera rotated 270 degrees from its normal orientation, or 90 degrees to the left. This could happen if the device was held upside down vertically.
			ctx.transform(0, -1, 1, 0, 0, width)
			break
		default:
			// No transformation needed - The image is in its normal orientation, with no rotation or flipping required.
			break
	}

	ctx.drawImage(image, 0, 0, width, height)

	const rotatedImage = new Image()
	rotatedImage.src = canvas.toDataURL('image/jpeg', 1.0)

	return rotatedImage
}

export const decodeHtmlEntities = (str) => {
	const txt = document.createElement('textarea')
	txt.innerHTML = str
	return txt.value
}

export const isEmptyObj = (obj) => {
	if (!obj) return true
	return Object.keys(obj).length === 0
}
