import { IonContent, IonPage } from '@ionic/react'
import MainFooter from '../../../components/mainFooter'
import MainHeader from '../../../components/headers/mainHeader'
import dietaSocialLogo from '../../../assets/images/ds-logo.svg'

const HowToUsePage = () => {
	return (
		<IonPage>
			<MainHeader
				page="WhatIsDietaSocialApp"
				title="Come utilizzare l’App"
			/>
			<IonContent className="ion-padding">
				<p>Ecco come utilizzare Dieta Social App:</p>
				<h4>Registrazione Iniziale</h4>
				<p>
					All’accesso iniziale, gli utenti accedono all’app come{' '}
					<b>‘‘GUEST’’</b>, avendo a disposizione una visualizzazione parziale
					dei contenuti.
				</p>
				<h4>Prova Gratuita di 7 Giorni</h4>
				<p>
					Per provare gratuitamente il metodo Dieta Social, è possibile seguire
					un percorso di prova della durata di 7 giorni. Durante questa fase, il
					profilo utente cambierà automaticamente a <b>‘‘FREE’’</b>, consentendo
					l’accesso a varie sezioni, tra cui menu, ricette, gruppi di
					discussione e contenuti multimediali.
				</p>
				<h4>Abbonamento PREMIUM</h4>
				<p>
					Al termine del periodo di prova gratuito, gli utenti interessati
					possono scegliere di continuare il percorso dimagrante sottoscrivendo
					un abbonamento <b>PREMIUM</b>. Questo offre accesso a tutti i vantaggi
					e i contenuti esclusivi dedicati agli abbonati.
				</p>
				<h4>Sezioni Disponibili in Base al Profilo Utente</h4>
				<p>
					<b>Sezione Home:</b> La pagina principale dove gli utenti possono
					scoprire contenuti interessanti, post di esperti e aggiornamenti dalla
					loro community.
				</p>
				<p>
					<b>Sezione Ricette:</b> Troverete qui le ricette incluse nel vostro
					piano alimentare personale.
				</p>
				<p>
					<b>Sezione Gruppi:</b> Partecipate alle discussioni e ai gruppi di
					interesse, condividete esperienze e ricevete supporto da altri membri
					con obiettivi simili.
				</p>
				<p>
					<b>Sezione Multimedia:</b> Accesso a video, podcast e altri contenuti
					multimediali educativi.
				</p>
				<p>
					<b>Sezione Diario:</b> Monitorate i vostri progressi e tenete traccia
					dell’andamento della vostra salute, registrando il vostro peso
					corporeo e le misure.
				</p>
				<p>
					<b>Profilo Personale:</b> Ogni utente possiede un profilo personale
					attraverso il quale può condividere informazioni su se stesso, i
					propri obiettivi e il proprio percorso di salute.
				</p>
				<p>
					Dieta Social App è progettata per essere intuitiva e facile da usare,
					aiutandovi a raggiungere i vostri obiettivi di salute in modo semplice
					e interattivo.
				</p>
			</IonContent>
			<MainFooter />
		</IonPage>
	)
}

export default HowToUsePage
