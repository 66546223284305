import {
	IonContent,
	IonItem,
	IonList,
	IonPage,
	IonButton,
	IonAvatar,
	IonText,
	IonSkeletonText,
	IonModal,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonButtons,
} from '@ionic/react'
import MainFooter from '../../components/mainFooter'
import MainHeader from '../../components/headers/mainHeader'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { getNotificationList, notificationReading } from '../../redux/actions'
import { format } from 'timeago.js'
import dsPictogram from '../../assets/images/ds-pictogram.svg'
import './Notifications.min.css'
import { useHistory } from 'react-router'
import { NoRecords } from '../../components/NoRecords'

const NotificationsPage = () => {
	const LoadingNotification = () => (
		<IonItem>
			<div
				slot="start"
				className="avatar-container">
				<IonAvatar>
					<IonSkeletonText animated={true} />
				</IonAvatar>
			</div>

			<div>
				<IonText color="gray">
					<div className="title">
						<IonSkeletonText
							animated={true}
							style={{ width: '7rem' }}
						/>
					</div>
				</IonText>
				<IonText color="gray">
					<div className="description">
						<p>
							<IonSkeletonText
								animated={true}
								style={{ width: '10rem' }}
							/>
						</p>
					</div>
				</IonText>
				<IonText color="gray">
					<div className="timeago">
						<IonSkeletonText
							animated={true}
							style={{ width: '3rem' }}
						/>
					</div>
				</IonText>
			</div>
		</IonItem>
	)

	const dispatch = useDispatch()

	const history = useHistory()

	const href =
		history.index > 0 ? history.entries[history.index - 1].pathname : '/'

	function readNotification(notification) {
		dispatch(notificationReading(notification))
		const updatedNotifications = notifications.map((n) =>
			n.id === notification.id ? { ...n, is_read_by_current_user: true } : n
		)
		setNotifications(updatedNotifications)
	}

	const { data, isLoading, error, extra } = useSelector(
		(state) => state.notifications.getNotificationList
	)

	const [notifications, setNotifications] = useState(data ?? null)

	const [isOpen, setIsOpen] = useState(false)

	useEffect(() => {
		dispatch(getNotificationList())
	}, [])

	useEffect(() => {
		if (data) {
			setNotifications(data)
		}
	}, [dispatch, data])

	const NotificationRow = ({ notification, detail = false }) => {
		if (!notification) return null
		return (
			<IonItem
				className={'notification-message' + (detail ? ' detail' : '')}
				detail={!detail && notification.description.length}
				button={!detail && notification.description.length}
				read={notification.is_read_by_current_user}
				onClick={() => {
					if (!detail && notification.description.length) {
						setIsOpen(notification)
						if (!notification.is_read_by_current_user)
							readNotification(notification)
					} else if (notification.link_url) {
						window.location.href = notification.link_url
					}
				}}
				key={notification.id}>
				{!detail && (
					<div
						slot="start"
						className="avatar-container">
						<IonAvatar>
							<img
								src={dsPictogram}
								alt={notification.title}
							/>
						</IonAvatar>
					</div>
				)}

				<div>
					<IonText color="gray">
						<div className="title">{notification.title}</div>
						<div
							className="description"
							dangerouslySetInnerHTML={{
								__html:
									notification.description.length > 100 && !detail
										? notification.description.substring(0, 100) + '...'
										: notification.description,
							}}></div>
					</IonText>
					<IonText color="gray">
						<div className="timeago">
							{format(notification.created_at, 'itLocale')}
						</div>
					</IonText>
				</div>
			</IonItem>
		)
	}

	return (
		<IonPage className="notifications-page">
			<MainHeader
				page="NotificationsPage"
				title="Notifiche"
				href={href}
			/>

			<IonContent>
				<IonList>
					{notifications === null && (isLoading || error) && (
						<>
							<LoadingNotification />
							<LoadingNotification />
							<LoadingNotification />
							<LoadingNotification />
							<LoadingNotification />
							<LoadingNotification />
							<LoadingNotification />
							<LoadingNotification />
							<LoadingNotification />
							<LoadingNotification />
							<LoadingNotification />
						</>
					)}
					{notifications?.length ? (
						notifications?.map((notification) => (
							<NotificationRow
								key={notification.id}
								notification={notification}
							/>
						))
					) : (
						<NoRecords text="notifiche" />
					)}
				</IonList>
				{(data?.length ?? 0) > 0 && data?.length !== extra?.data.all && (
					<IonButton
						onClick={() => {
							dispatch(
								getNotificationList(extra.notifications.current_page + 1)
							)
						}}
						color="primary"
						shape="round"
						expand="block"
						className="load-more-button"
						strong={true}>
						Carica ancora
					</IonButton>
				)}
				<IonModal
					isOpen={!!isOpen}
					onDidDismiss={() => setIsOpen(false)}>
					<IonHeader>
						<IonToolbar>
							<IonTitle></IonTitle>
							<IonButtons slot="end">
								<IonButton onClick={() => setIsOpen(false)}>Chiudi</IonButton>
							</IonButtons>
						</IonToolbar>
					</IonHeader>
					<IonContent className="ion-padding">
						<NotificationRow
							key={isOpen.id}
							notification={isOpen}
							detail={true}
						/>
					</IonContent>
				</IonModal>
			</IonContent>

			<MainFooter />
		</IonPage>
	)
}

export default NotificationsPage
