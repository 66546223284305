import {
	IonPage,
	IonContent,
	IonText,
	IonRadioGroup,
	IonRadio,
	IonButton,
	IonItem,
	IonLabel,
} from '@ionic/react'
import InitialConfigHeader from '../header'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setInitialConfigExtraProperty } from '../../../redux/reducer/initialConfigSlice'
import '../InitialConfiguration.min.css'
import { useHistory } from 'react-router'

const options = [
	'Meno di 4 ore',
	'Più di 4 ore',
	'Più di 5 ore',
	'Più di 6 ore',
	'Più di 7 ore',
	'Più di 8 ore',
]
const SleepDurationPage = ({ step, totalSteps }) => {
	const history = useHistory()
	const [isLoading, setIsLoading] = useState(false)
	const sleep = useSelector((state) => state.initialConfig.extras.sleep)
	const dispatch = useDispatch()
	const [error, setError] = useState('')

	const updateInput = (e) => {
		if (error !== '') setError('')
		if (e.target.value !== sleep)
			dispatch(
				setInitialConfigExtraProperty({ key: 'sleep', value: e.target.value })
			)
	}

	const validateInput = () => {
		let isValid = true
		if (sleep === '') {
			setError("Devi selezionare un'opzione per continuare.")
			isValid = false
		} else if (!options.includes(sleep)) {
			setError("L'opzione selezionata non è valida.")
			isValid = false
		}

		return isValid
	}

	const handleSubmit = () => {
		setIsLoading(true)
		const isInputValid = validateInput()
		if (!isInputValid) {
			setIsLoading(false)
			return
		}
		history.push(`/impostazioni-iniziali/${step + 1}`)
		setIsLoading(false)
	}

	const handleSkip = (e) => {
		setIsLoading(true)
		dispatch(setInitialConfigExtraProperty({ key: 'sleep', value: '' }))
		history.push(`/impostazioni-iniziali/${step + 1}`)
		setIsLoading(false)
	}

	return (
		<IonPage className="initial-configuration">
			<InitialConfigHeader
				step={step}
				totalSteps={totalSteps}
				isLoading={isLoading}
			/>

			<IonContent>
				<h1>Quante ore riesci a dormire?</h1>
				<IonRadioGroup value={sleep}>
					{options.map((option, index) => {
						return (
							<IonItem
								key={index}
								className="no-padding-item">
								<IonRadio
									value={option}
									onClick={updateInput}>
									<IonLabel className="capitalize-first-letter">
										{option}
									</IonLabel>
								</IonRadio>
							</IonItem>
						)
					})}
				</IonRadioGroup>
				<IonText className="error">{error}</IonText>
				<div className="buttons">
					<IonButton
						shape="round"
						onClick={handleSkip}
						disabled={isLoading}
						color="light-gray"
						expand="full">
						<IonText color="white">Salta</IonText>
					</IonButton>
					<IonButton
						shape="round"
						onClick={handleSubmit}
						disabled={isLoading}
						color="primary"
						expand="full">
						Avanti
					</IonButton>
				</div>
			</IonContent>
		</IonPage>
	)
}

export default SleepDurationPage
