import { IonPage, IonContent, IonText, IonInput, IonButton } from '@ionic/react'
import InitialConfigHeader from '../header'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setInitialConfigProperty } from '../../../redux/reducer/initialConfigSlice'
import { isValidNickname } from '../../../services/functions'
import config from '../../../settings/config'
import { useHistory } from 'react-router'

const NicknamePage = ({ step, totalSteps }) => {
	const history = useHistory()
	const [isLoading, setIsLoading] = useState(false)
	const nickname = useSelector((state) => state.initialConfig.nickname)
	const name = useSelector((state) => state.initialConfig.name)
	const dispatch = useDispatch()
	const [error, setError] = useState('')

	const handleSkip = () => {
		if (error !== '') setError('')
		dispatch(setInitialConfigProperty({ key: 'nickname', value: null }))
		history.push(`/impostazioni-iniziali/${step + 1}`)
	}

	const updateInput = (e) => {
		if (error !== '') setError('')
		dispatch(
			setInitialConfigProperty({ key: 'nickname', value: e.target.value })
		)
	}

	const validateInputs = async () => {
		let isValid = true

		if (nickname === '' || nickname === null) {
			setError('Devi inserire un nickname oppure puoi selezionare "salta".')
			isValid = false
		} else if (!isValidNickname(nickname)) {
			setError(
				'Il nickname non è valido, deve avere da 4 a 30 caratteri e può contenere lettere, numeri, underscore, trattino e punto.'
			)
			isValid = false
		} else {
			try {
				const response = await fetch(
					`${
						config.env[config.env.mode].url
					}/api/master-club-subscribers/check-nickname?nickname=${nickname}`,
					{
						headers: {
							Accept: 'application/json',
							'Content-Type': 'application/json',
							Authorization: `Bearer ${localStorage.getItem('adoc_t')}`,
						},
					}
				)
				if (!response.ok) throw new Error(response.message)
				const data = await response.json()
				if (data.message !== 'Nickname disponibile') {
					setError('Il nickname non è disponibile')
					isValid = false
				}
			} catch {
				setError('Si è verificato un problema, riprova più tardi.')
				isValid = false
				return isValid
			}
		}
		return isValid
	}

	const handleSubmit = () => {
		setIsLoading(true)
		validateInputs().then((areInputsValid) => {
			if (!areInputsValid) {
				setIsLoading(false)
				return
			}
			history.push(`/impostazioni-iniziali/${step + 1}`)
			setIsLoading(false)
		})
	}

	return (
		<IonPage className="initial-configuration">
			<InitialConfigHeader
				step={step}
				totalSteps={totalSteps}
				isLoading={isLoading}
			/>

			<IonContent>
				<h1>Vuoi utilizzare un nome di fantasia?</h1>

				<IonInput
					autoFocus={true}
					type="text"
					label="Nickname"
					name="Nickname"
					placeholder={name + '123'}
					labelPlacement="stacked"
					color="primary"
					fill="solid"
					value={nickname}
					onIonInput={updateInput}
				/>
				<IonText className="error">{error}</IonText>

				<div className="buttons">
					<IonButton
						shape="round"
						onClick={handleSkip}
						disabled={isLoading}
						color="light-gray"
						expand="full">
						<IonText color="white">Salta</IonText>
					</IonButton>
					<IonButton
						shape="round"
						onClick={handleSubmit}
						disabled={isLoading}
						color="primary"
						expand="full">
						Avanti
					</IonButton>
				</div>
			</IonContent>
		</IonPage>
	)
}

export default NicknamePage
