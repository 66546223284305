import {
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonCol,
	IonGrid,
	IonImg,
	IonRow,
} from '@ionic/react'
import images from '../../assets/images/Measures/parts'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import measuresConfig from '../../settings/measuresConfig'

const DiarioContentFull = ({ measures = measuresConfig }) => {
	const gender =
		useSelector((state) => state.user?.master_club_subscriber?.sex) ?? 'male'
	const history = useHistory()

	return (
		<div style={{ overflowY: 'auto' }}>
			<IonGrid>
				<IonRow>
					{measures.map((measure, index) => {
						if (!measure.diary) return null
						let subtitle = ''
						if (measure.values?.length === 1) {
							subtitle = `${measure.values[0]}${measure.suffix}`
						} else if (measure.values?.length > 1) {
							const firstValue = Number(measure.values[0])
							const lastValue = Number(
								measure.values[measure.values.length - 1]
							)
							if (firstValue === lastValue) {
								subtitle = `${firstValue}${measure.suffix}`
							} else {
								subtitle = `${(lastValue - firstValue).toFixed(2)} ${
									measure.suffix
								} in questo periodo`
							}
						}
						return (
							<IonCol
								sizeLg="2"
								size="6"
								key={measure.title}>
								<IonCard
									style={{ height: '95%', margin: 0, paddingBottom: '0.5rem' }}
									button
									onClick={() =>
										history.push(
											window.location.pathname + '?measure=' + measure.title,
											'forward'
										)
									}>
									<IonImg
										className="ion-padding-horizontal"
										src={images[gender][index]}
									/>
									<IonCardHeader className="ion-no-padding ion-padding-top ion-padding-horizontal">
										<IonCardTitle
											style={{
												fontSize: '17px',
												fontWeight: 'var(--font-size-bold)',
											}}>
											{measure.title}
										</IonCardTitle>
									</IonCardHeader>
									<IonCardContent>{subtitle}</IonCardContent>
								</IonCard>
							</IonCol>
						)
					})}
				</IonRow>
			</IonGrid>
		</div>
	)
}

export default DiarioContentFull
