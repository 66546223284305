import { IonItem, IonList, IonText, IonButton } from '@ionic/react'
import { useLocation } from 'react-router-dom'

const Ingredients = (props) => {
	const location = useLocation()
	const isDayDish = location.pathname.includes('Piatto unico del giorno')

	const foods = props.fromPlan
		? props?.recipeData?.recipeFoods
		: props?.recipeData?.recipe_foods
	return (
		<IonList>
			{foods?.map((food, index) => (
				<IonItem
					lines={index === props.recipeData.length - 1 ? 'none' : 'full'}
					key={index}
					className="item-ingredients-recipe">
					<div>
						<div
							style={{
								marginBottom: '0.25rem',
								display: 'flex',
								alignItems: 'center',
							}}>
							<b>{food.book_text}</b>
							{food.is_primary && (
								<IonButton
									disabled
									size="small"
									style={{
										opacity: 1,
										fontWeight: 'var(--font-size-black)',
										fontSize: '0.75rem',
										marginLeft: '10px',
										letterSpacing: 'normal',
									}}
									className="itemTag"
									color="blue">
									Alimento principale
								</IonButton>
							)}
						</div>
						{props.fromPlan ? (
							<div>{food.best_measure}</div>
						) : (
							!(food.measure.id === 2 && isDayDish) && (
								<div>
									<IonText color="medium">
										{food.quantity}{' '}
										{food.quantity === 1
											? food.measure.name
											: food.measure.plural}{' '}
										{food.food.measure_conversions.map((measure) =>
											measure.measure_id === food.measure.id &&
											!isDayDish &&
											Math.round(measure.grams_value * food.quantity)
												? '(' +
												  Math.round(measure.grams_value * food.quantity) +
												  'g)'
												: undefined
										)}
									</IonText>
								</div>
							)
						)}
					</div>
				</IonItem>
			))}
		</IonList>
	)
}

export default Ingredients
