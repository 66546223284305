import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	type: '',
}

const menuDaySlice = createSlice({
	name: 'menuDay',
	initialState,
	reducers: {
		setDayType: (state, action) => {
			state.type = action.payload.value
		},
	},
})

export const { setDayType } = menuDaySlice.actions
export default menuDaySlice.reducer
