import { IonButton, IonSkeletonText } from '@ionic/react'

export const PaymentSkeleton = () => (
	<>
		<IonSkeletonText
			animated={true}
			style={{
				display: 'inline-block',
				height: '2rem',
				margin: '1rem 5% 0 0 ',
				width: '30%',
			}}
		/>
		<IonSkeletonText
			animated={true}
			style={{
				display: 'inline-block',
				height: '2rem',
				margin: '1rem 5% 0 0 ',
				width: '30%',
			}}
		/>
		<IonSkeletonText
			animated={true}
			style={{
				display: 'inline-block',
				height: '2rem',
				margin: '1rem 0 0 0 ',
				width: '30%',
			}}
		/>
		<IonSkeletonText
			animated={true}
			style={{ height: '2rem', marginTop: '1rem', width: '100%' }}
		/>

		<IonSkeletonText
			animated={true}
			style={{
				display: 'inline-block',
				height: '2rem',
				margin: '1rem 5% 0 0 ',
				width: '47.5%',
			}}
		/>
		<IonSkeletonText
			animated={true}
			style={{
				display: 'inline-block',
				height: '2rem',
				margin: '1rem 0 0 0 ',
				width: '47.5%',
			}}
		/>
		<IonButton
			fill="clear"
			className="ion-no-padding">
			<IonSkeletonText
				animated={true}
				style={{ height: '3rem', marginTop: '1rem', width: '7rem' }}
			/>
		</IonButton>
	</>
)
