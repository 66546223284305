import { useState } from 'react'
import UploadAnImagePage from './UploadAnImagePage'
import WantToInstertAPicturePage from './WantToInstertAPicturePage'
import ImageUploadedPage from './ImageUploadedPage'
import { useSelector } from 'react-redux'
import SelectAnAvatarPage from './SelectAnAvatarPage'

const AvatarPages = props => {
  const avatar = useSelector(state => state.initialConfig.avatar)
  const [pageSelected, setPageSelected] = useState(() => {
    if (!avatar) {
      return 1
    } else {
      if (!avatar.startsWith('avatar')) {
        return 2
      } else {
        return 4
      }
    }
  })

  if (pageSelected === 1) return <WantToInstertAPicturePage {...props} setPageSelected={setPageSelected} />
  if (pageSelected === 2) return <UploadAnImagePage {...props} setPageSelected={setPageSelected} />
  if (pageSelected === 3) return <ImageUploadedPage {...props} setPageSelected={setPageSelected} />
  if (pageSelected === 4) return <SelectAnAvatarPage {...props} setPageSelected={setPageSelected} />
}

export default AvatarPages
