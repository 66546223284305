import {
	IonPage,
	IonContent,
	IonText,
	IonButton,
	IonItem,
	IonCheckbox,
} from '@ionic/react'
import InitialConfigHeader from '../header'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setInitialConfigExtraProperty } from '../../../redux/reducer/initialConfigSlice'
import '../InitialConfiguration.min.css'
import { useHistory } from 'react-router'

const goalsOptions = [
	'Dimagrire facilmente',
	'Mantenermi in salute',
	'Imparare a mangiare',
	'Migliorare le mie analisi',
]

const GoalsPage = ({ step, totalSteps }) => {
	const history = useHistory()
	const [isLoading, setIsLoading] = useState(false)
	const goals = useSelector((state) => state.initialConfig.extras.goals)
	const dispatch = useDispatch()
	const [error, setError] = useState('')

	const updateInput = (e) => {
		if (error !== '') setError('')
		const newGoals = [...goals]
		if (e.target.checked) {
			newGoals.push(e.target.value)
		} else {
			newGoals.splice(newGoals.indexOf(e.target.value), 1)
		}
		dispatch(setInitialConfigExtraProperty({ key: 'goals', value: newGoals }))
	}

	const validateInput = () => {
		let isValid = true
		if (goals.length === 0) {
			setError("Devi selezionare almeno un'opzione per continuare.")
			isValid = false
		} else if (!goals.every((option) => goalsOptions.includes(option))) {
			setError('Almeno una delle opzioni selezionate non è valida.')
			isValid = false
		}

		return isValid
	}

	const handleSubmit = () => {
		setIsLoading(true)
		const isInputValid = validateInput()
		if (!isInputValid) {
			setIsLoading(false)
			return
		}

		history.push(`/impostazioni-iniziali/${step + 1}`)
		setIsLoading(false)
	}
	return (
		<IonPage className="initial-configuration">
			<InitialConfigHeader
				step={step}
				totalSteps={totalSteps}
				isLoading={isLoading}
			/>

			<IonContent>
				<h1>Cosa ti piacerebbe ottenere con Dieta Social?</h1>
				{goalsOptions.map((option, index) => {
					return (
						<IonItem
							className="no-padding-item"
							key={index}>
							<IonCheckbox
								onIonChange={updateInput}
								value={option}
								checked={goals.includes(option)}>
								{option}
							</IonCheckbox>
						</IonItem>
					)
				})}
				<IonText className="error">{error}</IonText>
				<div className="buttons">
					<IonButton
						shape="round"
						onClick={handleSubmit}
						disabled={isLoading}
						color="primary"
						expand="full">
						Avanti
					</IonButton>
				</div>
			</IonContent>
		</IonPage>
	)
}

export default GoalsPage
