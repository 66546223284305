import { createSlice } from '@reduxjs/toolkit'
import {
	getGroupPosts,
	likePost,
	getGroupsList,
	getPost,
	addPost,
	likeComment,
	commentPost,
	getCommentAndReplies,
	replyComment,
} from '../../actions'
import groupsSliceFunctions, {
	replyCommentFunctions,
} from './groupsSliceFunctions'

const {
	createFakeReply,
	replaceFakeReplyforReal,
	deleteFakeReply,
	increaseRepliesCountComment,
	decreaseRepliesCountComment,
} = replyCommentFunctions
const { createFakeComment } = groupsSliceFunctions

export const selectSegmentGroupSlice = (is_fixed) => {
	return is_fixed ? 'fixed' : 'general'
}
export const selectGroupSlice = (from_main_group) => {
	return from_main_group ? 'main' : 'not_main'
}

const builderGroupData = (action) => {
	const is_fixed = action.meta.arg.is_fixed
	const from_main_group = action.meta.arg.from_main_group
	const segmentSelected = selectSegmentGroupSlice(is_fixed)
	const groupSelected = selectGroupSlice(from_main_group)
	return { segmentSelected, groupSelected }
}

const updateLikePostGroup = (state, action) => {
	const { segmentSelected, groupSelected } = builderGroupData(action)

	const postIndex = state.getGroupPosts[groupSelected][
		segmentSelected
	].data?.posts.findIndex((post) => post.id === action.meta.arg.element_id)
	if (postIndex >= 0) {
		const likedOldValue =
			state.getGroupPosts[groupSelected][segmentSelected].data.posts[postIndex]
				.liked
		state.getGroupPosts[groupSelected][segmentSelected].data.posts[
			postIndex
		].liked = !likedOldValue
		state.getGroupPosts[groupSelected][segmentSelected].data.posts[
			postIndex
		].likes_count += likedOldValue ? -1 : 1
	}
}

const updateCommentCountPostGroup = (state, action, value) => {
	const { segmentSelected, groupSelected } = builderGroupData(action)

	const postIndex = state.getGroupPosts[groupSelected][
		segmentSelected
	].data?.posts.findIndex((post) => post.id === action.meta.arg.element_id)
	if (postIndex >= 0) {
		state.getGroupPosts[groupSelected][segmentSelected].data.posts[
			postIndex
		].comments_count += value
	}
}

const updateLikePost = (state, action) => {
	if (!action.meta.arg.only_post) {
		updateLikePostGroup(state, action)
	} else {
		const group_Array = [true, false]

		for (const group_option of group_Array) {
			action.meta.arg.from_main_group = group_option
			updateLikePostGroup(state, action)
		}

		const likedOldValue = state.getPost.data.post_group.liked
		state.getPost.data.post_group.liked = !likedOldValue
		state.getPost.data.post_group.likes_count += likedOldValue ? -1 : 1
	}
}

const updateCommentCountPost = (state, action, value) => {
	const group_Array = [true, false]
	const segment_group_array = [true, false]

	for (const group_option of group_Array) {
		for (const segment_group_option of segment_group_array) {
			action.meta.arg.from_main_group = group_option
			action.meta.arg.is_fixed = segment_group_option
			updateCommentCountPostGroup(state, action, value)
		}
	}
	state.getPost.data.post_group.comments_count += value
}

const increaseCommentCountPost = (state, action) =>
	updateCommentCountPost(state, action, 1)
const decreaseCommentCountPost = (state, action) =>
	updateCommentCountPost(state, action, -1)
const updateLikeComment = (state, action) => {
	const commentId = action.meta.arg.element_id
	const commentIndex = state.getPost.data?.comments?.data?.findIndex(
		(comment) => comment.id === commentId
	)
	if (commentIndex >= 0) {
		const likedOldValue = state.getPost.data?.comments?.data[commentIndex].liked
		state.getPost.data.comments.data[commentIndex].liked = !likedOldValue
		state.getPost.data.comments.data[commentIndex].likes_count += likedOldValue
			? -1
			: 1
	}

	if (state.getCommentAndReplies.data.comment?.id === commentId) {
		const likedOldValue = state.getCommentAndReplies.data.comment.liked
		state.getCommentAndReplies.data.comment.liked = !likedOldValue
		state.getCommentAndReplies.data.comment.likes_count += likedOldValue
			? -1
			: 1
	}
}

const updateLikeReply = (state, action) => {
	const commentId = action.meta.arg.element_id
	const replyIndex = state.getCommentAndReplies.data.replies.data.findIndex(
		(comment) => comment.id === commentId
	)
	if (replyIndex >= 0) {
		const likedOldValue =
			state.getCommentAndReplies.data.replies.data[replyIndex].liked
		state.getCommentAndReplies.data.replies.data[replyIndex].liked =
			!likedOldValue
		state.getCommentAndReplies.data.replies.data[replyIndex].likes_count +=
			likedOldValue ? -1 : 1
	}
}

const initialState = {
	getGroupsList: {
		isLoading: true,
		error: null,
		data: null,
	},
	getGroupPosts: {
		main: {
			header: null,
			fixed: {
				isLoading: false,
				error: null,
				data: null,
			},
			general: {
				isLoading: false,
				error: null,
				data: null,
			},
		},
		not_main: {
			header: null,
			fixed: {
				isLoading: false,
				error: null,
				data: null,
			},
			general: {
				isLoading: false,
				error: null,
				data: null,
			},
		},
	},
	getPost: {
		isLoading: false,
		error: null,
		data: null,
	},
	addPost: {
		isLoading: false,
		error: null,
		data: null,
	},
	getCommentAndReplies: {
		isLoading: false,
		error: null,
		data: {
			comment: null,
			replies: null,
		},
	},
}

const groupsSlice = createSlice({
	name: 'groups',
	initialState,
	reducers: {
		setPost: (state, action) => {
			state.getPost.data = action.payload
		},
		setComment: (state, action) => {
			if (state.getCommentAndReplies.data.comment?.id !== action.payload.id) {
				state.getCommentAndReplies.data.replies = null
			}
			state.getCommentAndReplies.data.comment = action.payload
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getGroupPosts.pending, (state, action) => {
			const { segmentSelected, groupSelected } = builderGroupData(action)

			state.getGroupPosts[groupSelected][segmentSelected].isLoading = true
			state.getGroupPosts[groupSelected][segmentSelected].error = null
		})
		builder.addCase(getGroupPosts.fulfilled, (state, action) => {
			const { segmentSelected, groupSelected } = builderGroupData(action)

			state.getGroupPosts[groupSelected][segmentSelected].isLoading = false
			state.getGroupPosts[groupSelected][segmentSelected].error = null
			state.getGroupPosts[groupSelected][segmentSelected].data = action.payload
			state.getGroupPosts[groupSelected].header = action.payload.group
				.group_header_image
				? action.payload.group.storage_path +
				  action.payload.group.group_header_image
				: null
		})
		builder.addCase(getGroupPosts.rejected, (state, action) => {
			const { segmentSelected, groupSelected } = builderGroupData(action)

			state.getGroupPosts[groupSelected][segmentSelected].isLoading = false
			state.getGroupPosts[groupSelected][segmentSelected].error =
				action.error.message
			const initialStateData =
				initialState.getGroupPosts[groupSelected][segmentSelected].data
			state.getGroupPosts[groupSelected][segmentSelected].data =
				initialStateData
		})

		builder.addCase(likePost.pending, (state, action) => {
			updateLikePost(state, action)
		})
		builder.addCase(likePost.fulfilled, (state, action) => {})
		builder.addCase(likePost.rejected, (state, action) => {
			updateLikePost(state, action)
		})

		builder.addCase(getGroupsList.pending, (state) => {
			state.getGroupsList.isLoading = true
			state.getGroupsList.error = null
		})
		builder.addCase(getGroupsList.fulfilled, (state, action) => {
			state.getGroupsList.isLoading = false
			state.getGroupsList.data = action.payload
		})
		builder.addCase(getGroupsList.rejected, (state, action) => {
			state.getGroupsList.isLoading = false
			state.getGroupsList.error = action.error.message
			state.getGroupsList.data = initialState.getGroupsList.data
		})

		builder.addCase(getPost.pending, (state) => {
			state.getPost.isLoading = true
			state.getPost.error = null
		})
		builder.addCase(getPost.fulfilled, (state, action) => {
			state.getPost.isLoading = false
			state.getPost.data = action.payload
		})
		builder.addCase(getPost.rejected, (state, action) => {
			state.getPost.isLoading = false
			state.getPost.error = action.error.message
			state.getPost.data = initialState.getPost.data
		})

		builder.addCase(likeComment.pending, (state, action) => {
			const is_reply = action.meta.arg.is_reply
			if (is_reply) {
				updateLikeReply(state, action)
			} else {
				updateLikeComment(state, action)
			}
		})
		builder.addCase(likeComment.fulfilled, (state, action) => {})
		builder.addCase(likeComment.rejected, (state, action) => {
			const is_reply = action.meta.arg.is_reply
			if (is_reply) {
				updateLikeReply(state, action)
			} else {
				updateLikeComment(state, action)
			}
		})

		builder.addCase(commentPost.pending, (state, action) => {
			increaseCommentCountPost(state, action)
			createFakeComment(state, action)
		})
		builder.addCase(commentPost.fulfilled, (state, action) => {
			const newCommentIndex = state.getPost.data.comments.data.findIndex(
				(comment) => comment.id === action.meta.arg.id
			)
			state.getPost.data.comments.data[newCommentIndex] = {
				...action.payload.comment,
				is_enabled: true,
				is_checked: false,
				likes_count: 0,
				reports_count: 0,
				replies_count: 0,
			}
		})
		builder.addCase(commentPost.rejected, (state, action) => {
			decreaseCommentCountPost(state, action)
			state.getPost.data.comments.data =
				state.getPost.data.comments.data.filter(
					(comment) => comment.id !== action.meta.arg.id
				)
		})

		builder.addCase(replyComment.pending, (state, action) => {
			increaseRepliesCountComment(state, action)
			createFakeReply(state, action)
		})
		builder.addCase(replyComment.fulfilled, (state, action) => {
			replaceFakeReplyforReal(state, action)
		})
		builder.addCase(replyComment.rejected, (state, action) => {
			decreaseRepliesCountComment(state, action)
			deleteFakeReply(state, action)
		})

		builder.addCase(getCommentAndReplies.pending, (state, action) => {
			state.getCommentAndReplies.isLoading = true
			state.getCommentAndReplies.error = null
		})
		builder.addCase(getCommentAndReplies.fulfilled, (state, action) => {
			state.getCommentAndReplies.isLoading = false
			state.getCommentAndReplies.data = action.payload
		})
		builder.addCase(getCommentAndReplies.rejected, (state, action) => {
			state.getCommentAndReplies.error = action.error.message
			state.getCommentAndReplies.isLoading = false
		})

		builder.addCase(addPost.pending, (state, action) => {
			state.addPost.isLoading = true
			state.addPost.error = null
		})
		builder.addCase(addPost.fulfilled, (state, action) => {
			state.addPost.isLoading = false
			state.addPost.data = action.payload
		})
		builder.addCase(addPost.rejected, (state, action) => {
			state.addPost.error = action.error.message
			state.addPost.isLoading = false
		})
	},
})

export const { setPost, setComment } = groupsSlice.actions
export default groupsSlice.reducer
