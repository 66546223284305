import {
	IonContent,
	IonDatetime,
	IonItem,
	IonPage,
	IonText,
	IonPopover,
} from '@ionic/react'
import MainFooter from '../../../components/mainFooter'
import { useState } from 'react'
import {
	daysOfTheWeek,
	monthsOfTheYear,
	getWeekIndex,
	getDayAsObject,
} from '../../../services/functions'
import MainHeader from '../../../components/headers/mainHeader'
import Recipe from '../../../components/Recipe'
import dayjs from 'dayjs'
import { useLocation } from 'react-router'

const RecipeMenuPage = ({ match }) => {
	const { day, recipeId, food, cycleId } = match.params
	const href = match.url.slice(0, -1 * (recipeId.length + food.length + 2))

	const [date, setDate] = useState(day)
	const dateToShow = getDayAsObject(date)

	return (
		<>
			<IonPage>
				<MainHeader
					page="RecipeMenuPage"
					href={href}
					title="Ricetta"
				/>
				<IonContent>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							height: '100%',
							maxHeight: '100%',
						}}>
						<div>
							<IonItem
								lines="full"
								id="datetime-button1"
								style={{ pointerEvents: 'none' }}>
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										minHeight: '4rem',
										marginTop: '0.5rem',
									}}>
									<div
										style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
										<IonText color="medium capitalize-first-letter">
											{dayjs(date).format('dddd D MMMM YYYY')}
										</IonText>
										<IonText color="medium">
											<pre style={{ margin: 0 }}>{`  /  `}</pre>
										</IonText>
										<IonText color="medium">
											<div>{food}</div>
										</IonText>
										<IonText color="medium">
											<pre style={{ margin: 0 }}>{`  /  `}</pre>
										</IonText>
										<IonText>
											<div> Ricetta</div>
										</IonText>
									</div>
								</div>
							</IonItem>
						</div>
						<Recipe
							recipeId={recipeId}
							cycleId={cycleId}
						/>
					</div>
				</IonContent>

				<MainFooter />
			</IonPage>
			<IonPopover
				trigger="datetime-button1"
				triggerAction="click"
				keepContentsMounted
				arrow="false"
				className="withoutBackdrop"
				size="cover">
				<IonDatetime
					size="cover"
					id="datetime1"
					locale="it-IT"
					displayFormat="YYYY-MM-DD"
					presentation="date"
					value={date}
					onIonChange={(e) => {
						setDate(e.target.value)
					}}
				/>
			</IonPopover>
		</>
	)
}

export default RecipeMenuPage
