module.exports = {
	env: {
		mode: 'prod',
		packageName: 'it.dietasocial.web',
		mandatoryInstall: true,
                freeRoutes: true,
		prod: {
			url: 'https://simon.dietasocial.it',
			node: 'https://api.dietasocial.it:3001',
			app: 'https://app.dietasocial.it',
		},
	},
}

