import { IonText } from '@ionic/react'
import './Comments.min.css'
import { ImageLoader } from '../ImageLoader'
import PostHeader from '../Post/PostHeader'
import PostFooter from '../Post/PostFooter'

const Comment = ({
	comment,
	in_post,
	is_reply = false,
	match,
	classes = '',
}) => {
	const content = comment.content
	const commentImage = comment.image
		? comment.storage_path + comment.image
		: null

	return (
		<div className={classes + (is_reply ? ' ion-padding-start' : '')}>
			<PostHeader comment={comment} />
			<div className="ion-padding comment-content">
				<div>{content}</div>
				{commentImage && (
					<ImageLoader
						image={commentImage}
						css="comment-image"
					/>
				)}
			</div>
			<PostFooter
				comment={comment}
				is_reply={is_reply}
				match={match}
			/>
		</div>
	)
}

export default Comment
