import { createAsyncThunk } from '@reduxjs/toolkit'
import { baseUrl, headersDefault } from '.'
import axios from 'axios'

export const getCourse = createAsyncThunk('course/getCourse', async () => {
	const response = await axios.get(
		`${baseUrl}/api/master-club-courses/0/fetch?_=${new Date().getTime()}`,
		{
			headers: headersDefault(),
		}
	)

	return response.data
})

export const getPills = createAsyncThunk('course/getPills', async () => {
	const response = await axios.get(`${baseUrl}/api/master-club-pills/list`, {
		headers: headersDefault(),
	})

	return response.data
})

export const completePill = createAsyncThunk(
	'course/completePill',
	async ({ post_id, is_completed }) => {
		const response = await fetch(
			`${baseUrl}/api/master-club-pills/${post_id}`,
			{
				body: JSON.stringify({ is_completed }),
				method: 'PATCH',
				headers: headersDefault(),
			}
		)

		if (response.status !== 200)
			throw new Error(
				(response.message || (await response.json()).error) ?? 'GenericError'
			)

		return await response.json()
	}
)

// export const completeLesson = createAsyncThunk(
// 	'course/completeLesson',
// 	async ({ pill_id }) => {
// 		const body = {
// 			pill_id,
// 		}

// 		const response = await axios.post(
// 			`${baseUrl}/api/master-club-pills`,
// 			body,
// 			{
// 				headers: headersDefault(),
// 			}
// 		)

// 		return response.data
// 	}
// )
