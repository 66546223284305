import { IonButton, IonCard, IonSkeletonText } from '@ionic/react'

export const LessonCardSkeleton = () => (
	<IonCard className="ion-padding">
		<IonSkeletonText
			animated={true}
			style={{ width: '3.5rem', height: '18px', marginRight: '1rem' }}
		/>
		<div style={{ marginTop: '1rem', marginBottom: '1rem', display: 'flex' }}>
			<IonSkeletonText
				animated={true}
				style={{ width: '3.5rem', height: '18px', marginRight: '1rem' }}
			/>
			<IonSkeletonText
				animated={true}
				style={{ width: '4rem', height: '18px', marginRight: '1rem' }}
			/>
			<IonSkeletonText
				animated={true}
				style={{ width: '5.5rem', height: '18px' }}
			/>
		</div>
		<IonButton
			style={{
				position: 'absolute',
				bottom: '10px',
				right: '10px',
			}}
			size="small">
			<IonSkeletonText
				animated={true}
				style={{ width: '5.5rem', height: '18px' }}
			/>
		</IonButton>
	</IonCard>
)
