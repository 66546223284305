import React, { useState, useEffect } from 'react'
import {
	IonButton,
	IonContent,
	IonIcon,
	IonInput,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonItem,
	IonList,
	IonPage,
	IonText,
	IonThumbnail,
	IonSegment,
	IonSegmentButton,
	IonLabel,
	IonSkeletonText,
	IonRefresher,
	IonRefresherContent,
	useIonViewDidEnter,
	useIonAlert,
} from '@ionic/react'
import imgError from '../../assets/icons/chef-hat.svg'
import MainFooter from '../../components/mainFooter'
import MainHeader from '../../components/headers/mainHeader'
import { searchOutline } from 'ionicons/icons'
import DividerLine from '../../components/dividerLine'
import config from '../../settings/config'
import { useDispatch, useSelector } from 'react-redux'
import { clearCache, decodeHtmlEntities } from '../../services/functions'
import './Recipe.min.css'
import { NoRecords } from '../../components/NoRecords'
import { useHistory } from 'react-router'
import SubscriptionAlert from '../../components/SubscriptionAlert'
import { ImageLoader } from '../../components/ImageLoader'
import { RecipeSkeleton } from '../../components/skeletons/RecipeSkeleton'

const RecipesPage = () => {
	const dispatch = useDispatch()

	const subscription =
		useSelector((state) => state.user?.master_club_subscriber?.subscription) ??
		null
	const subscriberRoute = useSelector((state) => state.dietPlan)

	const uniqueRecipeIds = new Set() // Set to store unique recipe IDs
	const planRecipes = subscriberRoute?.dietPlan
		?.flatMap((day) =>
			day?.flatMap((meal) =>
				JSON.parse(meal.tables).parent.filter(
					(foodOrRecipe) =>
						foodOrRecipe.source_type === 'recipe' &&
						!uniqueRecipeIds.has(foodOrRecipe.recipe_id) // Check for uniqueness
				)
			)
		)
		.map((recipe) => {
			uniqueRecipeIds.add(recipe.recipe_id) // Add the ID to the set
			return recipe
		})
	planRecipes?.sort((a, b) => a.name.localeCompare(b.name))

	const [filteredRecipes, setFilteredRecipes] = useState([])
	const [searchValue, setSearchValue] = useState('')
	const [start, setStart] = useState(0)
	const history = useHistory()
	const [isInfiniteScrollEnabled, setIsInfiniteScrollEnabled] = useState(true)
	const [segmentSelected, setSegmentSelected] = useState(
		// subscription ? 'week' : 'all'
		// 'week'
		'all'
	)
	const [isOpen, setIsOpen] = useState(false)

	const setNextPage = (id) => {
		history.push(window.location.pathname + '/' + id)
	}

	// const guestFilter = subscription
	// 	? '&portion=4&random=1&app=1'
	// 	: '&portion=4&random=1&app=1'

	const guestFilter = '&app=1'

	const fetchRecipes = async (query = '', newStart = 0) => {
		try {
			const response = await fetch(
				`${
					config.env[config.env.mode].url
				}/api/ricette/list?draw=1&columns[0][data]=name&columns[0][name]=name&columns[0][searchable]=true&columns[0][search][value]=&columns[0][search][regex]=false&start=${newStart}&length=10&search[value]=${query}&search[regex]=false&orderable=false${guestFilter}`,
				{
					method: 'GET',
					headers: {
						Accept: 'application/json',
						'Content-Type': 'application/json',
						Authorization: `Bearer ${localStorage.getItem('adoc_t')}`,
					},
				}
			)

			if (!response.ok) {
				throw new Error('Failed to fetch recipes')
			}

			const json = await response.json()
			return json.data
		} catch (error) {
			// Handle the error appropriately (e.g. show an error message)
			return []
		}
	}

	const loadMoreRecipes = async (event) => {
		const newStart = start + 10
		const newRecipes = await fetchRecipes(searchValue, newStart)

		if (newRecipes.length > 0) {
			setFilteredRecipes((prevRecipes) => [...prevRecipes, ...newRecipes])
			setStart(newStart)
			event.target.complete() // Complete the infinite scroll
		} else {
			setIsInfiniteScrollEnabled(false)
			event.target.disabled = true // Disable infinite scroll when no more recipes
		}
	}

	const [isLoading, setIsLoading] = useState(true)
	const [presentAlert] = useIonAlert()

	// Load initial recipes on page load
	useIonViewDidEnter(() => {
		if (subscription?.subscription_type?.name === 'free') {
			presentAlert({
				header: 'Attenzione',
				message:
					'In questa sezione è possibile cercare tra oltre 10.000 ricette. Attualmente sei iscritto/a al percorso gratuito e le uniche ricette che puoi visualizzare sono quelle relative al menu della settimana di prova, accessibili dalla sezione menu.',
				buttons: [
					{
						text: 'Annulla',
						role: 'cancel',
						handler: () => {},
					},
					{
						text: 'Vai alle tue ricette',
						role: 'confirm',
						handler: () => {
							history.push('/menu')
						},
					},
				],
			})
		}

		setSearchValue('')
		setIsLoading(true) // Set loading to true before fetching data

		// Promise.all([dispatch(getDietPlan(new Date())), fetchRecipes()]).then(
		// 	([dietPlanAction, initialRecipes]) => {
		// 		setFilteredRecipes(initialRecipes)
		// 		setIsLoading(false) // Set loading to false after data is fetched
		// 	}
		// )

		fetchRecipes().then((initialRecipes) => {
			setFilteredRecipes(initialRecipes)
			setIsLoading(false) // Set loading to false after data is fetched
		})
	})

	// Handle search input change
	const handleSearchInputChange = (event) => {
		const newValue = event.target.value
		setSearchValue(newValue)
		setStart(0)
		setIsInfiniteScrollEnabled(true)
		fetchRecipes(newValue).then((newRecipes) => {
			setFilteredRecipes(newRecipes)
		})
	}

	return (
		<>
			<IonPage className="recipe-page">
				<MainHeader
					page="RecipesPage"
					title="Le tue ricette"
				/>

				<IonContent>
					<IonRefresher
						slot="fixed"
						pullFactor={0.5}
						pullMin={100}
						pullMax={200}
						onIonRefresh={clearCache}>
						<IonRefresherContent></IonRefresherContent>
					</IonRefresher>

					<div className="segment-container">
						<IonSegment
							value={segmentSelected}
							onIonChange={(e) => {
								if (e.target.value === 'week' && !subscription) {
									setIsOpen(true)
								} else {
									setSegmentSelected(e.target.value)
								}
							}}>
							{/* {subscription && (
								<IonSegmentButton value="week">
									<IonLabel>
										Settimana del
										{' ' + dayjs().startOf('week').format('D/MM/YYYY')}
									</IonLabel>
								</IonSegmentButton>
							)} */}
							<IonSegmentButton value="all">
								<IonLabel>Il Trova-Ricette</IonLabel>
							</IonSegmentButton>
						</IonSegment>
					</div>
					{isLoading ? (
						<IonList>
							{[...Array(8)].map((_, index) => (
								<RecipeSkeleton key={index} />
							))}
						</IonList>
					) : (
						<>
							{segmentSelected === 'week' && planRecipes && (
								<IonList>
									{planRecipes?.map((recipe, index) => (
										<IonItem
											className="no-padding-item"
											style={{ paddingLeft: '1rem', paddingRight: '1rem' }}
											key={recipe.id + index}
											button
											detail={false}
											onClick={() => setNextPage(recipe.recipe_id)}>
											{/* <IonThumbnail
												slot="start"
												className="radius">
												<img
													src={recipe?.image ?? imgError}
													onError={(e) => {
														e.target.onerror = null
														e.target.src = imgError
													}}
												/>
											</IonThumbnail> */}
											<IonThumbnail
												slot="start"
												className="radius">
												<ImageLoader
													image={
														recipe.image
															? recipe.image.includes('http')
																? recipe.image
																: config.env[config.env.mode].url + recipe.image
															: null
													}
													imageType="recipe"
												/>
											</IonThumbnail>
											<div>
												<IonText>
													<div
														dangerouslySetInnerHTML={{
															__html: recipe.name,
														}}
														className="recipe-text capitalize-first-letter"
													/>
												</IonText>
											</div>
										</IonItem>
									))}
								</IonList>
							)}
							{segmentSelected === 'week' && !planRecipes && <NoRecords />}

							{segmentSelected === 'all' && (
								<>
									<IonList>
										{filteredRecipes.map((recipe, index) => (
											<IonItem
												className="no-padding-item"
												button
												key={recipe.recipe_id + index}
												style={{
													paddingLeft: '1rem',
													paddingRight: '1rem',
													opacity:
														subscription?.subscription_type?.name === 'premium'
															? 1
															: 0.5,
												}}
												onClick={() => {
													if (
														subscription?.subscription_type?.name === 'premium'
													) {
														setNextPage(recipe.recipe_id)
													} else {
														setIsOpen(true)
													}
												}}
												detail={false}>
												<IonThumbnail
													slot="start"
													className="radius">
													{/* //Make image src change on error */}
													<img
														src={recipe.url ?? imgError}
														onError={(e) => {
															e.target.onerror = null
															e.target.src = imgError
														}}
													/>
												</IonThumbnail>
												<div>
													<IonText>
														<div className="recipe-text">
															{decodeHtmlEntities(recipe.name)}
														</div>
													</IonText>
													<IonButton
														size="small"
														style={{
															borderRadius: '0px',
															margin: 0,
															marginRight: '1rem',
															marginBottom: '0.5rem',
															fontSize: '12px',
															height: '18px',
														}}
														className="buttonBoldText"
														color="primary">
														Premium
													</IonButton>
												</div>
											</IonItem>
										))}
									</IonList>
									<IonInfiniteScroll
										threshold="100px"
										disabled={!isInfiniteScrollEnabled}
										onIonInfinite={loadMoreRecipes}>
										<IonInfiniteScrollContent
											loadingSpinner="bubbles"
											loadingText="Caricando altre ricette..."
										/>
									</IonInfiniteScroll>
								</>
							)}
						</>
					)}
				</IonContent>

				{segmentSelected === 'all' && subscription && (
					<div>
						<DividerLine />
						<IonItem lines="none">
							<IonInput
								placeholder="Cerca tra altre 10.000 ricette"
								style={{ minHeight: '3rem' }}
								value={searchValue}
								onIonInput={handleSearchInputChange}>
								<IonIcon
									slot="label"
									style={{ width: '1.5rem', height: '1.5rem' }}
									icon={searchOutline}
								/>
							</IonInput>
						</IonItem>
					</div>
				)}
				<MainFooter />
			</IonPage>
			{/* <RoutesModal
				isOpen={isOpen}
				setIsOpen={setIsOpen}
			/> */}
			<SubscriptionAlert
				isOpen={isOpen}
				setIsOpen={setIsOpen}
			/>
		</>
	)
}

export default RecipesPage
