import {
	IonAvatar,
	IonList,
	IonItem,
	IonText,
	IonIcon,
	IonButton,
	useIonLoading,
	IonToast,
	IonRange,
	useIonAlert,
	IonContent,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonAlert,
	IonGrid,
	IonRow,
	IonCol,
} from '@ionic/react'
import { useDispatch, useSelector } from 'react-redux'

import { call, mail } from 'ionicons/icons'
import defaultHeaderUser from './default-header-user.png'
import avatarDefault from '../../../assets/avatars/avatarDefault.png'
import config from '../../../settings/config'
import { Icon } from '@iconify/react'
import { useEffect, useState } from 'react'
import avatars from '../../../assets/avatars'
import './UserModal.min.css'
import { headersDefault, reportProblem } from '../../../redux/actions'
import { updateUserProperty } from '../../../redux/reducer/userSlice'
import DietButtons from '../../DietButtons'

let headers = {}

const UserModal = ({ history, onDismiss }) => {
	const user = useSelector((state) => state.user?.user)
	const {
		avatar,
		name,
		surname,
		telephone,
		email,
		storage_path,
		associated_users,
	} = user ?? {}

	const [ionLoading, hideIonLoading] = useIonLoading()
	const [presentAlert] = useIonAlert()

	const associated_users_array = associated_users
		? Object.keys(associated_users)
		: []

	const subscriber = useSelector((state) => state.user?.master_club_subscriber)
	const subscription =
		useSelector((state) => state.user?.master_club_subscriber?.subscription) ??
		null
	const subscriber_storage_path =
		useSelector((state) => state.user?.master_club_subscriber?.storage_path) ??
		null

	const subscriber_picture = subscriber_storage_path + subscriber?.image

	const avatarImage = avatar?.startsWith('avatar')
		? avatars.avatarsByNumber[avatar]
		: `${storage_path}${avatar}`

	const maxSizeBites = 8388800

	const dispatch = useDispatch()

	const defaultToast = {
		message: "Ops! C'è stato un errore. Riprova più tardi.",
		open: false,
	}

	const [toast, setToast] = useState(defaultToast)

	const getZoomFromLocalStorage = () => {
		const zoomValue = localStorage.getItem('zoom')
		return zoomValue ? parseInt(zoomValue, 10) : 105 // 105% default
	}

	const setZoomInLocalStorage = (zoom) => {
		localStorage.setItem('zoom', zoom)
	}

	const [zoom, setZoom] = useState(getZoomFromLocalStorage())

	const setToastIsOpen = (message = defaultToast.message) => {
		setToast({
			message,
			open: true,
		})
	}

	useEffect(() => {
		headers = headersDefault()
	}, [])

	const updateInput = (e) => {
		if (e.target.files[0] === undefined || e.target.files.length > 1) return
		if (e.target.files[0].size > maxSizeBites) {
			setToastIsOpen('Immagine troppo grande. Puoi caricare fino 8mb')
			return
		}

		ionLoading({
			message: 'Caricando immagine...',
		})

		const avatar = e.target.files[0]
		const body = new FormData()

		const backUrl = config.env[config.env.mode].url

		body.append('master-club-subscriber', avatar)

		fetch(
			`${
				config.env[config.env.mode].url
			}/api/file-upload/master-club-subscriber`,
			{
				body,
				method: 'POST',
				headers: { Authorization: `Bearer ${localStorage.getItem('adoc_t')}` },
			}
		)
			.then((response) => {
				if (!response.ok) throw new Error('fetch error')
				return response.json()
			})
			.then((data) => {
				const picturePath = data.filename
				const body = JSON.stringify({ image: picturePath })

				fetch(`${backUrl}/api/master-club-subscribers/1 `, {
					method: 'PATCH',
					body,
					headers,
				})
					.then((response) => {
						if (!response.ok) throw new Error('fetch error')
						return response.json()
					})
					.then((data) => {
						dispatch(
							updateUserProperty({
								stateKey: 'master_club_subscriber',
								key: 'image',
								value: picturePath,
							})
						)
						hideIonLoading()
					})
					.catch((e) => {
						setToastIsOpen(true)
						hideIonLoading()
					})
			})
			.catch((e) => {
				setToastIsOpen(true)
				hideIonLoading()
			})
	}

	const handleEmailPlan = (date, setToastIsOpen) => {
		ionLoading({
			message: 'Inviando email...',
		})

		const body = JSON.stringify({ date })
		const backUrl = config.env[config.env.mode].url
		fetch(`${backUrl}/api/master-club-email-health-book`, {
			method: 'POST',
			body,
			headers,
		})
			.then((response) => {
				if (!response.ok) throw new Error('fetch error')
				return response.json()
			})
			.then((data) => {
				hideIonLoading()
				setToastIsOpen(
					"Ti abbiamo inviato una email con il pdf all'indirizzo: " +
						email +
						'. Se non dovessi trovarla guarda nella cartelle Spam o Posta indesiderata'
				)
			})
			.catch((e) => {
				hideIonLoading()
				setToastIsOpen('Errore')
				console.log(e)
			})
	}

	const handleReportProblem = (e) => {
		const message = e.message
		dispatch(reportProblem({ message }))
			.then((result) => {
				if (result.error) {
					setToastIsOpen('Errore durante l’invio')
				} else {
					setToastIsOpen(
						'Email inviata. Ti contatteremo al più presto. Grazie!'
					)
					document.getElementById('report-problem-message').value = ''
				}
				hideIonLoading()
			})
			.catch((error) => {
				console.error('Error fetching:', error)
				setToastIsOpen('Errore durante l’invio')
				hideIonLoading()
			})
	}

	return (
		<>
			<style>
				{`
					body{
						-webkit-text-size-adjust: ${zoom}%;
						// zoom: ${zoom}%;
					}
				`}
			</style>
			<IonContent>
				<IonToolbar>
					<IonTitle>Profilo</IonTitle>
					<IonButtons
						slot="end"
						className="ion-margin-horizontal">
						<IonButton onClick={onDismiss}>Chiudi</IonButton>
					</IonButtons>
				</IonToolbar>
				<div className="profile-picture">
					<img
						onError={({ currentTarget }) => {
							currentTarget.onerror = null
							currentTarget.src = defaultHeaderUser
						}}
						src={subscriber?.image ? subscriber_picture : defaultHeaderUser}
					/>
					<IonButton
						size="small"
						shape="round"
						color="light"
						onClick={() =>
							document.getElementById('profile-picture-input').click()
						}>
						Aggiorna copertina
					</IonButton>
					<input
						id="profile-picture-input"
						onChange={updateInput}
						accept="image/png,image/jpeg"
						type="file"
						className="d-false"
					/>
				</div>

				<IonList style={{ paddingBottom: '0px', overflowY: 'auto' }}>
					<IonItem detail={false}>
						<div
							slot="start"
							style={{ position: 'relative', marginBottom: '10px' }}>
							<IonAvatar style={{ width: '40px', height: '40px' }}>
								<img
									onError={({ currentTarget }) => {
										currentTarget.onerror = null
										currentTarget.src = avatarDefault
									}}
									src={avatar ? avatarImage : avatarDefault}
									alt="Foto di profilo"
								/>
							</IonAvatar>
							<IonButton
								size="small"
								className="buttonBoldText profile-avatar-label"
								color={subscription?.subscription_type?.color ?? 'light'}>
								{(
									subscription?.subscription_type?.name ?? 'Guest'
								).toUpperCase()}
							</IonButton>
						</div>

						<div>
							<IonText>
								<div className="value">{name + ' ' + surname}</div>
							</IonText>
							<IonText color="medium">
								<div className="label">Tuo nome</div>
							</IonText>
						</div>
					</IonItem>
					<IonItem detail={false}>
						<div
							slot="start"
							className="icon">
							<IonIcon
								size="large"
								icon={call}
								color="light-gray"
							/>
						</div>

						<div>
							<IonText>
								<div className="value">
									{telephone ?? (
										<IonButton
											size="small"
											shape="round"
											color="light"
											onClick={() => {
												onDismiss()
												history.push('/profilo/dati-personali')
											}}
											style={{
												margin: 0,
												fontSize: '12px',
												height: '18px',
											}}>
											<span
												style={{
													margin: '0 5px',
												}}>
												Aggiungi
											</span>
										</IonButton>
									)}
								</div>
							</IonText>
							<IonText color="medium">
								<div className="label">Cellulare</div>
							</IonText>
						</div>
					</IonItem>
					<IonItem detail={false}>
						<div
							slot="start"
							className="icon">
							<IonIcon
								size="large"
								icon={mail}
								color="light-gray"
							/>
						</div>

						<div>
							<IonText>
								<div className="value">{email}</div>
							</IonText>
							<IonText color="medium">
								<div className="label">Email principale</div>
							</IonText>
							{associated_users_array.length > 0 && (
								<>
									<IonText>
										{associated_users_array.map((id) => {
											const email = associated_users[id]
											return (
												<div
													className="value"
													key={id}>
													{email}
												</div>
											)
										})}
									</IonText>
									<IonText color="medium">
										<div className="label">
											Email secondari
											{associated_users_array.length === 1 && 'o'}
										</div>
									</IonText>
								</>
							)}
						</div>
					</IonItem>
					<IonItem>
						<div
							slot="start"
							className="icon">
							<Icon
								icon="foundation:zoom-in"
								style={{
									color: 'var(--ion-color-light-gray)',
									width: '34px',
									height: '34px',
								}}
							/>
						</div>
						<div style={{ width: '100%', padding: 0 }}>
							<IonRange
								style={{
									padding: '5px 0 0 0',
								}}
								aria-label="Range with pin"
								pin={true}
								ticks={true}
								snaps={true}
								min={100}
								max={110}
								step={5}
								value={zoom}
								onIonChange={({ detail }) => {
									setZoomInLocalStorage(detail.value)
									setZoom(detail.value)
									document.body.style.zoom = `${detail.value}%`
								}}
								pinFormatter={(value) => `${value}%`}></IonRange>
							<IonText
								color="medium"
								style={{ marginTop: '-10px', display: 'block' }}>
								<div className="label">Zoom</div>
							</IonText>
						</div>
					</IonItem>

					<IonItem>
						<div
							slot="start"
							className="icon">
							<Icon
								icon="pajamas:preferences"
								style={{
									color: 'var(--ion-color-light-gray)',
									width: '34px',
									height: '34px',
								}}
							/>
						</div>
						<div style={{ width: '100%', padding: 0 }}>
							<DietButtons profile={true} />
						</div>
					</IonItem>
					<IonButton
						className="profile-modal-button"
						onClick={() => {
							onDismiss()
							history.push('/profilo/dati-personali')
						}}
						shape="round"
						expand="block"
						color="light">
						<Icon
							icon="mdi:user-edit"
							width={27}
							height={27}
						/>
						MODIFICA PROFILO
					</IonButton>
					<IonButton
						className="profile-modal-button"
						onClick={() => {
							onDismiss()
							history.push('/imposta-notifiche')
						}}
						shape="round"
						expand="block"
						color="light">
						<Icon
							icon="material-symbols:edit-notifications"
							width={27}
							height={27}
						/>
						IMPOSTA NOTIFICHE
					</IonButton>
					<IonButton
						// fill="outline"
						className="profile-modal-button"
						shape="round"
						expand="block"
						size="small"
						color="light-danger"
						id="present-alert">
						<Icon
							icon="fluent-mdl2:alert-solid"
							width={27}
							height={27}
							className="ion-margin-end"
						/>
						Segnala un problema <br />o contatta il team
					</IonButton>
					<IonAlert
						trigger="present-alert"
						header="Contatta il team"
						className="report-problem-alert"
						buttons={[
							{
								text: 'Annulla',
								role: 'cancel',
							},
							{
								text: 'Invia',
								role: 'confirm',
								handler: (e) => {
									handleReportProblem(e)
								},
							},
						]}
						inputs={[
							{
								name: 'message',
								id: 'report-problem-message',
								type: 'textarea',
								placeholder: 'Come possiamo aiutarti?',
							},
						]}></IonAlert>
					{subscriber && subscriber?.health_books?.length > 0 && (
						<>
							<h4
								style={{
									textAlign: 'center',
									marginBottom: 0,
									paddingTop: '20px',
								}}>
								Magazine
							</h4>
							<IonGrid
								style={{
									marginTop: 0,
								}}>
								{subscriber.health_books.map((health_book, i) => (
									<IonRow key={i}>
										<IonCol>
											<IonButton
												style={{
													marginTop: 0,
												}}
												fill="outline"
												onClick={() => {
													const link = document.createElement('a')
													link.href = health_book.url
													link.target = '_blank'
													link.download =
														'Health Book ' + health_book.name + '.pdf'
													document.body.appendChild(link)
													link.click()
													document.body.removeChild(link)
												}}
												shape="round"
												expand="block"
												color="gray">
												<Icon
													icon="ic:round-download"
													width={27}
													height={27}
												/>
												Scarica {health_book.name}
											</IonButton>
										</IonCol>
										<IonCol>
											<IonButton
												style={{
													marginTop: 0,
												}}
												fill="outline"
												onClick={() => {
													handleEmailPlan(
														health_book.start_date,
														setToastIsOpen
													)
												}}
												shape="round"
												expand="block"
												color="gray">
												<Icon
													icon="mdi:email"
													width={24}
													height={24}
												/>
												Email {health_book.name}
											</IonButton>
										</IonCol>
									</IonRow>
								))}
							</IonGrid>
						</>
					)}

					<div className="flex-center">
						<IonButton
							fill="outline"
							id="logout-button"
							onClick={() =>
								presentAlert({
									header: 'Chiudere la sessione',
									message: 'Confermi che vuoi uscire?',
									buttons: [
										{
											text: 'Cancella',
											role: 'cancel',
											handler: () => {},
										},
										{
											text: 'Si, confermo',
											role: 'confirm',
											handler: () => {
												onDismiss()
												history.replace('/logout')
											},
										},
									],
								})
							}
							shape="round"
							color="danger">
							<span>
								<Icon
									width="24"
									icon="el:off"
								/>
								<div>Esci</div>
							</span>
						</IonButton>
					</div>
				</IonList>
			</IonContent>
			<IonToast
				isOpen={toast.open}
				message={toast.message}
				duration={3000}
			/>
		</>
	)
}
export default UserModal
