import dietaSocialLogo from '../../assets/images/ds-logo.svg'
import dietaSocialLogoSandbox from '../../assets/images/ds-logo-sandbox.svg'
import {
	IonButton,
	useIonLoading,
	useIonToast,
	IonToggle,
	IonList,
	IonItem,
	IonModal,
	IonContent,
} from '@ionic/react'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import config from '../../settings/config'
import { useState } from 'react'

const CheckoutForm = ({
	successUrl,
	productPriceFormatted,
	setupOrPayment,
}) => {
	const stripe = useStripe()
	const elements = useElements()
	const [ionToast] = useIonToast()
	const [ionLoading, hideIonLoading] = useIonLoading()
	const [terms, setTerms] = useState(false)
	const [openTerms, setOpenTerms] = useState(false)
	const logo =
		config.env.mode !== 'prod' ? dietaSocialLogoSandbox : dietaSocialLogo

	const handleSubmit = async (event) => {
		ionLoading()
		// We don't want to let default form submission happen here,
		// which would refresh the page.
		event.preventDefault()
		if (!stripe || !elements) {
			// Stripe.js hasn't yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			hideIonLoading()
			return
		}

		const params = {
			//`Elements` instance that was used to create the Payment Element
			elements,
			confirmParams: {
				return_url:
					config.env[config.env.mode].app + location.pathname + '/processando',
			},
		}

		const result =
			setupOrPayment === 'payment'
				? await stripe.confirmPayment(params)
				: await stripe.confirmSetup(params)

		hideIonLoading()

		if (result.error) {
			// Show error to your customer (for example, payment details incomplete)
			console.log(result.error.message)
			ionToast({
				message: 'Errore processando metodo di pagamento',
				duration: 2000,
			})
		}
	}

	return (
		<form onSubmit={handleSubmit}>
			<p
				style={{
					textAlign: 'center',
				}}>
				<img
					style={{
						width: '100%',
						maxWidth: '300px',
						margin: '1rem 0 2rem 0',
					}}
					src={logo}
					alt="Dieta Social Logo"
				/>
			</p>
			<PaymentElement
				options={{
					terms: {
						card: 'never',
						sepaDebit: 'never',
						paypal: 'never',
						applePay: 'never',
						googlePay: 'never',
					},
				}}
			/>
			<IonToggle
				labelPlacement="end"
				className="ion-margin-vertical"
				checked={terms}
				onIonChange={() => setTerms(!terms)}></IonToggle>
			<span
				style={{ fontWeight: 'normal' }}
				onClick={() => setOpenTerms(true)}>
				Accetto <u>termini e condizioni</u>
			</span>
			<IonButton
				className="ion-margin-vertical"
				type="submit"
				size="large"
				expand="block"
				disabled={!stripe || !terms}>
				Paga {productPriceFormatted}
			</IonButton>
			<IonModal
				className="priority-modal"
				isOpen={openTerms}
				onDidDismiss={() => setOpenTerms(false)}>
				<IonContent>
					<div className="ion-padding">
						<h4>CONDIZIONI GENERALI DI VENDITA</h4>
						<p>
							Le presenti condizioni generali di vendita disciplinano la vendita
							dell’abbonamento alla testata telematica Dieta Social (di seguito
							l’{'Abbonamento'}) tramite la web app o il sito internet
							dietasocial.it (di seguito il “Sito”).
						</p>
						<p>
							<b>1. CONDIZIONE PER CONTRARRE</b>
						</p>

						<p>
							Per poter concludere validamente l’acquisto l’acquirente deve
							preventivamente registrarsi al Sito. A seguire, dovrà selezionare
							la tipologia di abbonamento che intende acquistare. Una volta
							selezionato l’abbonamento che intende acquistare, l’acquirente, al
							fine di poter inoltrare l’ordine, dovrà effettuare il pagamento
							secondo la modalità scelta.
						</p>
						<p>
							<b>2. MODALITA’ DI PAGAMENTO</b>
						</p>
						<p>
							All’interno del modulo d’ordine l’acquirente inserisce i dati del
							mezzo di pagamento, il nominativo del titolare, ed ogni altra
							informazione necessaria al perfezionamento della transazione
							commerciale.
						</p>
						<p>
							Nel caso in cui sia stato effettuato l’abbonamento con rinnovo
							automatico, alla scadenza del periodo di abbonamento, sulla carta
							di credito utilizzata in fase di pagamento dell’abbonamento, viene
							addebitato il nuovo importo, salvo disattivazione della modalità
							di rinnovo automatico, che può essere effettuata in qualsiasi
							momento dalla propria area riservata (sezione “profilo” e
							“bacheca”) cliccando semplicemente sull’apposto pulsante.
						</p>
						<p>
							Nel caso in cui, per una ragione qualunque, l’addebito
							dell’importo dovuto al Venditore si riveli impossibile, il
							processo di vendita viene automaticamente annullato e la vendita
							automaticamente risolta.
						</p>
						<p>
							I prezzi di vendita sono espressi in euro e si intendono Iva
							assolta dall’editore.
						</p>
						<p>
							<b>
								3. DIRITTO DI RECESSO E DISATTIVAZIONE ABBONAMENTO RICORRENTE
							</b>
						</p>
						<p>
							Chi acquista in qualità di “consumatore” ha il diritto di recedere
							dall’abbonamento entro 14 giorni dall’ordine mediante
							comunicazione scritta da inviarsi al seguente indirizzo e-mail:
							abbonamenti@dietasocial.it. Nel caso di recesso entro il predetto
							termine, il Venditore provvederà a rimborsare all’acquirente
							l’importo dal medesimo corrisposto.
						</p>
						<p>
							La garanzia di 14 giorni non è valida nel caso di rinnovo
							automatico dell’abbonamento. In caso di abbonamento ricorrente, è
							possibile disattivare il rinnovo automatico accedendo alla Bacheca
							dell’Area Riservata dell’abbonato e cliccando sull’apposito tasto
							di disattivazione.
						</p>
						<p>
							<b>4. TUTELA DEI MINORI</b>
						</p>
						<p>
							Il Venditore non è responsabile per la fornitura dell’abbonamento
							a minori.
						</p>
						<p>
							La responsabilità all’accesso nel Sito ed alla consultazione da
							parte di un minore è del tutore, di chi esercita la patria potestà
							o comunque di chi controlla l’accesso ad internet del minore,
							essendo il Sito raggiungibile solo telematicamente.
						</p>
						<p>
							<b>5. COPYRIGHT</b>
						</p>
						<p>
							Il Sito ed i loro contenuti, ivi inclusi quelli pubblicati nei
							gruppi/pagine social, quali marchi, logo, immagini, testi, video,
							ecc., sono nella esclusiva proprietà/disponibilità della società
							Media Share S.r.l. Start up con sede in Rende (CS), via Marco Polo
							55, P.IVA 03665450783, titolare dei diritti di sfruttamento del
							marchio Dieta Social e proprietaria del dominio dietasocial.it.
							Essi sono protetti dalle leggi italiane e internazionali sul
							diritto di autore.
						</p>
						<p>
							Tutti i contenuti relativi all’Abbonamento, su qualsiasi mezzo
							essi siano pubblicati (gruppi Facebook, altri social network,
							applicazioni mobile, testate telematiche, ecc.), sono ad uso
							esclusivamente personale. Pertanto, con riferimento ai predetti
							contenuti, è assolutamente vietata la copia, la trasmissione, la
							condivisione, l’inoltro, la riproduzione anche parziale, lo
							sfruttamento non autorizzato e qualsiasi altro utilizzo improprio.
							Ogni violazione sarà perseguibile in termini di legge. In caso di
							violazione, il Venditore si riserva il diritto di interrompere
							senza preavviso l’Abbonamento nonché di agire, sia in sede civile
							che penale, nei confronti dell’autore della violazione per la
							tutela dei propri interessi.
						</p>
						<p>Dieta Social è un marchio registrato.</p>
						<p>
							<b>6. LIMITAZIONE DI RESPONSABILITA’</b>
						</p>
						<p>
							Il Venditore declina qualsiasi responsabilità per eventuali
							inesattezze presenti nei contenuti relativi all’Abbonamento nonché
							nel caso di impossibilità di erogare l’Abbonamento per causa ad
							essa non imputabile. Il Venditore si riserva la facoltà di
							sospendere, in ogni momento e senza alcun preavviso, l’erogazione
							dell’abbonamento.
						</p>
						<p>
							<b>7. LEGGE APPLICABILE E FORO COMPETENTE</b>
						</p>
						<p>
							Le presenti Condizioni Generali di Vendita sono regolate e devono
							essere interpretate secondo la legge italiana. Ogni controversia
							relativa all’applicazione, all’esecuzione, all’interpretazione del
							contratto di abbonamento stipulato on line tramite il Sito è
							sottoposta alla giurisdizione italiana. Il Foro competente è
							quello della sede dell’editore.
						</p>
						<p>
							<b>8. MODIFICHE ALLE CONDIZIONI GENERALI DI VENDITA</b>
						</p>
						<p>
							Il Venditore si riserva il diritto di modificare il Sito e le
							presenti Condizioni Generali di Vendita in qualsiasi momento per
							offrire nuovi prodotti o servizi, ovvero per conformarsi a
							disposizioni di legge o regolamentari. L’acquirente sarà soggetto
							alle politiche e ai termini delle Condizioni Generali di Vendita
							di volta in volta vigenti al momento dell’acquisto. Qualora una
							qualsiasi previsione delle presenti condizioni fosse ritenuta
							invalida, nulla o per qualunque motivo inapplicabile, tale
							condizione non pregiudicherà comunque la validità e l’efficacia
							delle altre previsioni.
						</p>
						<p>
							<b>9. RINUNCIA</b>
						</p>
						<p>
							Il mancato esercizio da parte del Venditore del diritto di agire
							nei confronti dell’acquirente, non rappresenta una rinuncia ad
							agire per la violazione degli impegni assunti dall’acquirente con
							le presenti Condizioni Generali di Vendita.
						</p>
					</div>
				</IonContent>
			</IonModal>
		</form>
	)
}

export default CheckoutForm
