import {
	IonContent,
	IonPage,
	IonRefresher,
	IonRefresherContent,
} from '@ionic/react'

import MainFooter from '../../../components/mainFooter'
import MainHeader from '../../../components/headers/mainHeader'
import Group from '../../../components/Group'
import { useSelector } from 'react-redux'
import { clearCache, getGroupParams } from '../../../services/functions'
import { useRef } from 'react'

const GroupPage = ({ match }) => {
	const group_id = match.params.groupId
	const scrollRef = useRef(null)

	const { name, allow_subscriber_post } = useSelector((state) =>
		getGroupParams(state, group_id)
	)

	return (
		<>
			<IonPage className="group-page">
				<MainHeader
					href="/gruppi"
					title={name}
					page="GroupPage"
				/>
				<IonContent
					scrollEvents={true}
					// onIonScroll={handleScroll}
					ref={scrollRef}>
					<IonRefresher
						slot="fixed"
						pullFactor={0.5}
						pullMin={100}
						pullMax={200}
						onIonRefresh={clearCache}>
						<IonRefresherContent></IonRefresherContent>
					</IonRefresher>
					<Group group_id={group_id} />
				</IonContent>
				<MainFooter />
			</IonPage>
		</>
	)
}

export default GroupPage
