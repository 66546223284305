import {
	IonButton,
	IonContent,
	IonPage,
	IonRefresher,
	IonRefresherContent,
	useIonViewDidEnter,
} from '@ionic/react'
import './Home.css'

import MainFooter from '../../components/mainFooter'
import WellnessIndex from '../../components/modals/WellnessIndex'
import MainHeader from '../../components/headers/mainHeader'
import Group from '../../components/Group'
import { useDispatch, useSelector } from 'react-redux'
import { clearCache } from '../../services/functions'
import { useHistory, useLocation } from 'react-router'
import ActivateNotifications from '../../components/modals/NotificationsModal/ActivateNotifications'
import { checkPushSubscription } from '../../serviceWorkerRegistration'
import { useEffect } from 'react'
import { getClearData, getUserInfo } from '../../redux/actions'
import dayjs from 'dayjs'

const getTitle = (state) => {
	const main = state.groups.getGroupPosts.main
	const { fixed: main_fixed, general: main_general } = main

	return main_fixed?.data?.group?.name ?? main_general?.data?.group?.name ?? ''
}

const HomePage = () => {
	const title = useSelector(getTitle)
	const location = useLocation()
	const dispatch = useDispatch()
	const history = useHistory()

	const now = dayjs().format('YYYY-MM-DD HH:mm:ss')
	// console.log(
	// 	dayjs(localStorage.getItem('adoc_last_update')).format(
	// 		'YYYY-MM-DD HH:mm:ss'
	// 	)
	// )
	// console.log(dayjs().subtract(10, 'minutes').format('YYYY-MM-DD HH:mm:ss'))
	// console.log(
	// 	dayjs(localStorage.getItem('adoc_last_update')) <
	// 		dayjs().subtract(10, 'minutes')
	// )

	useIonViewDidEnter(() => {
		if (
			!localStorage.getItem('adoc_last_update') ||
			dayjs(localStorage.getItem('adoc_last_update')) <
				dayjs().subtract(5, 'minutes')
		) {
			dispatch(getClearData(history))
			dispatch(getUserInfo())
			localStorage.setItem('adoc_last_update', now)
		}
	})

	useEffect(() => {
		if (location.state?.login) {
			checkPushSubscription()
		}
	}, [])

	return (
		<IonPage>
			<MainHeader
				title={title}
				page="HomePage"
			/>

			<IonContent>
				<IonRefresher
					slot="fixed"
					pullFactor={0.5}
					pullMin={100}
					pullMax={200}
					onIonRefresh={clearCache}>
					<IonRefresherContent></IonRefresherContent>
				</IonRefresher>
				{/* {!location.state?.firstLogin && <WellnessIndex />} */}
				<Group from_main_group={true} />
			</IonContent>

			<MainFooter />
		</IonPage>
	)
}

export default HomePage
