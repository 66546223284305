import { IonPage, IonContent, IonText, IonInput, IonButton } from '@ionic/react'
import InitialConfigHeader from '../header'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setInitialConfigProperty } from '../../../redux/reducer/initialConfigSlice'
import { isValidName } from '../../../services/functions'
import { useHistory } from 'react-router'

const NamePage = ({ step, totalSteps }) => {
	const history = useHistory()

	const [isLoading, setIsLoading] = useState(false)
	const name = useSelector((state) => state.initialConfig.name)
	const surname = useSelector((state) => state.initialConfig.surname)
	const dispatch = useDispatch()
	const [error, setError] = useState({ name: '', surname: '' })

	const updateInput = (e) => {
		if (error[e.target.name] !== '')
			setError((oldError) => {
				const newError = { ...oldError }
				newError[e.target.name] = ''
				return newError
			})
		dispatch(
			setInitialConfigProperty({ key: e.target.name, value: e.target.value })
		)
	}

	const validateInputs = () => {
		let isValid = true

		if (name === '') {
			setError((oldError) => {
				const newError = { ...oldError }
				newError.name = 'É necessario inserire un nome.'
				return newError
			})
			isValid = false
		} else if (!isValidName(name)) {
			setError((oldError) => {
				const newError = { ...oldError }
				newError.name = 'Il nome inserito non è valido.'
				return newError
			})
			isValid = false
		}

		if (surname === '') {
			setError((oldError) => {
				const newError = { ...oldError }
				newError.surname = 'É necessario inserire un cognome.'
				return newError
			})
			isValid = false
		} else if (!isValidName(surname)) {
			setError((oldError) => {
				const newError = { ...oldError }
				newError.surname = 'Il cognome inserito non è valido.'
				return newError
			})
			isValid = false
		}

		return isValid
	}

	const handleSubmit = () => {
		setIsLoading(true)
		const areInputsValid = validateInputs()
		if (!areInputsValid) {
			setIsLoading(false)
			return
		}

		history.push(`/impostazioni-iniziali/${step + 1}`)
		setIsLoading(false)
	}
	return (
		<IonPage className="initial-configuration">
			<InitialConfigHeader
				step={step}
				totalSteps={totalSteps}
				isLoading={isLoading}
			/>

			<IonContent>
				<h1>Inserisci qui il tuo nome</h1>
				<IonInput
					autoFocus={true}
					type="text"
					label="Nome"
					name="name"
					fill="solid"
					color="primary"
					labelPlacement="stacked"
					value={name}
					onInput={updateInput}
				/>
				<IonText className="error">{error.name}</IonText>
				<IonInput
					label="Cognome"
					type="text"
					fill="solid"
					name="surname"
					color="primary"
					labelPlacement="stacked"
					value={surname}
					onInput={updateInput}
				/>
				<IonText className="error">{error.surname}</IonText>
				<div className="buttons">
					<IonButton
						shape="round"
						onClick={handleSubmit}
						disabled={isLoading}
						color="primary"
						expand="full">
						Avanti
					</IonButton>
				</div>
			</IonContent>
		</IonPage>
	)
}

export default NamePage
