import env from 'react-dotenv'
import Echo from 'laravel-echo'

window.Pusher = require('pusher-js')

export function createSocketConnection() {
	window.Echo = new Echo({
		broadcaster: 'pusher',
		key: env.PUSHER_APP_KEY,
		cluster: env.PUSHER_APP_CLUSTER,
		wsHost: env.PUSHER_APP_HOST,
		wsPort: env.PUSHER_APP_PORT,
		wsPath: env.PUSHER_APP_PATH,
		forceTLS: JSON.parse(env.PUSHER_APP_FORCE_TLS),
		disableStats: true,
	})
}
