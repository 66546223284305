import {
	IonContent,
	IonPage,
	IonRefresher,
	IonRefresherContent,
} from '@ionic/react'
import MainFooter from '../../components/mainFooter'
import MainHeader from '../../components/headers/mainHeader'
import FreeRoutes from '../../components/FreeRoutes'
import { Icon } from '@iconify/react'
import DietButtons from '../../components/DietButtons'
import { clearCache } from '../../services/functions'

const StartFreeRoutePage = () => {
	return (
		<IonPage>
			<MainHeader
				page="StartFreeRoute"
				title="Percorso gratuito"
			/>
			<IonContent className="ion-padding">
				<IonRefresher
					slot="fixed"
					pullFactor={0.5}
					pullMin={100}
					pullMax={200}
					onIonRefresh={clearCache}>
					<IonRefresherContent></IonRefresherContent>
				</IonRefresher>
				<div className="ion-padding-top ion-padding-start ion-padding-end">
					<h3>Percorso di prova gratuito:</h3>
					<ul className="checks">
						<li>
							<Icon icon="mdi:check-bold" /> Durata: 7 giorni
						</li>
						<li>
							<Icon icon="mdi:check-bold" /> Menù dimagranti giornalieri
						</li>
						<li>
							<Icon icon="mdi:check-bold" /> Ricette
						</li>
						<li>
							<Icon icon="mdi:check-bold" /> Video educativi di cucina sana,
							fitness e scienza dell’alimentazione
						</li>
						<li>
							<Icon icon="mdi:check-bold" />
							Community di supporto
						</li>
						<li>
							<Icon icon="mdi:check-bold" /> Coach motivazionale
						</li>
						<li>
							<Icon icon="mdi:check-bold" /> Monitoraggio progressi
						</li>
						<li>
							<Icon icon="mdi:check-bold" /> Senza vincoli, no carta di credito,
							continui solo se vuoi
						</li>
					</ul>
				</div>

				<DietButtons />
				<FreeRoutes isModal={false} />
			</IonContent>
			<MainFooter />
		</IonPage>
	)
}

export default StartFreeRoutePage
