import './Post.min.css'
import { PostMultimedia } from '../MultimediaInPost'
import PostHeader from './PostHeader'
import PostFooter from './PostFooter'
import PostContent from './PostContent'

const Post = ({
	postGroup,
	is_preview = false,
	from_main_group,
	only_post,
}) => {
	const is_enabled = postGroup?.status?.name === 'Pubblicato'
	return (
		<div className={'post-item enabled-' + is_enabled}>
			<PostHeader postGroup={postGroup} />
			<PostContent
				postGroup={postGroup}
				is_preview={is_preview}
				from_main_group={from_main_group}
			/>
			<PostMultimedia
				postGroup={postGroup}
				only_post={only_post}
			/>
			<PostFooter
				postGroup={postGroup}
				is_preview={is_preview}
				from_main_group={from_main_group}
				only_post={only_post}
			/>
		</div>
	)
}

export default Post
