import {
	IonButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonModal,
	IonTitle,
	IonToolbar,
} from '@ionic/react'
import { decodeHtmlEntities } from '../../services/functions'

export default function FoodPropertiesModal({
	showModal,
	setShowModal,
	foodProperties,
}) {
	return (
		<IonModal isOpen={showModal}>
			<IonHeader>
				<IonToolbar>
					<IonTitle>{decodeHtmlEntities(foodProperties?.name)}</IonTitle>
					<IonButtons slot="end">
						<IonButton onClick={() => setShowModal(false)}>Chiudi</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent className="ion-padding modal-food-nutritional-properties">
				<p className="ion-text-center">
					<img
						src={foodProperties?.image_url}
						onError={(e) => {
							e.target.style.display = 'none'
						}}
					/>
				</p>
				<div>{foodProperties?.description}</div>
			</IonContent>
		</IonModal>
	)
}
